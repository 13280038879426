import React from 'react';
import { gql, useFragment } from '@apollo/client';

import AppraisalCTA from './components/appraisal-cta';
import { AppraisalCtaFragment } from '../../generated/graphql';

type AppraisalCTAProps = {
  contactId: number;
  agentIdV2: string;
  agencyId: number;
  url?: string;
  onClick?: () => void;
};

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'AppraisalCTA';
const FRAGMENT = gql`
  fragment AppraisalCTA on Contact {
    id
    agencyId
    agentIdV2
    name
    profilePhoto {
      url
    }
  }
`;

function AppraisalCTAWithFragment({
  contactId,
  agencyId,
  agentIdV2,
  url,
  onClick,
}: AppraisalCTAProps): JSX.Element | null {
  const { complete, data: contact } = useFragment<
    AppraisalCtaFragment,
    unknown
  >({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: AppraisalCTAWithFragment.fragment,
    fragmentName: AppraisalCTAWithFragment.fragmentName,
  });

  if (!complete || !contact) {
    return null;
  }

  return (
    <AppraisalCTA
      contactId={contactId}
      name={contact?.name || undefined}
      thumbnail={contact?.profilePhoto?.url}
      url={url}
      onClick={onClick}
    />
  );
}

AppraisalCTAWithFragment.fragment = FRAGMENT;
AppraisalCTAWithFragment.fragmentName = FRAGMENT_NAME;

export default AppraisalCTAWithFragment;
