import { Option, LocationArray, AgencyArray, ContactArray } from '../types';

enum categories {
  locations = 'Suburb',
  agencies = 'Agency',
  agents = 'Agent',
}

export const buildLocations = (locationArray: LocationArray): Option[] =>
  locationArray.map((location) => ({
    category: categories.locations,
    label: location.displayName,
    value: location.nameSlug,
    url: undefined,
    redirectToURL: false,
    avatar: undefined,
  }));

export const buildAgencies = (agencyArray: AgencyArray): Option[] =>
  agencyArray.map((agency) => ({
    category: categories.agencies,
    label: agency.agencyName,
    value: agency.profileUrl,
    url: `/real-estate-agencies/${agency.profileUrl}`,
    redirectToURL: true,
    avatar: undefined,
  }));

export const buildContacts = (contactArray: ContactArray): Option[] =>
  contactArray.map((contact) => ({
    category: categories.agents,
    label: contact.name,
    value: contact.profileUrl, // with the duplicate agent issue there are contacts with the same id value
    url: `/real-estate-agent/${contact.profileUrl}`,
    redirectToURL: true,
    avatar: contact.thumbnail ? contact.thumbnail : undefined,
  }));
