import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export const card = css`
  display: block;
  padding: ${tokens.spacing.m};
  border: 1px solid ${tokens.colors.neutral.base.default};
  box-sizing: border-box;
  border-radius: 3px;
`;

export const image = css`
  display: block;
  max-width: 100%;
  margin: 0 auto ${tokens.spacing.s};
  grid-area: image;
`;

export const content = css`
  grid-area: content;
`;

export const copyPadding = css`
  margin-bottom: ${tokens.spacing.m};
`;

export const button = css`
  width: 100%;
  max-width: 278px;
`;
