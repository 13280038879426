import React from 'react';
import { Button } from '@domain-group/fe-co-button';
import { ChevronRightIconSmall } from '@domain-group/fe-co-icon';
import * as styles from './sort-by.styles';

const SortByModal = ({
  closeCallback,
}: {
  closeCallback: () => void;
}): JSX.Element => (
  <section css={styles.sortByModal}>
    <h2 css={styles.sortByHeading}>How we rank our featured agents/agencies</h2>
    <p css={styles.sortByParagraph}>
      We take many different factors into consideration to ensure we feature the
      best-performing agents and agencies at the top of search results.
    </p>
    <p css={styles.sortByParagraph}>Agents/agencies rank higher when they:</p>
    <ul css={styles.sortByList}>
      <li>Have complete profiles</li>
      <li>Have more listings, particularly sold and leased</li>
      <li>Have recent listings, which shows they’re active</li>
      <li>Disclose sold prices</li>
      <li>Have lower average days on market and higher average sales prices</li>
      <li>
        Have accurate sold listings data (eg. no listings with negative days on
        market or incorrect sold prices)
      </li>
      <li>
        Are active in suburbs that are being searched in, even if they’re not
        local
      </li>
    </ul>
    <p css={styles.sortByParagraph}>
      Featured agents are displayed on the basis of sales or leasing numbers,
      based on what is selected.
    </p>
    <p css={styles.sortByParagraph}>
      Featured agencies take into account statistics of both sales agents and
      property managers.
    </p>
    <p css={styles.sortByParagraph}>
      <a
        css={styles.sortByLink}
        href="https://help.domain.com.au/hc/en-us/articles/360011913054-How-are-agencies-and-agents-ranked-in-Find-Agent-search-results-"
      >
        Learn more about how Agents and Agencies are ranked
        <ChevronRightIconSmall size="small" />
      </a>
    </p>
    <Button type="button" onClick={closeCallback} appearance="ghostPrimary">
      Ok, got it
    </Button>
  </section>
);

export default SortByModal;
