import { css, SerializedStyles } from '@emotion/react';
import { tokens, media, applyFont } from '@domain-group/fe-brary';
import { PROFILE_TIERS } from '../../../constants';

const {
  colors: { neutral },
  spacing,
  typography,
} = tokens;

export const agencyDetails = ({
  profileTier,
}: {
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  text-align: center;

  ${media('>=tablet')} {
    max-width: 50%;
    text-align: right;
    padding-left: ${spacing.m};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  ${profileTier === PROFILE_TIERS.basic &&
  css`
    margin-top: ${spacing.m};
    padding-top: ${spacing.m};
    border-top: 1px solid ${neutral.trim.default};

    ${media('>=tablet')} {
      margin-top: ${spacing.none};
      padding-top: ${spacing.none};
      border-top: 0;
    }
  `}
`;

export const agencyNameSmall = css`
  ${applyFont(typography.captionBold)}
  color: ${neutral.heavy.default};
  margin-bottom: ${spacing.xxs};
`;
