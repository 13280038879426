import React from 'react';
import * as styles from './results.style';

const NoResults = (): JSX.Element => (
  <div css={styles.noResult}>
    <h3 css={styles.noResultTitle}>No exact matches</h3>
    <p css={styles.noResultText}>
      Try tweaking your search criteria for more results.
    </p>
  </div>
);

export default NoResults;
