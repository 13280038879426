import { css, SerializedStyles } from '@emotion/react';
import { media, tokens, applyFont } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

const statMinWidth = 60;
const spacingUnit = 6; // variables.global.spacingUnit

export const wrapper = (isVertical = false): SerializedStyles => css`
  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      display: flex;
      justify-content: center;
      min-width: ${statMinWidth * 3 + spacingUnit * 2}px;
      margin: 0 auto;
    }
  `}
`;

export const statistics = (isVertical = false): SerializedStyles => css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -${spacingUnit}px;

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      justify-content: center;
    }
  `}
`;

export const statistic = css`
  display: flex;
  flex-direction: column-reverse;
  flex: 0 1 auto;
  margin: 0 ${spacingUnit}px;

  ${media('>=mobile')} {
    min-width: ${statMinWidth}px;
    max-width: ${statMinWidth * 2}px;
  }

  dt {
    grid-area: dt;
    ${applyFont(typography.caption)};
    color: ${colors.neutral.medium.default};
    word-break: break-word;
  }

  dd {
    grid-area: dd;
    ${applyFont(typography.displaySmall)};
  }
`;

export const jointStat = css`
  width: 100%;
  flex-shrink: 0;
  margin: ${spacing.xxs} 0 0;
  align-self: flex-start;
  text-align: left;
  grid-area: joint;
  ${applyFont(typography.caption)};
  color: ${colors.neutral.medium.default};
`;

export const unavailable = css`
  ${applyFont(typography.caption)};
  color: ${colors.neutral.medium.default};
`;
