import React from 'react';
import { LinkButton } from '@domain-group/fe-co-button';
import Tooltip from '@domain-group/fe-co-tooltip';
import * as styles from './privacy-policy.style';

const PrivacyPolicy = (): JSX.Element => (
  <div css={styles.enquiryFormPrivacy}>
    By submitting your enquiry, you agree to our{' '}
    <LinkButton
      appearance="link"
      css={styles.privacyLink}
      href="https://www.domain.com.au/group/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </LinkButton>
    .{' '}
    <Tooltip
      content={
        <p>
          We collect personal data such as your contact and demographic
          information to share with our partners (such as this specific real
          estate agent and other relevant service providers) &amp; providing
          personalised content. For full details please refer to our&nbsp;
          <LinkButton
            appearance="link"
            href="https://www.domain.com.au/group/privacy-policy/"
            css={styles.privacyLinkModal}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </LinkButton>
          .
        </p>
      }
      placement="bottom"
      trigger="click"
      layer="layerModal"
    >
      <LinkButton appearance="link" css={styles.enquiryFormMoreInfo}>
        Click here for more information
      </LinkButton>
    </Tooltip>
    .
  </div>
);

export default PrivacyPolicy;
