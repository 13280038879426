import { css } from '@emotion/react';
import { media } from '@domain-group/fe-brary';

// Height calculates page height minus header and footer
export const loadingPage = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 54px - 452px);
  min-height: 300px;

  ${media('>=tablet')} {
    height: calc(100vh - 104px - 225px);
    min-height: 400px;
  }

  ${media('>=desktop')} {
    height: calc(100vh - 104px - 585px);
    min-height: 500px;
  }
`;

export const loading = css``;
