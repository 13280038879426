import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

const { colors, spacing, borderRadius } = tokens;

export const card = css`
  padding: ${spacing.m};
  border: 1px solid ${colors.neutral.base.default};
  box-sizing: border-box;
  border-radius: ${borderRadius.mini};
`;

export const copyPadding = css`
  margin-bottom: ${spacing.m};
`;
