import { css } from '@emotion/react';
import { media, tokens, applyFont } from '@domain-group/fe-brary';

const { neutral, interactive } = tokens.colors;

export const cardWrapper = css`
  display: flex;
  width: 100%;
  min-height: 139px;
  border: 1px solid ${neutral.trim.default};
  background: ${neutral.surface.selected};
  box-sizing: border-box;
  border-radius: ${tokens.borderRadius.inner};
`;

export const imageWrapper = css`
  width: 154px;
  height: 100%;
  position: relative;
  display: block;
`;

export const image = css`
  border-bottom-left-radius: ${tokens.borderRadius.inner};
  border-top-left-radius: ${tokens.borderRadius.inner};
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const articleLink = css`
  display: inline-flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
`;

export const articleInfo = css`
  width: 60%;
  text-align: left;
  justify-content: center;
  padding: ${tokens.spacing.s};
`;

export const title = css`
  ${applyFont(tokens.typography.caption)}
  color: ${interactive.base.default};
`;

export const subTitle = css`
  ${applyFont(tokens.typography.captionBold)}
  color: ${neutral.heavy.default};
  margin-top: ${tokens.spacing.xs};

  ${media('>=tablet')} {
    ${applyFont(tokens.typography.bodyBold)}
  }
`;
