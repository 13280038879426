import React from 'react';
import { gql, useFragment } from '@apollo/client';
import getConfig from 'next/config';
import Footer from '@domain-group/fe-co-footer';

import { ContactFooterFragment } from '../../generated/graphql';

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactFooter';
const FRAGMENT = gql`
  fragment ContactFooter on Contact {
    id
    address {
      displayAddress
      suburb {
        suburbId
        name
        slug
      }
    }
  }
`;

const ContactProfileFooterWithFragment = ({
  agentIdV2,
  agencyId,
}: {
  agentIdV2: string;
  agencyId: number;
}): JSX.Element => {
  const { data: contact } = useFragment<ContactFooterFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactProfileFooterWithFragment.fragment,
    fragmentName: ContactProfileFooterWithFragment.fragmentName,
  });

  const { publicRuntimeConfig } = getConfig();
  const baseUrl = publicRuntimeConfig.BASE_URL;

  let suburb;
  if (contact?.address?.suburb?.name && contact?.address?.suburb?.slug) {
    suburb = {
      name: contact.address.suburb.name,
      slug: contact.address.suburb.slug,
    };
  }

  return (
    <div data-testid="footer">
      <Footer
        baseUrl={baseUrl}
        suburb={suburb}
        sale={{
          apartmentsAndUnits: true,
          penthouses: true,
          studios: true,
          duplexes: true,
          houses: true,
          semiDetachedHouses: true,
          terracedHouses: true,
          villas: true,
          townhouses: true,
          blockOfUnits: true,
        }}
        rent={{
          apartmentsAndUnits: true,
          penthouses: true,
          studios: true,
          duplexes: true,
          houses: true,
          semiDetachedHouses: true,
          terracedHouses: true,
          villas: true,
          townhouses: true,
          // Doesn't seem to work for rent?
          // blockOfUnits: true,
        }}
      />
    </div>
  );
};

ContactProfileFooterWithFragment.fragment = FRAGMENT;
ContactProfileFooterWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactProfileFooterWithFragment;
