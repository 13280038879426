import { css, SerializedStyles } from '@emotion/react';
import { variables, media, tokens } from '@domain-group/fe-brary';
import { avatarSizes } from '../contact-banner/contact-banner.style';
import { stickyBarHeight } from '../../sticky-bar/sticky-bar.style';

const {
  colors: { neutral },
  spacing,
} = tokens;

export const agentDetails = ({
  isActive,
}: {
  isActive: boolean;
}): SerializedStyles => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 50%;
  margin-top: -${stickyBarHeight}px;
  height: ${stickyBarHeight}px;
  transition: margin 0.4s ease-in-out;
  overflow: hidden;

  ${isActive &&
  css`
    margin: 0;
  `};
`;

export const agentAvatarHolder = css`
  z-index: 2;
  padding-right: ${spacing.s};
  display: flex;
  align-items: center;
`;

export const agentAvatar = css`
  border: 3px solid ${neutral.surface.default};
  box-shadow: 0 1px 3px 0 rgba(30, 41, 61, 0.1);
  width: ${avatarSizes.sticky.mobile}px;
  height: ${avatarSizes.sticky.mobile}px;

  ${media('>=tablet')} {
    width: ${avatarSizes.sticky.tablet}px;
    height: ${avatarSizes.sticky.tablet}px;
  }

  ${media('>=desktop')} {
    width: ${avatarSizes.sticky.desktop}px;
    height: ${avatarSizes.sticky.desktop}px;
  }

  img {
    transition: opacity 0.2s ease-out;
    display: block;
  }
`;

export const detailsBody = css`
  width: 100%;
  overflow: hidden;
`;

export const name = css`
  color: ${neutral.surface.default};
  font-size: ${variables.font.h5FontSize};
  line-height: ${variables.font.h5LineHeight};
  font-weight: ${variables.font.h5FontBold};
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

export const agentJob = css`
  color: ${neutral.trim.default};
  font-size: ${variables.font.baseFontSize};
  line-height: ${variables.font.baseLineHeight};
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;
