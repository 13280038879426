import React, { ReactNode } from 'react';
import * as styles from './section.style';

interface SectionWrapperProps {
  componentId: string;
  hideTablet?: boolean;
  forceBorder?: boolean;
  isShallow?: boolean;
  isNarrow?: boolean;
  bottomBorder?: boolean;
  noStyles?: boolean;
  children: ReactNode;
}

const SectionWrapper = ({
  componentId = '',
  hideTablet = false,
  forceBorder = false,
  isShallow = false,
  isNarrow = false,
  bottomBorder = true,
  noStyles = false,
  children,
}: SectionWrapperProps): JSX.Element => (
  <section
    css={
      noStyles
        ? null
        : styles.section({
            hideTablet,
            forceBorder,
            isShallow,
            isNarrow,
            bottomBorder,
          })
    }
    id={componentId}
  >
    {children}
  </section>
);

export default SectionWrapper;
