import { css, SerializedStyles } from '@emotion/react';
import { media, fontH4, srOnly, tokens } from '@domain-group/fe-brary';

export const title = css`
  ${fontH4};
`;

export const gridWrapper = css`
  ${media('>=desktop')} {
    margin: ${tokens.spacing.s} 0 0 0;
  }
`;

export const gridAdditionalWrapper = css`
  margin-top: ${tokens.spacing.s};
`;

export const grid = (mobilePadding?: string | number): SerializedStyles => {
  const negativeMargin = mobilePadding || tokens.spacing.s;

  return css`
    display: flex;
    overflow-x: auto;
    /* Negative padding needs to match grid item margin */
    margin: 0 calc(-1 * ${negativeMargin});
    padding: ${tokens.spacing.xs} ${negativeMargin};
    ${media('>=desktop')} {
      overflow: initial;
      margin: 0;
      padding: 0;
      padding-bottom: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: ${tokens.spacing.m};
      grid-row-gap: ${tokens.spacing.m};
    }
  `;
};

export const gridItem = css`
  display: flex;
  width: 280px;
  padding: ${tokens.spacing.s} 0 0 0;
  &:not(:last-of-type) {
    margin-right: ${tokens.spacing.xs};
  }
  ${media('>=desktop')} {
    width: 100%;
    &,
    &:not(:last-of-type) {
      margin: 0;
    }
  }
`;

/* istanbul ignore next */
export const viewMore = (expanded: boolean): SerializedStyles => css`
  ${expanded ? srOnly : `margin-top: ${tokens.spacing.m};`}
`;

export const animationSettings = {
  collapsed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
  transition: {
    duration: 0.5,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
};

export const desktopGrid = css`
  display: none;

  ${media('>=desktop')} {
    display: block;
  }
`;

export const mobileGrid = css`
  ${media('>=desktop')} {
    display: none;
  }
`;
