import { css, SerializedStyles } from '@emotion/react';
import { tokens, media } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

export const pillsWrapper = (
  mobilePadding?: string | number,
): SerializedStyles => css`
  overflow-x: auto;
  /* Use negative margin to stretch container to edge of screen */
  margin: 0px calc(-1 * ${mobilePadding || tokens.spacing.m})
    ${tokens.spacing.xs};

  ${media('>tablet')} {
    margin: 0 ${tokens.spacing.xs} 0 0;
    /* Remove tablet+ padding in Horizontal Dropdown Pills */
    [data-testid='horizontal-dropdown-pills'] {
      padding: 0;
    }
  }
`;

export const filtersBar = css`
  position: relative;
  background: ${tokens.colors.neutral.surface.default};
  margin-bottom: ${tokens.spacing.m};

  ${media('>tablet')} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const sortByWrap = css`
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;

  ${media('>desktop')} {
    flex-direction: row;
  }
`;

export const sortBy = css`
  display: flex;
  align-items: center;
`;

export const sortByIcon = css`
  display: flex;
  margin-right: ${tokens.spacing.xxs};
`;

export const sortByTitle = css`
  margin-right: 10px;
`;
