import React, { useCallback } from 'react';
import { Wrapper, Data, Controls } from '.';
import { IActiveSuburb, ISuburbStatistics } from '../@types';

const ALL_SUBURBS_SUBURB_NAME = 'All suburbs';
const ALL_SUBURBS_SUBURB_LABEL = 'all suburbs';
export const ALL_SUBURBS_SUBURB_SLUG = 'all-suburbs';
const ARIA_CONTROLS_ID = 'sales-statistics-area';

export interface SalesStatisticsProps {
  tracking?: {
    onSuburbChange?: (suburbName: string) => void;
  };
  activeSuburbs: IActiveSuburb[];
  selectedSuburb: string;
  setSelectedSuburb: (suburbSlug: string) => void;
  allSuburbStatistics: ISuburbStatistics;
  loading: boolean;
  suburbStatisticsData?: ISuburbStatistics | null;
}

const SalesStatistics = ({
  tracking = {},
  selectedSuburb,
  setSelectedSuburb,
  activeSuburbs,
  allSuburbStatistics,
  // Suburb query data
  loading,
  suburbStatisticsData,
}: SalesStatisticsProps): JSX.Element | null => {
  const handleSelectSuburb = useCallback(
    (suburbSlug: string) => {
      const suburbName =
        activeSuburbs?.find((suburb) => suburbSlug === suburb.suburbSlug)
          ?.suburbName || ALL_SUBURBS_SUBURB_NAME;

      tracking?.onSuburbChange?.(suburbName);
      setSelectedSuburb(suburbSlug);
    },
    [tracking, setSelectedSuburb, activeSuburbs],
  );

  return (
    <div data-testid="sales-statistics">
      <Wrapper>
        <Controls
          id={ARIA_CONTROLS_ID}
          allSuburbsOption={{
            suburbName: ALL_SUBURBS_SUBURB_NAME,
            listingCount: allSuburbStatistics?.total || 0,
            suburbSlug: ALL_SUBURBS_SUBURB_SLUG,
          }}
          activeSuburbs={activeSuburbs.sort(
            (burbA, burbB) =>
              (burbB.listingCount || 0) - (burbA.listingCount || 0),
          )}
          setSelectedSuburb={handleSelectSuburb}
          selectedSuburb={selectedSuburb}
        />
        <Data
          id={ARIA_CONTROLS_ID}
          loading={loading}
          selectedSuburbName={
            activeSuburbs?.find(
              (suburb) => selectedSuburb === suburb.suburbSlug,
            )?.suburbName || ALL_SUBURBS_SUBURB_LABEL
          }
          suburbStatisticsData={
            selectedSuburb === ALL_SUBURBS_SUBURB_SLUG
              ? allSuburbStatistics
              : suburbStatisticsData
          }
        />
      </Wrapper>
    </div>
  );
};

export default SalesStatistics;
