import { css, SerializedStyles } from '@emotion/react';
import {
  media,
  tokens,
  globalTokens,
  applyFont,
  rgba,
} from '@domain-group/fe-brary';
import { contactButtonsDesktopWidth } from '../../../../styles/contact-buttons.style';

const { colors, spacing, typography, borderRadius } = tokens;

export const container = css`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: inherit;
  position: relative;
  box-sizing: border-box;
  background: ${colors.neutral.surface.default};
  color: ${colors.neutral.heavy.default};
`;

export const body = ({
  isVertical = false,
  isPlatinum = false,
  singleLine = false,
}: {
  isVertical: boolean;
  isPlatinum: boolean;
  singleLine: boolean;
}): SerializedStyles => css`
  border: 1px solid ${colors.neutral.trim.default};
  border-top: 0;
  border-radius: 0 0 ${borderRadius.mini} ${borderRadius.mini};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: inherit;
  height: inherit;
  grid-column-gap: ${spacing.s};
  grid-row-gap: ${spacing.s};
  padding: ${spacing.m};

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      display: grid;
      justify-content: normal;
      grid-template-columns: 86px auto auto;
      grid-template-rows: auto auto;
      grid-template-areas: '. . .' 'footer footer footer';
      grid-row-gap: ${spacing.m};
      padding: ${spacing.l} ${spacing.m};
      align-items: center;
    }

    ${media('>=desktop')} {
      grid-template-columns: 86px 228px auto ${singleLine
          ? contactButtonsDesktopWidth * 1.5
          : contactButtonsDesktopWidth * 2 + 12}px;
      grid-template-rows: auto;
      grid-template-areas: none;
    }

    ${isPlatinum &&
    css`
      ${media('>=tablet')} {
        padding: ${spacing.l} ${spacing.m};
      }
    `}
  `}
`;

const avatarSizeSmall = 68;
const avatarSizeLarge = 86;

export const avatar = (
  isVertical = false,
  isPlatinum = false,
): SerializedStyles => css`
  position: absolute;
  top: -${(avatarSizeSmall - 32) / 2}px; /* Header height 32px */
  width: ${avatarSizeSmall}px;
  height: ${avatarSizeSmall}px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid ${tokens.colors.neutral.surface.default};
  box-shadow: 0px 3px 3px ${rgba(globalTokens.colors.ocean700, 0.15)};

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      position: relative;
      top: auto;
      margin: 0 auto;

      ${isPlatinum &&
      css`
        width: ${avatarSizeLarge}px;
        height: ${avatarSizeLarge}px;
      `}
    }
  `}
`;

export const name = (
  isVertical = false,
  isPlatinum = false,
): SerializedStyles => css`
  ${applyFont(typography.subheading)}
  margin-bottom: ${spacing.xxs};

  ${!isVertical &&
  !isPlatinum &&
  css`
    ${media('>=tablet')} {
      ${applyFont(typography.body)};
    }
  `};
`;

export const contactButtonsWrapper = (
  isVertical = false,
): SerializedStyles => css`
  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      grid-area: footer;
    }

    ${media('>=desktop')} {
      display: flex;
      justify-content: flex-end;
      grid-area: inherit;
    }
  `}
`;

export const job = css`
  ${applyFont(typography.caption)};
  margin-bottom: ${spacing.xxs};
`;

export const link = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const modalContainer = css`
  padding: ${spacing.m};

  ${media('>=desktop')} {
    padding: ${spacing.l};
  }
`;

export const agency = css`
  ${applyFont(typography.caption)}
`;
