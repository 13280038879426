import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

export const filterHeading = css`
  display: block;
  color: ${colors.neutral.heavy.default};
  ${applyFont(typography.bodyBold)}
`;

export const headingSpacer = css`
  padding-bottom: ${spacing.s};
`;
