export enum SORT_VALUES {
  'default',
  'totalSoldAndAuctionedDescending',
  'forSaleDescending',
  'totalLeasedDescending',
  'forRentDescending',
  'nameAscending',
  'nameDescending',
}

export const DEFAULT_SORT_VALUE = 'default';
export const DEFAULT_SORT_LABEL = 'Featured';

interface SortOption {
  value: string;
  label: string;
}

export const SORT_OPTIONS_AGENCIES: SortOption[] = [
  { value: 'default', label: 'Featured' },
  { value: 'totalSoldAndAuctionedDescending', label: 'Sold' },
  { value: 'forSaleDescending', label: 'For Sale' },
  { value: 'totalLeasedDescending', label: 'Leased' },
  { value: 'nameAscending', label: 'Name (A-Z)' },
  { value: 'nameDescending', label: 'Name (Z-A)' },
];

export const SORT_OPTIONS_AGENTS: SortOption[] = [
  { value: 'default', label: 'Featured' },
  { value: 'totalSoldAndAuctionedDescending', label: 'Sold' },
  { value: 'avgSalePriceDescending', label: 'Avg Sale Price' },
  { value: 'avgDayOnMarketAscending', label: 'Avg Days On Market' },
  { value: 'nameAscending', label: 'Name (A-Z)' },
  { value: 'nameDescending', label: 'Name (Z-A)' },
];

// Available sort options
// default
// totalSoldDescending
// totalSoldAscending
// totalSoldAndAuctionedAscending
// totalSoldAndAuctionedDescending
// avgSalePriceAscending
// avgSalePriceDescending
// avgDayOnMarketAscending
// avgDayOnMarketDescending
// forSaleAscending
// forRentAscending
// nameAscending
// nameDescending
