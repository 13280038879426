import { css } from '@emotion/react';
import { rgba, tokens, globalTokens } from '@domain-group/fe-brary';

// eslint-disable-next-line import/prefer-default-export
export const loader = (absolute) => css`
  padding: ${tokens.spacing.xxl};
  display: flex;
  justify-content: center;
  min-height: 300px;

  ${absolute &&
  css`
    min-height: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${rgba(globalTokens.colors.white, 0.5)};
    z-index: 2;
  `}
`;
