import React from 'react';
import { useQuery, gql } from '@apollo/client';
import ContactTestimonialsWithFragment from './contact-testimonials-with-fragment';

export const GET_CONTACT_TESTIMONIALS_QUERY = gql`
  query GetContactTestimonials($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...Testimonials
    }
  }
  ${ContactTestimonialsWithFragment.fragment}
`;

const ContactTestimonialsWithQuery = ({
  agencyId,
  agentIdV2,
}: {
  agentIdV2: string;
  agencyId: number;
}): JSX.Element | null => {
  const { data } = useQuery(GET_CONTACT_TESTIMONIALS_QUERY, {
    variables: { agencyId, agentIdV2 },
  });

  if (!data) {
    return null;
  }

  return (
    <ContactTestimonialsWithFragment
      agencyId={agencyId}
      agentIdV2={agentIdV2}
    />
  );
};

export default ContactTestimonialsWithQuery;
