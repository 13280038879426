import React from 'react';
import { LinkButton } from '@domain-group/fe-co-button';
import { FeedbackIcon } from '@domain-group/fe-co-icon';
import { Avatar } from '../../shared';

import * as styles from './appraisal-cta.style';
import { buildAppraisalUrl } from '../../../utils';

type AppraisalCTAProps = {
  contactId?: number;
  name?: string;
  thumbnail?: string;
  url?: string;
  onClick?: () => void;
};

const AppraisalCTA = ({
  contactId,
  name,
  thumbnail,
  url,
  onClick,
}: AppraisalCTAProps): JSX.Element => (
  <div css={styles.card} data-testid="appraisal-cta">
    <Avatar imageUrl={thumbnail} imageTitle={name} css={styles.avatar} />

    <div css={styles.content}>
      <div css={styles.copies}>
        <p css={styles.title}>Looking to sell{name ? ` with ${name}` : ''}?</p>
        <p css={styles.description}>
          Start your selling journey with a free property appraisal
        </p>
      </div>

      <LinkButton
        href={
          url ||
          buildAppraisalUrl({
            contactId,
            entryPoint: 'Agent Profile - Appraisal CTA',
          })
        }
        target="_blank"
        css={styles.button}
        onClick={onClick}
      >
        <FeedbackIcon />
        Get a free appraisal
      </LinkButton>
    </div>
  </div>
);

export default AppraisalCTA;
