import { css, SerializedStyles } from '@emotion/react';
import { variables, rgba, tokens, globalTokens } from '@domain-group/fe-brary';
import { PROFILE_TIERS } from '../../constants';

export const stickyBarHeight = 70;

export const stickyOuterWrapper = ({
  isAgency,
  profileTier,
}: {
  isAgency: boolean;
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${stickyBarHeight}px;
  z-index: 100;

  @media (min-width: ${variables.global.tabletMinWidth}) {
    display: block;
  }

  ${isAgency &&
  profileTier === PROFILE_TIERS.platinum &&
  css`
    height: ${stickyBarHeight}px;
  `};
`;

export const stickyWrapper = css`
  z-index: 5;
  overflow: hidden;
  background-color: ${rgba(globalTokens.colors.coolGrey800, 0.9)};
  padding: 0 ${tokens.spacing.m};
`;

export const stickyBar = ({
  desktopWidth,
}: {
  desktopWidth: number;
}): SerializedStyles => css`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: ${desktopWidth}px;
  margin: 0 auto;
`;

export const stickyDetails = css`
  height: ${stickyBarHeight}px;
  min-width: 0; /* Important to stop overflow */
  flex-grow: 1;
  padding-right: ${tokens.spacing.s};
`;
