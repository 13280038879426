export const agencyPageTitleGenerator = ({
  agencyName,
  suburb,
  state,
  postCode,
}: {
  agencyName: string;
  suburb: string;
  state: string;
  postCode: string;
}): string =>
  agencyName
    ? `${agencyName} | Real Estate Agency in ${suburb}, ${state.toUpperCase()} ${postCode}`
    : '';

export const agentPageTitleGenerator = ({
  agencyName,
  displayAddress,
  name,
}: {
  agencyName: string;
  name: string;
  displayAddress: string;
}): string =>
  `${name} | ${agencyName} | Real Estate Agent in ${displayAddress}`;
