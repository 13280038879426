import React from 'react';
import { gql, useQuery } from '@apollo/client';
import ContactTopSummaryWithFragment from './contact-top-summary-with-fragment';
import { GetContactTopSummaryQuery } from '../../generated/graphql';

export type Props = {
  agentIdV2: string;
  agencyId: number;
};

export const GET_CONTACT_TOP_SUMMARY_QUERY = gql`
  query getContactTopSummary($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...ContactTopSummary
    }
  }
  ${ContactTopSummaryWithFragment.fragment}
`;

const ContactTopSummaryWithQuery = ({
  agentIdV2,
  agencyId,
}: Props): JSX.Element | null => {
  const { data } = useQuery<GetContactTopSummaryQuery>(
    GET_CONTACT_TOP_SUMMARY_QUERY,
    {
      variables: { agentIdV2, agencyId },
    },
  );

  if (!data) {
    return null;
  }

  return (
    <ContactTopSummaryWithFragment agentIdV2={agentIdV2} agencyId={agencyId} />
  );
};

export default ContactTopSummaryWithQuery;
