import React from 'react';
import { TextInput as Input } from '@domain-group/fe-co-form-elements';
import * as style from './text-input.style';

const TextInput = ({
  current = '',
  title,
  groupName,
  handleChange,
}: {
  current?: string | null;
  title: string;
  groupName: string;
  handleChange: (event: string) => void;
}): JSX.Element => (
  <div aria-labelledby={`label_${groupName}`} css={style.textInput}>
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label
      css={[style.filterHeading, style.headingSpacer]}
      data-testid="listing-tabs__filters-title"
      htmlFor={`label_input_${groupName}`}
      id={`label_${groupName}`}
    >
      {title}
    </label>
    <Input
      id={`label_input_${groupName}`}
      name={`label_input_${groupName}`}
      value={current}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.value);
      }}
    />
  </div>
);

export default TextInput;
