import DOMPurify from 'isomorphic-dompurify';

import type {
  AgencyDigitalDataFragment,
  ContactDigitalDataFragment,
} from '../../../../../generated/graphql';

import type {
  SchemaListItem,
  SchemaBreadCrumbList,
} from '../../../../../@types/schema';

import createSuburbSlug from '../../../../../utils/create-suburb-slug';
import { startCase } from '../../../../../utils';

type Statistics = ContactDigitalDataFragment['statistics'];

type Breadcrumb = {
  title: string;
  url: string;
};

const getAgentType = (
  statistics: Statistics,
): 'sales-agents' | 'property-managers' => {
  const totalForSale = statistics?.totalForSale ?? 0;
  const totalSoldAndAuctioned = statistics?.totalSoldAndAuctioned ?? 0;
  const totalForRent = statistics?.totalForRent ?? 0;
  const totalLeased = statistics?.totalLeased ?? 0;

  if (totalForSale + totalSoldAndAuctioned > 0) {
    return 'sales-agents';
  }
  if (totalForRent + totalLeased > 0) {
    return 'property-managers';
  }
  return 'sales-agents';
};

export const agentProfilesPageBreadcrumbs = ({
  baseUrl,
  originalUrl,
  data,
}: {
  baseUrl: string;
  originalUrl: string;
  data: ContactDigitalDataFragment;
}): Breadcrumb[] => {
  const suburbSlug = createSuburbSlug(
    data.address?.suburb?.name ?? '',
    data.address?.suburb?.state ?? '',
    data.address?.suburb?.postcode ?? '',
  );

  const agentType = getAgentType(data.statistics);
  const isDefaultFilter = agentType === 'sales-agents';

  return [
    {
      title: 'Real estate agents',
      url: `${baseUrl}/real-estate-agents/`,
    },
    {
      title: startCase(data?.address?.suburb?.name ?? ''),
      url: `${baseUrl}/real-estate-agents/${suburbSlug}/${
        isDefaultFilter ? '' : `?agentFilter=${agentType}`
      }`,
    },
    {
      title: data?.name ?? '',
      url: `${baseUrl}${originalUrl}`,
    },
  ];
};

export const agencyProfilesPageBreadcrumbs = ({
  baseUrl,
  originalUrl,
  data,
}: {
  baseUrl: string;
  originalUrl: string;
  data: AgencyDigitalDataFragment;
}): Breadcrumb[] => {
  const suburbSlug = createSuburbSlug(
    data.address?.suburb?.name ?? '',
    data.address?.suburb?.state ?? '',
    data.address?.suburb?.postcode ?? '',
  );

  return [
    {
      title: 'Real estate agencies',
      url: `${baseUrl}/real-estate-agents/`,
    },
    {
      title: startCase(data.address?.suburb?.name ?? ''),
      url: `${baseUrl}/real-estate-agencies/${suburbSlug}/`,
    },
    { title: data.name ?? 'Agent', url: `${baseUrl}${originalUrl}` },
  ];
};

export const createBreadcrumbsJSONBlob = (
  breadcrumbs: Breadcrumb[],
): SchemaBreadCrumbList => {
  DOMPurify.removeAllHooks();
  const crumbs = breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem' as SchemaListItem['@type'],
    position: index + 1,
    item: {
      '@id': DOMPurify.sanitize(breadcrumb.url),
      name: DOMPurify.sanitize(breadcrumb.title),
    },
  }));

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: crumbs,
  };
};
