import { css } from '@emotion/react';
import { breakpoints, tokens } from '@domain-group/fe-brary';

const { tabletMinWidth } = breakpoints.domain;

export const infoBox = css`
  color: ${tokens.colors.neutral.heavy.default};
  padding: ${tokens.spacing.xs};

  @media (min-width: ${tabletMinWidth}) {
    width: 400px;
  }
`;

export const infoBoxImage = css`
  color: ${tokens.colors.neutral.heavy.default};
  margin-bottom: ${tokens.spacing.xxs};

  img {
    height: 32px;
  }
`;

export const infoBoxName = css`
  font-family: ${tokens.typography.subheading.family};
  font-size: ${tokens.typography.subheading.size};
  line-height: ${tokens.typography.subheading.lineHeight};
  font-weight: ${tokens.typography.subheading.weight};
  max-width: 320px;
  margin-bottom: ${tokens.spacing.xs};
  color: ${tokens.colors.neutral.heavy.default};
`;

export const infoBoxAddress = css`
  text-decoration: none;
  font-size: ${tokens.typography.body.size};
  line-height: ${tokens.typography.body.lineHeight};
  font-weight: ${tokens.typography.body.weight};
  text-align: left;
  color: ${tokens.colors.neutral.medium.default};
`;

export const pinOffset = css`
  margin-bottom: 3px;
  margin-right: 3px;
`;
