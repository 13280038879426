import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';

const { colors, spacing, typography, borderRadius } = tokens;

export const noResult = css`
  background: ${colors.neutral.background.default};
  border: 1px solid ${colors.neutral.trim.default};
  padding: ${spacing.m};
  border-radius: ${borderRadius.mini};
`;

export const noResultTitle = css`
  ${applyFont(typography.heading)}
  color: ${colors.neutral.heavy.default};
  margin-bottom: ${spacing.xs};
`;

export const noResultText = css`
  ${applyFont(typography.body)}
  color: ${colors.neutral.medium.default};
`;
