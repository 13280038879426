import { css, SerializedStyles } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';
/* eslint-disable import/prefer-default-export */
export * from '../filter-headings.style';

export const radioGroup = css`
  padding: ${tokens.spacing.s};
`;

export const radioLine = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const selectedLabel = css`
  font-weight: bold;
`;

export const selectedBadge = css`
  color: ${tokens.colors.interactive.subdued.selected};
  background: ${tokens.colors.interactive.background.default};
`;

/**
 * Inspired by https://github.com/domain-group/fe-pa-search-results/blob/357aafd067d0ad83dfe1001abf9c628e1822f5a2/src/js/listing-details/components/toolbar/style.js#L157
 */
export const badge = (selected: boolean): SerializedStyles => css`
  display: block;
  font-weight: bold;
  top: -7px;
  right: 2px;
  padding: 0 8px;
  text-align: center;
  position: static;
  margin-left: 4px;
  border-radius: 22px;
  height: 22px;
  background: ${tokens.colors.neutral.surface.default};
  ${applyFont(tokens.typography.captionBold)}
  ${selected ? selectedBadge : undefined};
`;
