import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { gql, useFragment } from '@apollo/client';
import ProfileMap from '@domain-group/fe-co-profile-map';
import { useInView } from 'react-intersection-observer';

import { useIsMobile } from '../../hooks';

import { AgencyInfoBox } from './agency-info-box';
import { getSvgDataUrl, toGlobalId } from './utils';
import * as styles from './agency-profile-map-with-fragment.style';
import customGoogleMapOptions from './utils/custom-google-map-options';
import { AgencyProfileMapFragmentFragment } from '../../generated/graphql';

const AgencyProfileMapWithFragment = ({
  agencyId,
}: {
  agencyId: number;
}): JSX.Element | null => {
  const [isServerSide, setIsServerSide] = useState(true);
  const isMobile = useIsMobile();
  const [toggleSeen, setToggleSeen] = useState(false);
  const { ref, inView } = useInView({
    // This means it will fire when 75% of the component is in view
    threshold: 0.75,
  });

  useEffect(() => {
    if (inView && !toggleSeen) {
      setToggleSeen(true);
    }
  }, [inView, toggleSeen]);

  useEffect(() => {
    setIsServerSide(false);
  }, []);

  const id = toGlobalId('Agency', agencyId);

  const { complete, data } = useFragment<AgencyProfileMapFragmentFragment>({
    from: {
      __typename: 'Agency',
      id,
    },
    fragment: AgencyProfileMapWithFragment.fragment,
    fragmentName: AgencyProfileMapWithFragment.fragmentName,
  });

  if (isServerSide || !complete || !data?.address?.geolocation) {
    return null;
  }

  const pinUrl = getSvgDataUrl(data?.branding?.logoColour);

  const { latitude, longitude } = data?.address?.geolocation ?? {};

  const agencyInfoBox = (
    <AgencyInfoBox
      agencyName={data?.name}
      displayAddress={data?.address?.displayAddress}
      logoUrl={data?.branding?.logo?.url}
    />
  );

  const infoBox = isMobile ? '' : ReactDOMServer.renderToString(agencyInfoBox);

  return (
    <div
      data-testid="profile-map"
      ref={ref}
      css={styles.customGoogleMapsUIStyling(toggleSeen)}
    >
      <ProfileMap
        options={customGoogleMapOptions(isMobile)}
        mobileHeight="162px"
        tabletHeight="516px"
        zoom={16}
        infoBox={infoBox}
        googleMapsApiQueryParams={{
          client: 'gme-fairfax',
          channel: 'domaincomau',
        }}
        coords={{
          lat: latitude,
          lng: longitude,
        }}
        gaTracking={{
          category: 'Interaction',
          // During SSR "action" will be incorrectly set to a placeholder
          // but that's okay because this tracking event only fires
          // client side
          action:
            (typeof window !== 'undefined' &&
              window?.digitalData?.page?.category?.pageType) ||
            'Agent/Agency Profile',
          label: 'Map Movement',
        }}
        icon={{
          pinUrl,
          size: { width: 150, height: 150 },
          anchor: { x: 75, y: 75 },
        }}
        showInfoBoxAndPin
      />
      {isMobile && <div css={styles.mobileInfoBox}>{agencyInfoBox}</div>}
    </div>
  );
};

AgencyProfileMapWithFragment.fragmentName = 'AgencyProfileMapFragment';

AgencyProfileMapWithFragment.fragment = gql`
  fragment AgencyProfileMapFragment on Agency {
    __typename
    id
    name
    address {
      __typename
      displayAddress
      geolocation {
        latitude
        longitude
      }
    }
    branding {
      id
      logoColour
      logo {
        url
      }
    }
  }
`;

export default AgencyProfileMapWithFragment;
