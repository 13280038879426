import { css, SerializedStyles } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

export const badge = (isFullWidthSection: boolean): SerializedStyles => {
  const breakpoint = isFullWidthSection ? '>=tablet' : '>=desktop';

  return css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 54px 1px 1fr;
    align-items: center;
    justify-items: center;
    margin: ${tokens.spacing.l} 0 ${tokens.spacing.s} 0;

    border: 1px solid ${tokens.colors.neutral.trim.default};
    box-sizing: border-box;
    border-radius: 3px;

    ${media(breakpoint)} {
      grid-template-columns: 168px 1px 1fr;
      grid-template-rows: 1fr;
    }
  `;
};

export const iconContainer = css`
  padding: ${tokens.spacing.xs};
`;

export const divider = (isFullWidthSection: boolean): SerializedStyles => {
  const breakpoint = isFullWidthSection ? '>=tablet' : '>=desktop';

  return css`
    width: calc(100% - ${tokens.spacing.m});
    height: 100%;
    background-color: ${tokens.colors.neutral.trim.default};

    ${media(breakpoint)} {
      width: 100%;
      height: calc(100% - ${tokens.spacing.m});
    }
  `;
};

export const badgeText = (isFullWidthSection: boolean): SerializedStyles => {
  const breakpoint = isFullWidthSection ? '>=tablet' : '>=desktop';

  return css`
    padding: ${tokens.spacing.s};

    ${media(breakpoint)} {
      padding: ${tokens.spacing.xs} ${tokens.spacing.s};
      justify-self: left;
    }
  `;
};
