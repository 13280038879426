import React, { useState, useCallback } from 'react';

import * as styles from './testimonials.style';

export type TestimonialTextProps = {
  index: number;
  summaryCharLimit: number;
  setActiveModal: (index: number) => void;
  author: string;
  testimonial: string;
};

const TestimonialText = ({
  index,
  summaryCharLimit,
  setActiveModal,
  author,
  testimonial,
}: TestimonialTextProps): JSX.Element => {
  const [summary, setSummary] = useState(testimonial);

  const updateSummary = useCallback(() => {
    if (testimonial.length > summaryCharLimit) {
      const newSummary = testimonial.substring(0, summaryCharLimit);

      setSummary(`${newSummary} \u2026`);
    }
  }, [testimonial, summaryCharLimit]);

  React.useEffect(() => {
    updateSummary();
  }, [updateSummary]);

  return (
    <>
      <p css={styles.quote} data-testid="trade-testimonial__quote">
        {summary}
      </p>
      {testimonial.length > summaryCharLimit && (
        <button
          type="button"
          css={styles.readMore}
          data-testid="trade-testimonial__read-more"
          onClick={() => setActiveModal(index)}
        >
          Read more{' '}
          <span css={styles.srOnly} data-testid="sr-only">
            about {author}&rsquo;s experience with this agent
          </span>
        </button>
      )}
    </>
  );
};

export default TestimonialText;
