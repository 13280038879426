import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';
import { SerializedStyles } from '@emotion/core';

const { spacing } = tokens;

export const container = ({
  gap,
  backgroundColor = undefined,
}: {
  gap: number | string;
  backgroundColor?: string | undefined;
}): SerializedStyles => css`
  ${backgroundColor && `background-color: ${backgroundColor}`};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${gap};
`;

export const contentWrapper = ({
  gap,
  flexDirection,
  alignItems,
  justifyContent,
}: {
  gap: number | string;
  flexDirection: 'column' | 'rows';
  alignItems?: 'center' | 'flex-start';
  justifyContent?: 'center' | 'flex-start';
}): SerializedStyles => css`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: ${spacing.xxl} ${spacing.m};

  display: flex;
  flex-direction: ${flexDirection};
  ${alignItems && `align-items: ${alignItems}`};
  ${justifyContent && `justify-content: ${justifyContent}`};
  gap: ${gap};
`;
