import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import * as styles from './statistics.style';

export type Props = {
  isVertical: boolean;
  totalForRent?: number | null;
  totalJointLeased?: number | null;
};

const LeaseStatistics = ({
  isVertical = false,
  totalForRent = null,
  totalJointLeased = null,
}: Props): React.ReactElement => (
  <div css={styles.wrapper(isVertical)}>
    <div>
      <h4 css={srOnly}>Property management statistics</h4>
      <dl css={styles.statistics(isVertical)}>
        {totalForRent ? (
          <div css={styles.statistic}>
            <dt>For rent</dt>
            <dd>{totalForRent}</dd>
          </div>
        ) : null}
        {totalJointLeased ? (
          <div css={styles.statistic}>
            <dt>Leased</dt>
            <dd>{totalJointLeased}</dd>
          </div>
        ) : null}
      </dl>
      {!totalForRent && !totalJointLeased ? (
        <div css={styles.unavailable}>
          <p>* Rental statistics unavailable</p>
        </div>
      ) : null}
    </div>
  </div>
);

export default LeaseStatistics;
