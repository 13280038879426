import React, { useState } from 'react';
import { Button } from '@domain-group/fe-co-button';
import { RefineSearchIcon } from '@domain-group/fe-co-icon';
import {
  Pill,
  Pills,
  StickyContent,
} from '@domain-group/fe-co-horizontal-dropdown-pills';

import {
  Filters,
  EndFormButtonGroup,
  RadioOptionGroup,
  TextInput,
} from './pill-forms';
import {
  SEARCH_TYPE_OPTIONS,
  AGENCY_VIEW,
  AGENT_SEARCH_TYPE_OPTIONS,
  SALES_AGENT,
} from '../enums';

import * as style from './filters-bar.style';

interface FilterBarProps {
  isAgency: boolean;
  selectSearchType: (searchType: string | null) => void;
  currentSearchType?: string;
  selectAgentType: (agent: string | null) => void;
  currentAgentType?: string;
  selectFilter: (filter: string | null) => void;
  currentFilter?: string;
  selectSearchTypeAndFilters: (
    newSearchType: string | null,
    newAgentType: string | null,
    newFilter: string | null,
  ) => void;
}

const FiltersBar = ({
  isAgency = false,
  selectSearchType,
  currentSearchType = AGENCY_VIEW,
  selectAgentType,
  currentAgentType = SALES_AGENT,
  selectFilter,
  currentFilter = '',
  selectSearchTypeAndFilters,
}: FilterBarProps): JSX.Element => {
  // Quirk of fe-co-horizontal-dropdown-pills
  const [pillsOpen, setPillsOpen] = useState<string | null>(null);

  // State - Forms
  const [formSearchType, setFormSearchType] = useState(currentSearchType);
  const [formAgentType, setFormAgentType] = useState(currentAgentType);
  const [formFilter, setFormFilter] = useState<string | null>(currentFilter);

  const closePill = () => {
    setPillsOpen(null);
  };

  // Handlers
  const handleSearchTypeSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    selectSearchType(formSearchType);
    closePill();
  };

  const handleAgentTypeSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    selectAgentType(formAgentType);
    closePill();
  };

  const handleFilterSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    selectFilter(formFilter);
    closePill();
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    selectSearchTypeAndFilters(formSearchType, formAgentType, formFilter);
    closePill();
  };

  const handleClearFilter = () => {
    setPillsOpen(null);

    setFormFilter(null);
    selectFilter(null);
  };

  const agentSearchLabel = AGENT_SEARCH_TYPE_OPTIONS.find(
    (option) => option.value === currentAgentType,
  )?.label;

  const searchTypeLabel = SEARCH_TYPE_OPTIONS.find(
    (option) => option.value === currentSearchType,
  )?.label;

  return (
    <div css={style.filtersBar}>
      <h3 css={style.srOnly}>Filters bar</h3>
      <div css={style.pillsWrapper}>
        <Pills open={pillsOpen} onOpenChange={setPillsOpen}>
          <Pill
            key="allfilters"
            aria-labelledby="All filters"
            label="Filters"
            leftIcon={<RefineSearchIcon />}
            rightIcon={null}
          >
            <Filters
              isAgency={isAgency}
              setFormSearchType={setFormSearchType}
              formSearchType={formSearchType}
              setFormAgentType={setFormAgentType}
              formAgentType={formAgentType}
              setFormFilter={setFormFilter}
              formFilter={formFilter}
            />
            <StickyContent>
              <EndFormButtonGroup
                pillLabel="All filters"
                close={closePill}
                handleSubmit={handleSubmit}
              />
            </StickyContent>
          </Pill>
          {searchTypeLabel && (
            <Pill
              key="search-type"
              label={searchTypeLabel}
              active={!!currentSearchType}
            >
              <RadioOptionGroup
                title="Search for"
                groupName="search-type-selection"
                options={SEARCH_TYPE_OPTIONS}
                handleChange={setFormSearchType}
                current={formSearchType}
              />
              <StickyContent>
                <EndFormButtonGroup
                  pillLabel="Search for"
                  close={closePill}
                  handleSubmit={handleSearchTypeSubmit}
                />
              </StickyContent>
            </Pill>
          )}
          {!isAgency && agentSearchLabel && (
            <Pill
              key="agent-type"
              label={agentSearchLabel}
              active={!!currentAgentType}
            >
              <RadioOptionGroup
                title="Agent type"
                groupName="agent-type-selection"
                options={AGENT_SEARCH_TYPE_OPTIONS}
                handleChange={setFormAgentType}
                current={formAgentType}
              />
              <StickyContent>
                <EndFormButtonGroup
                  pillLabel="Agent type"
                  close={closePill}
                  handleSubmit={handleAgentTypeSubmit}
                />
              </StickyContent>
            </Pill>
          )}
          <Pill
            key="filter"
            label={currentFilter ? `Filter By: ${currentFilter}` : `Filter By`}
            active={!!currentFilter}
          >
            <Button
              type="button"
              appearance="link"
              size="mini"
              css={style.clearButtonStyleOverride}
              onClick={handleClearFilter}
              aria-label="Clear all filter selections"
            >
              Reset filter
            </Button>
            <TextInput
              groupName="filter-selection"
              handleChange={setFormFilter}
              current={formFilter}
              title="Filter by"
            />
            <EndFormButtonGroup
              pillLabel="filter"
              close={closePill}
              handleSubmit={handleFilterSubmit}
            />
          </Pill>
        </Pills>
      </div>
    </div>
  );
};

export default FiltersBar;
