export enum AGENT_TYPES {
  'sales-agents',
  'property-manager',
}

export const SALES_AGENT = 'sales-agents';
export const PROPERTY_MANAGER = 'property-manager';

export const AGENT_SEARCH_TYPE_OPTIONS = [
  { value: SALES_AGENT, label: 'Sales Agents' },
  { value: PROPERTY_MANAGER, label: 'Property Managers' },
];
