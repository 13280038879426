import React from 'react';
import PropTypes from 'prop-types';
import { PinIconSmall } from '@domain-group/fe-co-icon';
import { srOnly } from '@domain-group/fe-brary';
import * as styles from './agency-info-box.style';
import { Image } from '../../shared';

const AgencyInfoBox = ({ agencyName, displayAddress, logoUrl }) => (
  <div css={styles.infoBox}>
    <div css={styles.infoBoxImage}>
      {logoUrl && <Image src={logoUrl} alt={`${agencyName} logo`} />}
    </div>
    <p css={styles.infoBoxName}>
      <span css={srOnly}>Agency Name: </span>
      {agencyName}
    </p>
    <a
      title="View agency in google maps"
      css={styles.infoBoxAddress}
      href={`https://www.google.com/maps/search/?api=1&query=${displayAddress}`}
    >
      <PinIconSmall css={styles.pinOffset} />
      <span css={srOnly}>Agency Address: </span>
      {displayAddress}
    </a>
  </div>
);

AgencyInfoBox.defaultProps = {
  agencyName: undefined,
  displayAddress: undefined,
  logoUrl: undefined,
};

AgencyInfoBox.propTypes = {
  agencyName: PropTypes.string,
  displayAddress: PropTypes.string,
  logoUrl: PropTypes.string,
};

export default AgencyInfoBox;
