import React, { ReactNode } from 'react';
import { gql, useQuery } from '@apollo/client';
import AgencyHeroWithFragment from './agency-hero-with-fragment';
import { GetAgencyHeroQuery } from '../../generated/graphql';

export type Props = {
  agencyId: number;
  renderComponent: ReactNode;
  emailFormComponentId: string;
  onAgencyAddressClick?: () => void;
  onAgencyLogoClick?: () => void;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  onSocialClick?: () => void;
};

export const GET_AGENCY_HERO_QUERY = gql`
  query getAgencyHero($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      ...AgencyHero
    }
  }
  ${AgencyHeroWithFragment.fragment}
`;

const AgencyHeroWithQuery = ({
  agencyId,
  renderComponent,
  emailFormComponentId,
  onAgencyAddressClick,
  onAgencyLogoClick,
  onCallButtonClick,
  onEmailButtonClick,
  onSocialClick,
}: Props): React.ReactElement | null => {
  const { data } = useQuery<GetAgencyHeroQuery>(GET_AGENCY_HERO_QUERY, {
    variables: { agencyId },
  });

  if (!data) {
    return null;
  }

  return (
    <AgencyHeroWithFragment
      agencyId={agencyId}
      renderComponent={renderComponent}
      emailFormComponentId={emailFormComponentId}
      onAgencyAddressClick={onAgencyAddressClick}
      onAgencyLogoClick={onAgencyLogoClick}
      onCallButtonClick={onCallButtonClick}
      onEmailButtonClick={onEmailButtonClick}
      onSocialClick={onSocialClick}
    />
  );
};

export default AgencyHeroWithQuery;
