import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { handleError } from '@domain-group/fe-helper';
import { GetAgencySalesStatisticsDataQuery } from '../../../generated/graphql';
import { IActiveSuburb, ISuburbStatistics } from '../@types';
import SalesStatistics, { ALL_SUBURBS_SUBURB_SLUG } from './sales-statistics';
import { SUBURB_STATISTICS_FRAGMENT } from '../shared';

export const GET_AGENCY_SALES_STATISTICS_DATA_QUERY = gql`
  query getAgencySalesStatisticsData($agencyId: Int!, $suburbSlug: String) {
    agency(agencyId: $agencyId) {
      id
      suburbStatisticsData: suburbStatistics(suburbSlug: $suburbSlug) {
        ...SuburbStatistics
      }
    }
  }
  ${SUBURB_STATISTICS_FRAGMENT}
`;

export type Props = {
  agencyId: number;
  tracking?: {
    onSuburbChange?: (suburbName: string) => void;
  };
  activeSuburbs: IActiveSuburb[];
  allSuburbStatistics: ISuburbStatistics;
};

const AgencySalesStatistics = ({
  agencyId,
  tracking,
  activeSuburbs,
  allSuburbStatistics,
}: Props): React.ReactElement | null => {
  const [suburbSlug, setSuburbSlug] = useState<string>(ALL_SUBURBS_SUBURB_SLUG);

  const { data, error, loading } = useQuery<GetAgencySalesStatisticsDataQuery>(
    GET_AGENCY_SALES_STATISTICS_DATA_QUERY,
    {
      skip: suburbSlug === ALL_SUBURBS_SUBURB_SLUG,
      variables: {
        agencyId,
        suburbSlug,
      },
    },
  );

  if (error) {
    handleError(error);
  }

  return (
    <SalesStatistics
      tracking={tracking}
      activeSuburbs={activeSuburbs}
      selectedSuburb={suburbSlug}
      setSelectedSuburb={setSuburbSlug}
      allSuburbStatistics={allSuburbStatistics}
      loading={loading}
      suburbStatisticsData={data?.agency?.suburbStatisticsData}
    />
  );
};

export default AgencySalesStatistics;
