import { css, SerializedStyles } from '@emotion/react';
import {
  media,
  rgba,
  tokens,
  globalTokens,
  applyFont,
} from '@domain-group/fe-brary';
import { contactButtonsDesktopWidth } from '../../styles/contact-buttons.style';
import { salesStatisticsWidth } from './components/sold-statistics/sold-statistics.style';

const { colors, shadows, borderRadius, spacing, typography } = tokens;

const avatarSpacing = 4; // tokens.spacing.xxs
const avatarSize = 40;
export const avatarsWidth = avatarSize * 3 + avatarSpacing * 4;

export const container = css`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: inherit;
  position: relative;
  box-sizing: border-box;
  background: ${colors.neutral.surface.default};
  color: ${colors.neutral.heavy.default};
  box-shadow: ${shadows.elevation3.key.x} ${shadows.elevation3.key.y}
    ${shadows.elevation3.key.radius} ${shadows.elevation3.key.color}
    ${shadows.elevation3.key.opacity};
`;

export const body = ({
  isVertical = false,
  isPlatinum = false,
}: {
  isVertical: boolean;
  isPlatinum: boolean;
}): SerializedStyles => css`
  border: 1px solid ${colors.neutral.trim.default};
  border-top: 0;
  border-radius: 0 0 ${borderRadius.mini} ${borderRadius.mini};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: inherit;
  height: inherit;
  grid-column-gap: ${spacing.s};
  grid-row-gap: ${spacing.s};
  padding: ${spacing.m};

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      padding: ${spacing.m} ${spacing.m};
      display: grid;
      grid-template-columns: minmax(160px, 210px) auto auto;
      grid-template-rows: auto auto;
      grid-template-areas: '. . .' 'footer footer footer';
      grid-column-gap: ${spacing.l};
      grid-row-gap: ${spacing.l};
      align-items: center;
    }

    ${media('>=desktop')} {
      // Title/address, sold statistics, avatars, contact buttons
      grid-template-columns:
        280px minmax(${salesStatisticsWidth}px, auto)
        minmax(${avatarsWidth}px, auto)
        ${contactButtonsDesktopWidth}px;
      grid-template-rows: auto;
      grid-template-areas: none;
    }
  `}

  ${!isVertical &&
  isPlatinum &&
  css`
    ${media('>=tablet')} {
      padding: ${spacing.l} ${spacing.m};
    }
  `}
`;

export const name = ({
  isVertical = false,
  isPlatinum = false,
}: {
  isVertical: boolean;
  isBranded: boolean;
  isPlatinum: boolean;
}): SerializedStyles => css`
  ${applyFont(typography.subheading)}
  margin-bottom: ${spacing.xxs};

  ${!isVertical &&
  !isPlatinum &&
  css`
    ${media('>=tablet')} {
      ${applyFont(typography.bodyBold)}
  `};
`;

export const location = css`
  ${applyFont(typography.caption)};
`;

export const link = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const modalContainer = css`
  padding: ${spacing.m};

  ${media('>=desktop')} {
    padding: ${spacing.l};
  }
`;

export const avatars = ({
  isVertical,
}: {
  isVertical: boolean;
}): SerializedStyles => css`
  display: flex;
  min-width: ${avatarsWidth}px;
  margin: 0 calc(${spacing.xxs} * -1);

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      justify-content: flex-end;
    }

    ${media('>=desktop')} {
      justify-content: center;
    }
  `};
`;

export const avatar = css`
  height: ${avatarSize}px;
  width: ${avatarSize}px;
  border-radius: 50%;
  border: 2px solid ${colors.neutral.surface.default};
  box-shadow: 0px 3px 3px ${rgba(globalTokens.colors.ocean700, 0.15)};
  margin: 0 ${spacing.xxs};
  transition: all ease 0.2s;

  &:hover,
  &:active {
    border: 2px solid ${colors.neutral.surface.hover};
    box-shadow: 0px 3px 3px ${rgba(globalTokens.colors.black, 0.45)};
  }
`;

export const contactButtonsWrapper = (
  isVertical = false,
): SerializedStyles => css`
  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      grid-area: footer;
    }

    ${media('>=desktop')} {
      display: flex;
      justify-content: flex-end;
      grid-area: inherit;
    }
  `}
`;
