/* eslint-disable import/prefer-default-export */
import type { AgencyDigitalDataFragment } from '../../../../../generated/graphql';

import type { SchemaAgency } from '../../../../../@types/schema';

export const makeAgencyHTMLSchema = ({
  baseUrl,
  originalUrl,
  data,
}: {
  baseUrl: string;
  originalUrl: string;
  data: AgencyDigitalDataFragment;
}): SchemaAgency => {
  const schema = {
    '@context': 'http://schema.org' as SchemaAgency['@context'],
    '@type': 'RealEstateAgent' as SchemaAgency['@type'],
    areaServed: (data?.activeSuburbs ?? [])
      .map((entry) => entry?.suburbName ?? '')
      .filter((entry) => entry !== ''),
    name: data?.name ?? '',
    logo: data?.branding?.logo?.url ?? '',
    image: data?.branding?.hero?.url ?? '',
    address: data?.address?.displayAddress ?? '',
    url: `${baseUrl}${originalUrl}${data?.profileUrl ?? ''}/`,
  };
  return schema;
};
