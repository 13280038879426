import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const InView = ({
  children,
  onActivate,
}: {
  children: React.ReactNode;
  onActivate: (inView: boolean) => void;
}): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      onActivate(true);
    }
  }, [inView, onActivate]);

  return <div ref={ref}>{children}</div>;
};

export default InView;
