import {
  ContactCurrentListingsQuery,
  ContactListingsTabFragment,
  ListingFilterStatus,
} from '../../../generated/graphql';
import type { Choice } from '../@types';

const getListingTabData = (
  listingData?: NonNullable<
    ContactCurrentListingsQuery['contactByContactId']
  >['agentsearchListingsByAgentIdV2AndAgencyId'],
  listingStatus?: Choice<ListingFilterStatus>,
): ContactListingsTabFragment | null => {
  if (
    listingStatus?.value === 'SALE' ||
    (!listingStatus && listingData?.saleListings?.total)
  ) {
    return listingData?.saleListings || null;
  }

  if (
    listingStatus?.value === 'LEASE' ||
    (!listingStatus && listingData?.leaseListings?.total)
  ) {
    return listingData?.leaseListings || null;
  }

  if (
    listingStatus?.value === 'SOLD' ||
    (!listingStatus && listingData?.soldListings?.total)
  ) {
    return listingData?.soldListings || null;
  }

  if (
    listingStatus?.value === 'LEASED' ||
    (!listingStatus && listingData?.leasedListings?.total)
  ) {
    return listingData?.leasedListings || null;
  }

  return null;
};

export default getListingTabData;
