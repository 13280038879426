import React from 'react';

import * as styles from './search-filter-bar.styles';
import { SearchAutocomplete } from '../../..';
import FiltersBar from './filters-bar';
import { Option } from '../../../search-autocomplete/types';
import { AgencySearchQuery } from '../../../../generated/graphql';

interface SearchFilterBarProps {
  isAgency: boolean;
  suburbOptions: AgencySearchQuery['locationProfiles'];
  selectSuburbs: (newSuburbs: Option[]) => void;
  selectSearchType: (newSearchType: string | null) => void;
  currentSearchType?: string;
  selectAgentType: (newAgentType: string | null) => void;
  currentAgentType?: string;
  selectFilter: (newFilter: string | null) => void;
  currentFilter?: string;
  selectSearchTypeAndFilters: (
    newSearchType: string | null,
    newAgentType: string | null,
    newFilter: string | null,
  ) => void;
}

const SearchFilterBar = ({
  isAgency,
  suburbOptions = [],
  selectSuburbs,
  selectSearchType,
  currentSearchType,
  selectAgentType,
  currentAgentType,
  selectFilter,
  currentFilter,
  selectSearchTypeAndFilters,
}: SearchFilterBarProps): JSX.Element => {
  const initialSelectedOption = suburbOptions?.length
    ? suburbOptions.map((option) => ({
        category: 'Suburb' as Option['category'],
        label: `${option?.suburbName}, ${option?.postcode}, ${option?.state}`,
        value: option?.urlSlug ?? '',
        url: option?.urlSlug ?? '',
        redirectToURL: false,
      }))
    : [];

  return (
    <div css={styles.wrapper}>
      <div css={styles.layout}>
        <SearchAutocomplete
          appearance="small"
          icons={false}
          initialSelectedOption={initialSelectedOption}
          performSearchQuery={selectSuburbs}
        />
        <FiltersBar
          isAgency={isAgency}
          selectSearchType={selectSearchType}
          currentSearchType={currentSearchType}
          selectAgentType={selectAgentType}
          currentAgentType={currentAgentType}
          selectFilter={selectFilter}
          currentFilter={currentFilter}
          selectSearchTypeAndFilters={selectSearchTypeAndFilters}
        />
      </div>
    </div>
  );
};

export default SearchFilterBar;
