import React, { ReactNode, useState } from 'react';
import { gql, useFragment } from '@apollo/client';
import { ContactHeroFragment } from '../../generated/graphql';
import { PROFILE_TIERS } from './constants';
import Hero, {
  AgencyDetailsSectionSmall,
  AgencyDetailsSticky,
  ContactBanner,
  ContactDetailsSection,
  ContactDetailsSticky,
  Social,
} from './components';

export type Props = {
  agentIdV2: string;
  agencyId: number;
  renderComponent: ReactNode;
  emailFormComponentId: string;
  desktopWidth?: number;
  onAgencyAddressClick?: () => void;
  onAgencyLogoClick?: () => void;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  onSocialClick?: () => void;
};

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactHero';
const FRAGMENT = gql`
  fragment ContactHero on Contact {
    id
    agentIdV2
    agencyId

    name
    agencyName
    jobCategory

    telephone
    mobile
    hasEmail
    address {
      displayAddress
    }

    profilePhoto {
      url
    }

    agencyLogo
    agencyUrlPath
    agencyByAgencyId {
      hideOfficeLocation

      branding {
        backgroundColour
        logo {
          url
        }
        hero {
          url
        }
      }

      youtubeChannelUrl
      website
    }

    facebookUrl
    twitterUrl
    linkedInUrl
    instagramUrl
    youTubeChannelUrl
    agentVideoUrl
    personalWebsiteUrl
    profileTier
  }
`;

const ContactHeroWithFragment = ({
  agentIdV2,
  agencyId,
  renderComponent,
  emailFormComponentId,
  desktopWidth,
  onAgencyAddressClick = () => undefined,
  onAgencyLogoClick = () => undefined,
  onCallButtonClick = () => undefined,
  onEmailButtonClick = () => undefined,
  onSocialClick = () => undefined,
}: Props): JSX.Element | null => {
  const [isStickyActive, setStickyActive] = useState(false);

  const { complete, data: contact } = useFragment<ContactHeroFragment>({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactHeroWithFragment.fragment,
    fragmentName: ContactHeroWithFragment.fragmentName,
  });

  if (!complete || !contact) {
    return null;
  }

  const socialLinks = {
    web: contact.personalWebsiteUrl || contact.agencyByAgencyId?.website,
    facebook: contact.facebookUrl,
    twitter: contact.twitterUrl,
    linkedIn: contact.linkedInUrl,
    instagram: contact.instagramUrl,
    youtube:
      contact.youTubeChannelUrl || contact.agencyByAgencyId?.youtubeChannelUrl,
  };

  const profileTier =
    (contact.profileTier as PROFILE_TIERS) ?? PROFILE_TIERS.basic;

  return (
    <Hero
      desktopWidth={desktopWidth}
      profileTier={profileTier}
      backgroundImage={
        contact.agencyByAgencyId?.branding?.hero?.url ?? undefined
      }
      backgroundColour={
        contact.agencyByAgencyId?.branding?.backgroundColour ?? 'transparent'
      }
      isAgency={false}
      hasEmail={contact.hasEmail}
      phoneNumber={(contact.mobile || contact.telephone) ?? ''}
      name={contact.name ?? ''}
      setStickyActive={setStickyActive}
      emailFormComponentId={emailFormComponentId}
      renderComponent={renderComponent}
      onCallButtonClick={onCallButtonClick}
      onEmailButtonClick={onEmailButtonClick}
      bannerElement={
        <>
          {contact.profileTier === PROFILE_TIERS.platinum ||
          contact.profileTier === PROFILE_TIERS.branded ? (
            <ContactBanner
              profileTier={profileTier}
              avatarUrl={contact.profilePhoto?.url ?? ''}
              name={contact.name ?? ''}
              jobPosition={contact.jobCategory}
            />
          ) : null}
          <Social
            isAgency={false}
            {...socialLinks}
            onSocialClick={onSocialClick}
            isPlatinum={profileTier === PROFILE_TIERS.platinum}
          />
        </>
      }
      stickyElement={
        contact.profileTier === PROFILE_TIERS.platinum ? (
          <>
            <ContactDetailsSticky
              avatarUrl={contact.profilePhoto?.url ?? ''}
              name={contact.name ?? ''}
              agencyName={contact.agencyName}
              jobPosition={contact.jobCategory}
              isActive={isStickyActive}
            />
            <AgencyDetailsSticky
              isAgency={false}
              profileTier={profileTier}
              agencyName={contact.agencyName ?? ''}
              agencyUrl={contact.agencyByAgencyId?.website ?? ''}
              imageUrl={contact.agencyByAgencyId?.branding?.logo?.url ?? ''}
              address={contact.address?.displayAddress ?? ''}
              isActive
              hideOfficeLocation={
                contact.agencyByAgencyId?.hideOfficeLocation ?? false
              }
              onAgencyAddressClick={onAgencyAddressClick}
              onAgencyLogoClick={onAgencyLogoClick}
            />
          </>
        ) : null
      }
      sectionElement={
        <ContactDetailsSection
          profileTier={profileTier}
          avatarUrl={contact.profilePhoto?.url ?? ''}
          name={contact.name ?? ''}
          jobPosition={contact.jobCategory}
          phoneNumber={(contact.mobile || contact.telephone) ?? ''}
          hasEmail={contact.hasEmail}
          emailFormComponentId={emailFormComponentId}
          onCallButtonClick={onCallButtonClick}
          onEmailButtonClick={onEmailButtonClick}
          socialRender={
            profileTier === PROFILE_TIERS.basic ? (
              <Social
                inBanner={false}
                isAgency
                {...socialLinks}
                onSocialClick={onSocialClick}
              />
            ) : null
          }
          agencyDetailsRender={
            <AgencyDetailsSectionSmall
              profileTier={profileTier}
              agencyName={contact.agencyName ?? ''}
              agencyUrl={contact.agencyByAgencyId?.website ?? ''}
              imageUrl={contact.agencyByAgencyId?.branding?.logo?.url ?? ''}
              address={contact.address?.displayAddress ?? ''}
              isAgency={false}
              onAgencyAddressClick={onAgencyAddressClick}
              onAgencyLogoClick={onAgencyLogoClick}
            />
          }
        />
      }
    />
  );
};

ContactHeroWithFragment.fragment = FRAGMENT;
ContactHeroWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactHeroWithFragment;
