import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { ResponsiveCard } from '@domain-group/fe-co-listing-card';

import { useShortlist } from '../../hooks';
import { ListingCardFragment } from '../../generated/graphql';
import { mapToListingModel } from './utils/map-to-next-listing-model';
import { toGlobalId } from '../../utils';
import { ShortlistModel } from './@types';

const FRAGMENT_NAME = 'ListingCard';
const LISTING_CARD_FRAGMENT = gql`
  fragment ListingCard on Listing {
    id
    listingId
    promoLevel
    seoUrl
    isRural
    priceDetails {
      displayPrice
    }

    address {
      displayAddress
      street
      suburb {
        name
        suburbId
      }
      state
      postcode
      geolocation {
        latitude
        longitude
      }
    }

    inspectionDetails {
      inspections {
        openingDateTime
        closingDateTime
      }
    }

    parkingSpaces
    bedrooms
    bathrooms
    landArea(unit: SQUARE_METERS)
    propertyTypes

    media {
      __typename
      ... on Image {
        type
        url(resolution: { width: 660, height: 440 })
      }
    }
  }
`;

interface ListingCardWithFragmentProps {
  adId: number;
  adComponent: string;
}

const ListingCardWithFragment = ({
  adId,
  adComponent,
}: ListingCardWithFragmentProps): JSX.Element | null => {
  const listingGlobalId = toGlobalId('listing', adId); // Get GraphQL GlobalId

  const { complete, data } = useFragment<ListingCardFragment, unknown>({
    from: {
      __typename: 'Listing',
      id: listingGlobalId,
    },
    fragment: LISTING_CARD_FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });

  const { isOnShortlist, addToShortlist, removeFromShortlist, scrollToRef } =
    useShortlist(adId, adComponent);

  if (!complete || !data) {
    return null;
  }

  const isShortlisted = isOnShortlist;

  const shortlist: ShortlistModel = {
    shortlisted: isShortlisted || false,
  };

  const listingData = mapToListingModel(data, { shortlist });

  const onShortlistClick = () =>
    isShortlisted ? removeFromShortlist() : addToShortlist();

  return (
    <div id={`next-listing__${adId}`} ref={scrollToRef}>
      <ResponsiveCard
        {...listingData}
        lazyLoad={false}
        showNotesButton={false}
        showEnquireButton={false}
        onShortlistClick={onShortlistClick}
      />
    </div>
  );
};

ListingCardWithFragment.fragment = LISTING_CARD_FRAGMENT;
ListingCardWithFragment.fragmentName = FRAGMENT_NAME;

export default ListingCardWithFragment;
