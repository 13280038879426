import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeftIconSmall } from '@domain-group/fe-co-icon';
import { useFunnel } from '@domain-group/fe-funnel-utils';
import { srOnly } from '@domain-group/fe-brary';
import { Avatar, Form, PrivacyPolicy } from '../../../shared';
import * as styles from './step-details.style';

const Details = () => {
  const { funnelState, funnelProps, nav, dispatch } = useFunnel();

  const { details } = funnelState;
  const { isShortForm } = funnelProps;

  const onBack = () => {
    nav.next({
      type: 'clearInput',
      details: null,
    });
  };

  const onSubmit = (submitValues) => {
    if (isShortForm) {
      dispatch({
        type: 'setIntent',
        intent: submitValues.intent,
      });
    }

    nav.next({
      type: 'setDetails',
      details: submitValues,
    });
  };

  return (
    <div css={styles.wrapper(isShortForm)}>
      {isShortForm ? (
        <div css={styles.header}>
          <div css={styles.headerContent}>
            <h3 css={styles.title}>Contact {funnelProps.contact.name}</h3>
          </div>
        </div>
      ) : (
        <div css={styles.header}>
          <div css={styles.headerContent}>
            <button onClick={onBack} type="button" css={styles.backButton}>
              <ChevronLeftIconSmall css={styles.backIcon} />
              Back <span css={srOnly}>to previous step</span>
            </button>
            <h3 css={styles.title}>{funnelState.intent}</h3>
            <p css={styles.body}>
              Send a message to {funnelProps.contact.name}
            </p>
          </div>
          <motion.div layoutId="avatar" css={styles.avatarHolder}>
            <Avatar
              imageTitle={funnelProps.contact.name}
              imageUrl={funnelProps.contact.profilePhoto.url}
              css={styles.avatar}
            />
          </motion.div>
        </div>
      )}
      <Form
        details={details}
        showIntentOptions={isShortForm}
        onSubmit={onSubmit}
        submissionError={funnelState.submissionError}
      />
      <PrivacyPolicy />
    </div>
  );
};

export default Details;
