import { css } from '@emotion/react';
import { tokens, applyFont, aTag } from '@domain-group/fe-brary';

const { typography, spacing, colors } = tokens;

// eslint-disable-next-line import/prefer-default-export
export const paragraph = css`
  margin-bottom: ${spacing.s};
  color: ${colors.neutral.heavy.default};

  b {
    ${applyFont(typography.bodyBold)}
  }

  a {
    ${aTag};
  }
`;
