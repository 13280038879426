import React from 'react';
import * as styles from './domain-for-owners.style';
import Link from '../link/link';
import { getPublicUrl } from '../../../../utils';
import ContentContainer from '../content-container/content-container';

const myHomeImage = getPublicUrl('/seller-landing/my-home.png');

const textSection = ({
  baseUrl = 'https://www.domain.com.au',
  trackAppraisalFunnel,
}: {
  baseUrl?: string;
  trackAppraisalFunnel: () => void;
}): JSX.Element => (
  <div css={styles.textSection}>
    <h2>Domain for Owners</h2>
    <p>
      Track interest in your property. Add your property to your Domain account,
      to follow current demand and local activity.
    </p>
    <div css={styles.link}>
      <Link
        href={`${baseUrl}/services/track-home`}
        onClick={trackAppraisalFunnel}
      >
        See current demand
      </Link>
    </div>
  </div>
);

const imageSection = () => (
  <div css={styles.imageSection}>
    <img
      css={styles.image}
      src={myHomeImage}
      alt="My Home page on Domain.com.au"
    />
  </div>
);

const DomainForOwners = ({
  baseUrl = 'https://www.domain.com.au',
  trackAppraisalFunnel,
}: {
  baseUrl?: string;
  trackAppraisalFunnel: () => void;
}): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.contentWrapper}>
      <ContentContainer
        overrideStyles={styles.overriddenStyling}
        first={textSection({ baseUrl, trackAppraisalFunnel })}
        second={imageSection()}
        changeForDevice="tablet"
      />
    </div>
  </div>
);

export default DomainForOwners;
