import React from 'react';
import { gql, useFragment } from '@apollo/client';

import { AgencyProfileCard, AgencyProfileCardProps } from '..';
import { AgencySearchProfileCardFragment } from '../../generated/graphql';
import { notEmpty } from '../../utils';

const TYPE_NAME = 'AgencySearchAgency';
const FRAGMENT_NAME = 'AgencySearchProfileCard';
const FRAGMENT = gql`
  fragment AgencySearchProfileCard on AgencySearchAgency {
    id
    name

    displayAddress

    profileTier
    brandColour
    logoSmall

    profileUrl

    mobile
    telephone
    email

    hideOfficeLocation

    totalForSale
    totalSoldAndAuctioned
    totalForRent

    agents {
      name
      profileUrl
      profilePhoto
    }
  }
`;

const AgencySearchProfileCardWithFragment = ({
  agencyId,
  onCall,
  onCallReveal,
  onViewProfile,
  onOpenEnquiryForm,
  onEnquirySubmit,
}: {
  agencyId: number;
  onCall?: () => void;
  onCallReveal?: () => void;
  onViewProfile?: (details: { agencyId: number }) => void;
  onOpenEnquiryForm?: () => void;
  onEnquirySubmit?: (details: {
    firstName: string;
    lastName: string;
    email: string;
    postcode: string;
    phone?: string;
    intent?: string;
    message?: string;
  }) => void;
}): JSX.Element | null => {
  const { complete, data: agency } = useFragment<
    AgencySearchProfileCardFragment,
    unknown
  >({
    from: {
      __typename: TYPE_NAME,
      id: agencyId,
    },
    fragment: AgencySearchProfileCardWithFragment.fragment,
    fragmentName: FRAGMENT_NAME,
  });

  /* Handling unlikely missing data */
  if (!complete || !agency?.name || !agency?.profileUrl) {
    return null;
  }

  const topAgents = agency.agents?.filter(notEmpty)?.map((agent) => ({
    ...agent,
    profileUrl: `/real-estate-agent/${agent.profileUrl}`,
  }));

  return (
    <AgencyProfileCard
      agencyId={agencyId}
      name={agency?.name}
      profileUrl={`/real-estate-agencies/${agency?.profileUrl}`}
      telephone={agency?.telephone || agency?.mobile}
      logoUrl={agency?.logoSmall}
      logoColour={agency?.brandColour}
      address={agency?.displayAddress}
      statistics={{
        sold: agency?.totalSoldAndAuctioned ?? undefined,
        forSale: agency?.totalForSale ?? undefined,
        forRent: agency?.totalForRent ?? undefined,
      }}
      profileTier={agency?.profileTier as AgencyProfileCardProps['profileTier']}
      hideOfficeLocation={agency?.hideOfficeLocation}
      topAgents={topAgents}
      isVertical={false}
      // Tracking callbacks
      onCall={onCall}
      onCallReveal={onCallReveal}
      onViewProfile={onViewProfile}
      onOpenEnquiryForm={onOpenEnquiryForm}
      onEnquirySubmit={onEnquirySubmit}
    />
  );
};

AgencySearchProfileCardWithFragment.fragmentName = FRAGMENT_NAME;
AgencySearchProfileCardWithFragment.fragment = FRAGMENT;

export default AgencySearchProfileCardWithFragment;
