import React from 'react';
import PluralProperties from './plural-properties';

const showSaleRent = (forSale: number, forRent: number): boolean =>
  forSale > 0 || forRent > 0;

export type SaleRentPhraseProps = {
  forSale: number;
  forRent: number;
  highlight?: boolean;
};
const SaleRentPhrase = ({
  forSale,
  forRent,
  highlight = false,
}: SaleRentPhraseProps): JSX.Element | null => {
  const salePostfix = ' for sale';
  const rentPostfix = ' for rent';

  if (forSale > 0 && forRent > 0) {
    return (
      <>
        <PluralProperties
          propertyNum={forSale}
          postfix={salePostfix}
          highlight={highlight}
        />{' '}
        and{' '}
        <PluralProperties
          propertyNum={forRent}
          postfix={rentPostfix}
          highlight={highlight}
        />
      </>
    );
  }

  if (forSale > 0) {
    return (
      <PluralProperties
        propertyNum={forSale}
        postfix={salePostfix}
        highlight={highlight}
      />
    );
  }

  if (forRent > 0) {
    return (
      <PluralProperties
        propertyNum={forRent}
        postfix={rentPostfix}
        highlight={highlight}
      />
    );
  }

  return null;
};

export { showSaleRent };
export default SaleRentPhrase;
