import React from 'react';
import getConfig from 'next/config';
import Footer from '@domain-group/fe-co-footer';

const { publicRuntimeConfig } = getConfig();

const LandingPageFooter = (): JSX.Element => (
  <Footer baseUrl={publicRuntimeConfig.BASE_URL} />
);

export default LandingPageFooter;
