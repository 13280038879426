import React, { ReactNode, useState } from 'react';
import { gql, useFragment } from '@apollo/client';
import { toGlobalId } from '../../utils';
import { AgencyHeroFragment } from '../../generated/graphql';
import Hero, {
  AgencyBanner,
  AgencyDetailsSection,
  AgencyDetailsSticky,
  Social,
} from './components';
import { PROFILE_TIERS } from './constants';

export type Props = {
  agencyId: number;
  renderComponent: ReactNode;
  emailFormComponentId: string;
  desktopWidth?: number;
  onAgencyAddressClick?: () => void;
  onAgencyLogoClick?: () => void;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  onSocialClick?: () => void;
};

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyHero';
const FRAGMENT = gql`
  fragment AgencyHero on Agency {
    id
    profileTier
    name

    contactDetails {
      general {
        mobile
        phone
        email
      }
    }

    address {
      displayAddress
    }
    hideOfficeLocation

    facebookUrl
    twitterUrl
    instagramUrl
    linkedInUrl
    youtubeChannelUrl
    website

    branding {
      backgroundColour
      logo {
        url
      }
      hero {
        url
      }
    }
  }
`;

const AgencyHeroWithFragment = ({
  agencyId,
  renderComponent,
  emailFormComponentId,
  desktopWidth,
  onAgencyAddressClick = () => undefined,
  onAgencyLogoClick = () => undefined,
  onCallButtonClick = () => undefined,
  onEmailButtonClick = () => undefined,
  onSocialClick = () => undefined,
}: Props): JSX.Element | null => {
  const [isStickyActive, setStickyActive] = useState(false);

  const id = toGlobalId(TYPE_NAME, agencyId);

  const { complete, data: agency } = useFragment<AgencyHeroFragment>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyHeroWithFragment.fragment,
    fragmentName: AgencyHeroWithFragment.fragmentName,
  });

  if (!complete || !agency?.name) {
    return null;
  }

  const socialLinks = {
    web: agency.website,
    facebook: agency.facebookUrl,
    twitter: agency.twitterUrl,
    linkedIn: agency.linkedInUrl,
    instagram: agency.instagramUrl,
    youtube: agency.youtubeChannelUrl,
  };

  const showSocial = !!(
    socialLinks.web ||
    socialLinks.facebook ||
    socialLinks.twitter ||
    socialLinks.linkedIn ||
    socialLinks.instagram ||
    socialLinks.youtube
  );

  const profileTier =
    (agency.profileTier as PROFILE_TIERS) ?? PROFILE_TIERS.basic;

  return (
    <Hero
      desktopWidth={desktopWidth}
      profileTier={profileTier}
      backgroundImage={agency.branding?.hero?.url ?? undefined}
      backgroundColour={agency.branding?.backgroundColour ?? 'transparent'}
      isAgency
      hasEmail={!!agency.contactDetails?.general?.email ?? false}
      phoneNumber={agency.contactDetails?.general?.phone ?? ''}
      name={agency.name}
      setStickyActive={setStickyActive}
      emailFormComponentId={emailFormComponentId}
      renderComponent={renderComponent}
      onCallButtonClick={onCallButtonClick}
      onEmailButtonClick={onEmailButtonClick}
      bannerElement={
        <>
          {profileTier === PROFILE_TIERS.platinum ? (
            <AgencyBanner
              agencyName={agency.name}
              address={agency.address?.displayAddress ?? ''}
              hideOfficeLocation={agency.hideOfficeLocation ?? true}
              onAgencyAddressClick={onAgencyAddressClick}
            />
          ) : null}
          {showSocial ? (
            <Social
              isAgency
              {...socialLinks}
              onSocialClick={onSocialClick}
              isPlatinum={profileTier === PROFILE_TIERS.platinum}
            />
          ) : null}
        </>
      }
      stickyElement={
        profileTier === PROFILE_TIERS.platinum ? (
          <AgencyDetailsSticky
            profileTier={profileTier}
            agencyName={agency.name}
            agencyUrl={agency.website ?? ''}
            imageUrl={agency.branding?.logo?.url ?? ''}
            address={agency.address?.displayAddress ?? ''}
            onAgencyAddressClick={onAgencyAddressClick}
            onAgencyLogoClick={onAgencyLogoClick}
            isActive={isStickyActive}
          />
        ) : null
      }
      sectionElement={
        <AgencyDetailsSection
          profileTier={profileTier}
          hasEmail={!!agency.contactDetails?.general?.email ?? false}
          agencyName={agency.name}
          agencyUrl={agency.website ?? ''}
          imageUrl={agency.branding?.logo?.url ?? ''}
          address={agency.address?.displayAddress ?? ''}
          phoneNumber={agency.contactDetails?.general?.phone ?? ''}
          emailFormComponentId={emailFormComponentId}
          onAgencyAddressClick={onAgencyAddressClick}
          onAgencyLogoClick={onAgencyLogoClick}
          onCallButtonClick={onCallButtonClick}
          onEmailButtonClick={onEmailButtonClick}
          socialRender={
            showSocial && profileTier === PROFILE_TIERS.basic ? (
              <Social
                inBanner={false}
                isAgency
                {...socialLinks}
                onSocialClick={onSocialClick}
              />
            ) : null
          }
        />
      }
    />
  );
};

AgencyHeroWithFragment.fragment = FRAGMENT;
AgencyHeroWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyHeroWithFragment;
