import { css } from '@emotion/react';
import { h4Tag, fontBase, tokens } from '@domain-group/fe-brary';

export const header = css`
  display: flex;
  justify-content: space-between;
`;

export const headerContent = css`
  text-align: left;
  ${fontBase};
  color: ${tokens.colors.neutral.medium.default};
`;

export const title = css`
  ${h4Tag};
  color: ${tokens.colors.neutral.heavy.default};
  margin-bottom: ${tokens.spacing.xxs};
`;
