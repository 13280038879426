import DOMPurify from 'isomorphic-dompurify';

// Remove any <p> nodes which have no textual content or <br>s
const removeEmptyParas = (node: Element) => {
  if (
    node.nodeName === 'P' &&
    !node.textContent?.trim() &&
    Array.from(node.children).filter((each) => each.nodeName === 'BR')
      .length === 0
  ) {
    node.remove();
  }
};

/* Sanitisation of agency/agent bios which are created in a WYSIWYG Editor
 * TODO: Remove empty tags (such as <p>whitespace</p>)
 *
 * Discussion: https://github.com/domain-group/fe-faa-components/pull/60/files#r648054119
 * Original Code to emulate: https://github.com/domain-group/fe-server-find-an-agent/blob/1b5e15777bc16fbf2fb9ba238bd9ee7699b48bd4/src/component-data/fe-co-profile-description.js#L38-L40
 * Ticket: https://domain.atlassian.net/browse/VX-1198
 */
const sanitizeBio = (dirtyText: string): string => {
  DOMPurify.removeAllHooks();
  DOMPurify.addHook('afterSanitizeElements', removeEmptyParas);
  const sanitisedText = DOMPurify.sanitize(dirtyText.replace(/\r?\n|\r/g, ''), {
    ALLOWED_TAGS: [
      'p',
      'div',
      'strong',
      'em',
      'u',
      'strike',
      'ol',
      'ul',
      'li',
      'br',
      // allow aesthetically similar tags in case agent manually input HTML
      'b',
      'del',
      's',
      'i', // similar to <em>
    ],
    ALLOWED_ATTR: [],
    IN_PLACE: true,
  });
  // Filters out `\n` characters since they're invalid HTMLanyway.
  // Emulates https://github.com/domain-group/fe-server-find-an-agent/blob/1b5e15777bc16fbf2fb9ba238bd9ee7699b48bd4/src/component-data/fe-co-profile-description.js#L41-L43
  return sanitisedText.replace(/\r?\n|\r/g, '').trim();
};

export default sanitizeBio;
