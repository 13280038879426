import React, { useState } from 'react';
import Carousel from '@domain-group/fe-co-carousel';
import Modal from '@domain-group/fe-co-modal';
import Quote from './quote';
import TestimonialText from './testimonial-text';
import * as styles from './testimonials.style';

export interface Testimonial {
  testimonial: string;
  author: string;
  dateAuthored: string;
}

type TestimonialsProps = {
  testimonials: Testimonial[];
};

const Testimonials = ({ testimonials }: TestimonialsProps): JSX.Element => {
  const [activeModal, setActiveModal] = useState<number | undefined>();

  const activeTestimonial =
    typeof activeModal === 'number' && testimonials[activeModal];

  return (
    <div css={styles.testimonials} data-testid="trade-testimonials">
      <h2 css={styles.title} data-testid="trade-testimonials__title">
        Testimonials
      </h2>
      <Carousel dots dotsClass="carousel__dots">
        {testimonials.map((testimonial, index) => (
          <div key={testimonial.author}>
            <div css={styles.carouselTestimonial}>
              <Quote
                author={testimonial.author}
                dateAuthored={testimonial.dateAuthored}
              >
                <TestimonialText
                  index={index}
                  author={testimonial.author}
                  testimonial={testimonial.testimonial}
                  summaryCharLimit={180}
                  setActiveModal={setActiveModal}
                />
              </Quote>
            </div>
          </div>
        ))}
      </Carousel>
      {/* Modal needs to appear outside of Carousel */}
      {activeTestimonial && (
        <Modal closeCallback={() => setActiveModal(undefined)}>
          <div css={styles.testimonialsModal}>
            <Quote
              author={activeTestimonial.author}
              dateAuthored={activeTestimonial.dateAuthored}
            >
              <div
                css={styles.quote}
                data-testid="trade-testimonial__modal-quote"
              >
                {activeTestimonial.testimonial}
              </div>
            </Quote>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Testimonials;
