import { css, SerializedStyles } from '@emotion/react';
import { tokens, media, rgba, globalTokens } from '@domain-group/fe-brary';

export const socialLinks = (
  inBanner: boolean,
  belowDetails: boolean,
): SerializedStyles => css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${tokens.spacing.s} auto 0 auto;

  ${media('>=tablet')} {
    margin-top: ${belowDetails ? tokens.spacing.s : '0'};
  }

  ${!inBanner &&
  css`
    ${media('>=tablet')} {
      margin: ${tokens.spacing.xs} 0 0 0;
      justify-content: flex-start;
    }
  `};
`;

export const socialItem = (inBanner: boolean): SerializedStyles => css`
  ${inBanner
    ? css`
        margin: 0 ${tokens.spacing.xxs};
      `
    : css`
        &:not(:last-of-type) {
          margin-right: ${tokens.spacing.xs};
        }
      `}
`;

export const socialLinkIcon = (inBanner: boolean): SerializedStyles => css`
  display: block;
  border-radius: 100%;
  line-height: 1;
  transition: background-color 0.25s ease-in-out;
  padding: ${tokens.spacing.xxs};

  ${inBanner
    ? css`
        color: ${tokens.colors.neutral.surface.default};
        background-color: ${rgba(globalTokens.colors.white, 0.2)};

        &:hover {
          background-color: ${rgba(globalTokens.colors.white, 0.5)};
        }
      `
    : css`
        color: ${tokens.colors.neutral.surface.default};
        background-color: ${tokens.colors.neutral.base.hover};

        &:hover {
          background-color: ${tokens.colors.neutral.base.pressed};
        }
      `}
`;
