import React from 'react';

import ProfileDescription from './profile-description';
import Badge from './badge';
import * as styles from './about-section.style';
import fadeIn from '../../../styles/fade-in.style';
import { ListingCardWithFragment } from '../../listing-card';
import AD_COMPONENTS from '../../../constants/ad-components';

const renderListingCard = (adId?: number) => {
  if (!adId) {
    return null;
  }
  return (
    <div css={styles.nextOpenHome} data-testid="trade-profile__next-home-open">
      <h2
        css={styles.nextOpenHomeTitle}
        data-testid="trade-profile__next-home-open-title"
      >
        My next open home is
      </h2>
      <div css={fadeIn()}>
        <ListingCardWithFragment
          adId={adId}
          adComponent={AD_COMPONENTS.NextOpenHome}
        />
      </div>
    </div>
  );
};

type AboutSectionProps = {
  profileTier: {
    basic?: boolean;
    branded?: boolean;
    platinum?: boolean;
  };
  profileDescriptionText: string;
  hasReinswBadge: boolean;
  name: string;
  isAgency: boolean;
  adId?: number;

  // Tracking
  onReadMore?: () => void;
};

const AboutSection = ({
  profileTier,
  profileDescriptionText,
  hasReinswBadge,
  adId,
  name,
  isAgency,
  onReadMore = () => undefined,
}: AboutSectionProps): JSX.Element => (
  <div css={styles.aboutSection} data-testid="trade-profile__about-section">
    <div css={styles.descriptionWrapper}>
      <ProfileDescription
        text={profileDescriptionText}
        profileDescriptionMaxLength={profileDescriptionText?.length ? 800 : 400}
        name={name}
        onReadMore={onReadMore}
      />
      {hasReinswBadge && (
        <Badge
          name={name}
          isFullWidthSection={isAgency || profileTier.basic || !adId}
        />
      )}
    </div>
    {!profileTier.basic && renderListingCard(adId)}
  </div>
);

export default AboutSection;
