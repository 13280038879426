import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './fields.style';

const OptionsField = ({ fieldName, onBlur, onChange, options, selected }) =>
  options.map((option) => {
    const handleChange = (event) => {
      const { value } = event.currentTarget;
      onChange(fieldName, value);
    };
    const handleBlur = () => {
      onBlur(fieldName, true);
    };
    const isSelected = selected === option.value;
    return (
      <div css={styles.optionStyle(isSelected)} key={option.value}>
        <input
          type="radio"
          checked={isSelected}
          name={fieldName}
          id={encodeURIComponent(option.value)}
          value={option.value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor={encodeURIComponent(option.value)}>{option.label}</label> {/* eslint-disable-line */}
      </div>
    );
  });

OptionsField.defaultProps = {
  selected: undefined,
};

OptionsField.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.string,
};

export default OptionsField;
