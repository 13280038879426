import React from 'react';

import { formatCurrency } from '../../../../utils';
import { ISuburbStatistics } from '../../@types';
import * as commonStyles from '../common.style';
import * as styles from './stats.style';

export type Props = {
  suburbStatistics?: ISuburbStatistics | null;
};

const Stats = ({ suburbStatistics = {} }: Props): JSX.Element => (
  <dl css={styles.totals}>
    <div css={styles.statItem}>
      <dt>Total sales</dt>
      <dd>{suburbStatistics?.total}</dd>
    </div>
    <div css={styles.statItem}>
      <dt>
        Average sales price{' '}
        <div css={commonStyles.addendum}>Based on disclosed sale prices</div>
      </dt>
      <dd>{formatCurrency(suburbStatistics?.averageSoldPrice || 0)}</dd>
    </div>
    <div css={styles.statItem}>
      <dt>
        Total sales value{' '}
        <div css={commonStyles.addendum}>Based on disclosed sale prices</div>
      </dt>
      <dd>{formatCurrency(suburbStatistics?.totalSaleAmount || 0)}</dd>
    </div>
    <div css={styles.statItem}>
      <dt>Average days on market</dt>
      <dd>{Math.floor(suburbStatistics?.averageDaysOnMarket || 0)}</dd>
    </div>
    <div css={styles.soldRatio}>
      <dt>
        <span css={styles.srOnly}>Sold by </span>private treaty
      </dt>
      <dd>{suburbStatistics?.salesType?.sold || 0}</dd>
      <dt>
        <span css={styles.srOnly}>Sold by </span>auction
      </dt>
      <dd>{suburbStatistics?.salesType?.auction || 0}</dd>
      <div
        css={styles.soldBar({
          sold: suburbStatistics?.salesType?.sold || 0,
          auction: suburbStatistics?.salesType?.auction || 0,
        })}
      />
    </div>
  </dl>
);

export default Stats;
