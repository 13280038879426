import React from 'react';
import { EmailButton, CallButton } from '../../../shared';

import * as styles from './cta-button-group.style';
import { APPEARANCE } from '../../constants';

export type Props = {
  phoneNumber: string;
  name: string;
  hasEmail?: boolean | null;
  emailFormComponentId: string;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  callButtonAppearance?: APPEARANCE;
  emailButtonAppearance?: APPEARANCE;
  stretched?: boolean;
};

const ContactButtons = ({
  phoneNumber,
  name,
  hasEmail,
  emailFormComponentId,
  onCallButtonClick = () => null,
  onEmailButtonClick = () => null,
  callButtonAppearance = APPEARANCE.outline,
  emailButtonAppearance = APPEARANCE.highlight,
  stretched = false,
}: Props): JSX.Element => (
  <>
    {phoneNumber && (
      <div css={styles.buttonHolder}>
        <CallButton
          phoneNumber={phoneNumber}
          name={name}
          onCallReveal={onCallButtonClick}
          appearance={callButtonAppearance}
          stretched={stretched}
        />
      </div>
    )}
    {hasEmail && (
      <div css={styles.buttonHolder}>
        <EmailButton
          name={name}
          emailFormComponentId={emailFormComponentId}
          onClick={onEmailButtonClick}
          appearance={emailButtonAppearance}
          stretched={stretched}
        />
      </div>
    )}
  </>
);

export default ContactButtons;
