import React from 'react';
import * as styles from './footer.style';

const DomainForOwners = (): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.contentWrapper}>
      <p>
        Sources: 1. Ipsos iris Online Audience Measurement Service April to
        December 2023 Average, Age 14+, PC/laptop/smartphone/tablet, Text only,
        Domain Brand Group including Nine property pages & Realestate.com.au
        Brand Group Homes and Property Category, Exclusive Audience (000’s);
        2.Domain Internal data from 1st Jan 2019 - 31st Dec 2023. 3. Roy Morgan,
        survey period Oct 22 – Sep 23, Domain Print NSW/VIC + Domain Digital,
        last 4 weeks, 12 month average, looking to buy in the next 12 months; 4.
        Roy Morgan, survey period Oct 22 – Sep 23, Domain Print NSW/VIC + Domain
        Digital, last 4 weeks, 12 month average, manager or professional.
      </p>
    </div>
  </div>
);

export default DomainForOwners;
