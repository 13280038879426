/* eslint-disable import/prefer-default-export */
import { css, keyframes, SerializedStyles } from '@emotion/react';

const fadeInAnimation = keyframes`
0% {opacity: 0; transform: scale(0.95);}
100% {opacity: 1; transform: scale(1,1);}
`;

const fadeIn = (delayMs?: number): SerializedStyles => css`
  opacity: 0;
  transform: scale(0.95);
  animation: ${fadeInAnimation} 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: calc(${delayMs || 0} * 130ms);
`;

export default fadeIn;
