import React from 'react';

import * as styles from './wrapper.style';

export type Props = {
  children: JSX.Element | JSX.Element[];
};

const Wrapper = ({ children }: Props): JSX.Element => (
  <div css={styles.wrapper}>{children}</div>
);

export default Wrapper;
