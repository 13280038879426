import { css } from '@emotion/react';
import { tokens, globalTokens, applyFont, rgba } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

const selectedLabelStyle = (selected) =>
  selected
    ? css`
        border: 2px solid ${colors.primary.trim.pressed};
        box-shadow: 1px 1px 1px ${rgba(globalTokens.colors.navy800, 0.1)};

        &:hover,
        &:focus {
          background: ${colors.neutral.surface.default};
          border-color: ${colors.primary.trim.hover};
        }
      `
    : css``;

export const optionStyle = (selected) => css`
  position: relative;
  margin: ${spacing.s} 0;

  label {
    display: block;
    font-weight: bold;
    border-radius: 3px;
    border: 2px solid ${colors.neutral.trim.default};
    color: ${colors.neutral.heavy.default};
    background: ${colors.neutral.surface.default};
    text-align: center;
    cursor: pointer;
    transition:
      background 0.25s ease,
      box-shadow 0.25s ease;
    ${applyFont(typography.bodyBold)}
    padding: ${spacing.s};

    &:hover,
    &:focus {
      background: ${colors.neutral.surface.hover};
      border-color: ${colors.interactive.trim.hover};
      color: ${colors.interactive.heavy.hover};
    }

    ${selectedLabelStyle(selected)};
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus + label {
      ${selectedLabelStyle(selected)};
      color: ${colors.interactive.heavy.hover};
      box-shadow: 0 0 0 3px ${rgba(globalTokens.colors.domainGreen500, '0.25')};
    }
  }
`;

export const checkboxField = css`
  margin: ${spacing.s} 0;

  /* Hacky fix for text alignment in fe-co-form-elements Checkbox component */
  .domain-radio__label-text {
    text-align: left;
  }
`;
