import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import Form, { Details } from './form';
import { joinMessage } from '../../../utils';
import * as styles from './enquiry-form.style';
import { SubmissionSuccess } from './components';
import { PrivacyPolicy } from '..';

export const SEND_ENQUIRY = gql`
  mutation CreateEnquiry($input: EnquiryInput!) {
    createEnquiry(input: $input) {
      s3Key
    }
  }
`;

export type EnquiryFormProps = {
  referenceId?: string | number;
  name?: string | null;
  enquiryType: 'agentProfile' | 'agencyProfile';
  details?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    postcode?: string;
    phone?: string;
    intent?: string;
    message?: string;
  };
  onSubmit?: (submitValues: Details) => void;
  onSubmitSuccess?: (submitValues: Details) => void;
  onSubmitError?: (submitValues: Details) => void;
  onFinish?: () => void;
};

const EnquiryForm = ({
  referenceId,
  name,
  enquiryType,
  details,
  onSubmit = () => null,
  onSubmitSuccess = () => null,
  onSubmitError = () => null,
  onFinish,
}: EnquiryFormProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const [submitEnquiry] = useMutation(SEND_ENQUIRY);

  // Send enquiry
  const sendEnquiry = (submitValues: Details) => {
    setSubmitting(true);
    setSubmissionError(false);

    // Analytics callback
    onSubmit(submitValues);

    const input = {
      enquiryType,
      source: 'website',
      subject: `[domain.com.au] User enquiry from domain.com.au`,
      referenceId,
      firstName: submitValues?.firstName,
      lastName: submitValues?.lastName,
      email: submitValues?.email,
      message: joinMessage({
        intent: submitValues?.intent,
        message: submitValues?.message,
      }),
      ...(submitValues?.phone && { phoneNumber: submitValues?.phone }),
      ...(submitValues?.postcode && {
        postcode: parseInt(submitValues?.postcode, 10),
      }),
    };

    submitEnquiry({
      variables: { input },
    })
      .then(({ errors }) => {
        if (errors?.length) {
          throw new Error('Failed to send enquiry');
        }

        setSubmitting(false);
        setSubmissionSuccess(true);

        // Analytics callback
        onSubmitSuccess(submitValues);
      })
      .catch(() => {
        setSubmitting(false);
        setSubmissionError(true);

        // Analytics callback
        onSubmitError(submitValues);
      });
  };

  const resetForm = () => {
    setSubmitting(false);
    setSubmissionSuccess(false);
    setSubmissionError(false);

    onFinish?.();
  };

  if (submissionSuccess) {
    return <SubmissionSuccess resetForm={resetForm} />;
  }

  return (
    <>
      <div css={styles.header}>
        <div css={styles.headerContent}>
          <h3 css={styles.title}>Contact{name ? ` ${name}` : ''}</h3>
        </div>
      </div>
      <Form
        details={details}
        showIntentOptions
        onSubmit={sendEnquiry}
        submitting={submitting}
        submissionError={submissionError}
      />
      <PrivacyPolicy />
    </>
  );
};

export default EnquiryForm;
