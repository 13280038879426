import React from 'react';

import * as styles from './pagination.style';

export type PaginationDotProps = {
  index: number;
  active: boolean;
  onClick?: (event: React.MouseEvent, idx: number) => void;
};

const PaginationDot = ({
  index,
  active,
  /* istanbul ignore next */
  onClick = () => undefined,
}: PaginationDotProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent) => {
    onClick(event, index);
  };

  return (
    <button
      type="button"
      css={styles.button(active)}
      data-testid="sold-stats__pagination-button"
      onClick={handleClick}
    >
      {index + 1}
    </button>
  );
};

export default PaginationDot;
