import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Loading } from './components';
import AgencySalesStatisticsWithFragment from './agency-sales-statistics-with-fragment';

export const GET_AGENCY_SALES_STATISTICS_QUERY = gql`
  query getAgencySalesStatistics($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      id
      ...AgencyProfileSalesStatistics
    }
  }
  ${AgencySalesStatisticsWithFragment.fragment}
`;

export type AgencySalesStatisticsWithFragmentProps = {
  agencyId: number;
  tracking?: {
    onSuburbChange?: (suburbName: string) => void;
  };
};

const AgencySalesStatisticsWithQuery = ({
  agencyId,
  tracking,
}: AgencySalesStatisticsWithFragmentProps): JSX.Element | null => {
  const { data, loading, error } = useQuery(GET_AGENCY_SALES_STATISTICS_QUERY, {
    variables: { agencyId },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return null;
  }

  return (
    <AgencySalesStatisticsWithFragment
      agencyId={agencyId}
      tracking={tracking}
    />
  );
};

export default AgencySalesStatisticsWithQuery;
