import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';
import * as util from '../../style/util';

const { colors, spacing, typography } = tokens;

export const container = css`
  ${util.container({
    gap: spacing.xxl,
    backgroundColor: colors.neutral.surface.default,
  })};
  overflow: hidden;
`;

export const contentWrapper = css`
  ${util.contentWrapper({
    gap: spacing.xl,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  })};

  padding-bottom: 0;

  h2 {
    ${applyFont(typography.heading)}
  }

  p {
    ${applyFont(typography.body)}
  }
`;

const section = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing.m};
  flex: 1 1 50%;
`;

export const textSection = css`
  ${section};
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: ${spacing.xxl};
`;

export const imageSection = css`
  ${section};
  align-items: flex-end;
`;

export const overriddenStyling = css`
  gap: ${spacing.xl};
`;

export const image = css`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
`;

export const link = css`
  padding: ${spacing.m} 0;
  min-width: 170px;
  span {
    ${applyFont(typography.body)};
  }
`;
