/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export const clearButtonStyleOverride = css`
  font-weight: normal;
  color: ${tokens.colors.neutral.medium.default};
  float: right;
`;

export const formSection = css`
  border-bottom: 1px solid ${tokens.colors.neutral.trim.default};
`;
