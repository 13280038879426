import React, { ReactNode } from 'react';
import {
  DetailsWrapper,
  HeroBanner,
  StickyBar,
  MobileStickyContactButtons,
} from '.';
import { HERO_SECTION, PROFILE_TIERS } from '../constants';

export type Props = {
  profileTier: PROFILE_TIERS;
  backgroundImage?: string;
  backgroundColour?: string;
  isAgency: boolean;
  hasEmail?: boolean | null;
  phoneNumber: string;
  name: string;
  setStickyActive: (value: boolean) => void;
  emailFormComponentId: string;
  renderComponent?: ReactNode;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  bannerElement: ReactNode;
  stickyElement: ReactNode;
  sectionElement: ReactNode;
  desktopWidth?: number;
};

const Hero = ({
  profileTier,
  backgroundImage,
  backgroundColour,
  isAgency,
  hasEmail,
  phoneNumber,
  name,
  emailFormComponentId,
  setStickyActive,
  renderComponent = null,
  onCallButtonClick,
  onEmailButtonClick,
  bannerElement,
  stickyElement,
  sectionElement,
  desktopWidth,
}: Props): JSX.Element => {
  const showBanner =
    profileTier === PROFILE_TIERS.branded ||
    profileTier === PROFILE_TIERS.platinum;

  return (
    <div data-testid="hero">
      {showBanner ? (
        <HeroBanner
          renderComponent={renderComponent}
          backgroundImage={backgroundImage}
          colour={backgroundColour}
          isPlatinum={profileTier === PROFILE_TIERS.platinum}
        >
          <DetailsWrapper
            isAgency={isAgency}
            heroSection={HERO_SECTION.banner}
            profileTier={profileTier}
            desktopWidth={desktopWidth}
          >
            {bannerElement}
          </DetailsWrapper>
          {profileTier === PROFILE_TIERS.platinum ? (
            <StickyBar
              profileTier={profileTier}
              isAgency={isAgency}
              agencyColour={backgroundColour}
              phoneNumber={phoneNumber}
              name={name}
              hasEmail={hasEmail}
              setStickyActive={setStickyActive}
              emailFormComponentId={emailFormComponentId}
              onCallButtonClick={onCallButtonClick}
              onEmailButtonClick={onEmailButtonClick}
              desktopWidth={desktopWidth}
            >
              {stickyElement}
            </StickyBar>
          ) : null}
        </HeroBanner>
      ) : (
        renderComponent
      )}
      {sectionElement ? (
        <DetailsWrapper
          profileTier={profileTier}
          isAgency={isAgency}
          heroSection={HERO_SECTION.section}
          withWrapper
          backgroundColour={backgroundColour}
          desktopWidth={desktopWidth}
        >
          {sectionElement}
        </DetailsWrapper>
      ) : null}
      <MobileStickyContactButtons
        phoneNumber={phoneNumber}
        name={name}
        hasEmail={hasEmail}
        emailFormComponentId={emailFormComponentId}
        onCallButtonClick={onCallButtonClick}
        onEmailButtonClick={onEmailButtonClick}
      />
    </div>
  );
};

export default Hero;
