import React from 'react';
import * as styles from './statistics.style';

export type Props = {
  totalForSale?: number | null;
  totalSoldAndAuctioned?: number | null;
  totalJointSoldAndAuctioned?: number | null;
  totalForRent?: number | null;
  totalLeased?: number | null;
  totalJointLeased?: number | null;
};

const Statistics = ({
  totalForSale = null,
  totalSoldAndAuctioned = null,
  totalJointSoldAndAuctioned = null,
  totalForRent = null,
  totalLeased = null,
  totalJointLeased = null,
}: Props): React.ReactElement => (
  <div>
    <dl css={styles.statistics}>
      {totalForSale ? (
        <div>
          <dt>For Sale</dt>
          <dd>{totalForSale}</dd>
        </div>
      ) : null}
      {totalSoldAndAuctioned ? (
        <div>
          <dt>Sold</dt>
          <dd>{totalSoldAndAuctioned}</dd>
        </div>
      ) : null}
      {totalForRent ? (
        <div>
          <dt>For Rent</dt>
          <dd>{totalForRent}</dd>
        </div>
      ) : null}
      {totalLeased ? (
        <div>
          <dt>Leased</dt>
          <dd>{totalLeased}</dd>
        </div>
      ) : null}
    </dl>
    {(totalSoldAndAuctioned && totalJointSoldAndAuctioned) ||
    (totalLeased && totalJointLeased) ? (
      <p css={styles.jointSales}>
        * Plus{' '}
        {totalSoldAndAuctioned && totalJointSoldAndAuctioned
          ? `${totalJointSoldAndAuctioned} sales`
          : ''}
        {totalSoldAndAuctioned &&
        totalJointSoldAndAuctioned &&
        totalLeased &&
        totalJointLeased
          ? ', and '
          : ''}
        {totalLeased && totalJointLeased
          ? `${totalJointLeased} properties leased`
          : ''}{' '}
        as joint agent
      </p>
    ) : null}
  </div>
);

export default Statistics;
