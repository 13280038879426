/* eslint-disable import/prefer-default-export */
import { useRef, useContext, MutableRefObject, useEffect } from 'react';
import { ShortlistContext } from '../contexts/shortlist/shortlist-context';

/**
 * @param adId listing adId to add shortlist state to
 * @returns functions to add, remove, and check if an adId is on the shortlist
 */
const useShortlist = (
  adId: number,
  adComponent: string,
): {
  addToShortlist: () => void;
  removeFromShortlist: () => void;
  isOnShortlist: boolean;
  loading?: boolean;
  scrollToRef: MutableRefObject<HTMLDivElement | null>;
  adComponent?: string;
} => {
  const scrollToRef = useRef(null);
  const context = useContext(ShortlistContext);

  // This error should only fire if there is no context.
  // Views from users who arent logged in shouldn't trigger it.
  // Note that context will be an empty object (ie. {}) on initial server render
  // See shortlist-context.tsx for more information
  if (context === undefined) {
    console.log(
      'For shortlist to work, listing cards must be wrapped in a "ShortlistProvider"',
    );
  }

  const {
    addToShortlist,
    removeFromShortlist,
    adIdList,
    loading,
    registerScrollRef,
  } = context;

  useEffect(() => {
    registerScrollRef?.(`${adId}-${adComponent}`, scrollToRef);
  }, [adId, adComponent, registerScrollRef]);

  const listingSpecificContext = {
    addToShortlist: () => addToShortlist?.(adId),
    removeFromShortlist: () => removeFromShortlist?.(adId),
    isOnShortlist: adIdList.includes(adId),
    loading,
    scrollToRef,
  };

  return listingSpecificContext;
};

export default useShortlist;
