import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { ContactProfileCard } from '..';
import { ContactSearchProfileCardFragment } from '../../generated/graphql';

const TYPE_NAME = 'ContactSearchContact';
const FRAGMENT_NAME = 'ContactSearchProfileCard';
const FRAGMENT = gql`
  fragment ContactSearchProfileCard on ContactSearchContact {
    id
    agentIdV2
    agencyId
    name
    jobTitle
    hasEmail
    telephone
    mobile
    profileTier
    profileUrl

    profilePhoto

    totalForSale
    averageSoldPrice
    averageSoldDaysOnMarket
    totalSoldAndAuctioned
    totalJointSoldAndAuctioned
    totalForRent
    totalLeased
    totalJointLeased

    agencyName
    agencyLogoUrl
    brandColour
  }
`;

interface ContactSearchProfileCardProps {
  contactId: number;
  isPropertyManager?: boolean;
  onViewProfile?: ({ contactId }: { contactId: number }) => void;
  onCall?: ({ contactId }: { contactId: number }) => void;
  onCallReveal?: () => void;
  onGetAppraisal?: () => void;
  onOpenEnquiryForm?: () => void;
  onEnquirySubmit?: ({
    contactId,
    intent,
    phone,
    postcode,
    message,
  }: {
    contactId: number;
    postcode: string;
    intent?: string;
    phone?: string | null;
    message?: string;
  }) => void;
  onEnquirySubmitSuccess?: ({
    contactId,
    intent,
    name,
  }: {
    contactId: number;
    intent?: string;
    name?: string | null;
  }) => void;
  onEnquirySubmitError?: ({
    contactId,
    name,
  }: {
    contactId: number;
    name?: string | null;
  }) => void;
  onEnquiryFinish?: (() => void) | undefined;
}

const ContactSearchProfileCardWithFragment = ({
  contactId,
  isPropertyManager = false,
  onViewProfile,
  onCall,
  onCallReveal,
  onGetAppraisal,
  onOpenEnquiryForm,
  onEnquirySubmit,
  onEnquirySubmitSuccess,
  onEnquirySubmitError,
  onEnquiryFinish,
}: ContactSearchProfileCardProps): JSX.Element | null => {
  const { data: contact } = useFragment<ContactSearchProfileCardFragment>({
    from: {
      __typename: TYPE_NAME,
      id: contactId,
    },
    fragment: FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });

  /* Handling unlikely missing data */
  if (!contact?.name || !contact?.profileUrl) {
    return null;
  }

  return (
    <ContactProfileCard
      contactId={contactId}
      name={contact?.name}
      jobTitle={contact?.jobTitle}
      telephone={contact?.telephone}
      mobile={contact?.mobile}
      hasEmail={contact?.hasEmail}
      profileTier={contact?.profileTier}
      profileUrl={`/real-estate-agent/${contact?.profileUrl}`}
      profilePhoto={{
        url: contact?.profilePhoto ?? undefined,
      }}
      statistics={{
        totalForSale: contact?.totalForSale,
        averageSoldPrice: contact?.averageSoldPrice,
        averageDaysOnMarket: contact?.averageSoldDaysOnMarket,
        totalSoldAndAuctioned: contact?.totalSoldAndAuctioned,
        totalJointSoldAndAuctioned: contact?.totalJointSoldAndAuctioned,
        totalForRent: contact?.totalForRent,
        totalLeased: contact?.totalLeased,
        totalJointLeased: contact?.totalJointLeased,
      }}
      agency={{
        agencyId: contact?.agencyId,
        name: contact?.agencyName,
        branding: {
          logoColour: contact?.brandColour,
          logo: {
            url: contact?.agencyLogoUrl,
          },
        },
      }}
      isPropertyManager={isPropertyManager}
      hideAppraisalCta
      hideOffice={false}
      isVertical={false}
      isShortStats={false}
      // Callbacks for tracking
      onViewProfile={onViewProfile}
      onCall={onCall}
      onCallReveal={onCallReveal}
      onGetAppraisal={onGetAppraisal}
      onOpenEnquiryForm={onOpenEnquiryForm}
      onEnquirySubmit={onEnquirySubmit}
      onEnquirySubmitSuccess={onEnquirySubmitSuccess}
      onEnquirySubmitError={onEnquirySubmitError}
      onEnquiryFinish={onEnquiryFinish}
    />
  );
};

ContactSearchProfileCardWithFragment.fragment = FRAGMENT;
ContactSearchProfileCardWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactSearchProfileCardWithFragment;
