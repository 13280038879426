import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, InputWrapper } from '@domain-group/fe-co-form-elements';

const TextField = ({
  errors,
  fieldName,
  format,
  icon,
  label,
  multiline,
  onBlur,
  onChange,
  pattern,
  placeholder,
  rows,
  title,
  type,
  value,
  inputProps,
  iconAppearance,
  required,
}) => (
  <InputWrapper
    label={label}
    errors={errors ? [errors] : []}
    required={required}
  >
    <TextInput
      format={format}
      icon={icon}
      id={fieldName}
      inputProps={{ title, ...inputProps }}
      isInvalid={!!errors}
      aria-label={fieldName}
      label={label}
      multiline={multiline}
      name={fieldName}
      onBlur={() => onBlur(fieldName, true)}
      onChange={(event) => onChange(fieldName, event.currentTarget.value)}
      pattern={pattern}
      placeholder={placeholder}
      rows={rows}
      type={type}
      value={value || ''}
      iconAppearance={iconAppearance}
    />
  </InputWrapper>
);

TextField.defaultProps = {
  errors: undefined,
  format: undefined,
  icon: undefined,
  multiline: undefined,
  pattern: undefined,
  placeholder: undefined,
  rows: undefined,
  title: undefined,
  type: undefined,
  value: undefined,
  inputProps: {},
  iconAppearance: undefined,
  required: false,
};

TextField.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  icon: PropTypes.node,
  errors: PropTypes.string,
  format: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  title: PropTypes.string,
  inputProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  iconAppearance: PropTypes.string,
  required: PropTypes.bool,
};

export default TextField;
