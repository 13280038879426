import {
  MembershipType,
  MembershipState,
  UserDetailsRedacted,
  DigitalData,
} from '../../../@types';

const userDataGenerator = (user?: UserDetailsRedacted): DigitalData['user'] => {
  if (!user || !!user?.disableUserTracking) {
    return {
      profile: {
        profileInfo: {
          profileId: null,
          domainUserId: null,
        },
      },
      // TODO: This is likely incorrect? Likely set-up with Akamai to disable tracking for European traffic, but this would also include users who have opted out of tracking
      isEuropeanTraffic: true,
      membershipType: MembershipType.visitor,
      membershipState: MembershipState.Not_logged_in,
    };
  }

  return {
    profile: {
      profileInfo: {
        profileId: user.userId || null,
        domainUserId: user.domainUserId || null,
      },
    },
    isEuropeanTraffic: false,
    membershipType: user.userId
      ? MembershipType.member
      : MembershipType.visitor,
    membershipState: user.userId
      ? MembershipState.Logged_in
      : MembershipState.Not_logged_in,
    ipAddress: user.ipHash || null,
    sessionToken: user.sessionId || null,
    originalSession: user.sessionId || null,
    emailHash: user.emailHash || null,
  };
};

export default userDataGenerator;
