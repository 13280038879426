import React from 'react';

const metaTag = ({
  noIndexFollow,
  description,
  key,
}: {
  noIndexFollow?: boolean;
  description?: string;
  key: string;
}): JSX.Element => (
  <>
    {description && <meta name="description" content={description} key={key} />}
    {noIndexFollow && <meta name="robots" content="noindex, follow" />}
  </>
);

export default metaTag;
