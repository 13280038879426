import { css, SerializedStyles } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

const { colors, spacing } = tokens;

export const pagination = css`
  text-align: center;
  font-size: 0;
`;

export const paginationItem = css`
  display: inline-block;
  margin: 0 ${spacing.xs} ${spacing.xs} ${spacing.xs};
`;

export const button = (active: boolean): SerializedStyles => css`
  cursor: pointer;
  border: 0;
  background: none;
  padding: 0;
  background-color: ${colors.neutral.base.default};
  height: ${spacing.s};
  width: ${spacing.s};
  border-radius: 50%;

  /* Display the button label off to the right where it is invisible */
  text-indent: ${spacing.s};
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: ${colors.neutral.base.hover};
  }

  ${active &&
  css`
    background-color: ${colors.interactive.base.default};
  `};
`;
