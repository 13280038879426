import { css } from '@emotion/react';
import {
  tokens,
  applyFont,
  shadow,
  media,
  globalTokens,
} from '@domain-group/fe-brary';
import * as util from '../../style/util';

const { colors, spacing, typography, borderRadius } = tokens;

export const container = css`
  ${util.container({
    gap: spacing.xxl,
    backgroundColor: colors.neutral.background.default,
  })};
`;

export const contentWrapper = css`
  ${util.contentWrapper({
    gap: spacing.xl,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  })};

  h2 {
    ${applyFont(typography.heading)}
  }

  h3 {
    ${applyFont(typography.lead)}
  }
`;

export const gaps = css`
  display: flex;
  gap: ${spacing.m};
`;

export const cardContainer = css`
  border-radius: ${borderRadius.inner};
  background: ${colors.neutral.surface.default};
  box-shadow: ${shadow(tokens.shadows.elevation2)};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 50%;

  ${media('>=desktop')} {
    flex-direction: row;
    align-items: center;
  }

  text-decoration: none;
  color: ${colors.neutral.heavy.default};
  padding: ${spacing.m};
  gap: ${spacing.m};

  h2 {
    ${applyFont(typography.subheading)}
  }

  p {
    ${applyFont(typography.body)}
    color: ${colors.neutral.medium.default};
  }

  align-self: stretch;

  transition-duration: ${globalTokens.motion.duration.fast100}ms;
  transition-timing-function: ease;

  :hover {
    box-shadow: ${shadow(tokens.shadows.elevation4)};
  }

  :active {
    box-shadow: ${shadow(tokens.shadows.elevation1)};
  }
`;

export const textContainer = css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
`;
