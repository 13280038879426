import React from 'react';
import { gql, useQuery } from '@apollo/client';
import ContactVideoWithFragment from './contact-video-with-fragment';
import { ContactIdProps } from '../../@types';
import { GetContactIdVideoQuery } from '../../generated/graphql';

export const GET_CONTACT_ID_VIDEO_QUERY = gql`
  query getContactIdVideo($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      ...ContactVideo
    }
  }
  ${ContactVideoWithFragment.fragment}
`;

const ContactVideoWithQuery = ({
  contactId,
}: ContactIdProps): React.ReactElement | null => {
  const { data } = useQuery<GetContactIdVideoQuery>(
    GET_CONTACT_ID_VIDEO_QUERY,
    {
      variables: { contactId },
    },
  );

  const { agentIdV2, agencyId } = data?.contactByContactId || {};

  if (!data || !agentIdV2 || !agencyId) {
    return null;
  }

  return <ContactVideoWithFragment agentIdV2={agentIdV2} agencyId={agencyId} />;
};

export default ContactVideoWithQuery;
