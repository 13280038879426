/* eslint-disable import/prefer-default-export */
import { ApolloError, gql, useQuery } from '@apollo/client';

import {
  AgencyCurrentListingsFilterQuery,
  ListingFilterStatus,
} from '../../../generated/graphql';

import type { Choice } from '../@types';
import { filtersFromFilterData } from '../utils/filters-from-filter-data';
import type { ListingFilterResult } from '../utils/filters-from-filter-data';
import { ListingFilterStatusId } from '../@types';

export const AGENCY_CURRENT_LISTINGS_FILTER_QUERY = gql`
  query agencyCurrentListingsFilter($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      id
      agentsearchListingsFiltersByAgencyId {
        listingSummary {
          listingType
          bedrooms
          propertyTypes
          suburbSummaries {
            suburbSlug
            suburbName
            listingCount
          }
        }
      }
    }
  }
`;

interface UseListingFilterArgs {
  agencyId: number;
  listingStatus?: Choice<ListingFilterStatus>;
  initialListingStatusId?: ListingFilterStatusId;
}

export const useAgencyListingFilters = ({
  listingStatus,
  initialListingStatusId,
  agencyId,
}: UseListingFilterArgs): ListingFilterResult & {
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useQuery<AgencyCurrentListingsFilterQuery>(
    AGENCY_CURRENT_LISTINGS_FILTER_QUERY,
    {
      variables: { agencyId },
    },
  );

  const filterData = data?.agency?.agentsearchListingsFiltersByAgencyId;

  if (loading || !filterData) {
    return { loading, error };
  }

  const filters = filtersFromFilterData({
    filterData,
    listingStatus,
    initialListingStatusId,
  });

  return { error, loading, ...filters };
};
