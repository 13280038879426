import { css } from '@emotion/react';
import { tokens, media } from '@domain-group/fe-brary';

const { colors, spacing } = tokens;

export const main = css`
  color: ${colors.neutral.heavy.default};
`;

export const rowWrapper = css`
  padding-top: ${spacing.m};
  padding-bottom: ${spacing.xl};

  ${media('>=tablet')} {
    padding-bottom: ${spacing.xxl};
  }
`;

export const footer = css`
  margin-top: ${spacing.m};
  padding-top: ${spacing.m};
  padding-bottom: ${spacing.s};
  background-color: ${colors.neutral.background.default};

  ${media('>=tablet')} {
    margin-top: 0;
    padding-top: ${spacing.xxl};
    padding-bottom: 0;
  }
`;
