/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import DOMPurify from 'isomorphic-dompurify';

const sanitiseTestimonial = (input: string): string => {
  // Sanitise HTML and remove any style tags
  DOMPurify.removeAllHooks();
  const sanitisedInput = DOMPurify.sanitize(input, {
    FORBID_TAGS: ['style'],
  });

  // Remove all DOM tags
  const strippedText = new DOMParser().parseFromString(
    sanitisedInput,
    'text/html',
  )?.body?.textContent;

  return strippedText || '';
};

export default sanitiseTestimonial;
