import React from 'react';
import { gql, useQuery, useFragment } from '@apollo/client';

import { handleError } from '@domain-group/fe-helper';

import mapReviews from '../../utils/map-recommendation-reviews';
import ReviewList from '../shared/recommendations/reviews-list';

import type {
  ContactRecommendationsFragment,
  ContactByContactIdQuery,
} from '../../generated/graphql';
import type { TrackingCallbacks } from '../../@types/recommendations';

type ContactRecommendationsWithQueryProps = TrackingCallbacks & {
  contactId: number;
};

type ContactRecommendationsWithFragmentProps = TrackingCallbacks & {
  agentIdV2: string;
  agencyId: number;
};

export const FRAGMENT_NAME = 'ContactRecommendations';
export const FRAGMENT = gql`
  fragment ContactRecommendations on Contact {
    id
    agentIdV2
    agencyId
    recommendations {
      subscribed
      showRMAReviews
      reviews {
        title
        description
        reviewedOn
        reviewerName
        reviewType
        starRating
        isRecommended
        agentReviewTrackingPixelUrl
        agencyReviewTrackingPixelUrl
        reviewUrl
        propertyType
        bedrooms
        bathrooms
        carparks
        streetAddress
        suburb
        state
        postCode
      }
      lastUpdated
      agentProfileUrl
      agenciesAllSubscribed
    }
  }
`;

export const ContactRecommendationsWithFragment = ({
  agentIdV2,
  agencyId,
  onPageRendered,
  onVendorRecoViewAll,
  onVendorRecoRendered,
  onVendorRecoImpression,
  onVendorRecoModalViewMore,
  onVendorRecoModalPrev,
  onVendorRecoModalNext,
}: ContactRecommendationsWithFragmentProps): JSX.Element | null => {
  const { complete, data } = useFragment<
    ContactRecommendationsFragment,
    unknown
  >({
    from: {
      __typename: 'Contact',
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });

  if (complete && data?.recommendations?.reviews?.length) {
    const reviews = mapReviews(data.recommendations.reviews);

    return (
      <ReviewList
        profileUrl={data.recommendations.agentProfileUrl || ''}
        reviews={reviews}
        onPageRendered={() => {
          onPageRendered(data);
        }}
        onVendorRecoViewAll={() => {
          onVendorRecoViewAll(data);
        }}
        onVendorRecoRendered={() => {
          onVendorRecoRendered(data);
        }}
        onVendorRecoImpression={() => {
          onVendorRecoImpression(data);
        }}
        onVendorRecoModalViewMore={() => {
          onVendorRecoModalViewMore(data);
        }}
        onVendorRecoModalPrev={() => {
          onVendorRecoModalPrev(data);
        }}
        onVendorRecoModalNext={() => {
          onVendorRecoModalNext(data);
        }}
      />
    );
  }
  return null;
};

export const QUERY = gql`
  query contactByContactId($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      id
      ...ContactRecommendations
    }
  }
  ${FRAGMENT}
`;

ContactRecommendationsWithFragment.fragment = FRAGMENT;
ContactRecommendationsWithFragment.fragmentName = FRAGMENT_NAME;

export const ContactRecommendationsWithQuery = ({
  contactId,
  onPageRendered,
  onVendorRecoViewAll,
  onVendorRecoRendered,
  onVendorRecoImpression,
  onVendorRecoModalViewMore,
  onVendorRecoModalPrev,
  onVendorRecoModalNext,
}: ContactRecommendationsWithQueryProps): JSX.Element | null => {
  const { data, error } = useQuery<ContactByContactIdQuery>(QUERY, {
    variables: { contactId },
  });

  if (error) {
    handleError(error);
    return <div data-testid="fe-faa-contact-recommendations-error" />;
  }

  if (data) {
    const agencyId = data?.contactByContactId?.agencyId;
    const agentIdV2 = data?.contactByContactId?.agentIdV2;

    if (!agencyId || !agentIdV2) {
      return null;
    }

    return (
      <div data-testid="fe-faa-contact-recommendations-container">
        <ContactRecommendationsWithFragment
          agentIdV2={agentIdV2}
          agencyId={agencyId}
          onPageRendered={onPageRendered}
          onVendorRecoViewAll={onVendorRecoViewAll}
          onVendorRecoRendered={onVendorRecoRendered}
          onVendorRecoImpression={onVendorRecoImpression}
          onVendorRecoModalViewMore={onVendorRecoModalViewMore}
          onVendorRecoModalPrev={onVendorRecoModalPrev}
          onVendorRecoModalNext={onVendorRecoModalNext}
        />
      </div>
    );
  }

  return null;
};

ContactRecommendationsWithQuery.query = QUERY;
