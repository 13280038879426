import React from 'react';
import { gql, useQuery } from '@apollo/client';

import ContactEnquiryFormWithFragment, {
  Props,
} from './contact-enquiry-form-with-fragment';

export const GET_CONTACT_QUERY = gql`
  query contactByAgencyIdAndAgentId($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...${ContactEnquiryFormWithFragment.fragmentName}
    }
  }
  ${ContactEnquiryFormWithFragment.fragment}
`;

const ContactEnquiryFormWithQuery = ({
  agencyId,
  agentIdV2,
  contactId,
  isShortForm,
  onSelectIntent,
  onSubmitIntent,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  onFinish,
  onInView,
}: Props): JSX.Element | null => {
  const { data } = useQuery(GET_CONTACT_QUERY, {
    variables: { agencyId, agentIdV2 },
  });

  if (!data) {
    return null;
  }

  return (
    <ContactEnquiryFormWithFragment
      agencyId={agencyId}
      agentIdV2={agentIdV2}
      contactId={contactId}
      isShortForm={isShortForm}
      onSelectIntent={onSelectIntent}
      onSubmitIntent={onSubmitIntent}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitError={onSubmitError}
      onFinish={onFinish}
      onInView={onInView}
    />
  );
};

export default ContactEnquiryFormWithQuery;
