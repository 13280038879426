import { css, SerializedStyles } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

const headerHeight = 32;
const logoWidth = (isPlatinum: boolean) => (isPlatinum ? 92 : 69);
const logoHeight = (isPlatinum: boolean) => (isPlatinum ? headerHeight : 24);

export const radiusSize = 3;

const agencyLogoSpacing = 24; // variables.global.spacing.mobile.medium
const spacing = 6;

export const header = ({
  colour = 'transparent',
  isVertical = false,
  isBranded = false,
  isPlatinum = false,
}: {
  colour?: string;
  isVertical: boolean;
  isBranded: boolean;
  isPlatinum: boolean;
}): SerializedStyles => css`
  display: block;
  position: relative;
  height: ${headerHeight + 1}px;
  background-color: ${colour};
  border: 1px solid ${tokens.colors.neutral.trim.default};
  border-bottom: 0;
  border-radius: ${radiusSize}px ${radiusSize}px 0 0;

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      ${isBranded || isPlatinum
        ? css`
            height: ${(isPlatinum ? 12 : 8) + 1}px;
          `
        : css`
            height: ${radiusSize + 1}px;
            background: transparent;
          `}
    }
  `}
`;

export const agencyLogo = ({
  logoPosition = 'left',
  colour = 'transparent',
  isVertical = false,
  isPlatinum = false,
}: {
  logoPosition: 'left' | 'right';
  colour?: string;
  isVertical: boolean;
  isPlatinum: boolean;
}): SerializedStyles => css`
  position: absolute;
  height: ${headerHeight}px;
  width: ${92 + spacing * 2}px;
  padding: 0 ${spacing}px;
  top: 0;
  right: ${tokens.spacing.m};
  background-color: ${colour};

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      position: absolute;
      top: 0;
      ${logoPosition}: ${agencyLogoSpacing}px;
      border-radius: 0 0 ${radiusSize}px ${radiusSize}px;
      width: ${logoWidth(isPlatinum) + spacing * 2}px;
      height: ${logoHeight(isPlatinum)}px;
    }
  `}

  img {
    display: block;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export const curve = ({
  colour = 'transparent',
  isPlatinum = false,
  isRight = false,
}: {
  colour?: string;
  isPlatinum: boolean;
  isRight?: boolean;
}): SerializedStyles => css`
  position: absolute;
  top: ${isPlatinum ? 12 : 8}px;
  width: ${radiusSize}px;
  height: ${radiusSize}px;
  fill: ${colour};
  z-index: 1;

  ${isRight
    ? css`
        left: 100%;
      `
    : css`
        right: 100%;
      `}
`;
