/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  ContactCurrentListingsQuery,
  ContactListingsTabFragment,
  ListingFilterStatus,
} from '../../../generated/graphql';
import getListingTabData from '../utils/get-listing-tab-data';
import { Choice } from '../@types';
import { CURRENT_LISTING_QUERY } from '../components/listing-results/contact-listings-results';

export const useContactListingsResults = (variables: {
  listingStatus?: Choice<ListingFilterStatus>;
  contactId: number;
  bedrooms?: string;
  page: number;
  propertyType?: string;
  sortBy?: string;
  suburbId?: string;
}): {
  error?: ApolloError;
  loading: boolean;
  listings?: ContactListingsTabFragment | null;
} => {
  const { listingStatus, ...otherVariables } = variables;

  const { data, loading, error } = useQuery<ContactCurrentListingsQuery>(
    CURRENT_LISTING_QUERY,
    {
      variables: otherVariables,
    },
  );

  const currentListingData =
    data?.contactByContactId?.agentsearchListingsByAgentIdV2AndAgencyId;

  // useMemo because mapping a bunch of data
  const listings = useMemo(
    () =>
      !loading && currentListingData
        ? getListingTabData(currentListingData, listingStatus)
        : undefined,
    [currentListingData, listingStatus, loading],
  );

  return {
    loading,
    listings,
    error,
  };
};
