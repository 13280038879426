/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Button } from '@domain-group/fe-co-button';

import type { Choice } from '../../../@types';
import * as style from './filters-form.style';
import { RadioTiles } from './radio-tiles';
import { RadioOptionGroup } from './radio-option-group';

export type FiltersProps = {
  // Choices
  bedroomsOptions?: Choice<string>[];
  propertyTypeOptions?: Choice<string>[];
  suburbOptions?: Choice<string>[];

  // Select functions
  selectBedrooms: (newBedrooms?: Choice<string>) => void;
  selectPropertyType: (propertyType?: Choice<string>) => void;
  selectSuburb: (suburb?: Choice<string>) => void;
  clearAll: () => void;

  // Currently Selected Options
  currentBedrooms?: Choice<string>;
  currentPropertyType?: Choice<string>;
  currentSuburb?: Choice<string>;
};

export const Filters = ({
  bedroomsOptions = [],
  propertyTypeOptions = [],
  suburbOptions = [],

  currentBedrooms,
  currentPropertyType,
  currentSuburb,

  selectBedrooms,
  selectPropertyType,
  selectSuburb,
  clearAll,
}: FiltersProps): JSX.Element => {
  const handleClearAll = () => {
    clearAll();
    selectBedrooms(undefined);
    selectPropertyType(undefined);
    selectSuburb(undefined);
  };

  return (
    <>
      <Button
        type="button"
        appearance="link"
        size="mini"
        css={style.clearButtonStyleOverride}
        onClick={handleClearAll}
        aria-label="Clear all filter selections"
      >
        Reset filters
      </Button>
      <div css={style.formSection} data-testid="listing-tabs__filter">
        <RadioOptionGroup
          title="Filters"
          groupName="filter-form-suburb-selection"
          options={suburbOptions}
          handleChange={selectSuburb}
          current={currentSuburb}
        />
      </div>
      <div css={style.formSection} data-testid="listing-tabs__filter">
        <RadioOptionGroup
          title="Property type"
          groupName="filter-modal-form-property-type-selection"
          options={propertyTypeOptions}
          handleChange={selectPropertyType}
          current={currentPropertyType}
        />
      </div>
      <div data-testid="listing-tabs__filter">
        <RadioTiles
          title="Bedrooms"
          groupName="bedrooms-radio-form"
          options={bedroomsOptions}
          handleChange={selectBedrooms}
          current={currentBedrooms}
        />
      </div>
    </>
  );
};
