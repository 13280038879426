import { css } from '@emotion/react';
import { h4Tag, fontH5, tokens } from '@domain-group/fe-brary';
import { wrapper as centeredWrapper } from './step-intent.style';

export { avatar } from './step-intent.style';

export const wrapper = (isWide = false) => css`
  ${centeredWrapper(isWide)};
  text-align: left;
  color: ${tokens.colors.neutral.heavy.default};
`;

export const title = css`
  ${h4Tag};
`;

export const article = css`
  display: grid;
  grid-template-columns: 1fr 144px;
  grid-template-rows: auto auto auto;
  grid-column-gap: ${tokens.spacing.s};
  grid-row-gap: ${tokens.spacing.xxs};
  grid-template-areas: 'none illustration' 'none illustration' 'none illustration';
  width: 100%;
  margin: 30px 0;
  padding: ${tokens.spacing.m};
  padding-bottom: ${tokens.spacing.xs};
  border: 1px solid ${tokens.colors.neutral.trim.default};
  box-sizing: border-box;
  border-radius: 3px;
`;

export const illustration = css`
  grid-area: illustration;
  display: flex;
  align-items: center;
`;

export const articleTitle = css`
  ${fontH5};
`;

export const link = css`
  width: auto;
  text-align: left;
  margin-top: -8px;
`;

export const button = css`
  width: 225px;
`;
