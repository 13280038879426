import React from 'react';
import Illustration from '@domain-group/fe-co-brand-illustrations';
import * as styles from './why-domain.style';
import ContentContainer from '../content-container/content-container';

const firstSection = () => (
  <div css={styles.sectionsContainer}>
    <div css={styles.section}>
      <div css={styles.imageSection}>
        <Illustration type="spot" shape="house" name="niceHouse" size="large" />
      </div>
      <div css={styles.textSection}>
        <h3>Sellers trust Domain</h3>
        <p>
          Since 2019, over 5.2 million Australian homes have been sold on Domain
          <sup>2</sup>.
        </p>
      </div>
    </div>
    <div css={styles.section}>
      <div css={styles.imageSection}>
        <Illustration type="spot" shape="arch" name="auction" size="large" />
      </div>
      <div css={styles.textSection}>
        <h3>Buyers not browsers</h3>
        <p>
          Over 500,000 are looking to buy in the next 12 months<sup>3</sup>.
        </p>
        <span css={styles.supText}>
          Survey period October 2022 – September 2023
        </span>
      </div>
    </div>
  </div>
);

const secondSection = () => (
  <div css={styles.sectionsContainer}>
    <div css={styles.section}>
      <div css={styles.imageSection}>
        <Illustration
          type="spot"
          shape="D"
          name="browsingLocation"
          size="large"
        />
      </div>
      <div css={styles.textSection}>
        <h3>
          1 million people on Domain don&apos;t visit our nearest competitor
        </h3>
        <p>
          Reach 1 million local, regional and interstate people on Domain who
          don&apos;t visit our nearest competitor on average each month
          <sup>1</sup>.
        </p>
      </div>
    </div>
    <div css={styles.section}>
      <div css={styles.imageSection}>
        <Illustration type="spot" shape="circle" name="news" size="large" />
      </div>
      <div css={styles.textSection}>
        <h3>Reach professionals</h3>
        <p>
          1.3 million managers or professionals are on Domain<sup>4</sup>.
        </p>
        <span css={styles.supText}>
          Survey period October 2022 – September 2023
        </span>
      </div>
    </div>
  </div>
);

const WhyDomain = (): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.contentWrapper}>
      <h2>Why do sellers choose Domain?</h2>
      <ContentContainer
        overrideStyles={styles.contentContainer}
        first={firstSection()}
        second={secondSection()}
        changeForDevice="desktop"
      />
    </div>
  </div>
);

export default WhyDomain;
