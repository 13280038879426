import { ServerInfo } from './server-info';

export type DigitalDataSearch = {
  geoType?: string;
  searchTerm?: string;
  officeName?: string;
  searchDepth?: number;
  postcode?: string;
  searchSuburb?: string;
  state?: string;
  agencyIds?: string;
};

export type DigitalDataPage = {
  search?: DigitalDataSearch;

  brand?: 'domain';
  property: unknown; // TODO: is this even used any more?
  generator: 'DO';
  pageId?: string;
  pageName: string;
  pageTemplate?: string;

  sysEnv?: ServerInfo['sysEnv'];
  isEmbeddedApp?: boolean;
  source?: string;

  agencyId?: string;
  agentId?: number;
  agencyName?: string;
  agentName?: string;
  suburb?: string;
  postcode?: string | number;
  state?: string;
};

export type DigitalDataCategory = {
  pageType: string;
  primaryCategory: string;
  subCategory1?: string;
  subCategory2?: string;
};

export enum MembershipType {
  'member' = 'member',
  'visitor' = 'visitor',
}

export enum MembershipState {
  'Logged_in' = 'Logged_in',
  'Not_logged_in' = 'Not_logged_in',
}

export type DigitalData = {
  page: {
    pageInfo: DigitalDataPage;
    category: DigitalDataCategory;
  };
  user: {
    profile: {
      profileInfo: {
        profileId: string | null;
        domainUserId: string | null;
      };
    };
    isEuropeanTraffic: boolean;
    membershipType: MembershipType;
    membershipState: MembershipState;
    ipAddress?: string | null;
    sessionToken?: string | null;
    originalSession?: string | null;
    emailHash?: string | null;
  };
  version: '1.0';
  events: unknown[];
};

export type DigitalDataServerInfo = {
  contactId?: number;
} & Pick<DigitalDataPage, 'sysEnv' | 'isEmbeddedApp' | 'source'>;
