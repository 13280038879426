import { css } from '@emotion/react';
import { tokens, media, applyFont } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';
export { animationSettings } from '../../../shared/expander/expander.style';

const { colors, spacing, typography } = tokens;

const tableItemWidth = {
  small: '80px',
};

const firstTableItemSize = css`
  width: calc(100% - ${tableItemWidth.small} - ${tableItemWidth.small});

  ${media('>=desktop')} {
    width: 46%;
  }
`;

const tableItemSize = css`
  width: ${tableItemWidth.small};

  ${media('>=desktop')} {
    width: 27%;
  }
`;

export const tableWrapper = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const table = css`
  width: 100%;
`;

export const thItem = css`
  ${tableItemSize}
  box-sizing: border-box;
  padding: ${spacing.xs};
  border-right: 1px solid ${colors.neutral.trim.default};
  ${applyFont(typography.overline)}
  text-align: center;
  color: ${colors.neutral.medium.default};

  &:first-of-type {
    ${firstTableItemSize}
  }

  &:last-of-type {
    border-right: none;
  }
`;

export const tbody = css`
  ${applyFont(typography.displaySmall)}
  color: ${colors.neutral.heavy.default};
`;

export const row = css`
  border-top: 1px solid ${colors.neutral.trim.default};
  background-color: ${colors.neutral.surface.default};

  &:nth-of-type(odd):not(:last-of-type) {
    background-color: ${colors.neutral.subdued.default};
  }
`;

export const cell = css`
  ${tableItemSize}
  box-sizing: border-box;
  padding: ${spacing.s} ${spacing.xs};
  border-right: 1px solid ${colors.neutral.trim.default};
  text-align: center;
  position: relative;
  top: 2px;

  &:first-of-type {
    ${firstTableItemSize}
    padding-left: ${spacing.m};
    text-align: left;
  }

  &:last-of-type {
    border-right: none;
  }
`;

export const propertyIcon = css`
  position: relative;
  top: -4px;
  margin-right: ${spacing.m};

  > svg {
    height: 30px;
    width: 30px;
  }
`;

export const flipped = css`
  > svg {
    transform: rotateY(180deg);
  }
`;

export const greenFont = css`
  vertical-align: top;
  color: ${colors.primary.base.default};
`;
