import React from 'react';
// import { Button } from '@domain-group/fe-co-button';
import { RadioOptionGroup, TextInput } from '.';
import { AGENT_SEARCH_TYPE_OPTIONS, SEARCH_TYPE_OPTIONS } from '../../enums';

import * as style from './filters-form.style';

export interface Option {
  value: string;
  label: string;
  count?: number;
}

interface FiltersProps {
  isAgency: boolean;
  setFormSearchType: (searchType: string) => void;
  formSearchType: string | null;
  setFormAgentType: (agentType: string) => void;
  formAgentType: string | null;
  setFormFilter: (filter: string | null) => void;
  formFilter: string | null;
}

const Filters = ({
  isAgency,
  setFormSearchType,
  formSearchType,
  setFormAgentType,
  formAgentType,
  setFormFilter,
  formFilter,
}: FiltersProps): JSX.Element => (
  // const handleClearAll = () => {
  //   setFormFilter(null);
  // };

  <>
    {/* TODO 
      <Button
        type="button"
        appearance="link"
        size="mini"
        css={style.clearButtonStyleOverride}
        onClick={handleClearAll}
        aria-label="Clear all filter selections"
      >
        Reset filters
      </Button> */}
    <div css={style.formSection} data-testid="listing-tabs__filter">
      <RadioOptionGroup
        title="Search for"
        groupName="bar-search-type-selection-form"
        options={SEARCH_TYPE_OPTIONS}
        handleChange={setFormSearchType}
        current={formSearchType}
      />
    </div>
    {!isAgency && (
      <div css={style.formSection} data-testid="listing-tabs__filter">
        <RadioOptionGroup
          title="Agent type"
          groupName="bar-agent-type-selection-form"
          options={AGENT_SEARCH_TYPE_OPTIONS}
          handleChange={setFormAgentType}
          current={formAgentType}
        />
      </div>
    )}
    <div data-testid="listing-tabs__filter">
      <TextInput
        groupName="bar-filter-selection"
        handleChange={setFormFilter}
        current={formFilter}
        title="Filter by"
      />
    </div>
  </>
);
export default Filters;
