// https://stackoverflow.com/questions/5484578/how-to-get-document-height-and-width-without-using-jquery
export const getWindowHeight = () =>
  Math.max(
    document.documentElement.clientHeight,
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
  );

// https://stackoverflow.com/questions/3464876/javascript-get-window-x-y-position-for-scroll
export const getScrollPos = () => {
  const doc = document.documentElement;
  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
};

// https://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript
export const getViewportHeight = () =>
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

export const getViewportWidth = () =>
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

// get an elements vertical position relative to the body
// https://stackoverflow.com/questions/442404/retrieve-the-position-x-y-of-an-html-element
export const getElementTop = (element: HTMLElement) => {
  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = element.getBoundingClientRect();
  return elemRect.top - bodyRect.top;
};
