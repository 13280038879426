import React from 'react';
import { srOnly } from '@domain-group/fe-brary';

import SocialIcon, { IconName } from './social-icon';
import * as styles from './social.style';

export type Props = {
  inBanner?: boolean;
  url?: string;
  target: string;
  iconName: IconName;
  onClick?: ({
    eventLabel,
    eventAction,
  }: {
    eventLabel: string;
    eventAction: string;
  }) => void;
  eventLabel: string;
  eventAction: string;
};

const SocialLinkBase = ({
  inBanner = true,
  url,
  target,
  iconName,
  onClick = () => undefined,
  eventLabel,
  eventAction,
}: Props): JSX.Element | null => {
  if (!url) {
    return null;
  }

  return (
    <li css={styles.socialItem(inBanner)}>
      <a
        css={styles.socialLinkIcon(inBanner)}
        data-testid="trade-profile-social__social-link-icon"
        href={url}
        target={target}
        onClick={() => onClick({ eventLabel, eventAction })}
        rel="noopener noreferrer"
      >
        <SocialIcon iconName={iconName} size={inBanner ? 'regular' : 'small'} />
        <span css={srOnly}>{iconName}</span>
      </a>
    </li>
  );
};

export default SocialLinkBase;
