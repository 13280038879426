import React from 'react';
import { motion } from 'framer-motion';
import { PROPERTY_MANAGER } from '../search-filter-bar/enums';
import {
  AgencySearchProfileCardWithFragment,
  ContactSearchProfileCardWithFragment,
} from '../../..';
import NoResults from './no-results';
import {
  trackCallPhone,
  trackRevealPhone,
  trackDisplayEmailForm,
  trackSubmitEnquiry,
  trackSubmitEnquirySuccess,
  trackSubmitEnquiryFailure,
} from '../../../../tracking/search-results';

// TODO: Figure out how to replace these inline type defs with AgencySearchQuery['contactSearchBySuburbs']['results']
// import { AgencySearchQuery } from '../../../generated/graphql';

interface ResultsProps {
  isAgency: boolean;
  agentType?: string;
  agentResults?: Array<{
    __typename?: 'ContactSearchContact';
    id: number;
    agentIdV2: string;
    agencyId: number;
    name: string;
    jobTitle?: string | null;
    hasEmail?: boolean | null;
    telephone?: string | null;
    mobile?: string | null;
    profileTier?: string | null;
    profileUrl: string;
    profilePhoto?: string | null;
    totalForSale?: number | null;
    averageSoldPrice?: number | null;
    averageSoldDaysOnMarket?: number | null;
    totalSoldAndAuctioned?: number | null;
    totalJointSoldAndAuctioned?: number | null;
    totalForRent?: number | null;
    totalLeased?: number | null;
    totalJointLeased?: number | null;
    agencyName: string;
    agencyLogoUrl?: string | null;
    brandColour?: string | null;
  } | null> | null;
  agencyResults?: Array<{
    __typename?: 'AgencySearchAgency';
    id: number;
    name: string;
    displayAddress?: string | null;
    profileTier?: string | null;
    brandColour?: string | null;
    logoSmall?: string | null;
    profileUrl: string;
    mobile?: string | null;
    telephone?: string | null;
    email?: string | null;
    hideOfficeLocation: boolean;
    totalForSale?: number | null;
    totalSoldAndAuctioned?: number | null;
    totalForRent?: number | null;
    agents?: Array<{
      __typename?: 'AgentSearchAgencyContact';
      name: string;
      profileUrl: string;
      profilePhoto?: string | null;
    } | null> | null;
  } | null>;
  userId?: string;
  userToken?: string;
  suburbSlugs?: string[];
}

const Results = ({
  isAgency,
  agentType,
  agentResults,
  agencyResults,
  // Data used by tracking
  userId,
  userToken,
  suburbSlugs,
}: ResultsProps): JSX.Element => {
  if (isAgency) {
    if (!agencyResults?.length) {
      return <NoResults />;
    }

    return (
      <>
        {agencyResults.map((agency) => {
          if (!agency?.id) {
            return null;
          }

          return (
            <motion.div key={agency.id}>
              <AgencySearchProfileCardWithFragment
                agencyId={agency.id}
                onCall={() =>
                  trackCallPhone({
                    isAgency: true,
                    id: agency.id,
                    userId,
                    userToken,
                    suburbSlugs,
                  })
                }
                onCallReveal={() => trackRevealPhone({ isAgency: true })}
                onOpenEnquiryForm={() =>
                  trackDisplayEmailForm({ isAgency: true })
                }
                onEnquirySubmit={(values) =>
                  trackSubmitEnquiry({
                    isAgency: true,
                    id: agency.id,
                    userId,
                    userToken,
                    suburbSlugs,
                    enquiryValues: values,
                  })
                }
                // Note: agency enquiries are not being tracked in GA currently
                // onEnquirySubmitSuccess={(values) => {
                //   trackSubmitEnquirySuccess({
                //     isAgency: true,
                //   });
                // }}
                // onEnquirySubmitFailure={(values) => {
                //   trackSubmitEnquiryFailure();
                // }
              />
            </motion.div>
          );
        })}
      </>
    );
  }

  if (!agentResults?.length) {
    return <NoResults />;
  }

  return (
    <>
      {agentResults?.map((contact) => {
        if (!contact?.id) {
          return null;
        }

        return (
          <motion.div key={contact.id}>
            <ContactSearchProfileCardWithFragment
              contactId={contact.id}
              isPropertyManager={agentType === PROPERTY_MANAGER}
              onCall={() =>
                trackCallPhone({
                  isAgency: false,
                  id: contact.id,
                  userId,
                  userToken,
                  suburbSlugs,
                })
              }
              onCallReveal={() => trackRevealPhone({ isAgency: false })}
              onOpenEnquiryForm={() =>
                trackDisplayEmailForm({ isAgency: false })
              }
              onEnquirySubmit={(values) =>
                trackSubmitEnquiry({
                  isAgency: false,
                  id: contact.id,
                  userId,
                  userToken,
                  suburbSlugs,
                  enquiryValues: values,
                })
              }
              onEnquirySubmitSuccess={(values) => {
                trackSubmitEnquirySuccess({
                  isAgency: false,
                  contactId: contact.id,
                  contactName: contact.name,
                  intent: values.intent,
                });
              }}
              onEnquirySubmitError={() => {
                trackSubmitEnquiryFailure({
                  isAgency: false,
                  contactId: contact.id,
                  contactName: contact.name,
                });
              }}
            />
          </motion.div>
        );
      })}
    </>
  );
};

export default Results;
