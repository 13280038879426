import { css, SerializedStyles } from '@emotion/react';
import { media, applyFont, tokens } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

const statMinWidth = 60;
export const salesStatisticsWidth = statMinWidth * 3;

export const statistics = (isVertical = false): SerializedStyles => css`
  display: flex;
  justify-content: flex-start;
  margin: 0 calc(${spacing.xxs} * -1);

  ${!isVertical &&
  css`
    ${media('>=tablet')} {
      justify-content: center;
    }
    ${media('>=desktop')} {
      min-width: calc(${salesStatisticsWidth} + calc(${spacing.xxs} * 2));
    }
  `}
`;

export const statistic = css`
  display: flex;
  flex-direction: column-reverse;
  flex: 0 1 auto;
  margin: 0 ${spacing.xxs};

  ${media('>=mobile')} {
    min-width: ${statMinWidth}px;
    max-width: ${statMinWidth * 2}px;
  }

  dt {
    ${applyFont(typography.caption)};
    color: ${colors.neutral.medium.default};
    word-break: break-word;
  }

  dd {
    ${applyFont(typography.displaySmall)}
  }
`;

export const unavailable = css`
  ${applyFont(typography.caption)};
  color: ${colors.neutral.medium.default};
  text-align: center;
`;
