import { css, SerializedStyles } from '@emotion/react';
import { Target, Transition } from 'framer-motion';
import { media, tokens } from '@domain-group/fe-brary';
import { HERO_SECTION, PROFILE_TIERS } from '../../constants';

const {
  colors: { neutral },
  spacing,
} = tokens;

export const buttonGroup = ({
  heroSection,
  isAgency,
  profileTier,
}: {
  heroSection: HERO_SECTION;
  isAgency: boolean;
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  display: none;

  ${media('>=tablet')} {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-column-gap: ${spacing.xs};
  }

  ${heroSection === HERO_SECTION.section &&
  css`
    ${media('>=tablet')} {
      width: auto;
      margin: ${spacing.s} 0 0;
    }
  `};

  ${heroSection === HERO_SECTION.banner &&
  css`
    ${!isAgency &&
    profileTier === PROFILE_TIERS.platinum &&
    css`
      ${media('>=tablet')} {
        display: none;
      }
    `};
  `};

  ${(heroSection === HERO_SECTION.sticky ||
    heroSection === HERO_SECTION.section) &&
  css`
    width: auto;
  `};

  ${heroSection === HERO_SECTION.sticky &&
  css`
    margin: 0;
  `};
`;

export const buttonHolder = css``;

interface AnimationSettings {
  initial: Target;
  animate: Target;
  exit: Target;
  transition: Transition;
}

export const animationSettings = (
  shouldReduceMotion: boolean | null,
): AnimationSettings => ({
  initial: {
    opacity: 0,
    y: 20,
    overflow: 'hidden',
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 20,
    overflow: 'hidden',
  },
  transition: {
    duration: shouldReduceMotion ? 0 : 0.5,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
});

export const stickyContactButtons = css`
  background: ${neutral.surface.default};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing.xs};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid ${neutral.trim.default};
  z-index: 3;
  padding: ${tokens.spacing.s};
  ${media('>=tablet')} {
    display: none;
  }
`;
