import React, { ReactElement } from 'react';

const canonicalSeo = ({
  canonical,
  doNotIndex = false,
}: {
  canonical?: string;
  doNotIndex?: boolean;
}): ReactElement | null => {
  if (doNotIndex) {
    return <meta name="robots" content="noindex, follow" />;
  }

  if (!canonical) {
    return null;
  }

  return <link rel="canonical" href={canonical} />;
};

export default canonicalSeo;
