/* eslint-disable import/prefer-default-export */
import { ApolloError, gql, useQuery } from '@apollo/client';

import {
  ContactCurrentListingsFilterQuery,
  ListingFilterStatus,
} from '../../../generated/graphql';

import type { Choice } from '../@types';
import {
  filtersFromFilterData,
  ListingFilterResult,
} from '../utils/filters-from-filter-data';

import { ListingFilterStatusId } from '../@types';

export const CONTACT_CURRENT_LISTINGS_FILTER_QUERY = gql`
  query contactCurrentListingsFilter($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      id
      agentsearchListingsFiltersByAgentIdV2AndAgencyId {
        listingSummary {
          listingType
          bedrooms
          propertyTypes
          suburbSummaries {
            suburbSlug
            suburbName
            listingCount
          }
        }
      }
    }
  }
`;

interface UseListingFilterArgs {
  contactId: number;
  listingStatus?: Choice<ListingFilterStatus>;
  initialListingStatusId?: ListingFilterStatusId;
}

export const useContactListingFilters = ({
  listingStatus,
  contactId,
  initialListingStatusId,
}: UseListingFilterArgs): ListingFilterResult & {
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, error, loading } = useQuery<ContactCurrentListingsFilterQuery>(
    CONTACT_CURRENT_LISTINGS_FILTER_QUERY,
    {
      variables: { contactId },
    },
  );

  const filterData =
    data?.contactByContactId?.agentsearchListingsFiltersByAgentIdV2AndAgencyId;

  if (loading || !filterData) {
    return { loading, error };
  }

  const filters = filtersFromFilterData({
    filterData,
    listingStatus,
    initialListingStatusId,
  });

  return { loading, error, ...filters };
};
