import { css } from '@emotion/react';
import { applyFont, media, tokens } from '@domain-group/fe-brary';

export const statistics = css`
  display: flex;
  flex-wrap: wrap;

  ${media('>=tablet')} {
    flex-wrap: nowrap;
    align-items: center;
  }

  div {
    min-height: 45px;
    width: 50%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'dd' 'dt';
    flex-grow: 1;
    padding: 0;
    margin: ${tokens.spacing.s} 0 0;

    ${media('>=tablet')} {
      width: auto;
      padding: 0 ${tokens.spacing.xl};
      margin: 0 ${tokens.spacing.xs};
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    ${media('>=desktop')} {
      &:not(:first-of-type) {
        border-left: 1px solid ${tokens.colors.neutral.trim.default};
      }
    }

    dt {
      grid-area: dt;
      ${applyFont(tokens.typography.overline)};
      color: ${tokens.colors.neutral.medium.default};
      white-space: nowrap;
      margin-top: ${tokens.spacing.xxs};

      ${media('>=tablet')} {
        text-align: center;
      }
    }

    dd {
      grid-area: dd;
      ${applyFont(tokens.typography.heading)};

      ${media('>=tablet')} {
        text-align: center;
      }
    }
  }
`;

export const jointSales = css`
  display: block;
  margin-top: 16px;
  ${applyFont(tokens.typography.caption)};
  color: ${tokens.colors.neutral.medium.default};

  ${media('>=tablet')} {
    text-align: right;
    margin-top: ${tokens.spacing.s};
  }
`;
