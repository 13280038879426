import { css, SerializedStyles } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export * from '../filter-headings.style';

export const radioGroup = css`
  padding: ${tokens.spacing.s};
`;

export const tileContainer = css`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 1px;
`;

export const tile = ({
  isChecked,
}: {
  isChecked: boolean;
}): SerializedStyles => css`
  position: relative;
  font-weight: bold;
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  background-color: ${tokens.colors.neutral.surface.default};
  border: 1px solid ${tokens.colors.neutral.trim.default};
  cursor: pointer;
  padding: ${tokens.spacing.xs} ${tokens.spacing.s};
  color: ${tokens.colors.neutral.heavy.default};
  margin: 0 -1px;
  transition:
    0.5s ease color,
    0.5s ease background-color;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover,
  &:focus {
    color: ${tokens.colors.interactive.base.hover};
  }

  ${isChecked &&
  css`
    background-color: ${tokens.colors.interactive.background.default};
    color: ${tokens.colors.interactive.base.default};
    border: 1px solid ${tokens.colors.interactive.trim.default};
    position: relative;
    z-index: 1;
  `}
`;

export const hiddenInput = css`
  position: absolute;
  opacity: 0;
`;

export const formSection = css`
  padding-bottom: ${tokens.spacing.m};
  margin-bottom: ${tokens.spacing.m};
  border-bottom: 1px solid ${tokens.colors.neutral.trim.default};
`;
