import { css, SerializedStyles } from '@emotion/react';
import { tokens, media, applyFont } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

export const wrapper = css`
  margin-bottom: ${spacing.s};

  ${media('>=desktop')} {
    margin-bottom: 0;
  }
`;

export const radioList = css`
  display: none;

  ${media('>=desktop')} {
    display: block;
  }

  > div:first-of-type {
    border-bottom: 1px solid ${colors.neutral.trim.default};
    margin-bottom: ${spacing.m};
  }
`;

// Select styles
export const fieldWrapper = css`
  padding-bottom: ${spacing.s};
  display: block;
  width: 100%;

  > label {
    width: 100%;
    display: flex;

    > span:last-of-type {
      flex-grow: 1;
    }
  }
`;

export const radioLabel = (isActive: boolean): SerializedStyles => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${applyFont(typography.body)}

  ${isActive &&
  css`
    ${applyFont(typography.bodyBold)}
  `}
`;

export const pill = (isActive: boolean): SerializedStyles => css`
  padding: 0 ${spacing.xs};
  ${applyFont(typography.bodyBold)}

  ${isActive &&
  css`
    color: ${colors.interactive.base.default};
  `}
`;

// Select styles
export const select = css`
  ${media('>=desktop')} {
    display: none;
  }
`;

export const selectLabel = css`
  margin-right: 5px;
  ${applyFont(typography.subheading)}
  color: ${colors.neutral.heavy.default};
`;

export const dropdown = css`
  ${applyFont(typography.subheading)}
  padding-right: 20px;
  background: transparent;
  border: none;
  color: ${colors.interactive.base.default};
  appearance: none;
  width: auto;
`;

export const dropdownIcon = css`
  margin-left: -18px;
  > svg {
    margin-top: -2px;
  }
`;
