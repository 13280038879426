import React from 'react';
import { h5Tag, aTag, srOnly } from '@domain-group/fe-brary';
import * as styles from './selling-guide-card.styles';

const SellingGuideCard = (): JSX.Element => {
  const linkUrl = '/advice/selling/';

  return (
    <article css={styles.card}>
      <h1 css={h5Tag}>The ultimate guide to selling</h1>
      <p css={styles.copyPadding}>
        Selling your property? Here&apos;s a guide that answers most of your
        important questions.
      </p>
      <a
        css={aTag}
        href={linkUrl}
        /* It's an internal link */
        target="_blank"
        rel="noreferrer"
        // TODO
        // onClick={() => {}}
      >
        Read more <span css={srOnly}>about selling your property</span>
      </a>
    </article>
  );
};

export default SellingGuideCard;
