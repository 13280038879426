const getIntroductionPhrase = (agencyName: string, address?: string) =>
  `The team at ${agencyName}${address ? ` located at ${address}` : ''}`;

const getIntouchPhrase = (agencyName: string, suburb: string): string =>
  `<p>
    For all your local property needs in the ${suburb} area, get in touch with ${agencyName}.
  </p>`;

const propertyText = (propertyNumber: number): string =>
  propertyNumber > 1 ? 'properties' : 'property';

/* "By" generator funtions */
const byNoListing = ({
  agencyName,
  address,
  suburb,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
}): string =>
  `<div>
    ${
      address
        ? `<p>
  For all your property needs in the ${suburb} area, get in touch with the team at ${agencyName} located at ${address}
</p>`
        : getIntouchPhrase(agencyName, suburb)
    }
  </div>`;

const bySoldSaleRentLeased = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  sale,
  lease,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  sale: number;
  lease: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)}
      and leased ${leased} ${propertyText(leased)} in the last 12 months,
      and currently has ${sale} ${propertyText(
        sale,
      )} for sale and ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for sale and rent below as well as properties
      we have sold and leased in the last 12 months.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldSaleLeased = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  sale,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  sale: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)}
      and leased ${leased} ${propertyText(leased)} in the last 12 months,
      and currently has ${sale} ${propertyText(sale)} for sale.
    </p>
    <p>
      You can view our properties for sale below as well as properties we have sold and leased in the last 12 months. 
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldRentLeased = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  lease,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  lease: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)}
      and leased ${leased} ${propertyText(leased)} in the last 12 months,
      and currently has ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for rent below as well as properties we have sold and leased in the last 12 months. 
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySaleRentLeased = ({
  agencyName,
  address,
  suburb,
  sale,
  lease,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  sale: number;
  lease: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has leased ${leased} ${propertyText(leased)} in the last 12 months,
      and currently has ${sale} ${propertyText(sale)} for sale
      and ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for sale and rent below as well as properties we have leased the last 12 months.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const byRentLeased = ({
  agencyName,
  address,
  suburb,
  lease,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  lease: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has leased ${leased} ${propertyText(leased)} in the last 12 months,
      and currently has ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
    You can view our properties for rent below as well as properties we have leased in the last 12 months. 
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySaleLeased = ({
  agencyName,
  address,
  suburb,
  sale,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  sale: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has leased ${leased} ${propertyText(leased)} in the last 12 months,
      and currently has ${sale} ${propertyText(sale)} for sale.
    </p>
    <p>
      You can view our properties for sale below as well as properties we have leased in the last 12 months.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldLeased = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(
        soldTotal,
      )} and leased ${leased} ${propertyText(leased)} in the last 12 months.
    </p>
    <p>
      You can view the properties we have sold and leased in the last 12 months below.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const byLeasedOnly = ({
  agencyName,
  address,
  suburb,
  leased,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  leased: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has leased ${leased} ${propertyText(leased)} in the last 12 months.
    </p>
    <p>
      You can view the properties we have leased in the last 12 months below.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const byRentOnly = ({
  agencyName,
  address,
  suburb,
  lease,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  lease: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} currently has ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for rent below.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySaleOnly = ({
  agencyName,
  address,
  suburb,
  sale,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  sale: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} currently has ${sale} ${propertyText(sale)} for sale.
    </p>
    <p>
      You can view our properties for sale below.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySaleRent = ({
  agencyName,
  address,
  suburb,
  sale,
  lease,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  sale: number;
  lease: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} currently has ${sale} ${propertyText(sale)} for sale
      and ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for sale and rent below. 
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldOnly = ({
  agencyName,
  address,
  suburb,
  soldTotal,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)}
      in the last 12 months.
    </p>
    <p>
      You can view the properties we have sold the last 12 months below.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldRent = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  lease,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  lease: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)} in the last
      12 months, and currently has ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for rent below as well as properties we have sold in the last 12 months.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldSaleRent = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  sale,
  lease,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  sale: number;
  lease: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)} in the
      last 12 months, and currently has ${sale} ${propertyText(sale)} for sale
      and ${lease} ${propertyText(lease)} for rent.
    </p>
    <p>
      You can view our properties for sale and rent below as well as properties we have leased in the last 12 months.
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

const bySoldSale = ({
  agencyName,
  address,
  suburb,
  soldTotal,
  sale,
}: {
  agencyName: string;
  address?: string;
  suburb: string;
  soldTotal: number;
  sale: number;
}): string =>
  `<div>
    <p>
      ${getIntroductionPhrase(
        agencyName,
        address,
      )} has sold ${soldTotal} ${propertyText(soldTotal)}
      in the last 12 months,
      and currently has ${sale} ${propertyText(sale)} for sale.
    </p>
    <p>
      You can view our properties for sale below as well as properties we have sold in the last 12 months. 
    </p>
    ${getIntouchPhrase(agencyName, suburb)}
  </div>`;

type ListingsTotal = {
  sale: number;
  soldTotal: number;
  lease: number;
  leased: number;
};

export default ({
  agencyName,
  address,
  listingsTotal,
  suburb,
}: {
  agencyName?: string;
  address?: string;
  listingsTotal: ListingsTotal;
  suburb: string;
}): string => {
  if (!agencyName) {
    return '';
  }
  let bioDetail;
  if (listingsTotal.soldTotal) {
    if (listingsTotal.sale) {
      if (listingsTotal.lease) {
        if (listingsTotal.leased) {
          bioDetail = bySoldSaleRentLeased({
            agencyName,
            address,
            suburb,
            soldTotal: listingsTotal.soldTotal,
            sale: listingsTotal.sale,
            lease: listingsTotal.lease,
            leased: listingsTotal.leased,
          });
        } else {
          bioDetail = bySoldSaleRent({
            agencyName,
            address,
            suburb,
            soldTotal: listingsTotal.soldTotal,
            sale: listingsTotal.sale,
            lease: listingsTotal.lease,
          });
        }
      } else if (listingsTotal.leased) {
        bioDetail = bySoldSaleLeased({
          agencyName,
          address,
          suburb,
          soldTotal: listingsTotal.soldTotal,
          sale: listingsTotal.sale,
          leased: listingsTotal.leased,
        });
      } else {
        bioDetail = bySoldSale({
          agencyName,
          address,
          suburb,
          soldTotal: listingsTotal.soldTotal,
          sale: listingsTotal.sale,
        });
      }
    } else if (listingsTotal.lease) {
      if (listingsTotal.leased) {
        bioDetail = bySoldRentLeased({
          agencyName,
          address,
          suburb,
          soldTotal: listingsTotal.soldTotal,
          lease: listingsTotal.lease,
          leased: listingsTotal.leased,
        });
      } else {
        bioDetail = bySoldRent({
          agencyName,
          address,
          suburb,
          soldTotal: listingsTotal.soldTotal,
          lease: listingsTotal.lease,
        });
      }
    } else if (listingsTotal.leased) {
      bioDetail = bySoldLeased({
        agencyName,
        address,
        suburb,
        soldTotal: listingsTotal.soldTotal,
        leased: listingsTotal.leased,
      });
    } else {
      bioDetail = bySoldOnly({
        agencyName,
        address,
        suburb,
        soldTotal: listingsTotal.soldTotal,
      });
    }
  } else if (listingsTotal.sale) {
    if (listingsTotal.lease) {
      if (listingsTotal.leased) {
        bioDetail = bySaleRentLeased({
          agencyName,
          address,
          suburb,
          sale: listingsTotal.sale,
          lease: listingsTotal.lease,
          leased: listingsTotal.leased,
        });
      } else {
        bioDetail = bySaleRent({
          agencyName,
          address,
          suburb,
          sale: listingsTotal.sale,
          lease: listingsTotal.lease,
        });
      }
    } else if (listingsTotal.leased) {
      bioDetail = bySaleLeased({
        agencyName,
        address,
        suburb,
        sale: listingsTotal.sale,
        leased: listingsTotal.leased,
      });
    } else {
      bioDetail = bySaleOnly({
        agencyName,
        address,
        suburb,
        sale: listingsTotal.sale,
      });
    }
  } else if (listingsTotal.lease) {
    if (listingsTotal.leased) {
      bioDetail = byRentLeased({
        agencyName,
        address,
        suburb,
        lease: listingsTotal.lease,
        leased: listingsTotal.leased,
      });
    } else {
      bioDetail = byRentOnly({
        agencyName,
        address,
        suburb,
        lease: listingsTotal.lease,
      });
    }
  } else if (listingsTotal.leased) {
    bioDetail = byLeasedOnly({
      agencyName,
      address,
      suburb,
      leased: listingsTotal.leased,
    });
  } else {
    bioDetail = byNoListing({
      agencyName,
      address,
      suburb,
    });
  }

  return bioDetail;
};
