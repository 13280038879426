import { css } from '@emotion/react';
import { h4Tag, tokens } from '@domain-group/fe-brary';

export const title = css`
  ${h4Tag};
  margin-bottom: ${tokens.spacing.m};
  color: ${tokens.colors.neutral.heavy.default};
`;

export const story = css`
  margin-bottom: ${tokens.spacing.m};
  color: ${tokens.colors.neutral.heavy.default};

  strong,
  b {
    font-weight: bold;
  }
`;
