import { css, SerializedStyles } from '@emotion/react';
import { tokens, media, applyFont } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

export const totals = css`
  padding: ${spacing.l};
  border-bottom: 1px solid ${colors.neutral.trim.default};

  ${media('>=tablet')} {
    border-bottom: none;
    border-right: 1px solid ${colors.neutral.trim.default};
  }
`;

export const statItem = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.m};

  dt {
    ${applyFont(typography.displaySmall)}
    color: ${colors.neutral.heavy.default};
  }

  dd {
    ${applyFont(typography.displayMedium)}
    color: ${colors.primary.base.default};
  }
`;

export const soldRatio = css`
  display: grid;
  position: relative;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 18px 30px;
  grid-template-areas: 'dt1 dt2' 'dd1 dd2';

  grid-row-gap: ${spacing.xs};

  dt {
    grid-area: dt2;

    ${applyFont(typography.overline)}
    color: ${colors.neutral.medium.default};
    text-align: right;
    white-space: nowrap;

    &:first-of-type {
      grid-area: dt1;
      text-align: left;
    }
  }

  dd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-area: dd2;
    margin: 0 ${spacing.s};
    z-index: 1;

    ${applyFont(typography.displaySmall)}
    color: ${colors.neutral.surface.default};

    &:first-of-type {
      grid-area: dd1;
      justify-content: flex-start;
    }
  }
`;

export const soldBar = ({
  sold,
  auction,
}: {
  sold: number;
  auction: number;
}): SerializedStyles => {
  // calculate percent otherwise set 50% to show balance
  const soldPercent = !sold && !auction ? 50 : (sold / (sold + auction)) * 100;

  return css`
    display: block;
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
    border-radius: 18px;
    background-color: ${colors.secondary.base.default};
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: ${soldPercent}%;
      height: 30px;
      bottom: 0;
      left: 0;
      background-color: ${colors.primary.base.default};

      transition: width 0.5s ease;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  `;
};
