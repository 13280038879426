import { css } from '@emotion/react';
import { media, h4Tag, fontBase, shadow, tokens } from '@domain-group/fe-brary';

export const wrapper = (isWide = false) => css`
  max-width: ${isWide ? '629px' : '498px'};
  margin: 0 auto;
  text-align: center;
`;

export const avatarSize = css`
  width: 86px;
  height: 86px;
`;

export const avatarHolder = css`
  ${avatarSize};
  margin: 0 auto ${tokens.spacing.xxs} auto;
`;

export const avatar = css`
  ${avatarSize};
  margin: 0 auto;
  border: 2px solid ${tokens.colors.neutral.surface.default};
  box-shadow: ${shadow(tokens.shadows.elevation4)};
  margin-bottom: ${tokens.spacing.m};
`;

export const title = css`
  ${h4Tag};
  margin-bottom: ${tokens.spacing.xxs};
`;

export const intro = css`
  ${fontBase};
  margin-bottom: ${tokens.spacing.xs};

  ${media('>tablet')} {
    margin-bottom: ${tokens.spacing.s};
  }
`;

export const button = css`
  margin-top: ${tokens.spacing.xs};

  ${media('>tablet')} {
    margin-top: ${tokens.spacing.s};
  }
`;
