import React from 'react';

import { Avatar } from '../../../../shared';

import * as styles from './contact-details-sticky.style';
import { HERO_SECTION } from '../../../constants';

export type Props = {
  avatarUrl: string;
  name: string;
  jobPosition?: string | null;
  agencyName?: string | null;
  isActive: boolean;
};

const heroSection = HERO_SECTION.sticky;

const ContactDetailsPlatinumSticky = ({
  avatarUrl,
  name,
  jobPosition,
  agencyName,
  isActive,
}: Props): JSX.Element => {
  const avatarTitle = [name, jobPosition || 'Real Estate Agent']
    .filter(Boolean)
    .join(', ');

  return (
    <div
      css={styles.agentDetails({
        isActive,
      })}
      data-testid="agent-details"
    >
      <div
        css={styles.agentAvatarHolder}
        data-testid="trade-profile-hero__agent-avatar"
      >
        <Avatar
          size="custom"
          imageUrl={avatarUrl}
          imageTitle={avatarTitle}
          css={styles.agentAvatar}
        />
      </div>
      <div
        css={styles.detailsBody}
        data-testid="trade-profile-hero__details-body"
      >
        <p
          css={styles.name}
          data-testid={`trade-profile-hero-${heroSection}__name`}
        >
          {' '}
          {name}{' '}
        </p>
        {jobPosition && (
          <p
            css={styles.agentJob}
            data-testid={`trade-profile-hero-${heroSection}__agent-job-position`}
          >
            {jobPosition}{' '}
            {agencyName && (
              <span data-testid="trade-profile-hero__agent-agency">
                @ {agencyName}
              </span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactDetailsPlatinumSticky;
