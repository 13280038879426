import React, { ReactNode } from 'react';

import * as styles from './details-wrapper.style';
import { HERO_SECTION, PROFILE_TIERS } from '../../constants';

export type Props = {
  isAgency: boolean;
  withWrapper?: boolean;
  heroSection: HERO_SECTION;
  profileTier: PROFILE_TIERS;
  children: ReactNode;
  desktopWidth?: number;
  backgroundColour?: string;
};

const DetailsWrapper = ({
  isAgency,
  heroSection,
  profileTier,
  children,
  withWrapper = false,
  desktopWidth = 992,
  backgroundColour,
}: Props): JSX.Element | null => {
  if (!children) {
    return null;
  }

  const content = (
    <div
      css={styles.detailsWrapper({
        desktopWidth,
        heroSection,
        isAgency,
        profileTier,
      })}
      data-testid="details"
    >
      {children}
    </div>
  );

  if (withWrapper) {
    return (
      <section
        css={styles.section({ profileTier, isAgency, backgroundColour })}
        data-testid="trade-profile-hero__section"
      >
        {content}
      </section>
    );
  }

  return content;
};

export default DetailsWrapper;
