import { css } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

export const pillsWrapper = css`
  margin: 0;

  ${media('>=desktop')} {
    margin: 0 ${tokens.spacing.xs} 0 0;
  }
`;

export const filtersBar = css``;

export const clearButtonStyleOverride = css`
  color: ${tokens.colors.neutral.medium.default};
  float: right;
`;
