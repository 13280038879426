const getIntouchPhrase = (firstName: string): string =>
  `<p>
    For all your local property needs in this area, get in touch with ${firstName}.
  </p>`;

const byNoListing = ({
  introductionPhrase,
  firstName,
}: {
  introductionPhrase: string;
  firstName: string;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const byRentOnly = ({
  introductionPhrase,
  firstName,
  lease,
}: {
  introductionPhrase: string;
  firstName: string;
  lease: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and currently has ${lease} listings for rent.
    </p>
    <p>
      You can view ${firstName}’s listings for rent below.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const bySaleOnly = ({
  introductionPhrase,
  firstName,
  sale,
}: {
  introductionPhrase: string;
  firstName: string;
  sale: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and currently has ${sale} listings for sale.
    </p>
    <p>
      You can view ${firstName}’s listings for sale below.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const bySaleRent = ({
  introductionPhrase,
  firstName,
  sale,
  lease,
}: {
  introductionPhrase: string;
  firstName: string;
  sale: number;
  lease: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and currently has ${sale} listings for sale and
      ${lease} listings for rent.
    </p>
    <p>
      You can view ${firstName}’s listings for sale and rent below.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const bySoldOnly = ({
  introductionPhrase,
  firstName,
  soldTotal,
}: {
  introductionPhrase: string;
  firstName: string;
  soldTotal: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and has sold ${soldTotal} properties in the last 12 months.
    </p>
    <p>
      You can view ${firstName}’s recently sold properties below.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const bySoldRent = ({
  introductionPhrase,
  firstName,
  soldTotal,
  lease,
}: {
  introductionPhrase: string;
  firstName: string;
  soldTotal: number;
  lease: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and has sold ${soldTotal} properties in the last
      12 months, and currently has ${lease} listings for rent.
    </p>
    <p>
      You can view ${firstName}’s listings for rent below as well as any properties
      sold the last 12 months.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const bySoldSaleRent = ({
  introductionPhrase,
  firstName,
  soldTotal,
  sale,
  lease,
}: {
  introductionPhrase: string;
  firstName: string;
  soldTotal: number;
  sale: number;
  lease: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and has sold ${soldTotal} properties in the
      last 12 months, and currently has ${sale} listings for sale and ${lease} listings for rent.
    </p>
    <p>
      You can view ${firstName}’s listings for sale and rent below as well as any
      properties sold the last 12 months.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

const bySoldSale = ({
  introductionPhrase,
  firstName,
  soldTotal,
  sale,
}: {
  introductionPhrase: string;
  firstName: string;
  soldTotal: number;
  sale: number;
}): string =>
  `<div>
    <p>
      ${introductionPhrase}, and has sold ${soldTotal} properties in the last 12 months,
      and currently has ${sale} listings for sale.
    </p>
    <p>
      You can view ${firstName}’s listings for sale below as well as any
      properties sold the last 12 months.
    </p>
    ${getIntouchPhrase(firstName)}
  </div>`;

type ListingsTotal = {
  sale: number;
  sold: number;
  auction: number;
  lease: number;
};

export interface GetDefaultAgentBioArgs {
  name?: string;
  agencyName?: string;
  agencyAddress?: string;
  listingsTotal: ListingsTotal;
}
export default ({
  name,
  agencyName,
  agencyAddress,
  listingsTotal,
}: GetDefaultAgentBioArgs): string => {
  let bioDetail = '';
  const hideOfficeLocation = !agencyAddress;

  if (!name) {
    return bioDetail;
  }

  const firstName = name.split(' ')[0];
  const soldTotal = (listingsTotal.sold || 0) + (listingsTotal.auction || 0);
  const hasSale = listingsTotal?.sale > 0;
  const hasRent = listingsTotal?.lease > 0;
  const introductionPhrase = `${name} is part of the team at ${agencyName}${
    hideOfficeLocation ? '' : ` located at ${agencyAddress}`
  }`;

  if (soldTotal > 0) {
    if (hasSale && hasRent) {
      bioDetail = bySoldSaleRent({
        introductionPhrase,
        firstName,
        soldTotal,
        sale: listingsTotal.sale,
        lease: listingsTotal.lease,
      });
    } else if (hasSale) {
      bioDetail = bySoldSale({
        introductionPhrase,
        firstName,
        soldTotal,
        sale: listingsTotal.sale,
      });
    } else if (hasRent) {
      bioDetail = bySoldRent({
        introductionPhrase,
        firstName,
        soldTotal,
        lease: listingsTotal.lease,
      });
    } else {
      bioDetail = bySoldOnly({ introductionPhrase, firstName, soldTotal });
    }
  } else if (hasSale && hasRent) {
    bioDetail = bySaleRent({
      introductionPhrase,
      firstName,
      sale: listingsTotal.sale,
      lease: listingsTotal.lease,
    });
  } else if (hasSale) {
    bioDetail = bySaleOnly({
      introductionPhrase,
      firstName,
      sale: listingsTotal.sale,
    });
  } else if (hasRent) {
    bioDetail = byRentOnly({
      introductionPhrase,
      firstName,
      lease: listingsTotal.lease,
    });
  } else {
    bioDetail = byNoListing({ introductionPhrase, firstName });
  }

  return bioDetail;
};
