import { css } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

// const gutterWidth = variables.global.spacing.mobile.medium;
const halfGutter = tokens.spacing.xs;

export const descriptionWrapper = css`
  flex-grow: 1;

  ${media('>=tablet')} {
    padding: 0 ${halfGutter};
  }
`;

export const aboutSection = css`
  ${media('>=tablet')} {
    display: flex;
    align-items: flex-start;
    margin: 0 calc(-1 * ${halfGutter});
  }
`;

export const nextOpenHome = css`
  ${media('>=tablet')} {
    padding: 0 ${halfGutter};
    flex-shrink: 0;
    width: calc(100% / 3);
  }
  ${media('>=desktop')} {
    width: 25%;
  }
`;

const nextHomeFontSize = tokens.typography.overline.size;
const nextHomeMarginBottom = tokens.spacing.s;
const nextHomeMarginTop = tokens.spacing.xl;

export const nextOpenHomeTitle = css`
  color: ${tokens.colors.neutral.heavy.default};
  font-size: ${nextHomeFontSize};
  line-height: ${tokens.typography.overline.lineHeight};
  font-weight: ${tokens.typography.overline.weight};
  text-transform: ${tokens.typography.overline.textTransform};
  margin-bottom: ${nextHomeMarginBottom};
  margin-top: ${nextHomeMarginTop};

  ${media('>=tablet')} {
    margin-top: 0;
  }
`;
