import { css } from '@emotion/react';

import { tokens, applyFont, media } from '@domain-group/fe-brary';

const { spacing, typography } = tokens;

const pageSize = 1280;
export const breadcrumbWrapper = css`
  ${media('>desktop')} {
    max-width: ${pageSize}px;
    margin: 0 auto;
    padding: 0 ${spacing.m};
  }
`;

export const title = css`
  margin: ${spacing.xs} 0 ${spacing.xxs};
  ${applyFont(typography.subheading)}
`;

export const subTitle = css`
  margin-bottom: ${spacing.m};
`;

export const buttons = css``;

export const resultsGroup = css`
  > *:not(:last-of-type) {
    margin-bottom: ${spacing.m};
  }
`;

export const sortGroup = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const pagination = css`
  margin-top: ${spacing.m};
`;
