import { handleError } from '@domain-group/fe-helper';

import { ListingCardFragment } from '../../../generated/graphql';
import {
  AddressModel,
  FeaturesModel,
  InspectionModel,
  ListingModel,
  NextListingProps,
} from '../@types';

/* eslint-disable import/prefer-default-export */
export function mapToListingModel(
  listingData: ListingCardFragment,
  overwrite?: Partial<ListingModel>,
): NextListingProps | null {
  if (!listingData?.promoLevel) {
    /** eslint-disable-next-line no-console */
    handleError('Listing must have a promo level');
    return null;
  }

  const formattedAddress = listingData?.address?.displayAddress?.split(',')[0];

  const address: AddressModel = {
    street: formattedAddress,
    state: listingData?.address?.state || undefined,
    suburb: listingData?.address?.suburb?.name?.toUpperCase() || undefined,
    postcode: listingData?.address?.postcode || undefined,
    lat: listingData?.address?.geolocation?.latitude,
    lng: listingData?.address?.geolocation?.longitude,
  };

  const inspectionData = listingData?.inspectionDetails?.inspections?.[0];
  const inspection: InspectionModel = {
    openTime: inspectionData?.openingDateTime || undefined,
  };

  const capitalize = (word: string) =>
    typeof word === 'string' && word.charAt(0).toUpperCase() + word.slice(1);

  const features: FeaturesModel = {
    beds: listingData?.bedrooms || undefined,
    baths: listingData?.bathrooms || undefined,
    parking: listingData?.parkingSpaces || undefined,
    propertyType: capitalize(listingData?.propertyTypes?.[0]) || undefined,
    isRural: !!listingData?.isRural,
    landSize: listingData?.landArea || undefined,
  };

  const photos = (listingData?.media || []).reduce(
    (photoArray: string[], media) => {
      /* eslint-disable-next-line no-underscore-dangle */
      if (media && media.__typename === 'Image') {
        return [...photoArray, media.url];
      }
      return photoArray;
    },
    [],
  );

  const listing: ListingModel = {
    address,
    hasVideo: false,
    branding: {},
    features,
    images: photos || undefined,
    promoType: listingData?.promoLevel.toLowerCase(),
    inspection,
    domainWebsiteUrl: listingData?.seoUrl || undefined,
    url: '', // seoUrl takes care of the full URL string
    price: listingData?.priceDetails?.displayPrice || undefined,

    tags: {
      tagClassName: undefined,
      tagText: undefined,
    },
    ...overwrite,
  };

  return {
    id: listingData?.id,
    listingModel: listing,
    listingType: 'listing',
  };
}
