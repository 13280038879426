import { css } from '@emotion/react';
import { tokens, media } from '@domain-group/fe-brary';

const { colors } = tokens;

export const linksContainer = css`
  display: none;
  ${media('>=tablet')} {
    display: block;
  }
`;

export const buttonSwitch = (isActive) => css`
  z-index: 1;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: ${colors.interactive.medium.hover};
    background-color: ${colors.primary.subdued.hover};
  }

  ${isActive &&
  css`
    &:disabled {
      color: ${colors.interactive.medium.default};
      border-color: ${colors.interactive.trim.default};
      background-color: ${colors.primary.subdued.default};
    }
  `}
`;

export const buttonLabel = css`
  padding-right: 12px;
`;

export const buttonBadge = css`
  margin-top: -3px;
`;
