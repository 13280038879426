/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';

export type DigitalDataInitProps = {
  trackingDisabled: boolean;
};

export const DigitalDataInit = (props: DigitalDataInitProps): JSX.Element => (
  <>
    {!props.trackingDisabled && (
      <Head>
        <script
          title="digital-data-init"
          key="data-layer"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `if (typeof dataLayer === 'undefined') {
        dataLayer = [];
      }`,
          }}
        />
      </Head>
    )}
  </>
);
