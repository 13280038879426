/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { ListingFilterStatus } from '../../../generated/graphql';
import { LISTINGS_TAB_PAGE_PARAM } from '../../../constants/query-params';
import type { Choice, ListingFilterStatusId } from '../@types';
import { listingStatusChoiceMap } from '../utils/listing-status-choice-map';
import { updateQueryParam } from '../../../utils';

type SelectFunction = (option?: Choice<string>) => void;

export type FilterStateTracking = {
  onSelectListingStatus?: (listingStatus: string) => void;
  onSelectSelectSortBy?: (listingStatus: string, sortBy: string) => void;
  onSelectPropertyType?: (listingStatus: string) => void;
  onSelectSuburb?: (listingStatus: string) => void;
  onSelectBedrooms?: (listingStatus: string) => void;
  onPageChange?: (listingStatus: string) => void;
  onListingCardClick?: (listingStatus: string) => void;
};

type FilterState = {
  clearAll: () => void;
  currentBedrooms?: Choice<string>;
  currentPropertyType?: Choice<string>;
  currentSortBy?: Choice<string>;
  currentSuburb?: Choice<string>;
  page: number;
  selectBedrooms: SelectFunction;
  selectPropertyType: SelectFunction;
  selectSortBy: SelectFunction;
  selectSuburb: SelectFunction;
  changeToNewPage: (newPage: number) => void;
  setPage: (newPage: number) => void;
  setTotalPages: (newPage: number) => void;
  totalPages: number;
  selectListingStatus: (newListingStatus: Choice<ListingFilterStatus>) => void;
  listingStatus?: Choice<ListingFilterStatus>;
};

type FilterStateArgs = {
  initialPage?: number;
  initialTabId?: ListingFilterStatusId;
  tracking?: FilterStateTracking;
};

export const useFilterState = ({
  initialPage,
  initialTabId,
  tracking = {},
}: FilterStateArgs): FilterState => {
  // State

  const initialListingStatusTabChoice = listingStatusChoiceMap.find(
    (status) => status.id === initialTabId,
  );

  const [currentSuburb, setSuburb] = useState<Choice<string> | undefined>(
    undefined,
  );
  const [currentBedrooms, setBedroom] = useState<Choice<string> | undefined>(
    undefined,
  );
  const [currentPropertyType, setPropertyType] = useState<
    Choice<string> | undefined
  >(undefined);
  const [currentSortBy, setSortBy] = useState<Choice<string> | undefined>(
    undefined,
  );
  const [page, setPage] = useState<number>(initialPage || 1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [listingStatus, setListingStatus] = useState<
    Choice<ListingFilterStatus> | undefined
  >(initialListingStatusTabChoice);

  // handlers
  const handleResetPage = () => setPage(1);

  const handleSelectBedrooms = (bedroomOption?: Choice<string>) => {
    handleResetPage();
    setBedroom(bedroomOption);
    if (listingStatus?.label) {
      tracking.onSelectBedrooms?.(listingStatus?.label);
    }
  };
  const handleSelectPropertyType = (newPropertyType?: Choice<string>) => {
    handleResetPage();
    setPropertyType(newPropertyType);
    if (listingStatus?.label) {
      tracking.onSelectPropertyType?.(listingStatus?.label);
    }
  };
  const handleSelectSortBy = (newSortBy?: Choice<string>) => {
    handleResetPage();
    setSortBy(newSortBy);
    if (listingStatus?.label && newSortBy?.label) {
      tracking.onSelectSelectSortBy?.(listingStatus?.label, newSortBy?.label);
    }
  };
  const handleSelectSuburb = (newSuburb?: Choice<string>) => {
    handleResetPage();
    setSuburb(newSuburb);
    if (listingStatus?.label) {
      tracking.onSelectSuburb?.(listingStatus?.label);
    }
  };

  const handleListingStatusChange = (status: Choice<ListingFilterStatus>) => {
    const previousStatus = listingStatus;
    setListingStatus(status);
    setPage(1);

    // Making sure this doesnt track the initial status on page load
    if (previousStatus?.label && status.label) {
      tracking.onSelectListingStatus?.(status.label);
    }
  };
  const handlePageChange = (newPage: number) => {
    updateQueryParam(LISTINGS_TAB_PAGE_PARAM, `${newPage}`);
    setPage(newPage);
    if (listingStatus?.label && newPage) {
      tracking.onPageChange?.(listingStatus?.label);
    }
  };

  const handleClearAll = () => {
    setPage(1);
    setBedroom(undefined);
    setPropertyType(undefined);
    setSortBy(undefined);
    setSuburb(undefined);
  };

  return {
    clearAll: handleClearAll,
    currentBedrooms,
    changeToNewPage: handlePageChange,
    currentPropertyType,
    currentSortBy,
    currentSuburb,
    page,
    setPage,
    selectBedrooms: handleSelectBedrooms,
    selectPropertyType: handleSelectPropertyType,
    selectSortBy: handleSelectSortBy,
    selectSuburb: handleSelectSuburb,
    selectListingStatus: handleListingStatusChange,
    listingStatus,
    setTotalPages,
    totalPages,
  };
};
