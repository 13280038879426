import React, { ReactNode } from 'react';
import Sticky from 'react-stickynode';

import { CtaButtonGroup } from '..';
import { APPEARANCE, HERO_SECTION, PROFILE_TIERS } from '../../constants';

import * as styles from './sticky-bar.style';

export type Props = {
  profileTier: PROFILE_TIERS;
  isAgency: boolean;
  name: string;
  agencyColour?: string;
  phoneNumber: string;
  hasEmail?: boolean | null;
  setStickyActive: (value: boolean) => void;
  emailFormComponentId: string;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  children: ReactNode;
  desktopWidth?: number;
};

const StickyBar = ({
  isAgency,
  name,
  profileTier,
  agencyColour,
  phoneNumber,
  hasEmail,
  setStickyActive,
  emailFormComponentId,
  onCallButtonClick = () => undefined,
  onEmailButtonClick = () => undefined,
  children,
  desktopWidth = 992,
}: Props): JSX.Element => {
  const onStateChange = ({ status }: { status: Sticky.StatusCode }) => {
    switch (status) {
      case Sticky.STATUS_FIXED: {
        setStickyActive(true);
        break;
      }
      default: {
        setStickyActive(false);
      }
    }
  };

  return (
    <div css={styles.stickyOuterWrapper({ isAgency, profileTier })}>
      <Sticky top="trade-profile-hero__banner" onStateChange={onStateChange}>
        <div css={styles.stickyWrapper}>
          <div
            css={styles.stickyBar({ desktopWidth })}
            data-testid="sticky-bar"
            style={{
              borderBottomColor: agencyColour || 'transparent',
            }}
          >
            <div
              css={styles.stickyDetails}
              data-testid="trade-profile-hero__sticky-details"
            >
              {children}
            </div>
            <CtaButtonGroup
              isAgency={isAgency}
              profileTier={profileTier}
              callButtonAppearance={APPEARANCE.ghost}
              emailButtonAppearance={APPEARANCE.highlight}
              heroSection={HERO_SECTION.sticky}
              phoneNumber={phoneNumber}
              name={name}
              onCallButtonClick={onCallButtonClick}
              hasEmail={hasEmail}
              emailFormComponentId={emailFormComponentId}
              onEmailButtonClick={onEmailButtonClick}
            />
          </div>
        </div>
      </Sticky>
    </div>
  );
};

export default StickyBar;
