import React, { useContext } from 'react';
import Head from 'next/head';
import { gql, useQuery, useFragment } from '@apollo/client';

import { handleError } from '@domain-group/fe-helper';

import { agentProfilePageDescriptionGenerator } from './utils/description';

import {
  DigitalDataTag,
  DigitalDataInit,
  MetaTags,
  PaginationSeoLinks,
  GoogleSchema,
  createBreadcrumbsJSONBlob,
  agentProfilesPageBreadcrumbs,
  makeAgentHTMLSchema,
} from './components/head-snippets';

import type {
  ContactDigitalDataFragment,
  ContactByContactIdQuery,
} from '../../generated/graphql';

import { agentPageTitleGenerator } from './utils/title';
import ServerInfoContext from '../../contexts/server-info';
import type { ServerInfo } from '../../contexts/server-info';
import { UserDetailsRedacted } from '../../@types';

type ContactHeadWithQueryProps = {
  contactId: number;
  user?: UserDetailsRedacted;
};

type ContactHeadWithFragmentProps = {
  agentIdV2: string;
  agencyId: number;
  contactId: number;
  user?: UserDetailsRedacted;
};

export const FRAGMENT_NAME = 'ContactDigitalData';
export const FRAGMENT = gql`
  fragment ContactDigitalData on Contact {
    id
    agencyId
    agentIdV2
    agencyName
    agencyByAgencyId {
      id
      agencyId
      activeSuburbs {
        suburbName
      }
      address {
        suburb {
          name
          postcode
          state
          suburbId
        }
        displayAddress
      }
      branding {
        id
        logo {
          url
        }
        hero {
          url
        }
      }
      profileUrl
    }
    name
    address {
      suburb {
        name
        postcode
        state
        suburbId
      }
      displayAddress
    }
    profileTier
    profilePhoto {
      url
    }
    profileUrl
    agencyLogo
    jobTitle
    statistics {
      id
      totalForSale
      totalSoldAndAuctioned
      totalForRent
      totalLeased
      totalAuctioned
      totalSold
    }
  }
`;

export const QUERY = gql`
  query digitalDataForContact($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      __typename
      ...ContactDigitalData
    }
  }
  ${FRAGMENT}
`;

export const ContactHeadWithFragment = ({
  agentIdV2,
  agencyId,
  contactId = 0,
  user,
}: ContactHeadWithFragmentProps): JSX.Element | null => {
  const { complete, data } = useFragment<ContactDigitalDataFragment>({
    from: {
      __typename: 'Contact',
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });
  const serverInfo = useContext(ServerInfoContext);

  if (complete && data) {
    const {
      sysEnv,
      baseUrl,
      path,
      source,
      isEmbeddedApp,
      trackingDisabled,
      noIndexFollow,
      seoPaginationNext,
      seoPaginationPrev,
    } = serverInfo as ServerInfo;

    const title = agentPageTitleGenerator({
      agencyName: data?.agencyName ?? '',
      displayAddress: data?.address?.displayAddress ?? '',
      name: data?.name ?? '',
    });

    const breadcrumbs = agentProfilesPageBreadcrumbs({
      originalUrl: path,
      baseUrl,
      data,
    });

    const agencyData = {
      id: data?.id,
      agencyId: data?.agencyId ?? 0,
      name: data.agencyName ?? '',
      branding: data?.agencyByAgencyId?.branding ?? { id: '' },
      activeSuburbs: data.agencyByAgencyId?.activeSuburbs ?? [],
      address: data.agencyByAgencyId?.address,
      profileUrl: data?.agencyByAgencyId?.profileUrl ?? '',
    };

    const agentSchema = makeAgentHTMLSchema({
      originalUrl: path,
      baseUrl,
      agentData: data,
      agencyData,
    });

    const canonical = `${baseUrl}${path}`.toLowerCase();
    const description = agentProfilePageDescriptionGenerator(data);

    return (
      <>
        <Head>
          <title>{title}</title>
          <link rel="canonical" href={canonical} />
        </Head>
        <DigitalDataTag
          contactData={data}
          isEmbeddedApp={isEmbeddedApp}
          sysEnv={sysEnv}
          source={source}
          contactId={contactId}
          user={user}
        />
        <DigitalDataInit trackingDisabled={trackingDisabled} />
        <MetaTags noIndexFollow={!!noIndexFollow} description={description} />
        {seoPaginationNext || seoPaginationPrev ? (
          <PaginationSeoLinks
            seoPaginationNext={seoPaginationNext || ''}
            seoPaginationPrev={seoPaginationPrev || ''}
          />
        ) : null}
        <GoogleSchema
          breadcrumbsJSON={createBreadcrumbsJSONBlob(breadcrumbs)}
          pageSchema={agentSchema}
        />
      </>
    );
  }

  return null;
};

ContactHeadWithFragment.fragment = FRAGMENT;
ContactHeadWithFragment.fragmentName = FRAGMENT_NAME;

export const ContactHeadWithQuery = ({
  contactId,
  user,
}: ContactHeadWithQueryProps): JSX.Element | null => {
  const { data, error } = useQuery<ContactByContactIdQuery>(QUERY, {
    variables: { contactId },
  });

  // Upstream API _sometimes_ returns null for suburbId - however, we can still render fine without it
  if (error) {
    handleError(error);
  }

  if (data) {
    const agencyId = data?.contactByContactId?.agencyId;
    const agentIdV2 = data?.contactByContactId?.agentIdV2;

    if (!agencyId || !agentIdV2) {
      return null;
    }

    return (
      <ContactHeadWithFragment
        agentIdV2={agentIdV2}
        agencyId={agencyId}
        contactId={contactId}
        user={user}
      />
    );
  }

  return null;
};

ContactHeadWithQuery.query = QUERY;
