import { css } from '@emotion/react';
import { media, applyFont, tokens, shadow } from '@domain-group/fe-brary';

export const mobileBreakpoint = 380;

export const card = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${mobileBreakpoint}px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  ${media('>=desktop')} {
    align-items: center;
  }
`;

export const avatar = css`
  width: 86px;
  height: 86px;
  flex-shrink: 0;
  margin-bottom: ${tokens.spacing.s};
  border: 2px solid ${tokens.colors.neutral.surface.default};
  box-shadow: ${shadow(tokens.shadows.elevation4)};
  @media (min-width: ${mobileBreakpoint}px) {
    margin-top: ${tokens.spacing.xs};
    margin-right: ${tokens.spacing.m};
  }
  ${media('>=desktop')} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;

  @media (min-width: ${mobileBreakpoint}px) {
    text-align: left;
  }

  ${media('>=desktop')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
`;

export const copies = css`
  font-size: ${tokens.typography.body.size};
  line-height: ${tokens.typography.body.lineHeight};

  ${media('>=desktop')} {
    margin-right: ${tokens.spacing.m};
  }
`;

export const title = css`
  ${applyFont(tokens.typography.displayMedium)}
  margin-bottom: ${tokens.spacing.xxs};
  color: ${tokens.colors.neutral.heavy.default};

  ${media('>=desktop')} {
    margin-bottom: 0;
  }
`;

export const description = css`
  margin-bottom: ${tokens.spacing.s};
  color: ${tokens.colors.neutral.medium.default};

  ${media('>=desktop')} {
    margin-bottom: 0;
  }
`;

export const button = css`
  width: 100%;

  @media (min-width: ${mobileBreakpoint}px) {
    width: auto;
    flex-shrink: 0;
  }

  ${media('>=desktop')} {
    padding-top: ${tokens.spacing.s};
    padding-bottom: ${tokens.spacing.s};
    width: 269px;
  }

  svg {
    margin-right: ${tokens.spacing.xs};
  }
`;
