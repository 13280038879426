import React from 'react';
import ContactButtons from './contact-buttons';

import * as styles from './cta-button-group.style';
import { APPEARANCE, HERO_SECTION, PROFILE_TIERS } from '../../constants';

export type Props = {
  isAgency: boolean;
  profileTier: PROFILE_TIERS;
  heroSection: HERO_SECTION;
  phoneNumber: string;
  name: string;
  hasEmail?: boolean | null;
  emailFormComponentId: string;
  onCallButtonClick: () => void;
  onEmailButtonClick: () => void;
  callButtonAppearance: APPEARANCE;
  emailButtonAppearance: APPEARANCE;
};

const CtaButtonGroup = ({
  isAgency,
  profileTier,
  heroSection,
  phoneNumber,
  name,
  hasEmail = false,
  emailFormComponentId,
  onCallButtonClick,
  onEmailButtonClick,
  callButtonAppearance,
  emailButtonAppearance,
}: Props): JSX.Element => (
  <div
    css={styles.buttonGroup({
      heroSection,
      isAgency,
      profileTier,
    })}
    data-testid="cta-button-group"
  >
    <ContactButtons
      phoneNumber={phoneNumber}
      name={name}
      onCallButtonClick={onCallButtonClick}
      hasEmail={hasEmail}
      emailFormComponentId={emailFormComponentId}
      onEmailButtonClick={onEmailButtonClick}
      callButtonAppearance={callButtonAppearance}
      emailButtonAppearance={emailButtonAppearance}
    />
  </div>
);

export default CtaButtonGroup;
