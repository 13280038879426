import { css } from '@emotion/react';
import { media, tokens, applyFont } from '@domain-group/fe-brary';

/* Call to actions */
export const callToActions = css`
  display: grid;
  grid-column-gap: ${tokens.spacing.s};
  grid-row-gap: ${tokens.spacing.s};
  grid-auto-rows: auto;
  grid-template-columns: 1fr;

  ${media('>=tablet')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ctaItem = css`
  text-decoration: none;

  ${media('>=tablet')} {
    text-align: center;
  }
`;

export const ctaLink = css`
  text-decoration: none;
  ${applyFont(tokens.typography.bodyBold)}
  background: ${tokens.colors.neutral.surface.selected};
  padding: ${tokens.spacing.s};
  color: ${tokens.colors.neutral.heavy.default};
  background: ${tokens.colors.neutral.surface.selected};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid ${tokens.colors.neutral.trim.default};

  &:hover,
  &:focus {
    border-color: ${tokens.colors.neutral.trim.hover};
  }

  ${media('>=tablet')} {
    display: block;
    height: 100%;
  }
`;

export const ctaIcon = css`
  color: ${tokens.colors.primary.base.default};
  margin-right: ${tokens.spacing.s};

  svg {
    width: 24px;
    height: 24px;
  }

  ${media('>=tablet')} {
    margin-right: 0;
    margin-bottom: ${tokens.spacing.s};

    svg {
      width: 48px;
      height: 48px;
    }
  }

  ${media('>=desktop')} {
    svg {
      width: 60px;
      height: 60px;
    }
  }
`;

export const text = css`
  display: inline;

  ${media('>=tablet')} {
    display: block;
  }
`;

export const mobileOnly = css`
  ${media('>=tablet')} {
    display: none;
  }
`;
