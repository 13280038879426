import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { Tall as TallListing } from '@domain-group/fe-co-listing-card';
import { handleError } from '@domain-group/fe-helper';

import { useShortlist } from '../../../../../hooks';
import { CurrentListingCardFragment } from '../../../../../generated/graphql';
import { mapToCurrentListingModel } from './utils/map-to-current-listing-model';
import { ShortlistModel } from './@types';

import fadeIn from '../../../../../styles/fade-in.style';
import * as styles from './current-listing-card-with-fragment.style';

const CURRENT_LISTING_CARD_FRAGMENT = gql`
  fragment CurrentListingCard on AgentsearchListing {
    id
    listingId
    soldInfo {
      soldDate
      soldPrice
      soldMethod
    }
    priceDetails {
      displayPrice
    }
    media {
      ... on Image {
        url(resolution: { width: 395, height: 225 })
      }
    }
    bedrooms
    bathrooms
    parkingSpaces
    isRural
    url
    seoUrl
    propertyId
    address {
      displayAddress
      suburb {
        name
        suburbId
      }
      state
      postcode
      geolocation {
        latitude
        longitude
      }
    }
  }
`;
const FRAGMENT_NAME = 'CurrentListingCard';

export type Props = {
  adId: number;
  index: number; // Index in an array of many cards
  adComponent: string;

  // Tracking
  onListingCardClick?: () => void;
};

const CurrentListingCardWithFragment = ({
  adId,
  index,
  adComponent,
  onListingCardClick = () => undefined,
}: Props): JSX.Element | null => {
  const { complete, data } = useFragment<CurrentListingCardFragment, unknown>({
    from: {
      __typename: 'AgentsearchListing',
      id: adId,
    },
    fragment: CURRENT_LISTING_CARD_FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });

  const { isOnShortlist, addToShortlist, removeFromShortlist, scrollToRef } =
    useShortlist(adId, adComponent);

  if (!complete || !data) {
    handleError(`Could not find data for ${adId}`);
    return null;
  }

  const isShortlisted = isOnShortlist;

  const shortlist: ShortlistModel = {
    shortlisted: isShortlisted || false,
  };

  const listingData = mapToCurrentListingModel(data, { shortlist });

  const onShortlistClick = () =>
    isShortlisted ? removeFromShortlist() : addToShortlist();

  return (
    <div
      data-testid="listings-tabs__card"
      id={`listings-tab__card_${adId}`}
      css={[fadeIn(index + 1), styles.listingCard]}
      ref={scrollToRef}
    >
      <TallListing
        lazyLoad={false}
        {...listingData?.listingModel}
        onShortlistClick={onShortlistClick}
        onClick={onListingCardClick}
        showEnquireButton={false}
        showNotesButton={false}
      />
    </div>
  );
};

CurrentListingCardWithFragment.fragment = CURRENT_LISTING_CARD_FRAGMENT;
CurrentListingCardWithFragment.fragmentName = FRAGMENT_NAME;

export default CurrentListingCardWithFragment;
