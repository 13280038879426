import React from 'react';
import getConfig from 'next/config';
import {
  HouseIcon,
  HomePriceGuideIcon,
  AuctionResultsIcon,
} from '@domain-group/fe-co-icon';
import { urlUtil } from '@domain-group/fe-helper';
import {
  entryPoint,
  trackAppraisalFunnelEntry,
} from '../../../../tracking/landing-page';
import * as styles from './call-to-actions.style';

const CallToActions = (): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  const baseUrlWithProtocol = publicRuntimeConfig?.BASE_URL;

  return (
    <ul css={styles.callToActions}>
      <li css={styles.ctaItem}>
        <a
          css={styles.ctaLink}
          data-testid="appraisal-link"
          href={urlUtil.createUrlWithParams({
            baseUrl: baseUrlWithProtocol,
            path: '/services/appraisal',
            params: {
              exitUrl: `${baseUrlWithProtocol}/real-estate-agents`,
              'entry-point': entryPoint,
            },
          })}
          onClick={trackAppraisalFunnelEntry}
          onAuxClick={trackAppraisalFunnelEntry}
        >
          <div css={styles.ctaIcon}>
            <HouseIcon size="custom" />
          </div>
          <div>
            <span css={styles.text}>Looking to sell?</span>
            <span css={styles.mobileOnly}>&nbsp;</span>
            <span css={styles.text}>Get a free appraisal</span>
          </div>
        </a>
      </li>

      <li css={styles.ctaItem}>
        <a css={styles.ctaLink} href={`${baseUrlWithProtocol}/owners`}>
          <div css={styles.ctaIcon}>
            <HomePriceGuideIcon size="custom" />
          </div>
          <div>
            <span css={styles.text}>Follow your property</span>
            <span css={styles.mobileOnly}>&nbsp;</span>
            <span css={styles.text}>to understand its value</span>
          </div>
        </a>
      </li>

      <li css={styles.ctaItem}>
        <a css={styles.ctaLink} href={`${baseUrlWithProtocol}/?mode=sold`}>
          <div css={styles.ctaIcon}>
            <AuctionResultsIcon size="custom" />
          </div>
          <div>
            <span css={styles.text}>See recently sold</span>
            <span css={styles.mobileOnly}>&nbsp;</span>
            <span css={styles.text}>properties in your area</span>
          </div>
        </a>
      </li>
    </ul>
  );
};

export default CallToActions;
