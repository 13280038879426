import React from 'react';
import Head from 'next/head';

export type PaginationSeoLinksProps = {
  seoPaginationNext: string;
  seoPaginationPrev: string;
};

export const PaginationSeoLinks = ({
  seoPaginationNext,
  seoPaginationPrev,
}: PaginationSeoLinksProps): JSX.Element => (
  <>
    {seoPaginationNext && (
      <Head>
        <link title="pagination-seo-next" rel="next" href={seoPaginationNext} />
      </Head>
    )}
    {seoPaginationPrev && (
      <Head>
        <link title="pagination-seo-prev" rel="prev" href={seoPaginationPrev} />
      </Head>
    )}
  </>
);
