/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';

const titan = ({ titanEnabled }: { titanEnabled: boolean }): ReactElement => (
  <>
    {titanEnabled && process.env.NODE_ENV !== 'production' && (
      <script
        title="titan-localStorage"
        dangerouslySetInnerHTML={{
          __html: `['sdsat_debug', 'sdsat_stagingLibrary'].forEach((key) => {
            /* DTM toggle explicitly sets this to false when toggled off. It's null if not set */
            if (window.localStorage.getItem(key) !== 'false') {
              window.localStorage.setItem(key, true);
            }
          });`,
        }}
      />
    )}
    {titanEnabled && (
      <script
        title="titan-externalScript"
        async
        defer
        src="https://assets.adobedtm.com/7d5ea80d054b96730162d0905d59678c4d2bf30c/satelliteLib-056effb979f86654615e68101b9d6a79e53928b4.js"
        type="text/javascript"
      />
    )}
  </>
);

export default titan;
