import React, { useEffect } from 'react';
import { Global } from '@emotion/react';
import { MediaQueryProvider } from 'react-media-query-hoc';
import { SearchAutocomplete } from '..';
import { UltimateGuide, CallToActions } from './components';
import * as styles from './landing-page.style';
import { trackLandingPage } from '../../tracking';
import { getPublicUrl } from '../../utils';

interface LandingPageProps {
  skipLinkContentId: string;
  mixpanelHasInitialized?: boolean;
}

const FindAnAgent = ({
  skipLinkContentId,
  mixpanelHasInitialized = false,
}: LandingPageProps): JSX.Element => {
  useEffect(() => {
    if (mixpanelHasInitialized) {
      trackLandingPage.trackViewLandingPage();
    }
  }, [mixpanelHasInitialized]);

  const bannerImage = getPublicUrl('/background.png');

  return (
    <MediaQueryProvider values={{ width: 300, type: 'screen' }}>
      <div css={styles.container}>
        <Global styles={styles.globalStyle} />

        <div css={styles.bannerContainer(bannerImage, bannerImage)}>
          <div css={styles.landing}>
            <div css={styles.wrapper}>
              <div css={styles.content} id={skipLinkContentId}>
                <h1 css={styles.title}>
                  <span>Find your local</span>
                  <span> Real Estate Agent</span>
                </h1>
                <p css={styles.subtitle}>Choose from over 45,000 nationwide</p>
                <div css={styles.search}>
                  <SearchAutocomplete recentSearches icons />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div css={styles.page}>
          <div css={styles.wrapper}>
            <CallToActions />
          </div>
          <div css={styles.wrapper}>
            <UltimateGuide />
          </div>
        </div>
      </div>
    </MediaQueryProvider>
  );
};

export default FindAnAgent;
