export default (
  currentItem: number | null,
  setItem: (n: number) => void,
  maxItems: number,
): [() => void, () => void] => {
  const nextItem = () => {
    const nextItemIndex = currentItem === null ? 0 : currentItem + 1;

    if (nextItemIndex <= maxItems - 1) {
      setItem(nextItemIndex);
    } else {
      setItem(0);
    }
  };

  const prevItem = () => {
    const prevItemIndex = currentItem === null ? maxItems : currentItem - 1;

    if (prevItemIndex >= 0) {
      setItem(prevItemIndex);
    } else {
      setItem(maxItems - 1);
    }
  };

  return [nextItem, prevItem];
};
