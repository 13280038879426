import React from 'react';
import * as styles from './option.style';

export interface HighlightInputProps {
  optionText: string;
  highlightText: string;
}

const HighlightInput = ({
  optionText,
  highlightText,
}: HighlightInputProps): JSX.Element => {
  const regexString = highlightText.replace(/[.*+?^${}()|[\]\\]/g, '');

  const parts = highlightText
    ? optionText.split(new RegExp(`(${regexString})`, 'i'))
    : [optionText];

  return (
    <>
      {parts
        .filter((part) => part)
        .map((part, index) => {
          const match = part.toLowerCase() === regexString.toLowerCase();

          return (
            <span
              key={`${part.toLowerCase()}-${index}`} // eslint-disable-line react/no-array-index-key
              data-testid={match ? 'typeahead__option-item-highlight-text' : ''}
              css={styles.optionItemHighlightText(match)}
            >
              {part}
            </span>
          );
        })}
    </>
  );
};

export default HighlightInput;
