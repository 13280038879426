import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import * as styles from './statistics.style';

export type Props = {
  isVertical: boolean;
  totalForSale?: number | null;
  totalSoldAndAuctioned?: number | null;
  totalJointSoldAndAuctioned?: number | null;
  totalForRent?: number | null;
  totalLeased?: number | null;
  totalJointLeased?: number | null;
};

const ShortStatistics = ({
  isVertical = false,
  totalForSale = null,
  totalSoldAndAuctioned = null,
  totalJointSoldAndAuctioned = null,
  totalForRent = null,
  totalLeased = null,
  totalJointLeased = null,
}: Props): React.ReactElement => (
  <div css={styles.wrapper(isVertical)}>
    <div>
      <h4 css={srOnly}>Agent statistics</h4>
      <dl css={styles.statistics(isVertical)}>
        {totalForSale ? (
          <div css={styles.statistic}>
            <dt>For sale</dt>
            <dd>{totalForSale}</dd>
          </div>
        ) : null}
        {totalSoldAndAuctioned ? (
          <div css={styles.statistic}>
            <dt>Sold</dt>
            <dd>{totalSoldAndAuctioned}</dd>
          </div>
        ) : null}
        {totalForRent ? (
          <div css={styles.statistic}>
            <dt>For rent</dt>
            <dd>{totalForRent}</dd>
          </div>
        ) : null}
        {totalLeased ? (
          <div css={styles.statistic}>
            <dt>Leased</dt>
            <dd>{totalLeased}</dd>
          </div>
        ) : null}
      </dl>
      {(totalSoldAndAuctioned && totalJointSoldAndAuctioned) ||
      (totalLeased && totalJointLeased) ? (
        <div css={styles.jointStat}>
          <p>
            * Plus{' '}
            {totalSoldAndAuctioned && totalJointSoldAndAuctioned
              ? `${totalJointSoldAndAuctioned} sales`
              : ''}
            {totalSoldAndAuctioned &&
            totalJointSoldAndAuctioned &&
            totalLeased &&
            totalJointLeased
              ? ', and '
              : ''}
            {totalLeased && totalJointLeased
              ? `${totalJointLeased} properties leased`
              : ''}{' '}
            as joint agent
          </p>
        </div>
      ) : null}

      {!totalForSale &&
      !totalSoldAndAuctioned &&
      !totalJointSoldAndAuctioned &&
      !totalForRent &&
      !totalLeased &&
      !totalJointLeased ? (
        <div css={styles.unavailable}>
          <p>* Sales statistics unavailable</p>
        </div>
      ) : null}
    </div>
  </div>
);

export default ShortStatistics;
