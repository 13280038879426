import { css, SerializedStyles } from '@emotion/react';
import { media, rgba, tokens, globalTokens } from '@domain-group/fe-brary';
import { stickyBarHeight } from '../sticky-bar/sticky-bar.style';

const {
  colors: { neutral },
  spacing,
} = tokens;

export const banner = ({
  backgroundImage = 'https://images.domain.com.au/img/Agencys/heroes/Hero+01.jpg',
  borderColour = 'transparent',
}: {
  backgroundImage?: string;
  borderColour?: string;
}): SerializedStyles => css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${neutral.heavy.default};
  position: relative;
  border-bottom: 4px solid ${borderColour};
  background-image: url(${`"${backgroundImage}"`});

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${rgba(globalTokens.colors.coolGrey700, 0.825)} 0%,
      ${rgba(globalTokens.colors.coolGrey700, 0.675)} 100%
    );
  }
`;

export const bannerContainer = css`
  position: relative;
`;

export const bannerContents = (isPlatinum: boolean): SerializedStyles => css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px; /* Required for IE11; any number works, as min-height will take precedence anyway. */
  min-height: 360px;
  padding-left: ${spacing.m};
  padding-right: ${spacing.m};

  ${media('>=tablet')} {
    height: 420px; /* Required for IE11; any number works, as min-height will take precedence anyway. */
    min-height: 420px;
  }

  ${isPlatinum &&
  css`
    height: 400px; /* Required for IE11; any number works, as min-height will take precedence anyway. */
    min-height: 400px;

    ${media('>=tablet')} {
      padding-bottom: ${stickyBarHeight}px;
      height: ${480 +
      stickyBarHeight}px; /* Required for IE11; any number works, as min-height will take precedence anyway. */
      min-height: ${480 + stickyBarHeight}px;
    }

    ${media('>=desktop')} {
      height: 600px; /* Required for IE11; any number works, as min-height will take precedence anyway. */
      min-height: 600px;
    }
  `};
`;
