import { css } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

// eslint-disable-next-line import/prefer-default-export
export const section = ({
  hideTablet = false,
  forceBorder = false,
  isShallow = false,
  isNarrow = false,
  bottomBorder = true,
}) => css`
  position: relative;
  /* 1040px */
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 ${tokens.spacing.m};
  padding-top: ${tokens.spacing.m};
  padding-bottom: ${tokens.spacing.m};

  ${media('>=desktop')} {
    padding-top: ${tokens.spacing.l};
    padding-bottom: ${tokens.spacing.l};
  }

  &:empty {
    display: none;
  }

  ${isShallow &&
  css`
    ${media('>=desktop')} {
      padding-top: ${tokens.spacing.m};
      padding-bottom: ${tokens.spacing.m};
    }
  `};

  ${hideTablet &&
  css`
    ${media('>=tablet')} {
      display: none;
    }
  `};

  ${isNarrow &&
  css`
    min-width: 290px;
  `};

  ${bottomBorder &&
  css`
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: ${tokens.spacing.m};
      width: calc(100% - ${tokens.spacing.m} - ${tokens.spacing.m});
      height: 1px;
      background-color: ${tokens.colors.neutral.trim.default};
    }
  `}

  ${!forceBorder &&
  css`
    &:last-of-type > div {
      border-bottom: none;
    }
  `};

  > * + * {
    margin-top: ${tokens.spacing.m};
  }
`;
