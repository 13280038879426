import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { toGlobalId } from '../../utils';
import { AgencyTopSummaryFragment } from '../../generated/graphql';
import { Summary } from './components';

export type Props = {
  agencyId: number;
};

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyTopSummary';
const FRAGMENT = gql`
  fragment AgencyTopSummary on Agency {
    id
    statistics {
      id
      forSale
      soldTotal
      forRent
      leased
    }
  }
`;

const AgencyTopSummaryWithFragment = ({
  agencyId,
}: Props): JSX.Element | null => {
  const id = toGlobalId(TYPE_NAME, agencyId);

  const { data: agency } = useFragment<AgencyTopSummaryFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyTopSummaryWithFragment.fragment,
    fragmentName: AgencyTopSummaryWithFragment.fragmentName,
  });

  const { statistics } = agency || {};
  const { forSale, soldTotal, forRent, leased } = statistics || {};

  // return if there are no stats
  if (!forSale && !soldTotal && !forRent && !leased) {
    return null;
  }

  return (
    <div data-testid="top-summary">
      <Summary
        totalForSale={forSale}
        totalSoldAndAuctioned={soldTotal}
        totalForRent={forRent}
        totalLeased={leased}
      />
    </div>
  );
};

AgencyTopSummaryWithFragment.fragment = FRAGMENT;
AgencyTopSummaryWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyTopSummaryWithFragment;
