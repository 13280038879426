export default <T>(list: Array<T>, key: keyof T): Array<T> => {
  const seen = new Set();

  return list.filter((entry) => {
    const val = entry[key];
    if (seen.has(val)) {
      return false;
    }
    seen.add(val);
    return true;
  });
};
