import React from 'react';
import { gql, useFragment } from '@apollo/client';

import { ContactListingsStoryFragment } from '../../generated/graphql';

import ListingsStory from './listings-story/listings-story';
import type { ListingsTotals } from './listings-story/listings-story';

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactListingsStory';
const FRAGMENT = gql`
  fragment ContactListingsStory on Contact {
    id
    agentIdV2
    agencyId
    name
    agencyName
    hideOfficeLocation
    agencyByAgencyId {
      id
      agencyId
      address {
        displayAddress
      }
    }
    statistics {
      id
      totalForSale
      totalForRent
      totalLeased
      totalAuctioned
      totalSold
    }
  }
`;

type ContactListingsStoryWithFragmentProps = {
  agentIdV2: string;
  agencyId: number;
};

const ContactListingsStoryWithFragment = ({
  agentIdV2,
  agencyId,
}: ContactListingsStoryWithFragmentProps): JSX.Element | null => {
  const { complete, data } = useFragment<ContactListingsStoryFragment>({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactListingsStoryWithFragment.fragment,
    fragmentName: ContactListingsStoryWithFragment.fragmentName,
  });

  const contactName = data?.name;
  const agencyName = data?.agencyName;

  if (!complete || !contactName || !agencyName) {
    // Cant write a story about an agent with no name
    return null;
  }

  const contactAddress = data?.agencyByAgencyId?.address?.displayAddress;
  const hideOfficeLocation = data?.hideOfficeLocation;

  const statistics = data?.statistics;
  const { totalForSale, totalSold, totalForRent, totalLeased, totalAuctioned } =
    statistics || {};

  // No stats
  if (
    !totalForSale &&
    !totalSold &&
    !totalForRent &&
    !totalLeased &&
    !totalAuctioned
  ) {
    return null;
  }

  const listingsTotal: ListingsTotals = {
    auction: totalAuctioned,
    lease: totalForRent,
    leased: totalLeased,
    sale: totalForSale,
    sold: totalSold,
  };

  return (
    <div data-testid="listings-story">
      <ListingsStory
        contactName={contactName}
        agencyName={agencyName}
        agencyAddress={contactAddress}
        hideOfficeLocation={hideOfficeLocation}
        listingsTotal={listingsTotal}
      />
    </div>
  );
};

ContactListingsStoryWithFragment.fragment = FRAGMENT;
ContactListingsStoryWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactListingsStoryWithFragment;
