import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { ContactTopSummaryFragment } from '../../generated/graphql';
import Summary from './components/summary/summary';

export type Props = {
  agentIdV2: string;
  agencyId: number;
};

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactTopSummary';
const FRAGMENT = gql`
  fragment ContactTopSummary on Contact {
    id
    agentIdV2
    agencyId
    statistics {
      id
      totalForSale
      totalSoldAndAuctioned
      totalJointSoldAndAuctioned
      totalForRent
      totalLeased
      totalJointLeased
    }
  }
`;

const ContactTopSummaryWithFragment = ({
  agentIdV2,
  agencyId,
}: Props): JSX.Element | null => {
  const { complete, data: contact } = useFragment<
    ContactTopSummaryFragment,
    unknown
  >({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactTopSummaryWithFragment.fragment,
    fragmentName: ContactTopSummaryWithFragment.fragmentName,
  });

  const { statistics } = contact || {};
  const {
    totalForSale,
    totalSoldAndAuctioned,
    totalJointSoldAndAuctioned,
    totalForRent,
    totalLeased,
    totalJointLeased,
  } = statistics || {};

  // return if there are no stats
  if (
    !complete ||
    (!totalForSale &&
      !totalSoldAndAuctioned &&
      !totalJointSoldAndAuctioned &&
      !totalForRent &&
      !totalLeased &&
      !totalJointLeased)
  ) {
    return null;
  }

  return (
    <div data-testid="top-summary">
      <Summary
        totalForSale={totalForSale}
        totalSoldAndAuctioned={totalSoldAndAuctioned}
        totalJointSoldAndAuctioned={totalJointSoldAndAuctioned}
        totalForRent={totalForRent}
        totalLeased={totalLeased}
        totalJointLeased={totalJointLeased}
      />
    </div>
  );
};

ContactTopSummaryWithFragment.fragment = FRAGMENT;
ContactTopSummaryWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactTopSummaryWithFragment;
