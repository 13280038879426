import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { EnquiryForm } from '../../../shared';
import { Details } from '../../../shared/enquiry-form/form';

export type AgencyEnquiryFormProps = {
  agencyId?: number;
  agencyName?: string;
  enquiryType?: 'agencyProfile';
  details?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    postcode?: string;
    phone?: string;
    intent?: string;
    message?: string;
  };
  onInView?: () => void;
  onSubmit?: (submitValues: Details) => void;
  onSubmitSuccess?: (submitValues: Details) => void;
  onSubmitError?: (submitValues: Details) => void;
};

const AgencyEnquiryForm = ({
  agencyId,
  agencyName,
  enquiryType = 'agencyProfile',
  details,
  onInView = () => null,
  onSubmit = () => null,
  onSubmitSuccess = () => null,
  onSubmitError = () => null,
}: AgencyEnquiryFormProps): JSX.Element => {
  const [toggleSeen, setToggleSeen] = useState(false);

  const { ref, inView } = useInView({
    // This means it will fire when 75% of the component is in view
    threshold: 0.75,
  });

  useEffect(() => {
    if (inView && !toggleSeen) {
      onInView();
      setToggleSeen(true);
    }
  }, [inView, toggleSeen, onInView]);

  return (
    <div ref={ref} data-testid="agency-enquiry-form">
      <EnquiryForm
        referenceId={agencyId}
        name={agencyName}
        enquiryType={enquiryType}
        details={details}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitError={onSubmitError}
      />
    </div>
  );
};

export default AgencyEnquiryForm;
