import React from 'react';
import getConfig from 'next/config';
import FePaErrorPage from '@domain-group/fe-pa-error-page';

export type Props = {
  messageTitle?: string;
  messageContent?: string;
};

const { publicRuntimeConfig } = getConfig();

const ErrorPage = ({
  messageTitle = 'Oops...',
  messageContent = 'Sorry, something has gone wrong.',
}: Props): JSX.Element => (
  <FePaErrorPage
    header={{
      baseUrl: publicRuntimeConfig?.HEADER_BASE_URL,
      loginUrl: `${publicRuntimeConfig?.HEADER_BASE_URL}${publicRuntimeConfig?.LOG_IN_PATH}`,
      logoutUrl: `${publicRuntimeConfig?.HEADER_BASE_URL}${publicRuntimeConfig?.LOG_OUT_PATH}`,
      signupUrl: `${publicRuntimeConfig?.HEADER_BASE_URL}${publicRuntimeConfig?.SIGN_UP_PATH}`,
    }}
    footer={{ baseUrl: publicRuntimeConfig?.BASE_URL }}
    messageTitle={messageTitle}
    messageContent={messageContent}
  />
);

export default ErrorPage;
