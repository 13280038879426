import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { handleError } from '@domain-group/fe-helper';

import AgencyAboutSectionWithFragment from './agency-about-section-with-fragment';

export const GET_AGENCY_QUERY = gql`
  query agencyAboutSection($agencyId: Int) {
    agency(agencyId: $agencyId) {
      ...AgencyAboutSection
    }
  }
  ${AgencyAboutSectionWithFragment.fragment}
`;

const AgencyAboutSectionWithQuery = ({
  agencyId,
}: {
  agencyId: number;
}): JSX.Element | null => {
  const { data, error } = useQuery(GET_AGENCY_QUERY, {
    variables: { agencyId },
  });

  if (error) {
    handleError(error);
  }

  if (!data?.agency?.name) {
    return null;
  }

  return <AgencyAboutSectionWithFragment agencyId={agencyId} />;
};

export default AgencyAboutSectionWithQuery;
