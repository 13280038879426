import { Testimonial } from '../components/testimonials';
import sanitiseTestimonial from './sanitise-testimonial';
import notEmpty from '../../../utils/array-filter-not-empty';

// Remove empty entries, and sanitise testimonial content
const formatTestimonials = (
  testimonials: (Testimonial | null)[],
): Testimonial[] =>
  testimonials.filter(notEmpty).map((testimonial) => {
    const testimonialSanitised = sanitiseTestimonial(testimonial.testimonial);

    return {
      ...testimonial,
      testimonial: testimonialSanitised,
    };
  });

export default formatTestimonials;
