/* eslint-disable import/prefer-default-export */
import { css, SerializedStyles } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

export const listingsCardsContainer = (
  mobilePadding?: string | number,
): SerializedStyles => {
  const negativeMargin = mobilePadding || tokens.spacing.s;

  return css`
    display: flex;
    flex-flow: nowrap;
    overflow: auto;
    /* Use negative margin to stretch container to edge of screen */
    margin: 0 calc(-1 * ${negativeMargin}) ${tokens.spacing.s}
      calc(-1 * ${negativeMargin});
    padding: 0 ${negativeMargin};

    ${media('>=tablet')} {
      flex-flow: row wrap;
      margin: 0 calc(-0.5 * ${tokens.spacing.m});
      padding: 0;
    }

    /* TODO: Fix this bug in fe-co-listing-result */
    .listing-result__shortlist-listing .listing-result__property-details {
      height: auto;
    }
  `;
};

export const loadingContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const errorMessage = css`
  background-color: ${tokens.colors.critical.background.default};
  color: ${tokens.colors.critical.base.default};
  margin: 0 auto ${tokens.spacing.m};
  padding-bottom: ${tokens.spacing.xxs};
`;
