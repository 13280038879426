import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { AgencyEnquiryForm } from './components';
import { Details } from '../shared/enquiry-form/form';
import {
  AgencyEnquiryFormSectionFragment,
  AgencyEnquiryFormUserDetailsPrefillFragment,
} from '../../generated/graphql';

import { toGlobalId } from '../../utils';

export type AgencyEnquiryFormWithFragmentProps = {
  agencyId: number;
  userFragmentId?: string;
  onInView?: () => void;
  onSubmit?: (submitValues: Details) => void;
  onSubmitSuccess?: (submitValues: Details) => void;
  onSubmitError?: (submitValues: Details) => void;
};

const fragmentName = 'AgencyEnquiryFormSection';
export const AGENCY_ENQUIRY_FORM_SECTION_FRAGMENT = gql`
  fragment AgencyEnquiryFormSection on Agency {
    __typename
    id
    agencyId
    name
    contactDetails {
      general {
        email
      }
    }
  }
`;

const prefillFragmentName = 'AgencyEnquiryFormUserDetailsPrefill';
export const AGENCY_ENQUIRY_FORM_PREFILL_FRAGMENT = gql`
  fragment AgencyEnquiryFormUserDetailsPrefill on User {
    __typename
    id
    firstName
    lastName
    email
  }
`;

const AgencyEnquiryFormWithFragment = ({
  agencyId,
  userFragmentId,
  onInView = () => null,
  onSubmit = () => null,
  onSubmitSuccess = () => null,
  onSubmitError = () => null,
}: AgencyEnquiryFormWithFragmentProps): JSX.Element | null => {
  // Agency data
  const agencyFragmentId = toGlobalId('Agency', agencyId);
  const { data } = useFragment<AgencyEnquiryFormSectionFragment, unknown>({
    from: {
      __typename: 'Agency',
      id: agencyFragmentId,
    },
    fragment: AGENCY_ENQUIRY_FORM_SECTION_FRAGMENT,
    fragmentName,
  });

  // Prefill user data (optional)
  const { data: userData } = useFragment<
    AgencyEnquiryFormUserDetailsPrefillFragment,
    unknown
  >({
    from: {
      __typename: 'User',
      id: userFragmentId,
    },
    fragment: AGENCY_ENQUIRY_FORM_PREFILL_FRAGMENT,
    fragmentName: prefillFragmentName,
  });

  const details = {
    firstName: userData?.firstName ?? undefined,
    lastName: userData?.lastName ?? undefined,
    email: userData?.email ?? undefined,
  };

  if (!data?.agencyId || !data?.name || !data?.contactDetails?.general?.email) {
    return null;
  }

  return (
    <AgencyEnquiryForm
      agencyId={data?.agencyId}
      agencyName={data?.name}
      details={details}
      onInView={onInView}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitError={onSubmitError}
    />
  );
};

AgencyEnquiryFormWithFragment.fragment = AGENCY_ENQUIRY_FORM_SECTION_FRAGMENT;
AgencyEnquiryFormWithFragment.fragmentName = fragmentName;

AgencyEnquiryFormWithFragment.prefillFragment =
  AGENCY_ENQUIRY_FORM_PREFILL_FRAGMENT;
AgencyEnquiryFormWithFragment.prefillFragmentName = prefillFragmentName;

export default AgencyEnquiryFormWithFragment;
