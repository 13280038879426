import { createDigitalData } from '.';
import { DigitalData, UserDetailsRedacted } from '../../../@types';

export type generateDigitalDataLandingPageProps = {
  sysEnv: string;
  source: string;
  isEmbeddedApp: boolean;
  user?: UserDetailsRedacted;
};

const generateDigitalDataLandingPage = ({
  sysEnv,
  source,
  isEmbeddedApp,
  user,
}: generateDigitalDataLandingPageProps): DigitalData =>
  createDigitalData({
    pageInfo: {
      pageName: 'Agency Search Page',
      pageTemplate: 'agencyLanding',
      sysEnv,
      source,
      isEmbeddedApp,
      property: {}, // No associated property
    },
    category: {
      pageType: 'Search Page',
      primaryCategory: 'Find an Agent',
      subCategory1: 'Agency Search Page',
    },
    user,
  });

export default generateDigitalDataLandingPage;
