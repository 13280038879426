import React, { useContext } from 'react';
import Head from 'next/head';
import { ServerInfoContext } from '../../contexts';

import {
  DigitalDataInit,
  DigitalDataTag,
  GoogleSchema,
  MetaTags,
} from './components';
import {
  generateDigitalDataLandingPage,
  createBreadcrumbsJSONBlob,
} from './utils';
import { ServerInfo, UserDetailsRedacted } from '../../@types';
import { Breadcrumb } from '../header/utils';

const title =
  'Search for real estate agents to sell your property in Australia';
const description =
  'Find the right real estate agent across Australia to sell or manage your property. Search for a real estate agent by State or Agent name in your area.';

const LandingPageHead = ({
  breadcrumbsData,
  user,
}: {
  breadcrumbsData: Breadcrumb[];
  user?: UserDetailsRedacted;
}): JSX.Element => {
  const {
    sysEnv,
    baseUrl,
    path,
    source,
    isEmbeddedApp,
    trackingDisabled,
    noIndexFollow,
  } = useContext<ServerInfo>(ServerInfoContext);

  const canonical = `${baseUrl}${path}`.toLowerCase();

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={canonical} />
      </Head>
      <MetaTags noIndexFollow={!!noIndexFollow} description={description} />
      <GoogleSchema
        breadcrumbsJSON={createBreadcrumbsJSONBlob(breadcrumbsData)}
      />

      <DigitalDataTag
        digitalData={generateDigitalDataLandingPage({
          sysEnv,
          source,
          isEmbeddedApp,
          user,
        })}
      />
      <DigitalDataInit trackingDisabled={trackingDisabled} />
    </>
  );
};

export default LandingPageHead;
