import React from 'react';
import { Maybe } from '../../../generated/graphql';

import SaleRentPhrase, { showSaleRent } from './sale-rent-phrase';
import SoldLeasedPhrase, { showSoldLeased } from './sold-leased-phrase';
import * as styles from './story.style';

export type ListingsTotals = {
  sold?: Maybe<number>;
  auction?: Maybe<number>;
  leased?: Maybe<number>;
  sale?: Maybe<number>;
  lease?: Maybe<number>;
};

export type ListingsStoryProps = {
  contactName?: string;
  agencyName: string;
  agencyAddress?: Maybe<string>;
  hideOfficeLocation?: Maybe<boolean>;
  listingsTotal: ListingsTotals;
};

const ListingsStory = ({
  contactName,
  agencyName,
  agencyAddress,
  hideOfficeLocation = false,
  listingsTotal,
}: ListingsStoryProps): JSX.Element | null => {
  if (!agencyName) {
    return null;
  }

  const isAgentListingsStory = !!contactName;
  const highlight = !isAgentListingsStory;

  const soldTotal = (listingsTotal.sold || 0) + (listingsTotal.auction || 0);
  const leasedTotal = listingsTotal.leased || 0;
  const soldLeased = showSoldLeased(soldTotal, leasedTotal);

  const saleTotal = listingsTotal.sale || 0;
  const rentTotal = listingsTotal.lease || 0;
  const saleRent = showSaleRent(saleTotal, rentTotal);

  let soldLeasedPhrase;
  let soldRentPhrase;

  const locationText =
    (agencyAddress && !hideOfficeLocation && ` located at ${agencyAddress}`) ||
    '';

  const introPhrase = isAgentListingsStory
    ? `${contactName} is part of the team at ${agencyName}${locationText}`
    : `The team at ${agencyName}${locationText}`;

  const HighlightWrap = highlight ? 'strong' : 'span';

  if (soldLeased) {
    const joiner = isAgentListingsStory ? `, and has ` : ` has `;
    soldLeasedPhrase = (
      <>
        {joiner}
        <SoldLeasedPhrase
          soldTotal={soldTotal}
          leasedTotal={leasedTotal}
          highlight={highlight}
        />{' '}
        in the last <HighlightWrap>12 months</HighlightWrap>
      </>
    );
  }

  if (saleRent) {
    const conjunction =
      isAgentListingsStory || (soldLeased && saleRent) ? ', and' : '';
    soldRentPhrase = (
      <>
        {conjunction} currently has{' '}
        <SaleRentPhrase
          forSale={saleTotal}
          forRent={rentTotal}
          highlight={highlight}
        />
      </>
    );
  }

  return (
    <>
      <h2 css={styles.title} data-testid="listings-tabs__title">
        Current Listings
      </h2>
      <p css={styles.story}>
        {introPhrase}
        {soldLeasedPhrase}
        {soldRentPhrase}.
      </p>
    </>
  );
};

export default ListingsStory;
