import React from 'react';
import { gql, useFragment } from '@apollo/client';
import getConfig from 'next/config';
import Header from '@domain-group/fe-co-header';

import { toGlobalId } from '../../utils';
import { mapAgencyBreadcrumbs, mapHeaderData } from './utils';
import { AgencyHeaderFragment } from '../../generated/graphql';

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyHeader';
const FRAGMENT = gql`
  fragment AgencyHeader on Agency {
    id
    agencyId
    profileTier
    profileUrl
    name
    address {
      displayAddress
      state
      suburb {
        id
        suburbId
        name
        slug
      }
      postcode
    }
  }
`;

const AgencyProfileHeaderWithFragment = ({
  agencyId,
}: {
  agencyId: number;
}): JSX.Element => {
  const id = toGlobalId(TYPE_NAME, `${agencyId}`);

  const { data: agency } = useFragment<AgencyHeaderFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyProfileHeaderWithFragment.fragment,
    fragmentName: AgencyProfileHeaderWithFragment.fragmentName,
  });

  const { publicRuntimeConfig } = getConfig();
  const baseUrl = publicRuntimeConfig.BASE_URL;

  const headerData = mapHeaderData({
    baseUrl,
    breadcrumbsData: mapAgencyBreadcrumbs({
      baseUrl,
      profileUrl: agency?.profileUrl ?? undefined,
      agencyName: agency?.name ?? undefined,
      suburbName: agency?.address?.suburb?.name,
      suburbSlug: agency?.address?.suburb?.slug ?? undefined,
    }),
  });

  return (
    <div data-testid="header">
      <Header
        {...headerData}
        appearance={
          agency?.profileTier === 'platinum' ? 'transparent' : undefined
        }
      />
    </div>
  );
};

AgencyProfileHeaderWithFragment.fragment = FRAGMENT;
AgencyProfileHeaderWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyProfileHeaderWithFragment;
