import React from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  LinkIcon,
  TwitterIcon,
  VideoIcon,
  YoutubeIcon,
} from '@domain-group/fe-co-icon';

export type IconName =
  | 'link'
  | 'video'
  | 'facebook'
  | 'instagram'
  | 'youtube'
  | 'twitter'
  | 'linkedin';

const SocialIcon = ({
  iconName,
  size,
}: {
  iconName: IconName;
  size: 'regular' | 'small';
}): JSX.Element | null => {
  switch (iconName) {
    case 'link':
      return <LinkIcon name={iconName} size={size} />;
    case 'video':
      return <VideoIcon name={iconName} size={size} />;
    case 'facebook':
      return <FacebookIcon name={iconName} size={size} />;
    case 'instagram':
      return <InstagramIcon name={iconName} size={size} />;
    case 'youtube':
      return <YoutubeIcon name={iconName} size={size} />;
    case 'twitter':
      return <TwitterIcon name={iconName} size={size} />;
    case 'linkedin':
      return <LinkedinIcon name={iconName} size={size} />;
    default:
      return null;
  }
};

export default SocialIcon;
