import { sendGroupStats } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../mixpanel';
import { getPageDetails } from '../utils';

export default ({
  isAgency,
  id,
  userId,
  userToken,
  suburbSlugs,
  enquiryValues,
  enquiryCTA = 'Profile Card Email Button',
  enquiryFormType = 'modal',
}: {
  isAgency: boolean;
  id: string | number;
  userId?: string;
  userToken?: string;
  suburbSlugs?: string[];
  enquiryValues: {
    postcode: string;
    intent?: string | null;
    phone?: string | null;
  };
  enquiryCTA?: string;
  enquiryFormType?: string;
}): void => {
  const { pageName } = getPageDetails();

  sendToMixpanel('Email Enquiry', {
    enquiryLocation: pageName,
    enquiryIntentSelections: enquiryValues.intent || 'No Intent Provided',
    enquiryPhoneProvided: !!enquiryValues.phone,
    enquiryPostcode: enquiryValues.postcode,
    enquiryCTA,
    enquiryFormType,
  });

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/EmailEnquiry/2.0.7-schema.json
  sendGroupStats({
    // eventVersion: '2.0.7',
    platform: 'Website' as any,
    eventType: 'EmailEnquiry',
    eventCategory: 'lead.email',
    sourceEntityType: isAgency ? 'agency' : 'agent', // Inconsistent casing is required by the schema for this event/value, unfortunately
    sourceEntityId: id,
    eventSource: 'AgentSearch',
    eventSourceDetails: pageName,
    eventProvider: 'fe-ops-agency-server',
    userId,
    userToken,
    teamName: 'Seller',
    locationId: suburbSlugs?.join(',') ?? '',
  });
};
