import React, { ReactElement } from 'react';
import Head from 'next/head';

import canonicalSeo from './canonical-seo';
import digitalData from './digital-data';
import digitalDataInit from './digital-data-init';
import { googleSchema } from './google-schema';
import meta from './meta-tag';
import paginationSeo, { PaginationSeoParams } from './pagination-seo';
import titan from './titan';
import openGraph from './open-graph';
import { DigitalData } from '../../@types';

interface PageProps {
  children: ReactElement;
  title: string;
  description: string;
  breadcrumbsJSON?: string;
  canonical?: string;
  digitalData?: DigitalData;
  noIndexFollow?: boolean;
  titanEnabled?: boolean;
  trackingDisabled?: boolean;
  paginationSEOLinks?: PaginationSeoParams['paginationSEOLinks'];
  pageSchema?: string;
  doNotIndex?: boolean;
  metaTitle?: string;
  metaImage?: string;
  metaDescription?: string;
}

// This is a legacy component (still used by Error/404/Appraisal/Sell pages) for generating head tags
// We are keeping some unused logic in here as a back-up in case we find issues with
// the newer head components
const Page = ({
  children,
  breadcrumbsJSON,
  canonical,
  title,
  description,
  digitalData: dData,
  noIndexFollow,
  titanEnabled = false,
  trackingDisabled = true,
  pageSchema,
  paginationSEOLinks,
  doNotIndex,
  metaTitle,
  metaImage,
  metaDescription,
}: PageProps): JSX.Element => {
  const showGoogleSchema = !!breadcrumbsJSON && !!pageSchema;
  const showDigitalData = !!dData;

  return (
    <>
      <Head>
        {/* Static head elements can be found  in pages/_document.js */}
        <title>{title}</title>
        {canonicalSeo({ canonical, doNotIndex })}
        {meta({ description, noIndexFollow, key: title })}
        {showGoogleSchema
          ? googleSchema({ breadcrumbsJSON, pageSchema })
          : null}
        {paginationSeo({ paginationSEOLinks })}
        {openGraph({
          image: metaImage,
          title: metaTitle || title,
          description: metaDescription || description,
        })}
        {digitalData({ digitalData: dData, titanEnabled })}
        {showDigitalData ? digitalDataInit({ trackingDisabled }) : undefined}
        {titan({ titanEnabled })}
      </Head>
      {children}
    </>
  );
};

export default Page;
