/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Modal from '@domain-group/fe-co-modal';
import { Button, LinkButton } from '@domain-group/fe-co-button';
import {
  ChevronRightIconSmall,
  ChevronLeftIconSmall,
  ExternalLinkIcon,
} from '@domain-group/fe-co-icon';

import Pagination from '../pagination';
import uniqueByKey from '../../../utils/array-unique-by-key';
import RmaLogo from './rma-logo';

import type { ReviewListEntry } from '../../../@types/recommendations';

import useModalCarousel from './use-modal-carousel';
import Review from './review';
import * as reviewListStyles from './reviews-list.style';

const renderableReviews = (review: ReviewListEntry) =>
  !!review.title && !!review.description;

export type ReviewsListProps = {
  profileUrl: string;
  reviews: ReviewListEntry[];
  onPageRendered: () => void;
  onVendorRecoViewAll: () => void;
  onVendorRecoRendered: () => void;
  onVendorRecoImpression: () => void;
  onVendorRecoModalViewMore: () => void;
  onVendorRecoModalPrev: () => void;
  onVendorRecoModalNext: () => void;
};

const ReviewsList = ({
  reviews,
  profileUrl,
  onPageRendered,
  onVendorRecoViewAll,
  onVendorRecoRendered,
  onVendorRecoImpression,
  onVendorRecoModalViewMore,
  onVendorRecoModalPrev,
  onVendorRecoModalNext,
}: ReviewsListProps): JSX.Element => {
  const [modalReview, setModalReview] = useState<number | null>(null);
  const [nextItem, prevItem] = useModalCarousel(
    modalReview,
    setModalReview,
    reviews.length,
  );

  const [activeReview, setActiveReview] = useState(1);

  /*
  Tracking related functions
  */
  const [pageHasRendered, setPageHasRendered] = useState(false);
  const [userWasImpressed, setUserWasImpressed] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!pageHasRendered) {
      onVendorRecoRendered();
      setPageHasRendered(true);
    }

    if (inView && !userWasImpressed) {
      onVendorRecoImpression();
      setUserWasImpressed(true);
    }
  }, [
    inView,
    onVendorRecoImpression,
    onVendorRecoRendered,
    userWasImpressed,
    pageHasRendered,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onPageRendered, []);

  const uniqueReviews = uniqueByKey<ReviewListEntry>(
    reviews.filter(renderableReviews),
    'description',
  );

  /* istanbul ignore next */
  const updateDots = (visible: boolean, index: number) => {
    /* istanbul ignore next */
    if (visible) {
      /* istanbul ignore next */
      setActiveReview(index - 1);
    }
  };

  return (
    <>
      <header ref={ref} css={reviewListStyles.header}>
        <h2 css={reviewListStyles.heading}>Recommendations</h2>
        <LinkButton
          href={profileUrl}
          appearance="link"
          icon={<ExternalLinkIcon />}
          iconPosition="right"
          target="_blank"
          rel="noopener"
          onClick={onVendorRecoViewAll}
          onAuxClick={onVendorRecoViewAll}
        >
          View all
        </LinkButton>
      </header>
      <ul css={reviewListStyles.list}>
        {uniqueReviews.map((review, index) => (
          <li
            css={reviewListStyles.listItem}
            key={`${review.reviewerName}-${review.title}`}
          >
            <Review
              {...review}
              listView
              descLength={265}
              openModal={() => {
                setModalReview(index);
                onVendorRecoModalViewMore();
              }}
              onActivate={updateDots}
            />
          </li>
        ))}
      </ul>
      <div css={reviewListStyles.paginationContainer}>
        <Pagination dots={uniqueReviews.length} index={activeReview} />
      </div>

      <span css={reviewListStyles.promo}>
        Recommendations provided by <RmaLogo height={30} />
      </span>
      {modalReview !== null && (
        <Modal
          closeCallback={() => setModalReview(null)}
          hasCloseAnimation
          idealWidth={600}
        >
          <div css={reviewListStyles.modalReview}>
            <Review
              {...reviews[modalReview]}
              listView={false}
              showPropertyCard
            />
          </div>
          <div css={reviewListStyles.modalFooter}>
            <Button
              onClick={() => {
                prevItem();
                onVendorRecoModalPrev();
              }}
              appearance="link"
              icon={<ChevronLeftIconSmall />}
            >
              Previous recommendation
            </Button>
            <Button
              onClick={() => {
                nextItem();
                onVendorRecoModalNext();
              }}
              appearance="link"
              icon={<ChevronRightIconSmall />}
              iconPosition="right"
            >
              Next recommendation
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReviewsList;
