import React from 'react';
import ContentLoader from 'react-content-loader';

import * as styles from './appraisal-cta-loader.style';

const AppraisalCTALoader = (): JSX.Element => (
  <div css={styles.card} data-testid="appraisal-cta-loader">
    <ContentLoader css={styles.avatar} viewBox="0 0 100% 100%">
      <circle cx="50%" cy="50%" r="50%" />
    </ContentLoader>

    <ContentLoader css={styles.text} viewBox="0 0 100% 100%">
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
    </ContentLoader>
  </div>
);

export default AppraisalCTALoader;
