import React, { useContext } from 'react';
import Head from 'next/head';
import { gql, useQuery, useFragment } from '@apollo/client';

import { handleError } from '@domain-group/fe-helper';

import { toGlobalId } from '../../utils';
import { agencyProfilePageDescriptionGenerator } from './utils/description';

import {
  DigitalDataTag,
  DigitalDataInit,
  MetaTags,
  PaginationSeoLinks,
  GoogleSchema,
  createBreadcrumbsJSONBlob,
  agencyProfilesPageBreadcrumbs,
  makeAgencyHTMLSchema,
} from './components/head-snippets';

import type {
  AgencyDigitalDataFragment,
  AgencyByAgencyIdQuery,
} from '../../generated/graphql';

import { agencyPageTitleGenerator } from './utils/title';
import ServerInfoContext from '../../contexts/server-info';
import type { ServerInfo } from '../../contexts/server-info';
import { UserDetailsRedacted } from '../../@types';

type AgencyHeadWithQueryProps = {
  agencyId: number;
  user?: UserDetailsRedacted;
};

type AgencyHeadWithFragmentProps = {
  agencyId: number;
  user?: UserDetailsRedacted;
};

export const FRAGMENT_NAME = 'AgencyDigitalData';
export const FRAGMENT = gql`
  fragment AgencyDigitalData on Agency {
    agencyId
    name
    profileUrl
    branding {
      id
      logo {
        url
      }
      hero {
        url
      }
    }
    address {
      suburb {
        name
        postcode
        state
        suburbId
      }
      displayAddress
    }
    activeSuburbs {
      suburbName
    }
    profileTier
  }
`;

export const QUERY = gql`
  query digitalDataForAgency($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      id
      __typename
      ...AgencyDigitalData
    }
  }
  ${FRAGMENT}
`;

export const AgencyHeadWithFragment = ({
  agencyId,
  user,
}: AgencyHeadWithFragmentProps): JSX.Element | null => {
  const id = toGlobalId('Agency', agencyId);
  const { complete, data } = useFragment<AgencyDigitalDataFragment, unknown>({
    from: {
      __typename: 'Agency',
      id,
    },
    fragment: FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });
  const serverInfo = useContext(ServerInfoContext);

  if (complete && data?.name) {
    const {
      sysEnv,
      baseUrl,
      path,
      source,
      isEmbeddedApp,
      trackingDisabled,
      noIndexFollow,
      seoPaginationNext,
      seoPaginationPrev,
    } = serverInfo as ServerInfo;

    const title = agencyPageTitleGenerator({
      agencyName: data.name,
      suburb: data.address?.suburb?.name ?? '',
      state: data.address?.suburb?.state ?? '',
      postCode: data.address?.suburb?.postcode ?? '',
    });

    const breadcrumbs = agencyProfilesPageBreadcrumbs({
      originalUrl: path,
      baseUrl,
      data,
    });

    const agentSchema = makeAgencyHTMLSchema({
      originalUrl: path,
      baseUrl,
      data,
    });

    const canonical = `${baseUrl}${path}`.toLowerCase();
    const description = agencyProfilePageDescriptionGenerator({
      agencyName: data.name,
      state: data.address?.suburb?.state ?? '',
      suburb: data.address?.suburb?.name ?? '',
      postCode: data.address?.suburb?.postcode ?? '',
    });

    return (
      <>
        <Head>
          <title>{title}</title>
          <link rel="canonical" href={canonical} />
        </Head>
        <DigitalDataTag
          agencyData={data}
          isEmbeddedApp={isEmbeddedApp}
          sysEnv={sysEnv}
          source={source}
          user={user}
        />
        <DigitalDataInit trackingDisabled={trackingDisabled} />
        <MetaTags noIndexFollow={!!noIndexFollow} description={description} />
        <PaginationSeoLinks
          seoPaginationNext={seoPaginationNext || ''}
          seoPaginationPrev={seoPaginationPrev || ''}
        />
        <GoogleSchema
          breadcrumbsJSON={createBreadcrumbsJSONBlob(breadcrumbs)}
          pageSchema={agentSchema}
        />
      </>
    );
  }
  return null;
};

AgencyHeadWithFragment.fragment = FRAGMENT;
AgencyHeadWithFragment.fragmentName = FRAGMENT_NAME;

export const AgencyHeadWithQuery = ({
  agencyId,
  user,
}: AgencyHeadWithQueryProps): JSX.Element | null => {
  const { data, error } = useQuery<AgencyByAgencyIdQuery>(QUERY, {
    variables: { agencyId },
  });

  // Upstream API _sometimes_ returns null for suburbId - however, we can still render fine without it
  if (error) {
    handleError(error);
  }

  if (data) {
    return <AgencyHeadWithFragment agencyId={agencyId} user={user} />;
  }

  return null;
};

AgencyHeadWithQuery.query = QUERY;
