import React from 'react';
import { gql, useQuery, useFragment } from '@apollo/client';

import { handleError } from '@domain-group/fe-helper';

import { toGlobalId } from '../../utils';
import mapReviews from '../../utils/map-recommendation-reviews';
import ReviewList from '../shared/recommendations/reviews-list';

import type {
  AgencyRecommendationsFragment,
  AgencyByAgencyIdQuery,
} from '../../generated/graphql';
import type { TrackingCallbacks } from '../../@types/recommendations';

type AgencyRecommendationsWithQueryProps = TrackingCallbacks & {
  agencyId: number;
};

type AgencyRecommendationsWithFragmentProps = TrackingCallbacks & {
  agencyId: number;
};

export const FRAGMENT_NAME = 'AgencyRecommendations';
export const FRAGMENT = gql`
  fragment AgencyRecommendations on Agency {
    id
    agencyId
    recommendations {
      subscribed
      showRMAReviews
      agencyProfileUrl
      lastUpdated
      reviews {
        title
        description
        reviewedOn
        reviewerName
        reviewType
        starRating
        isRecommended
        agentReviewTrackingPixelUrl
        agencyReviewTrackingPixelUrl
        reviewUrl
        propertyType
        bedrooms
        bathrooms
        carparks
        streetAddress
        suburb
        state
        postCode
      }
    }
  }
`;

export const AgencyRecommendationsWithFragment = ({
  agencyId,
  onPageRendered,
  onVendorRecoViewAll,
  onVendorRecoRendered,
  onVendorRecoImpression,
  onVendorRecoModalViewMore,
  onVendorRecoModalPrev,
  onVendorRecoModalNext,
}: AgencyRecommendationsWithFragmentProps): JSX.Element | null => {
  const id = toGlobalId('Agency', agencyId);
  const { complete, data } = useFragment<
    AgencyRecommendationsFragment,
    unknown
  >({
    from: {
      __typename: 'Agency',
      id,
    },
    fragment: FRAGMENT,
    fragmentName: FRAGMENT_NAME,
  });

  if (complete && data?.recommendations?.reviews?.length) {
    const reviews = mapReviews(data.recommendations.reviews);

    return (
      <ReviewList
        profileUrl={data.recommendations.agencyProfileUrl || ''}
        reviews={reviews}
        onPageRendered={() => {
          onPageRendered(data);
        }}
        onVendorRecoViewAll={() => {
          onVendorRecoViewAll(data);
        }}
        onVendorRecoRendered={() => {
          onVendorRecoRendered(data);
        }}
        onVendorRecoImpression={() => {
          onVendorRecoImpression(data);
        }}
        onVendorRecoModalViewMore={() => {
          onVendorRecoModalViewMore(data);
        }}
        onVendorRecoModalPrev={() => {
          onVendorRecoModalPrev(data);
        }}
        onVendorRecoModalNext={() => {
          onVendorRecoModalNext(data);
        }}
      />
    );
  }
  return null;
};

export const QUERY = gql`
  query agencyByAgencyId($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      id
      ...AgencyRecommendations
    }
  }
  ${FRAGMENT}
`;

AgencyRecommendationsWithFragment.fragment = FRAGMENT;
AgencyRecommendationsWithFragment.fragmentName = FRAGMENT_NAME;

export const AgencyRecommendationsWithQuery = ({
  agencyId,
  onPageRendered,
  onVendorRecoViewAll,
  onVendorRecoRendered,
  onVendorRecoImpression,
  onVendorRecoModalViewMore,
  onVendorRecoModalPrev,
  onVendorRecoModalNext,
}: AgencyRecommendationsWithQueryProps): JSX.Element | null => {
  const { data, error } = useQuery<AgencyByAgencyIdQuery>(QUERY, {
    variables: { agencyId },
  });

  if (error) {
    handleError(error);
    return <div data-testid="fe-faa-agency-recommendations-error" />;
  }

  if (data) {
    return (
      <div data-testid="fe-faa-agency-recommendations-container">
        <AgencyRecommendationsWithFragment
          agencyId={agencyId}
          onPageRendered={onPageRendered}
          onVendorRecoViewAll={onVendorRecoViewAll}
          onVendorRecoRendered={onVendorRecoRendered}
          onVendorRecoImpression={onVendorRecoImpression}
          onVendorRecoModalViewMore={onVendorRecoModalViewMore}
          onVendorRecoModalPrev={onVendorRecoModalPrev}
          onVendorRecoModalNext={onVendorRecoModalNext}
        />
      </div>
    );
  }

  return null;
};

AgencyRecommendationsWithQuery.query = QUERY;
