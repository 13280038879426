import { css } from '@emotion/react';
import { tokens, h4Tag } from '@domain-group/fe-brary';

const twoCardRowHeight = 614;

/**
 * Borrowed from
 * @link https://github.com/domain-group/fe-pa-trade-profile/blob/c4b2968d717c0414fdc03b263b53188f050c2dd4/src/js/components/listings-tabs/listing-tabs.style.js#L192
 */
export const listingsNoResults = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${twoCardRowHeight}px;
  padding-bottom: ${tokens.spacing.m};
`;

export const listingsNoResultsTitle = css`
  ${h4Tag};
`;
