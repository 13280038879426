import { css } from '@emotion/react';
import { tokens, media, h4Tag } from '@domain-group/fe-brary';

export const profileDescription = css`
  p {
    margin: ${tokens.spacing.s} 0;
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  ul {
    list-style: circle inside none;
  }

  ol {
    list-style: decimal inside none;
  }
`;

export const title = css`
  ${h4Tag};
  margin-bottom: ${tokens.spacing.xs};
  color: ${tokens.colors.neutral.heavy.default};

  ${media('>=desktop')} {
    margin-bottom: ${tokens.spacing.s};
  }
`;
