import React, { useState, useEffect } from 'react';

import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import ContactButtons, { Props } from './contact-buttons';
import { getWindowHeight, getScrollPos, getViewportHeight } from '../../utils';
import { debounce } from '../../../../utils';

import * as styles from './cta-button-group.style';

const MobileStickyCtaButtonGroup = (props: Props): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();

  const [isAtBottomOfPage, setAtBottom] = useState(false);

  const [handleScroll, teardownHandleScroll] = debounce(() => {
    const windowHeight = getWindowHeight();
    const scrollPos = getScrollPos();
    const viewportHeight = getViewportHeight();
    const stickyHeight = 70;

    const atBottom = windowHeight - stickyHeight <= scrollPos + viewportHeight;

    if (isAtBottomOfPage !== atBottom) {
      setAtBottom(atBottom);
    }
  }, 200);

  useEffect(() => {
    // call throttle scroll immediately so that if user refreshes the page
    // after having scrolled down the sticky nav is correctly positioned
    if (getScrollPos() !== 0) {
      handleScroll();
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      teardownHandleScroll();
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <AnimatePresence>
      {!isAtBottomOfPage && (
        <motion.div
          {...styles.animationSettings(shouldReduceMotion)}
          css={styles.stickyContactButtons}
        >
          <ContactButtons {...props} stretched />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileStickyCtaButtonGroup;
