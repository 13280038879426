import { css } from '@emotion/react';
import { h4Tag, media, tokens } from '@domain-group/fe-brary';

export const header = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${tokens.spacing.m};
`;

export const heading = css`
  ${h4Tag};
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  color: ${tokens.colors.neutral.heavy.default};
`;

export const list = css`
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;

  ${media('>=tablet')} {
    grid-auto-flow: row;
  }
`;

export const listItem = css`
  margin-bottom: ${tokens.spacing.s};
  scroll-snap-align: start;

  width: calc(100vw - 2 * ${tokens.spacing.m});
  background-color: ${tokens.colors.neutral.subdued.default};

  ${media('>=tablet')} {
    background-color: transparent;
    width: 100%;
  }
`;

export const modalReview = css`
  ${media('>=desktop')} {
    padding: ${tokens.spacing.xl};
  }
`;

export const modalFooter = css`
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-left: ${tokens.spacing.s};
  padding-right: ${tokens.spacing.s};
  padding-bottom: ${tokens.spacing.xs};
`;

export const promo = css`
  display: flex;
  color: ${tokens.colors.neutral.medium.default};
`;

export const paginationContainer = css`
  display: block;

  ${media('>=tablet')} {
    display: none;
  }
`;
