import { ContactDigitalDataFragment } from '../../../generated/graphql';

type AddresInfo = {
  agencyName: string;
  postCode: string;
  state: string;
  suburb: string;
};

export const agencyProfilePageDescriptionGenerator = ({
  agencyName,
  postCode,
  state,
  suburb,
}: AddresInfo): string =>
  `${agencyName} Real Estate Agency in ${suburb},` +
  `${state} ${postCode} offers ` +
  'specialist property services to buy, sell and rent real estate.';

export const agentProfilePageDescriptionGenerator = (
  agentData: ContactDigitalDataFragment,
): string => {
  const listingsTotal = (agentData?.statistics ??
    {}) as ContactDigitalDataFragment['statistics'];

  const soldTotal = listingsTotal?.totalSoldAndAuctioned ?? 0;
  let activityVerb = 'sold';
  let liveName = 'for sale';
  let activityCompleted = soldTotal;
  let activityInProgress = listingsTotal?.totalForSale ?? 0;
  if (soldTotal < (listingsTotal?.totalLeased ?? 0)) {
    activityVerb = 'leased';
    liveName = 'for rent';
    activityCompleted = listingsTotal?.totalLeased ?? 0;
    activityInProgress = listingsTotal?.totalForRent ?? 0;
  }
  const liveListingPhrase = activityInProgress === 1 ? 'listing' : 'listings';
  const completedListingPhrase =
    activityCompleted === 1 ? 'listing' : 'listings';

  let listingsText = '';
  if (soldTotal > 0 || (listingsTotal?.totalLeased ?? 0) > 0) {
    listingsText = ` has ${activityVerb} ${activityCompleted} ${completedListingPhrase} in the last 12 months`;
    listingsText +=
      activityInProgress > 0
        ? ` and currently has ${activityInProgress} ${liveListingPhrase} ${liveName}.`
        : '.';
  } else {
    listingsText +=
      activityInProgress > 0
        ? ` currently has ${activityInProgress} ${liveListingPhrase} ${liveName}.`
        : ' currently has no listings for sale.';
  }
  return (
    `Your local real estate agent ${agentData.name} from ` +
    `${
      agentData?.address?.displayAddress ?? ''
    }${listingsText} Visit us now for more details.`
  );
};
