import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { ProfileCard } from './components';
import { ContactEnquiryFormWithFragment } from '../contact-enquiry-form';
import { ContactProfileCardFragment } from '../../generated/graphql';

export type Props = {
  contactId: number;
  agentIdV2: string;
  agencyId: number;
  isPropertyManager?: boolean;
  hideAppraisalCta?: boolean;
  isShortStats?: boolean;
  isVertical?: boolean;
  hideOffice?: boolean;
  // Events
  onViewProfile?: ({ contactId }: { contactId: number }) => void;
  onCall?: ({ contactId }: { contactId: number }) => void;
  onCallReveal?: () => void;
  onGetAppraisal?: () => void;
  onOpenEnquiryForm?: () => void;
  // Enquiry Form events
  onEnquirySubmit?: ({
    contactId,
    intent,
    phone,
    postcode,
    message,
  }: {
    contactId: number;
    postcode: string;
    intent?: string;
    phone?: string | null;
    message?: string;
  }) => void;
  onEnquirySubmitSuccess?: ({
    contactId,
    intent,
    name,
  }: {
    contactId: number;
    intent?: string;
    name?: string | null;
  }) => void;
  onEnquirySubmitError?: ({
    contactId,
    name,
  }: {
    contactId: number;
    name?: string | null;
  }) => void;
  onEnquiryFinish?: (() => void) | undefined;
  appraisalEntryPoint?: string;
};

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactProfileCard';
const FRAGMENT = gql`
  fragment ContactProfileCard on Contact {
    contactId
    id
    agentIdV2
    agencyId
    name
    jobTitle
    hasEmail
    telephone
    mobile
    profileTier
    profileUrl
    profilePhoto {
      url
    }
    statistics {
      id
      totalForSale
      averageSoldPrice
      averageDaysOnMarket
      totalSoldAndAuctioned
      totalJointSoldAndAuctioned
      totalForRent
      totalLeased
      totalJointLeased
    }
    agencyByAgencyId {
      id
      agencyId
      name
      branding {
        id
        logo {
          url
        }
        logoColour
      }
    }
    ...ContactEnquiryForm
  }
  ${ContactEnquiryFormWithFragment.fragment}
`;

const ContactProfileCardWithFragment = ({
  contactId,
  agentIdV2,
  agencyId,
  isPropertyManager = false,
  hideAppraisalCta = false,
  isShortStats = false,
  isVertical = false,
  hideOffice = false,
  onViewProfile = () => undefined,
  onCall = () => undefined,
  onCallReveal = () => undefined,
  onGetAppraisal = () => undefined,
  onOpenEnquiryForm = () => undefined,
  onEnquirySubmit = () => undefined,
  onEnquirySubmitSuccess = () => undefined,
  onEnquirySubmitError = () => undefined,
  onEnquiryFinish = () => undefined,
  appraisalEntryPoint,
}: Props): JSX.Element | null => {
  const { complete, data: contact } = useFragment<ContactProfileCardFragment>({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactProfileCardWithFragment.fragment,
    fragmentName: ContactProfileCardWithFragment.fragmentName,
  });

  if (!complete || !contact) {
    return null;
  }

  return (
    <ProfileCard
      contactId={contactId}
      name={contact?.name}
      jobTitle={contact?.jobTitle}
      hasEmail={contact?.hasEmail}
      telephone={contact?.telephone}
      mobile={contact?.mobile}
      profileTier={contact?.profileTier}
      profileUrl={contact?.profileUrl}
      profilePhoto={contact?.profilePhoto}
      statistics={contact?.statistics}
      agency={contact?.agencyByAgencyId}
      isPropertyManager={isPropertyManager}
      hideAppraisalCta={hideAppraisalCta}
      isShortStats={isShortStats}
      isVertical={isVertical}
      hideOffice={hideOffice}
      onViewProfile={onViewProfile}
      onCall={onCall}
      onCallReveal={onCallReveal}
      onGetAppraisal={onGetAppraisal}
      onOpenEnquiryForm={onOpenEnquiryForm}
      onEnquirySubmit={onEnquirySubmit}
      onEnquirySubmitSuccess={onEnquirySubmitSuccess}
      onEnquirySubmitError={onEnquirySubmitError}
      onEnquiryFinish={onEnquiryFinish}
      appraisalEntryPoint={appraisalEntryPoint}
    />
  );
};

ContactProfileCardWithFragment.fragment = FRAGMENT;
ContactProfileCardWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactProfileCardWithFragment;
