import React from 'react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { BuildingIcon, HouseIcon, RuralIcon } from '@domain-group/fe-co-icon';

import { formatCurrency } from '../../../../utils';
import { ISuburbStatistics } from '../../@types';
import * as commonStyles from '../common.style';
import * as styles from './table.style';

export type Props = {
  suburbStatistics?: ISuburbStatistics | null;
};

const Table = ({ suburbStatistics = {} }: Props): JSX.Element => {
  const shouldReduceMotion = useReducedMotion();

  const animation = styles.animationSettings(shouldReduceMotion);

  return (
    <div css={styles.tableWrapper}>
      <table css={styles.table}>
        <thead>
          <tr>
            <th css={styles.thItem}>
              <span css={styles.srOnly}>property type</span>
            </th>
            <th css={styles.thItem}>
              <span css={styles.srOnly}>number of </span>sales
            </th>
            <th css={styles.thItem}>
              average<span css={styles.srOnly}> value</span>
            </th>
          </tr>
        </thead>
        <tbody css={styles.tbody}>
          <tr css={styles.row}>
            <td css={styles.cell}>
              <span css={styles.propertyIcon}>
                <HouseIcon />
              </span>
              Houses
            </td>
            <td css={styles.cell}>
              {suburbStatistics?.listingType?.house || 0}
            </td>
            <td css={styles.cell}>
              {formatCurrency(suburbStatistics?.listingTypeAverage?.house || 0)}
            </td>
          </tr>
          <tr css={styles.row}>
            <td css={styles.cell}>
              <span css={styles.propertyIcon}>
                <BuildingIcon />
              </span>
              Units
            </td>
            <td css={styles.cell}>
              {suburbStatistics?.listingType?.apartment || 0}
            </td>
            <td css={styles.cell}>
              {formatCurrency(
                suburbStatistics?.listingTypeAverage?.apartment || 0,
              )}
            </td>
          </tr>
          <tr css={styles.row}>
            <td css={styles.cell}>
              <span css={styles.propertyIcon}>
                <RuralIcon />
              </span>
              Land
            </td>
            <td css={styles.cell}>
              {suburbStatistics?.listingType?.land || 0}
            </td>
            <td css={styles.cell}>
              {formatCurrency(suburbStatistics?.listingTypeAverage?.land || 0)}
            </td>
          </tr>
          <AnimatePresence>
            {suburbStatistics?.listingType?.rural && (
              <motion.tr
                initial={animation.collapsed}
                animate={animation.open}
                exit={animation.collapsed}
                transition={animation.transition}
                css={styles.row}
              >
                <td css={styles.cell}>
                  <span css={[styles.propertyIcon, styles.flipped]}>
                    <RuralIcon />
                  </span>
                  Rural
                </td>
                <td css={styles.cell}>
                  {suburbStatistics?.listingType?.rural || 0}
                </td>
                <td css={styles.cell}>
                  {formatCurrency(
                    suburbStatistics?.listingTypeAverage?.rural || 0,
                  )}
                </td>
              </motion.tr>
            )}
          </AnimatePresence>
          <tr css={styles.row}>
            <td css={[styles.cell]}>
              <span css={styles.srOnly}>All property types</span>
            </td>
            <td css={[styles.cell, styles.greenFont]}>
              {suburbStatistics?.total}
              <div css={commonStyles.addendum}>total sales</div>
            </td>
            <td css={[styles.cell, styles.greenFont]}>
              {formatCurrency(suburbStatistics?.averageSoldPrice || 0)}
              <div css={commonStyles.addendum}>avg sale price</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
