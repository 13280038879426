import getConfig from 'next/config';
import {
  AGENCY_VIEW,
  AGENT_VIEW,
  DEFAULT_SORT_VALUE,
  SALES_AGENT,
} from '../components/search-filter-bar/enums';

const { publicRuntimeConfig } = getConfig();

interface SearchResultsState {
  isAgency: boolean;
  suburbs: string[];
  keywordName?: string;
  page: number;
  agentFilter: string;
  sortBy: string;
}

const buildUrl = (
  state: SearchResultsState,
  removePage = false,
): {
  to: string;
  as: string;
} => {
  const searchParams = new URLSearchParams();

  // Suburbs
  let singleSuburbPrefix = '';
  if (state?.suburbs?.length === 1) {
    singleSuburbPrefix = state.suburbs[0];
  } else if (state?.suburbs?.length > 1) {
    searchParams.append('suburbs', state?.suburbs?.join?.(',') ?? '');
  }
  // Keyword
  if (state?.keywordName) {
    searchParams.append('keywordName', state?.keywordName);
  }
  // Page
  if (!removePage && state?.page && state?.page !== 1) {
    searchParams.append('page', `${state?.page}`);
  }

  // Search view
  const searchView = state?.isAgency ? AGENCY_VIEW : AGENT_VIEW;

  // Agent type
  if (
    !state?.isAgency &&
    state?.agentFilter &&
    state?.agentFilter !== SALES_AGENT
  ) {
    searchParams.append('agentFilter', state?.agentFilter);
  }
  // TODO: Sort by options are different for agencies/agents
  // Sort by
  if (state?.sortBy && state?.sortBy !== DEFAULT_SORT_VALUE) {
    searchParams.append('sortBy', state?.sortBy);
  }

  // Create query string (?what=this)
  let searchParamsString = searchParams.toString();
  if (searchParamsString) {
    searchParamsString = `?${searchParamsString}`;
  }

  if (singleSuburbPrefix) {
    return {
      // Route
      to: `/${searchView}/search-results${
        searchParamsString ? `${searchParamsString}&` : '?'
      }suburbs=${singleSuburbPrefix}`,
      // Decorator
      as: `/${searchView}/${singleSuburbPrefix}/${searchParamsString}`,
    };
  }

  return {
    // Route
    to: `/${searchView}/search-results${searchParamsString}`,
    // Decorator
    as: `/${searchView}/${searchParamsString}`,
  };
};

export const buildPaginatorUrl = (state: SearchResultsState): string => {
  const { as: pageUrl } = buildUrl(state, true);

  const url = new URL(`${publicRuntimeConfig.BASE_URL}/${pageUrl}`);

  const hasSearchParams = !!url.search;

  return `${url.toString()}${hasSearchParams ? '&' : '?'}page={{ page }}`;
};

export default buildUrl;
