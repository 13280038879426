import { css, SerializedStyles } from '@emotion/react';
import { tokens, applyFont, srOnly, media } from '@domain-group/fe-brary';

const { colors, spacing, typography, borderRadius } = tokens;

export const autocomplete = (
  appearance: 'large' | 'medium' | 'small',
): SerializedStyles => css`
  display: block;
  width: 100%;
  overflow: visible;
  position: relative;
  z-index: 2;

  ${appearance === 'small' &&
  css`
    padding: ${spacing.m} ${spacing.m} 0;
  `}

  ${media('>=desktop')} {
    padding: 0;
  }
`;

export const typeaheadContainer = css`
  display: flex;
  position: relative;
`;

export const typeahead = (
  appearance: 'large' | 'medium' | 'small',
): SerializedStyles => css`
  flex: 1 1 auto;
  min-width: 0;

  ${appearance !== 'small' &&
  css`
    margin-right: -2px;
  `};
`;

const iconSize = 24;

export const searchButton = (
  appearance: 'large' | 'medium' | 'small',
  isFullScreen = false,
): SerializedStyles => css`
  flex-shrink: 0;
  display: inline-block;
  cursor: pointer;
  ${applyFont(typography.body)}
  z-index: 2; /* Keep button above Typeahead */
  border: 2px solid ${colors.interactive.base.default};
  background-color: ${colors.interactive.base.default};
  color: ${colors.interactive.surface.default};
  padding: ${spacing.xs} ${spacing.s};
  border-radius: 0 ${borderRadius.mini} ${borderRadius.mini} 0;

  &:hover,
  &:focus {
    border-color: ${colors.interactive.base.hover};
    background-color: ${colors.interactive.base.hover};
  }

  svg {
    display: block;
    width: ${iconSize}px;
    height: ${iconSize}px;
  }

  ${!isFullScreen &&
  appearance === 'small' &&
  css`
    position: absolute;
    right: 0;
    height: 42px;
  `}

  ${isFullScreen &&
  css`
    border-radius: ${borderRadius.mini};
  `}
`;

export const searchButtonFullScreen = css`
  padding: ${spacing.xs};
`;

export const noResultsMessage = css`
  ${applyFont(typography.caption)}
  padding: ${spacing.s};
  color: ${colors.neutral.medium.default};
`;

export const errorMessage = css`
  margin: ${spacing.xs} 0;
`;

export { srOnly };
