import { css } from '@emotion/react';
import { tokens, media } from '@domain-group/fe-brary';

const { colors, spacing } = tokens;

export const wrapper = css`
  background: ${colors.neutral.surface.default};
  border-top: 1px solid ${colors.neutral.base.default};
  position: sticky;
  /* Use negative margin to hide search bar and just pin filters on mobile/tablet */
  top: -${42 + 18}px;
  z-index: 199;
  margin-bottom: ${spacing.m};
  border-bottom: 1px solid ${colors.neutral.base.default};

  ${media('>=desktop')} {
    /* Pin both search bar and filters on desktop */
    top: 0;
    margin-bottom: ${spacing.l};
  }
`;

const pageSize = 1280;
export const layout = css`
  ${media('>=desktop')} {
    max-width: ${pageSize}px;
    margin: 0 auto;
    padding: 0px ${spacing.m};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
  }
`;
