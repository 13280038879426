import { Breadcrumb } from '.';

const mapAgencyBreadcrumbs = ({
  baseUrl,
  profileUrl = '',
  agencyName = '',
  suburbName = '',
  suburbSlug = '',
}: {
  baseUrl: string;
  profileUrl?: string;
  agencyName?: string;
  suburbName?: string;
  suburbSlug?: string;
}): Breadcrumb[] => [
  {
    title: 'Real Estate Agencies',
    url: `${baseUrl}/real-estate-agents/`,
  },
  {
    title: suburbName,
    url: `${baseUrl}/real-estate-agencies/${suburbSlug}/`,
  },
  { title: agencyName, url: `${baseUrl}/real-estate-agencies/${profileUrl}` },
];

export default mapAgencyBreadcrumbs;
