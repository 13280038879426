// eslint-disable-next-line import/prefer-default-export
export const INTENT_OPTIONS = [
  {
    value: 'Selling my property and appraisals',
    label: 'Selling my property and appraisals',
  },
  {
    value: 'Leasing my property',
    label: 'Leasing my property',
  },
  {
    value: 'Buying a property',
    label: 'Buying a property',
  },
  {
    value: 'Renting a property',
    label: 'Renting a property',
  },
  {
    value: 'General enquiry',
    label: 'I have a general enquiry',
  },
];
