/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

const arrowStyling = (inView) => css`
  .gm-style .gm-style-iw-t::after {
    box-shadow: none;
    opacity: 0;
    transition: opacity 1s ease;

    ${inView &&
    css`
      opacity: 1;
    `}
  }
`;

const infoBoxStyling = (inView) => css`
  .gm-style .gm-style-iw-c {
    border-radius: 3px;
    box-shadow: none;
    opacity: 0;
    transition: opacity 1s ease;

    ${inView &&
    css`
      opacity: 1;
    `}
  }
`;

export const customGoogleMapsUIStyling = (inView) => css`
  ${arrowStyling(inView)}
  ${infoBoxStyling(inView)}
`;

export const mobileInfoBox = css`
  padding: ${tokens.spacing.xs};
  padding-bottom: ${tokens.spacing.l};
`;
