import { css } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

const pageSize = 1280;
export const oneColumnWrapper = (marginBottom = `none`) => css`
  display: block;
  max-width: ${pageSize}px;
  margin: 0 auto;
  padding: 0 ${tokens.spacing.m};
  margin-bottom: ${tokens.spacing[marginBottom]};
  ${media('>desktop')} {
    padding: 0 ${tokens.spacing.m};
  }
`;

// Custom breakpoint for sidebar
const sidebarBreakpoint = 1200;

export const twoColumnWrapper = (marginBottom = `none`) => css`
  ${oneColumnWrapper(marginBottom)}

  @media (min-width: ${sidebarBreakpoint}px) {
    display: grid;
    grid-template-columns: 1fr 260px;
    grid-column-gap: ${tokens.spacing.m};
  }
`;

export const main = css`
  position: relative;
  max-width: 100%;
`;

export const aside = css`
  position; relative;
  border-top: 1px solid ${tokens.colors.neutral.trim.default};
  margin-top: ${tokens.spacing.m};
  padding-top: ${tokens.spacing.m};

  ${media('>=tablet')} {
    margin-top: ${tokens.spacing.m};
    padding-top: ${tokens.spacing.m};
  }

  @media (min-width: ${sidebarBreakpoint}px) {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }
`;

export const stickyAside = css`
  position: sticky;
  /* Needs to sit below sticky search bar */
  top: ${68 + parseInt(tokens.spacing.m, 10)}px;

  > *:not(:last-of-type) {
    margin-bottom: ${tokens.spacing.m};
  }

  ${media('>=tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${tokens.spacing.m};

    > *:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${sidebarBreakpoint}px) {
    display: block;

    > *:not(:last-of-type) {
      margin-bottom: ${tokens.spacing.m};
    }
  }
`;
