import { createDigitalData, maxLengthJoin } from '.';
import { DigitalData, UserDetailsRedacted } from '../../../@types';
import { AgencySearchQuery } from '../../../generated/graphql';

export type GenerateDigitalDataSearchResultsProps = {
  isAgency: boolean;
  sysEnv: string;
  source: string;
  isEmbeddedApp: boolean;
  pageNumber: number;
  agencyIds?: number[];
  suburbs: AgencySearchQuery['locationProfiles'];
  user?: UserDetailsRedacted;
};

const generateDigitalDataSearchResults = ({
  isAgency,
  sysEnv,
  source,
  isEmbeddedApp,
  pageNumber,
  agencyIds = [],
  suburbs,
  user,
}: GenerateDigitalDataSearchResultsProps): DigitalData => {
  const searchTerms: string[] = [];
  const searchSuburbs: string[] = [];
  const searchStates: string[] = [];
  const searchPostcodes: string[] = [];

  suburbs?.forEach((suburb) => {
    // Suburb label is formatted as "Newtown, NSW, 2042"
    const suburbDetail = `${suburb?.suburbName}, ${suburb?.state}, ${suburb?.postcode}`;
    searchTerms.push(suburbDetail);

    if (
      suburb?.suburbName &&
      !searchSuburbs.find((searchSuburb) => searchSuburb === suburb.suburbName)
    ) {
      searchSuburbs.push(suburb.suburbName);
    }
    if (
      suburb?.postcode &&
      !searchPostcodes.find(
        (searchPostcode) => searchPostcode === suburb.postcode,
      )
    ) {
      searchPostcodes.push(suburb.postcode);
    }
    if (
      suburb?.state &&
      !searchStates.find((searchState) => searchState === suburb.state)
    ) {
      searchStates.push(suburb.state);
    }
  });

  return createDigitalData({
    pageInfo: {
      pageName: `${isAgency ? 'Agency' : 'Agent'} Search Results`,
      pageTemplate: `${isAgency ? 'agency' : 'agent'}SearchResults`,
      sysEnv,
      source,
      isEmbeddedApp,
      property: {}, // No associated property
    },
    search: {
      searchTerm: maxLengthJoin(searchTerms),
      searchDepth: pageNumber,
      postcode: maxLengthJoin(searchPostcodes),
      searchSuburb: maxLengthJoin(searchSuburbs),
      state: maxLengthJoin(searchStates),
      agencyIds: agencyIds.length > 0 ? maxLengthJoin(agencyIds) : undefined,
      geoType: 'Area, Suburb, Postcode',
    },
    category: {
      pageType: 'Search Results',
      primaryCategory: 'Find an Agent',
      subCategory1: `${isAgency ? 'Agency' : 'Agent'} Search Results`,
    },
    user,
  });
};

export default generateDigitalDataSearchResults;
