import React from 'react';
import { gql, useQuery } from '@apollo/client';
import ContactVideoWithFragment from './contact-video-with-fragment';
import { GetContactVideoQuery } from '../../generated/graphql';

type ContactVideoProps = {
  agentIdV2: string;
  agencyId: number;
};

export const GET_CONTACT_VIDEO_QUERY = gql`
  query getContactVideo($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...ContactVideo
    }
  }
  ${ContactVideoWithFragment.fragment}
`;

const ContactVideoWithQuery = ({
  agentIdV2,
  agencyId,
}: ContactVideoProps): JSX.Element | null => {
  const { data } = useQuery<GetContactVideoQuery>(GET_CONTACT_VIDEO_QUERY, {
    variables: { agentIdV2, agencyId },
  });

  if (!data) {
    return null;
  }

  return <ContactVideoWithFragment agentIdV2={agentIdV2} agencyId={agencyId} />;
};

export default ContactVideoWithQuery;
