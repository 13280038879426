import { variables } from '@domain-group/fe-brary';
import { useMediaQuery } from 'beautiful-react-hooks';

// Ignoring in coverage because it is just an existing hook with our def of a mobile size
/* istanbul ignore next */
const useIsMobile = (): boolean => {
  const { tabletMinWidth } = variables.global;
  const isLarge = useMediaQuery(`(min-width: ${tabletMinWidth})`);
  return !isLarge;
};

export default useIsMobile;
