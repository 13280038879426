import React from 'react';

const openGraph = ({
  image,
  title,
  description,
}: {
  image?: string;
  title?: string;
  description?: string;
}): JSX.Element | null => {
  if (!image && !title && !description) {
    return null;
  }

  return (
    <>
      {image ? <meta property="og:image" content={image} /> : undefined}
      {title ? <meta property="og:title" content={title} /> : undefined}
      {description ? (
        <meta property="og:description" content={description} />
      ) : undefined}
    </>
  );
};

export default openGraph;
