import React, { ReactNode } from 'react';
import { gql, useQuery } from '@apollo/client';
import ContactHeroWithFragment from './contact-hero-with-fragment';
import { GetContactHeroQuery } from '../../generated/graphql';

export type Props = {
  agentIdV2: string;
  agencyId: number;
  renderComponent: ReactNode;
  emailFormComponentId: string;
  onAgencyAddressClick?: () => void;
  onAgencyLogoClick: () => void;
  onCallButtonClick?: () => void;
  onEmailButtonClick?: () => void;
  onSocialClick?: () => void;
};

export const GET_CONTACT_HERO_QUERY = gql`
  query getContactHero($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...ContactHero
    }
  }
  ${ContactHeroWithFragment.fragment}
`;

const ContactHeroWithQuery = ({
  agentIdV2,
  agencyId,
  renderComponent,
  emailFormComponentId,
  onAgencyAddressClick = () => undefined,
  onAgencyLogoClick = () => undefined,
  onCallButtonClick = () => undefined,
  onEmailButtonClick = () => undefined,
  onSocialClick = () => undefined,
}: Props): JSX.Element | null => {
  const { data } = useQuery<GetContactHeroQuery>(GET_CONTACT_HERO_QUERY, {
    variables: { agentIdV2, agencyId },
  });

  if (!data) {
    return null;
  }

  return (
    <ContactHeroWithFragment
      agentIdV2={agentIdV2}
      agencyId={agencyId}
      renderComponent={renderComponent}
      emailFormComponentId={emailFormComponentId}
      onAgencyAddressClick={onAgencyAddressClick}
      onAgencyLogoClick={onAgencyLogoClick}
      onCallButtonClick={onCallButtonClick}
      onEmailButtonClick={onEmailButtonClick}
      onSocialClick={onSocialClick}
    />
  );
};

export default ContactHeroWithQuery;
