import React from 'react';
import Illustration from '@domain-group/fe-co-brand-illustrations';
import * as styles from './selling-guide.style';
import Link from '../link/link';
import ContentContainer from '../content-container/content-container';

const firstSection = (): JSX.Element => (
  <div css={styles.sectionsContainer(true)}>
    <Illustration
      type="scene"
      shape="house"
      name="understandingPropertyValue"
      size="default"
    />
  </div>
);

const secondSection = ({
  baseUrl = 'https://www.domain.com.au',
  trackStartReading,
}: {
  baseUrl?: string;
  trackStartReading: () => void;
}) => (
  <div css={styles.sectionsContainer(false)}>
    <h2>Selling guide</h2>
    <p>
      We’ve compiled this guide to answer your most important questions about
      the selling process.
    </p>
    <div css={styles.link}>
      <Link href={`${baseUrl}/advice/selling/`} onClick={trackStartReading}>
        Start reading
      </Link>
    </div>
  </div>
);

const SellingGuide = ({
  baseUrl = 'https://www.domain.com.au',
  trackStartReading,
}: {
  baseUrl?: string;
  trackStartReading: () => void;
}): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.contentWrapper}>
      <ContentContainer
        overrideStyles={styles.overriddenStyling}
        first={firstSection()}
        second={secondSection({ baseUrl, trackStartReading })}
        changeForDevice="tablet"
      />
    </div>
  </div>
);

export default SellingGuide;
