/* eslint-disable import/prefer-default-export */
import type {
  ContactDigitalDataFragment,
  AgencyDigitalDataFragment,
} from '../../../../../generated/graphql';

import type { SchemaAgent } from '../../../../../@types/schema';
import { makeAgencyHTMLSchema } from './agency';

export const makeAgentHTMLSchema = ({
  baseUrl,
  originalUrl,
  agentData,
  agencyData,
}: {
  baseUrl: string;
  originalUrl: string;
  agentData: ContactDigitalDataFragment;
  agencyData: AgencyDigitalDataFragment;
}): SchemaAgent => {
  const schema = {
    '@context': 'http://schema.org' as SchemaAgent['@context'],
    '@type': 'Person' as SchemaAgent['@type'],
    worksFor: makeAgencyHTMLSchema({
      originalUrl,
      baseUrl,
      data: agencyData,
    }),
    name: agentData?.name ?? '',
    jobTitle: agentData?.jobTitle ?? '',
    image: agentData?.profilePhoto?.url ?? '',
    address: agentData?.address?.displayAddress ?? '',
    url: `${agentData?.profileUrl ?? ''}/`,
  };
  return schema;
};
