import { css, SerializedStyles } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';
import { HERO_SECTION, PROFILE_TIERS } from '../../constants';

export const section = ({
  profileTier,
  isAgency = false,
  backgroundColour = 'transparent',
}: {
  profileTier: PROFILE_TIERS;
  isAgency?: boolean;
  backgroundColour?: string;
}): SerializedStyles => css`
  position: relative;
  padding: 0 ${tokens.spacing.m};

  &:empty {
    display: none;
  }

  ${profileTier === PROFILE_TIERS.platinum &&
  css`
    ${isAgency &&
    css`
      background-color: ${backgroundColour};
      border-bottom: 4px solid ${backgroundColour};
    `}

    ${media('>=tablet')} {
      display: none;
    }
  `};
`;

export const detailsWrapper = ({
  desktopWidth = 992,
  heroSection,
  isAgency,
  profileTier,
}: {
  desktopWidth: number;
  heroSection: HERO_SECTION;
  isAgency: boolean;
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  padding: ${tokens.spacing.m} 0;
  max-width: ${desktopWidth}px;
  margin: 0 auto;
  position: relative;

  ${profileTier === PROFILE_TIERS.platinum &&
  isAgency &&
  css`
    padding: 0;
  `}

  ${heroSection === HERO_SECTION.banner &&
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;

    ${isAgency &&
    (profileTier === PROFILE_TIERS.platinum ||
      profileTier === PROFILE_TIERS.branded) &&
    css`
      ${profileTier === PROFILE_TIERS.platinum &&
      css`
        ${media('>=tablet')} {
          display: block;
          text-align: center;
          width: 100%;
        }
      `};
    `};
  `};

  ${heroSection === HERO_SECTION.section &&
  css`
    width: 100%;

    ${media('>=tablet')} {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    ${!(isAgency && profileTier === PROFILE_TIERS.platinum) &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${tokens.colors.neutral.trim.default};
      }
    `}
  `};
`;
