import React, { useState } from 'react';
import { SerializedStyles } from '@emotion/react';

export type Props = {
  src: string;
  alt: string;
  css?: SerializedStyles | null;
};

const Image = ({ src, alt, css = null }: Props): JSX.Element | null => {
  const [imgError, setImgError] = useState(false);

  if (imgError) {
    return null;
  }

  return (
    <img
      onError={() => setImgError(true)}
      src={src}
      alt={alt}
      css={css}
      loading="lazy"
    />
  );
};

export default Image;
