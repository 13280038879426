import { ListingFilterStatus } from '../../../generated/graphql';

/// Mappers for choices
// This also orders which data is shown first

// eslint-disable-next-line import/prefer-default-export
export const listingStatusChoiceMap = [
  { value: ListingFilterStatus.Sale, label: 'FOR SALE', id: 'for-sale' },
  { value: ListingFilterStatus.Lease, label: 'FOR RENT', id: 'for-rent' },
  { value: ListingFilterStatus.Sold, label: 'SOLD', id: 'sold' },
  { value: ListingFilterStatus.Leased, label: 'LEASED', id: 'leased' },
];
