import { format as formatDate } from 'date-fns';

import { CurrentListingCardFragment } from '../../../../../../generated/graphql';
import {
  AddressModel,
  FeaturesModel,
  InspectionModel,
  ListingModel,
  ListingCardProps,
} from '../@types';

const formatSoldMethod = (soldMethod?: string | null) => {
  switch (soldMethod) {
    case 'sold':
      return 'Private Treaty';
    case 'auction':
      return 'Auction';
    case 'soldpriortoauction':
      return 'Sold prior to auction';
    default:
      return '';
  }
};

/* eslint-disable import/prefer-default-export */
export function mapToCurrentListingModel(
  listingData: CurrentListingCardFragment,
  overwrite?: Partial<ListingModel>,
): ListingCardProps | null {
  const formattedAddress = listingData?.address?.displayAddress?.split(',')[0];

  const address: AddressModel = {
    street: formattedAddress,
    state: listingData?.address?.state || undefined,
    suburb: listingData?.address?.suburb?.name?.toUpperCase() || undefined,
    postcode: listingData?.address?.postcode || undefined,
    lat: listingData?.address?.geolocation?.latitude,
    lng: listingData?.address?.geolocation?.longitude,
  };

  const inspection: InspectionModel = {};

  const features: FeaturesModel = {
    beds: listingData?.bedrooms ?? undefined,
    baths: listingData?.bathrooms ?? undefined,
    parking: listingData?.parkingSpaces ?? undefined,
    isRural: !!listingData?.isRural,
  };

  const photos = (listingData?.media || []).reduce(
    (photoArray: string[], media) => {
      /* eslint-disable-next-line no-underscore-dangle */
      if (media && media.__typename === 'Image') {
        return [...photoArray, media.url];
      }
      return photoArray;
    },
    [],
  );

  const displayPrice =
    listingData?.priceDetails?.displayPrice?.replace('SOLD - ', '') ?? '';

  let tags = {};

  if (listingData?.soldInfo) {
    const soldMethod = formatSoldMethod(listingData.soldInfo?.soldMethod);

    tags = {
      tagText: `${soldMethod} ${
        listingData.soldInfo?.soldDate &&
        formatDate(new Date(listingData.soldInfo.soldDate), 'dd MMM yyyy')
      }`,
      tagClassName: 'is-sold',
    };
  }

  const listing: ListingModel = {
    id: listingData?.id,
    address,
    hasVideo: false,
    features,
    images: photos || undefined,
    promoType: 'standard',
    inspection,
    showNotesButton: false,
    domainWebsiteUrl: listingData?.seoUrl || undefined,
    url: '', // seoUrl takes care of the full URL string
    price: displayPrice,
    tags,

    ...overwrite,
  };

  return {
    id: listingData?.id,
    listingModel: listing,
    listingType: 'listing',
  };
}
