import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';
import * as util from '../../style/util';

const { colors, spacing, typography } = tokens;

export const container = css`
  ${util.container({
    gap: spacing.xs,
    backgroundColor: colors.neutral.surface.default,
  })};
`;

export const contentWrapper = css`
  ${util.contentWrapper({
    gap: spacing.xl,
    flexDirection: 'rows',
  })};
  p {
    color: ${colors.neutral.base.default};
    ${applyFont(typography.mini)}
  }
`;
