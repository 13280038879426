import { css } from '@emotion/react';
import { variables, fontH4, fontH5, fontBase } from '@domain-group/fe-brary';

export const article = css`
  position: relative;
  border-radius: 3px;
  background: ${variables.colour.white};
  color: ${variables.colour.neutral700};
  border: 1px solid ${variables.colour.neutral200};
  padding: ${variables.global.spacing.desktop.medium};
  margin: ${variables.global.spacing.desktop.small} 0;
  display: flex; /* Fallback */
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${variables.global.spacing.desktop.small};
  align-items: center;
`;

export const modalTitle = css`
  ${fontH4};
`;

export const articleTitle = css`
  ${fontH5};
  color: ${variables.colour.neutral700};
  margin: 0 0 ${variables.global.spacingUnit};
`;

export const articleParagraph = css`
  ${fontBase};
`;

export const articleLink = css`
  ${fontBase};
  color: ${variables.colour.secondary};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: ${variables.colour.secondaryDark};
  }
`;

export const illustration = css`
  max-width: 100%;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;
