import { urlUtil } from '@domain-group/fe-helper';

export default ({
  contactId: agentId,
  entryPoint,
  baseUrl,
}: {
  contactId?: string | number;
  entryPoint?: string;
  baseUrl?: string;
} = {}): string => {
  const base =
    baseUrl || global?.window?.location?.origin || 'https://www.domain.com.au';

  return urlUtil.createUrlWithParams({
    baseUrl: base,
    path: '/services/appraisal',
    params: {
      ...(agentId ? { agentId: String(agentId) } : {}),
      ...(entryPoint ? { 'entry-point': entryPoint } : {}),
    },
  });
};
