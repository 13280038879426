const funnelReducer = (state, action) => {
  switch (action.type) {
    case 'setIntent':
      return {
        ...state,
        intent: action.intent,
      };
    case 'setDetails':
      return {
        ...state,
        details: action.details,
      };
    case 'setSubmissionError':
      return {
        ...state,
        submissionError: action.submissionError,
      };
    case 'clearInput':
      return {};
    default:
      return state;
  }
};

export default funnelReducer;
