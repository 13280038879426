import React from 'react';
import PluralProperties from './plural-properties';

const showSoldLeased = (soldTotal: number, leasedTotal: number): boolean =>
  soldTotal > 0 || leasedTotal > 0;

export type SoldLeasedPhraseProps = {
  soldTotal: number;
  leasedTotal: number;
  highlight?: boolean;
};
const SoldLeasedPhrase = ({
  soldTotal,
  leasedTotal,
  highlight = false,
}: SoldLeasedPhraseProps): JSX.Element | null => {
  const soldPrefix = 'sold ';
  const leasedPrefix = 'leased ';

  if (soldTotal > 0 && leasedTotal > 0) {
    return (
      <>
        <PluralProperties
          propertyNum={soldTotal}
          prefix={soldPrefix}
          highlight={highlight}
        />{' '}
        and{' '}
        <PluralProperties
          propertyNum={leasedTotal}
          prefix={leasedPrefix}
          highlight={highlight}
        />
      </>
    );
  }
  if (soldTotal > 0) {
    return (
      <PluralProperties
        propertyNum={soldTotal}
        prefix={soldPrefix}
        highlight={highlight}
      />
    );
  }
  if (leasedTotal > 0) {
    return (
      <PluralProperties
        propertyNum={leasedTotal}
        prefix={leasedPrefix}
        highlight={highlight}
      />
    );
  }

  return null;
};

export { showSoldLeased };
export default SoldLeasedPhrase;
