import { css, SerializedStyles } from '@emotion/react';
import {
  media,
  tokens,
  globalTokens,
  rgba,
  applyFont,
} from '@domain-group/fe-brary';

export const globalStyle = css`
  body {
    /* Match footer background */
    background-color: ${tokens.colors.primary.background.default};
  }
`;

export const container = css`
  background-color: ${tokens.colors.neutral.surface.default};
  position: relative;
  overflow: auto;
`;

// NOTE: CSS Variables are not supported by browser RGBA function.
// We need to use the fe-brary helper util rgba along side a static
// colour to get the RGB components. This then feeds into the broswer
// RGBA. Hence thats why we are using global tokens here.
// See: https://domainau.slack.com/archives/C86A0V77E/p1668990790176139?thread_ts=1668989115.509179&cid=C86A0V77E
export const landing = css`
  position: relative;
  padding: ${tokens.spacing.xxl} 0;
  background: linear-gradient(
    to bottom,
    ${rgba(globalTokens.colors.coolGrey900, 0.6)} 0%,
    ${rgba(globalTokens.colors.coolGrey900, 0.7)} 100%
  );

  ${media('>=tablet')} {
    padding: ${tokens.spacing.xxl} 0;
    display: flex;
    align-items: center;
  }
`;

export const content = css`
  position: relative;
  text-align: center;
`;

export const page = css`
  background-color: ${tokens.colors.neutral.surface.default};
`;

export const wrapper = css`
  width: 100%;
  max-width: 1280px;
  padding: 0 ${tokens.spacing.m};
  margin: ${tokens.spacing.xl} auto;
`;

export const title = css`
  font-size: ${tokens.typography.displayLarge.size};
  line-height: ${tokens.typography.displayLarge.lineHeight};
  font-weight: ${tokens.typography.displayLarge.weight};
  color: ${tokens.colors.neutral.surface.default};
  margin-bottom: ${tokens.spacing.s};

  span {
    display: block;
  }

  ${media('>=desktop')} {
    font-size: ${tokens.typography.displayLarge.size};
    line-height: ${tokens.typography.displayLarge.lineHeight};
    font-weight: ${tokens.typography.displayLarge.weight};
    margin-bottom: ${tokens.spacing.s};
  }

  ${media('>=desktop')} {
    font-size: ${tokens.typography.displayLarge.size};
    line-height: ${tokens.typography.displayLarge.lineHeight};
    font-weight: ${tokens.typography.displayLarge.weight};
    margin-bottom: ${tokens.spacing.s};
  }
`;

export const subtitle = css`
  ${applyFont(tokens.typography.body)}
  color: ${tokens.colors.neutral.surface.default};
  margin-bottom: ${tokens.spacing.m};

  ${media('>=tablet')} {
    ${applyFont(tokens.typography.subheading)}
    font-weight: normal;
    margin-bottom: ${tokens.spacing.m};
  }

  ${media('>=desktop')} {
    ${applyFont(tokens.typography.displayMedium)}
    font-weight: normal;
    margin-bottom: ${tokens.spacing.m};
  }
`;

export const bannerContainer = (
  fullImageUrlDesktop: string,
  fullImageUrlMobile: string,
): SerializedStyles => css`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('${fullImageUrlMobile}');

  ${media('>=desktop')} {
    background-image: url('${fullImageUrlDesktop}');
  }
`;

/* Search bar */
export const search = css`
  margin: 0 auto;
  max-width: 600px;
  text-align: left;
`;
