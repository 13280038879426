import { css, SerializedStyles } from '@emotion/react';
import { variables, media, tokens, rgba } from '@domain-group/fe-brary';
import { PROFILE_TIERS } from '../../../constants';
import { avatarSizes } from '../contact-banner/contact-banner.style';

const liftBrandedAgentAvatar = 104;

const {
  colors: { neutral },
  spacing,
} = tokens;

export const details = ({
  profileTier,
}: {
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  width: 100%;
  text-align: center;

  ${media('>=tablet')} {
    display: flex;
    align-items: ${profileTier === 'basic' ? 'center' : 'flex-start'};
    text-align: left;
  }
`;

export const agentAvatarHolder = ({
  profileTier,
}: {
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  margin-bottom: ${spacing.xs};

  ${media('>=tablet')} {
    margin-bottom: 0;
    margin-right: ${spacing.m};
  }

  ${media('>=desktop')} {
    margin-right: ${spacing.l};
  }

  ${profileTier === PROFILE_TIERS.branded &&
  css`
    display: none;

    ${media('>=tablet')} {
      display: block;
    }

    ${media('>=desktop')} {
      margin-top: -${liftBrandedAgentAvatar}px;
    }
  `};
`;

export const agentAvatar = ({
  profileTier,
}: {
  profileTier: PROFILE_TIERS;
}): SerializedStyles => {
  let size = avatarSizes.brandedAgent;

  if (profileTier === PROFILE_TIERS.basic) {
    size = avatarSizes.basic;
  }

  return css`
    border: 3px solid ${neutral.surface.default};
    box-shadow:
      ${rgba(neutral.heavy.default, 0.2)} 0px 30px 60px -10px,
      ${rgba(neutral.heavy.default, 0.3)} 0px 15px 30px -20px;
    width: ${size.mobile}px;
    height: ${size.mobile}px;
    margin: 0 auto;

    ${media('>=tablet')} {
      margin: 0;
      width: ${size.tablet}px;
      height: ${size.tablet}px;
    }

    ${media('>=desktop')} {
      width: ${size.desktop}px;
      height: ${size.desktop}px;
    }

    img {
      transition: opacity 0.2s ease-out;
      display: block;
    }
  `;
};

export const name = css`
  color: ${neutral.heavy.default};
  display: inline-block;
  font-size: ${variables.font.h4MobileFontSize};
  line-height: ${variables.font.h4MobileLineHeight};
  font-weight: ${variables.font.h4FontWeight};

  ${media('>=tablet')} {
    font-size: ${variables.font.h3FontSize};
    line-height: ${variables.font.h3LineHeight};
    font-weight: ${variables.font.h3FontWeight};
  }
`;

export const agentJob = css`
  color: ${neutral.medium.default};
  font-size: ${variables.font.baseFontSize};
  line-height: ${variables.font.baseLineHeight};

  ${media('>=tablet')} {
    margin-top: ${spacing.xxs};
  }
`;

export const body = css`
  width: 100%;

  ${media('>=tablet')} {
    display: flex;
    align-items: stretch;
  }
`;

export const agentDetails = ({
  profileTier,
}: {
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  min-width: 0;
  flex-shrink: 0;
  flex-grow: 2;

  ${profileTier === PROFILE_TIERS.branded &&
  css`
    display: none;

    ${media('>=tablet')} {
      display: block;
    }
  `}
`;
