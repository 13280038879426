import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { ListingCardQuery } from '../../generated/graphql';
import ListingCardWithFragment from './listing-card-with-fragment';
import AD_COMPONENTS from '../../constants/ad-components';

export const GET_LISTING_QUERY = gql`
  query listingCard($adId: Int!) {
    listing(adId: $adId) {
      ...ListingCard
    }
  }
  ${ListingCardWithFragment.fragment}
`;

interface ListingCardWithQueryProps {
  adId: number;
}

const ListingCardWithQuery = ({
  adId,
}: ListingCardWithQueryProps): JSX.Element | null => {
  const { data, loading } = useQuery<ListingCardQuery>(GET_LISTING_QUERY, {
    variables: { adId },
  });

  if (loading || !data) {
    return null;
  }

  return (
    <ListingCardWithFragment
      adId={adId}
      adComponent={AD_COMPONENTS.ListingCard}
    />
  );
};

export default ListingCardWithQuery;
