import React from 'react';
import { gql, useQuery } from '@apollo/client';
import AgencyTopSummaryWithFragment from './agency-top-summary-with-fragment';
import { GetAgencyTopSummaryQuery } from '../../generated/graphql';

export type Props = {
  agencyId: number;
};

export const GET_AGENCY_TOP_SUMMARY_QUERY = gql`
  query getAgencyTopSummary($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      ...AgencyTopSummary
    }
  }
  ${AgencyTopSummaryWithFragment.fragment}
`;

const AgencyTopSummaryWithQuery = ({
  agencyId,
}: Props): React.ReactElement | null => {
  const { data } = useQuery<GetAgencyTopSummaryQuery>(
    GET_AGENCY_TOP_SUMMARY_QUERY,
    {
      variables: { agencyId },
    },
  );

  if (!data) {
    return null;
  }

  return <AgencyTopSummaryWithFragment agencyId={agencyId} />;
};

export default AgencyTopSummaryWithQuery;
