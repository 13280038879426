import { css, SerializedStyles } from '@emotion/react';
import { variables, media, tokens } from '@domain-group/fe-brary';
import { PROFILE_TIERS } from '../../../constants';

const {
  colors: { neutral },
  spacing,
} = tokens;

export const avatarSizes = {
  basic: {
    mobile: 80,
    tablet: 125,
    desktop: 226,
  },
  brandedAgent: {
    mobile: 100,
    tablet: 125,
    desktop: 226,
  },
  platinumAgent: {
    mobile: 100,
    tablet: 125,
    desktop: 226,
  },
  sticky: {
    mobile: 48,
    tablet: 48,
    desktop: 48,
  },
};

export const agentDetails = (
  profileTier: PROFILE_TIERS,
): SerializedStyles => css`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${profileTier === PROFILE_TIERS.branded &&
  css`
    ${media('>=tablet')} {
      display: none;
    }
  `}
`;

export const agentAvatarHolder = css`
  display: inline-block;
  margin-bottom: ${spacing.xs};
`;

export const agentAvatar = css`
  border: 3px solid ${neutral.surface.default};
  box-shadow: 0 1px 3px 0 rgba(30, 41, 61, 0.1);
  width: ${avatarSizes.platinumAgent.mobile}px;
  height: ${avatarSizes.platinumAgent.mobile}px;

  ${media('>=tablet')} {
    width: ${avatarSizes.platinumAgent.tablet}px;
    height: ${avatarSizes.platinumAgent.tablet}px;
  }

  ${media('>=desktop')} {
    width: ${avatarSizes.platinumAgent.desktop}px;
    height: ${avatarSizes.platinumAgent.desktop}px;
  }

  img {
    transition: opacity 0.2s ease-out;
    display: block;
  }
`;

export const name = css`
  color: ${neutral.surface.default};
  font-size: ${variables.font.h4FontSize};
  line-height: ${variables.font.h4LineHeight};
  font-weight: ${variables.font.h4FontWeight};

  ${media('>=tablet')} {
    font-size: ${variables.font.h3FontSize};
    line-height: ${variables.font.h3LineHeight};
    font-weight: ${variables.font.h3FontWeight};
  }
`;

export const agentJob = css`
  color: ${neutral.surface.default};
  font-size: ${variables.font.baseFontSize};
  line-height: ${variables.font.baseLineHeight};

  ${media('>=tablet')} {
    margin-top: ${spacing.xxs};
  }
`;
