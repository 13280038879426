import React from 'react';
import HighlightInput from './highlight-input';
import * as styles from './option.style';
import OptionIcon from './option-icon';
import { Option } from '../types';

export interface OptionTypeProps {
  option: Option;
  inputValue: string;
}

const createOptionType =
  (icons: boolean) =>
  ({ option, inputValue }: OptionTypeProps): JSX.Element | string => (
    <div css={styles.optionContent}>
      {icons && (
        <div css={styles.icon}>
          <OptionIcon option={option} />
        </div>
      )}
      <div css={styles.option}>
        <div css={styles.name}>
          <HighlightInput
            optionText={option.label}
            highlightText={inputValue}
          />
        </div>
        <div css={styles.optionCategory}>{option.category}</div>
      </div>
    </div>
  );

export default createOptionType;
