import React from 'react';
import { ChevronDownIconSmall } from '@domain-group/fe-co-icon';

import { IActiveSuburb } from '../../@types';
import * as styles from './controls.style';
import { Expander } from '../../../shared';
import { arrayFilterNotEmpty } from '../../../../utils';
import RadioButton from './radio-button';

export interface Props {
  id: string;
  allSuburbsOption?: IActiveSuburb;
  activeSuburbs?: IActiveSuburb[];
  selectedSuburb: string;
  setSelectedSuburb: (suburbSlug: string) => void;
}

const Controls = ({
  id,
  allSuburbsOption,
  activeSuburbs = [],
  selectedSuburb,
  setSelectedSuburb,
}: Props): JSX.Element => {
  const suburbOptions = [...[allSuburbsOption], ...activeSuburbs].filter(
    arrayFilterNotEmpty,
  );

  const onSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const selected = suburbOptions.find(
      (suburb) => suburb.suburbSlug === event.target.value,
    )?.suburbSlug;

    if (selected) {
      setSelectedSuburb(selected);
    }
  };

  const listLength = 7;

  return (
    <form>
      <fieldset css={styles.wrapper}>
        <legend css={styles.srOnly}>
          Select suburb to see sales statistics
        </legend>

        {/* Radio options - desktop */}
        <div css={styles.radioList}>
          {suburbOptions
            ?.slice(0, listLength) // break up list in visible and read more sections
            .map((suburb) => (
              <div key={`radio-${suburb.suburbSlug}`}>
                <RadioButton
                  id={id}
                  selectedSuburb={selectedSuburb}
                  suburb={suburb}
                  onSelectChange={onSelectChange}
                />
              </div>
            ))}
          {suburbOptions.length > listLength && (
            <Expander id="sales-stat-expander" labelName="suburbs">
              {suburbOptions
                ?.slice(listLength) // remainder of list for read more sections
                .map((suburb) => (
                  <div key={`radio-${suburb.suburbSlug}`}>
                    <RadioButton
                      id={id}
                      selectedSuburb={selectedSuburb}
                      suburb={suburb}
                      onSelectChange={onSelectChange}
                    />
                  </div>
                ))}
            </Expander>
          )}
        </div>

        {/* 
          Select dropdown - mobile / tablet

          On a phone/tablet this will appear as a native select control and will look different from the styles that
          are applied by us.
        */}
        <div css={styles.select}>
          <label htmlFor="statistics-select-field">
            <span css={styles.srOnly}>Select to see</span>
            <span css={styles.selectLabel}>Statistics for</span>
            <span css={styles.srOnly}> suburb</span>
            <select
              aria-controls={id}
              css={styles.dropdown}
              name="statistics suburbs"
              id="statistics-select-field"
              value={selectedSuburb || ''}
              onChange={onSelectChange}
            >
              {suburbOptions?.map((suburb) => (
                <option
                  value={suburb.suburbSlug || ''}
                  key={`select-${suburb.suburbSlug}`}
                >
                  {suburb.suburbName}
                </option>
              ))}
            </select>
          </label>
          <span css={styles.dropdownIcon}>
            <ChevronDownIconSmall />
          </span>
        </div>
      </fieldset>
    </form>
  );
};

export default Controls;
