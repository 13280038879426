import React, { useEffect, useState } from 'react';

import {
  Pill,
  Pills,
  StickyContent,
} from '@domain-group/fe-co-horizontal-dropdown-pills';
import { RefineSearchIcon, SortIcon } from '@domain-group/fe-co-icon';

import type { Choice } from '../../@types';
import { Filters, RadioTiles, SelectOption } from './pill-forms';

import * as style from './filters-bar.style';
import { EndFormButtonGroup } from './pill-forms/end-form-button-group';
import { RadioOptionGroup } from './pill-forms/radio-option-group';

export type FiltersBarProps = {
  // Choices
  bedroomsOptions?: Choice<string>[];
  propertyTypeOptions?: Choice<string>[];
  sortByOptions?: Choice<string>[];
  suburbOptions?: Choice<string>[];

  // Select functions
  selectBedrooms: (newBedrooms?: Choice<string>) => void;
  selectPropertyType: (propertyType?: Choice<string>) => void;
  selectSortBy: (sortBy?: Choice<string>) => void;
  selectSuburb: (suburb?: Choice<string>) => void;
  clearAll: () => void;

  // Currently Selected Options
  currentBedrooms?: Choice<string>;
  currentPropertyType?: Choice<string>;
  currentSortBy?: Choice<string>;
  currentSuburb?: Choice<string>;
  mobilePadding?: string | number;
};

const FiltersBar = ({
  bedroomsOptions = [],
  propertyTypeOptions = [],
  sortByOptions = [],
  suburbOptions = [],

  currentBedrooms,
  currentPropertyType,
  currentSortBy,
  currentSuburb,

  selectBedrooms,
  selectPropertyType,
  selectSortBy,
  selectSuburb,
  clearAll,

  mobilePadding,
}: FiltersBarProps): JSX.Element => {
  // State
  const [pillsOpen, setPillsOpen] = useState<true | undefined>(undefined); // `false` will cause the modal to remain open
  // State - Forms
  const [formBedrooms, setFormBedrooms] = useState(currentBedrooms);
  const [formPropertyType, setFormPropertyType] = useState(currentPropertyType);
  const [formSuburb, setFormSuburb] = useState(currentSuburb);

  // Update form fields when props change.
  useEffect(() => {
    setFormPropertyType(currentPropertyType);
    setFormBedrooms(currentBedrooms);
    setFormSuburb(currentSuburb);
  }, [currentBedrooms, currentPropertyType, currentSuburb]);

  //  Helpers
  const propertyTypePillLabel = currentPropertyType?.value
    ? `${currentPropertyType.label}`
    : `Property Type`;

  const bedroomSingular =
    currentBedrooms?.value === 'ONE' || currentBedrooms?.value === 'STUDIO';

  const bedroomPillLabel = currentBedrooms?.value
    ? `${currentBedrooms.label} Bedroom${bedroomSingular ? '' : 's'}`
    : `Bedrooms`;

  const suburbPillLabel = currentSuburb?.value
    ? currentSuburb?.label
    : `Suburb`;

  const closePill = () => {
    setPillsOpen(undefined);
  };

  // Handlers
  const handlePropertyTypeSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    closePill();
    selectPropertyType(formPropertyType);
  };
  const handleBedroomSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    closePill();
    selectBedrooms(formBedrooms);
  };
  const handleSuburbSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    closePill();
    selectSuburb(formSuburb);
  };

  const handleSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    selectBedrooms(formBedrooms);
    selectPropertyType(formPropertyType);
    selectSuburb(formSuburb);
    closePill();
  };

  return (
    <div css={style.filtersBar}>
      <h3 css={style.srOnly}>Filters bar</h3>
      <div css={style.pillsWrapper(mobilePadding)}>
        <Pills open={pillsOpen} onOpenChange={setPillsOpen}>
          <Pill
            key="allfilters"
            aria-labelledby="All filters"
            label="Filters"
            leftIcon={<RefineSearchIcon />}
            rightIcon={null}
          >
            <Filters
              bedroomsOptions={bedroomsOptions}
              clearAll={clearAll}
              currentBedrooms={formBedrooms}
              currentPropertyType={formPropertyType}
              currentSuburb={formSuburb}
              propertyTypeOptions={propertyTypeOptions}
              selectBedrooms={setFormBedrooms}
              selectPropertyType={setFormPropertyType}
              selectSuburb={setFormSuburb}
              suburbOptions={suburbOptions}
            />
            <StickyContent>
              <EndFormButtonGroup
                pillLabel="All filters"
                close={closePill}
                handleSubmit={handleSubmit}
              />
            </StickyContent>
          </Pill>
          <Pill
            key="suburb"
            label={suburbPillLabel}
            active={!!currentSuburb?.value}
          >
            <RadioOptionGroup
              title="Suburb"
              groupName="suburb-selection"
              options={suburbOptions}
              handleChange={setFormSuburb}
              current={formSuburb}
            />
            <StickyContent>
              <EndFormButtonGroup
                pillLabel="Suburb"
                close={closePill}
                handleSubmit={handleSuburbSubmit}
              />
            </StickyContent>
          </Pill>

          <Pill
            key="property-type"
            label={propertyTypePillLabel}
            active={!!currentPropertyType?.value}
          >
            <RadioOptionGroup
              title="Property type"
              groupName="property-type-selection"
              options={propertyTypeOptions}
              handleChange={setFormPropertyType}
              current={formPropertyType}
            />
            <StickyContent>
              <EndFormButtonGroup
                pillLabel="Property type"
                close={closePill}
                handleSubmit={handlePropertyTypeSubmit}
              />
            </StickyContent>
          </Pill>
          <Pill
            key="bedrooms"
            label={bedroomPillLabel}
            active={!!currentBedrooms?.value}
          >
            <RadioTiles
              title="Bedrooms"
              groupName="bedrooms"
              current={formBedrooms}
              handleChange={setFormBedrooms}
              options={bedroomsOptions}
            />
            <EndFormButtonGroup
              pillLabel="Bedrooms"
              close={closePill}
              handleSubmit={handleBedroomSubmit}
            />
          </Pill>
        </Pills>
      </div>
      <div css={style.sortByWrap}>
        <div css={style.sortBy}>
          <div css={style.sortByIcon}>
            <SortIcon size="small" />
          </div>
          <span css={style.sortByTitle}>Sort by:</span>
          <SelectOption
            instanceId="filtersortorder"
            label="sort order"
            labelHidden
            current={currentSortBy}
            handleChange={selectSortBy}
            options={sortByOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default FiltersBar;
