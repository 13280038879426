import React from 'react';
import LoadingSpinner from '@domain-group/fe-co-loader';
import * as styles from './loader.style';

const Loader = ({ absolute = false }: { absolute?: boolean }): JSX.Element => (
  <div css={styles.loader(absolute)}>
    <LoadingSpinner />
  </div>
);

export default Loader;
