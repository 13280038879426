import React from 'react';
import getConfig from 'next/config';

import Header from '@domain-group/fe-co-header';

import { mapHeaderData } from './utils';

const { publicRuntimeConfig } = getConfig();

const SearchResultsHeader = (): JSX.Element => {
  const headerData = mapHeaderData({
    baseUrl: publicRuntimeConfig.BASE_URL,
    breadcrumbsData: null,
  });

  return <Header {...headerData} />;
};

export default SearchResultsHeader;
