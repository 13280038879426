import React, { useState } from 'react';
import Link from 'next/link';
import { useReducedMotion } from 'framer-motion';
import Modal from '@domain-group/fe-co-modal';
import { srOnly } from '@domain-group/fe-brary';
import {
  SoldStatistics,
  LeaseStatistics,
  ShortStatistics,
  ContactButtons,
} from '..';
import { Header, Avatar, EnquiryForm, Details } from '../../../shared';

import * as styles from './profile-card.style';

interface ProfilePhoto {
  url?: string;
}

interface Statistics {
  totalForSale?: number | null;
  averageSoldPrice?: number | null;
  averageDaysOnMarket?: number | null;
  totalSoldAndAuctioned?: number | null;
  totalJointSoldAndAuctioned?: number | null;
  totalForRent?: number | null;
  totalLeased?: number | null;
  totalJointLeased?: number | null;
}

interface Agency {
  agencyId?: number | null;
  name?: string | null;
  branding?: {
    logo?: {
      url?: string | null;
    } | null;
    logoColour?: string | null;
  } | null;
}

export interface ProfileCardProps {
  contactId: number;
  name?: string | null;
  jobTitle?: string | null;
  hasEmail?: boolean | null;
  telephone?: string | null;
  mobile?: string | null;
  profileTier?: string | null;
  profileUrl?: string | null;
  profilePhoto?: ProfilePhoto | null;
  statistics?: Statistics | null;
  agency?: Agency | null;
  isPropertyManager?: boolean;
  hideAppraisalCta?: boolean;
  isShortStats?: boolean;
  isVertical?: boolean;
  hideOffice?: boolean;
  appraisalEntryPoint?: string;
  // Events
  onViewProfile?: ({ contactId }: { contactId: number }) => void;
  onCall?: ({ contactId }: { contactId: number }) => void;
  onCallReveal?: () => void;
  onGetAppraisal?: () => void;
  onOpenEnquiryForm?: () => void;
  // Enquiry Form events
  onEnquirySubmit?: ({
    contactId,
    intent,
    phone,
    postcode,
    message,
  }: {
    contactId: number;
    postcode: string;
    intent?: string;
    phone?: string | null;
    message?: string;
  }) => void;
  onEnquirySubmitSuccess?: ({
    contactId,
    intent,
    name,
  }: {
    contactId: number;
    intent?: string;
    name?: string | null;
  }) => void;
  onEnquirySubmitError?: ({
    contactId,
    name,
  }: {
    contactId: number;
    name?: string | null;
  }) => void;
  onEnquiryFinish?: (() => void) | undefined;
}

const ProfileCard = ({
  contactId,
  // Details
  name,
  jobTitle,
  hasEmail,
  telephone,
  mobile,
  profileUrl: agentUrl,
  profilePhoto,
  profileTier,
  statistics,
  agency,
  appraisalEntryPoint,
  hideOffice = false,
  isVertical = false,
  isPropertyManager = false,
  hideAppraisalCta = false,
  isShortStats = false,
  // Callbacks
  onViewProfile = () => null,
  onCall = () => null,
  onCallReveal = () => null,
  onGetAppraisal = () => null,
  onOpenEnquiryForm = () => null,
  onEnquirySubmit = () => null,
  onEnquirySubmitSuccess = () => null,
  onEnquirySubmitError = () => null,
  onEnquiryFinish = () => null,
}: ProfileCardProps): JSX.Element | null => {
  const [modalOpen, setModalOpen] = useState(false);
  const shouldReduceMotion = useReducedMotion();

  const { name: agencyName } = agency || {};

  const profileUrl =
    agentUrl ||
    `/real-estate-agent/${name
      ?.toLowerCase()
      .split(' ')
      .join('-')}-${contactId}`;

  const isBranded = profileTier === 'branded';
  const isPlatinum = profileTier === 'platinum';

  // Proxy for isPropertyManager
  const hasNoSales =
    !statistics?.totalForSale && !statistics?.totalSoldAndAuctioned;

  const canStretch = isPropertyManager || hasNoSales || hideAppraisalCta;

  return (
    <>
      <div css={styles.container} data-testid="profile-card">
        <Header
          logoPosition="right"
          logoColour={agency?.branding?.logoColour || undefined}
          agencyLogoUrl={agency?.branding?.logo?.url || ''}
          agencyName={agency?.name || ''}
          isVertical={isVertical}
          isBranded={isBranded}
          isPlatinum={isPlatinum}
        />
        <div
          css={styles.body({
            isVertical,
            isPlatinum,
            singleLine: canStretch,
          })}
        >
          <Avatar
            css={styles.avatar(isVertical, isPlatinum)}
            imageUrl={profilePhoto?.url}
            imageTitle={`${agency?.name} - ${name}`}
          />
          <div>
            <h3 css={styles.name(isPlatinum)}>{name}</h3>
            {jobTitle && <p css={styles.job}>{jobTitle}</p>}
            {hideOffice ? null : <p css={styles.agency}>{agency?.name}</p>}
            <Link href={profileUrl}>
              <a
                css={styles.link}
                href={profileUrl}
                onClick={() => onViewProfile({ contactId })}
              >
                <span css={srOnly}>View {name}&apos;s profile</span>
              </a>
            </Link>
          </div>
          {isShortStats && (
            <ShortStatistics
              isVertical={isVertical}
              totalForSale={statistics?.totalForSale}
              totalSoldAndAuctioned={statistics?.totalSoldAndAuctioned}
              totalJointSoldAndAuctioned={
                statistics?.totalJointSoldAndAuctioned
              }
              totalForRent={statistics?.totalForRent}
              totalLeased={statistics?.totalLeased}
              totalJointLeased={statistics?.totalJointLeased}
            />
          )}
          {!isShortStats && isPropertyManager && (
            <LeaseStatistics
              isVertical={isVertical}
              totalJointLeased={statistics?.totalJointLeased}
              totalForRent={statistics?.totalForRent}
            />
          )}
          {!isShortStats && !isPropertyManager && (
            <SoldStatistics
              isVertical={isVertical}
              averageSoldPrice={statistics?.averageSoldPrice}
              averageDaysOnMarket={statistics?.averageDaysOnMarket}
              totalSoldAndAuctioned={statistics?.totalSoldAndAuctioned}
              totalJointSoldAndAuctioned={
                statistics?.totalJointSoldAndAuctioned
              }
            />
          )}
          <div css={styles.contactButtonsWrapper(isVertical)}>
            <ContactButtons
              isVertical={isVertical}
              name={name || ''}
              hasEmail={hasEmail}
              telephone={telephone || mobile}
              agencyName={agencyName || ''}
              contactId={contactId}
              onCall={onCall}
              onCallReveal={onCallReveal}
              onGetAppraisal={onGetAppraisal}
              onOpenModal={() => {
                setModalOpen(true);
                onOpenEnquiryForm();
              }}
              isPropertyManager={isPropertyManager}
              hideAppraisalCta={hideAppraisalCta || hasNoSales}
              appraisalEntryPoint={appraisalEntryPoint}
            />
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal
          closeCallback={() => setModalOpen(false)}
          hasCloseAnimation={!shouldReduceMotion}
        >
          <div css={styles.modalContainer}>
            <EnquiryForm
              name={name}
              referenceId={contactId}
              // enquiry type needs to be agentProfile (regardless of page usage)
              enquiryType="agentProfile"
              onSubmit={(submitValues: Details) => {
                onEnquirySubmit({
                  contactId,
                  intent: submitValues?.intent,
                  phone: submitValues?.phone,
                  postcode: submitValues.postcode,
                  message: submitValues?.message,
                });
              }}
              onSubmitSuccess={(submitValues: Details) => {
                onEnquirySubmitSuccess({
                  contactId,
                  intent: submitValues?.intent,
                  name,
                });
              }}
              onSubmitError={() => {
                onEnquirySubmitError({ contactId, name });
              }}
              onFinish={() => {
                onEnquiryFinish();
                setModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProfileCard;
