import React from 'react';
import { gql, useQuery } from '@apollo/client';
import ContactSalesStatisticsStoryWithFragment from './contact-sales-statistics-story-with-fragment';
import { GetContactSalesStatisticsStoryQuery } from '../../generated/graphql';

export type Props = {
  agentIdV2: string;
  agencyId: number;
};

export const GET_CONTACT_SALES_STATISTICS_STORY_QUERY = gql`
  query getContactSalesStatisticsStory($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...ContactSalesStatisticsStory
    }
  }
  ${ContactSalesStatisticsStoryWithFragment.fragment}
`;

const ContactSalesStatisticsStoryWithQuery = ({
  agentIdV2,
  agencyId,
}: Props): JSX.Element | null => {
  const { data } = useQuery<GetContactSalesStatisticsStoryQuery>(
    GET_CONTACT_SALES_STATISTICS_STORY_QUERY,
    {
      variables: { agentIdV2, agencyId },
    },
  );

  if (!data) {
    return null;
  }

  return (
    <ContactSalesStatisticsStoryWithFragment
      agentIdV2={agentIdV2}
      agencyId={agencyId}
    />
  );
};

export default ContactSalesStatisticsStoryWithQuery;
