import { css } from '@emotion/react';
import { tokens, applyFont, media } from '@domain-group/fe-brary';

const { colors, typography, spacing } = tokens;

export const wrapper = css``;

export const title = css`
  ${applyFont(typography.heading)}
  display: block;
  align-items: center;
  color: ${colors.neutral.heavy.default};
  margin: 0;

  ${media('>=tablet')} {
    display: flex;
  }
`;

export const subtitle = css`
  color: ${colors.neutral.medium.default};
  ${applyFont(typography.body)}
  margin: ${spacing.xs} 0
    ${spacing.m};
`;
