import { css } from '@emotion/react';
import { media, applyFont, tokens } from '@domain-group/fe-brary';

export const wrapper = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media('>=tablet')} {
    flex-direction: row;
  }
`;

export const title = css`
  ${applyFont(tokens.typography.heading)};
  margin: 0;
`;

export const subtitle = css`
  ${applyFont(tokens.typography.lead)}
  margin: 0;
`;
