// import { pushToDataLayer } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../mixpanel';

// Avoid recording GA virtual page view initially
// const hasRecordedInitialPageView = false;

export default (): void => {
  // MixPanel page view
  sendToMixpanel('View FAA Search Results');

  // Google Analytics page view
  // pushToDataLayer({
  //   event: 'GAvirtualpageview',
  // });
};
