import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export const filterHeadingBase = css`
  color: ${tokens.colors.neutral.heavy.default};
`;

export const filterHeading = css`
  ${filterHeadingBase};
  font-weight: bold;
`;
export const headingSpacer = css`
  padding-bottom: ${tokens.spacing.s};
`;
