import React from 'react';
import { format, parseISO } from 'date-fns';
import * as styles from './testimonials.style';

type QuoteProps = {
  children: JSX.Element | null;
  author: string;
  dateAuthored: string;
};

const Quote = ({ children, author, dateAuthored }: QuoteProps): JSX.Element => {
  const date = format(parseISO(dateAuthored), 'MMMM yyyy');

  return (
    <blockquote css={styles.testimonial} data-testid="trade-testimonial">
      <div
        css={styles.quoteContainer}
        data-testid="trade-testimonial__quote-container"
      >
        {children}
        <div css={styles.footer} data-testid="trade-testimonial__footer">
          <p css={styles.author} data-testid="trade-testimonial__author">
            {author}
          </p>
          <p css={styles.date} data-testid="trade-testimonial__date">
            {date}
          </p>
        </div>
      </div>
    </blockquote>
  );
};

export default Quote;
