import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { handleError } from '@domain-group/fe-helper';

import AppraisalCTAWithFragment from './appraisal-cta-with-fragment';
import AppraisalCTALoader from './components/appraisal-cta-loader';
import AppraisalCTA from './components/appraisal-cta';
import { GetContactAppraisalCtaQuery } from '../../generated/graphql';

type AppraisalCTAProps = {
  contactId: number;
  agentIdV2: string;
  agencyId: number;
  url?: string;
  onClick?: () => void;
};

export const GET_CONTACT_APPRAISAL_CTA_QUERY = gql`
  query getContactAppraisalCTA($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...AppraisalCTA
    }
  }
  ${AppraisalCTAWithFragment.fragment}
`;

const AppraisalCTAWithQuery = ({
  contactId,
  agentIdV2,
  agencyId,
  url,
  onClick,
}: AppraisalCTAProps): JSX.Element | null => {
  const { data, loading, error } = useQuery<GetContactAppraisalCtaQuery>(
    GET_CONTACT_APPRAISAL_CTA_QUERY,
    {
      variables: { agentIdV2, agencyId },
    },
  );

  if (loading) {
    return <AppraisalCTALoader />;
  }

  if (error || !data) {
    handleError(error);
    return <AppraisalCTA contactId={contactId} url={url} />;
  }

  return (
    <AppraisalCTAWithFragment
      contactId={contactId}
      agencyId={agencyId}
      agentIdV2={agentIdV2}
      url={url}
      onClick={onClick}
    />
  );
};

AppraisalCTAWithQuery.query = GET_CONTACT_APPRAISAL_CTA_QUERY;

export default AppraisalCTAWithQuery;
