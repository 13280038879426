import React from 'react';
import Illustration from '@domain-group/fe-co-brand-illustrations';
import * as styles from './hero.style';
import ContentContainer from '../content-container/content-container';

const textSection = () => (
  <div css={styles.textSection}>
    <h1>
      <span>Connect with</span>1 million people who don&apos;t visit our nearest
      competitor<sup>1</sup>
    </h1>
    <p>Reach people looking to buy, not browse.</p>
  </div>
);

const imageSection = () => (
  <div css={styles.imageSection}>
    <Illustration
      type="scene"
      shape="arch"
      name="communicateWithPartner"
      size="default"
    />
  </div>
);

const Hero = (): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.contentWrapper}>
      <ContentContainer
        overrideStyles={styles.contentContainerStyles}
        first={textSection()}
        second={imageSection()}
        changeForDevice="desktop"
      />
    </div>
  </div>
);

export default Hero;
