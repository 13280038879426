import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';

const { colors, typography } = tokens;

export const enquiryFormPrivacy = css`
  ${applyFont(typography.caption)}
  margin-top: 36px;
  color: ${colors.neutral.heavy.default};
  display: inline-block;
`;

export const privacyLink = css`
  ${applyFont(typography.caption)}
`;

export const privacyLinkModal = css`
  ${applyFont(typography.caption)}
  display: inline;
`;

export const enquiryFormMoreInfo = css`
  ${applyFont(typography.caption)}
`;
