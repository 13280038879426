/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  AgencyCurrentListingsQuery,
  AgentListingsTabFragment,
  ListingFilterStatus,
} from '../../../generated/graphql';
import getListingTabData from '../utils/get-listing-tab-data';
import { Choice } from '../@types';
import { AGENCY_CURRENT_LISTING_QUERY } from '../components/listing-results/agency-listings-results';

export const useAgencyListingsResults = (variables: {
  listingStatus?: Choice<ListingFilterStatus>;
  agencyId: number;
  bedrooms?: string;
  page: number;
  propertyType?: string;
  sortBy?: string;
  suburbId?: string;
}): {
  error?: ApolloError;
  loading: boolean;
  listings?: AgentListingsTabFragment | null;
} => {
  const { listingStatus, ...otherVariables } = variables;

  const { data, loading, error } = useQuery<AgencyCurrentListingsQuery>(
    AGENCY_CURRENT_LISTING_QUERY,
    {
      variables: otherVariables,
    },
  );

  const currentListingData = data?.agency?.agentsearchListingsByAgencyId;

  // useMemo because mapping a bunch of data
  const listings = useMemo(
    () =>
      !loading && currentListingData
        ? getListingTabData(currentListingData, listingStatus)
        : undefined,
    [currentListingData, listingStatus, loading],
  );

  return {
    loading,
    listings,
    error,
  };
};
