/* eslint-disable import/prefer-default-export */
import {
  AgencyApiPropertyType,
  ListingFilters,
  ListingFilterStatus,
} from '../../../generated/graphql';
import type { Choice } from '../@types';
import { ListingFilterStatusId } from '../@types';
import { notEmpty } from '../../../utils/not-empty';
import { listingStatusChoiceMap } from './listing-status-choice-map';

type FiltersFromFilterDataArgs = {
  filterData: ListingFilters;
  listingStatus?: Choice<ListingFilterStatus>;
  initialListingStatusId?: ListingFilterStatusId;
};

export type ListingFilterResult = {
  // All the choices for the filters
  listingStatusOptions?: Choice<ListingFilterStatus>[];
  suburbSlugsOptions?: Choice<string>[];
  propertyTypesOptions?: Choice<string>[];
  sortByOptions?: Choice<string>[];
  bedroomsOptions?: Choice<string>[];
  currentListingStatus?: Choice<ListingFilterStatus>;
};

// A Map type is required because an enum such as AgencyApiPropertyType cannot be used
// for accessing properties on an Object
const propertyTypeLabelMap = new Map([
  [AgencyApiPropertyType.Apartment, 'Apartment'],
  [AgencyApiPropertyType.House, 'House'],
  [AgencyApiPropertyType.Land, 'Land'],
  [AgencyApiPropertyType.Rural, 'Rural'],
  [AgencyApiPropertyType.Commercial, 'Rural'],
]);

const bedroomChoiceMap: Record<string, Choice<string>> = {
  Studio: { label: 'Studio', value: 'STUDIO' },
  '1 Bedroom': { label: '1', value: 'ONE' },
  '2 Bedrooms': { label: '2', value: 'TWO' },
  '3 Bedrooms': { label: '3', value: 'THREE' },
  '4 Bedrooms': { label: '4', value: 'FOUR' },
  '5+ Bedrooms': { label: '5+', value: 'FIVEPLUS' },
};

export const filtersFromFilterData = ({
  filterData,
  listingStatus,
  initialListingStatusId,
}: FiltersFromFilterDataArgs): ListingFilterResult => {
  // Determine which status to use by default based on FAA
  const getDefaultListingStatus = ():
    | Choice<ListingFilterStatus>
    | undefined => {
    // Get available listing statuses
    const listingTypes = filterData?.listingSummary
      ?.map((entry) => entry?.listingType)
      .filter(notEmpty);

    // If page has been passed a listing status in the query params
    if (initialListingStatusId) {
      const defaultListingStatus: Choice<ListingFilterStatus> | undefined =
        listingStatusChoiceMap.find(
          (status) => status.id === initialListingStatusId,
        );
      if (defaultListingStatus) {
        return defaultListingStatus;
      }
    }

    const defaultListingStatus: Choice<ListingFilterStatus> | undefined =
      // Following the order of tabs, which one is in the data response
      listingStatusChoiceMap.find((status) =>
        listingTypes?.includes(status.value),
      );

    return defaultListingStatus;
  };

  // If a listingStatus has not been passed or set in current state, work out default based on return data
  const currentListingStatus = listingStatus || getDefaultListingStatus();

  const thisListingStatus = (filterData?.listingSummary || []).find(
    (summaryItem) => summaryItem?.listingType === currentListingStatus?.value,
  );

  // What listings statuses are available
  const listingStatusesInData = (filterData?.listingSummary || [])
    .map((summaryItem) => summaryItem?.listingType)
    .filter(notEmpty);

  // Get choices in the given order based on whats available
  const listingStatusOptions: Choice<ListingFilterStatus>[] =
    listingStatusChoiceMap.filter(({ value }) =>
      listingStatusesInData.includes(value),
    );

  const suburbSlugsOptions = (thisListingStatus?.suburbSummaries || [])
    .filter((suburb) => notEmpty(suburb?.suburbName))
    .map((suburb) => ({
      value: suburb?.suburbSlug || undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      label: `${suburb!.suburbName}`,
      count: suburb?.listingCount,
    }));

  const propertyTypesOptions: Choice<string>[] = (
    thisListingStatus?.propertyTypes || []
  )
    .filter(
      (propertyType): propertyType is AgencyApiPropertyType =>
        notEmpty(propertyType) && propertyTypeLabelMap.has(propertyType),
    )
    .map((propertyType) => ({
      value: propertyType,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      label: propertyTypeLabelMap.get(propertyType)!,
    }));
  propertyTypesOptions.unshift({ label: 'Any', value: undefined });

  const bedrooms = thisListingStatus?.bedrooms || [];
  const bedroomsOptions: Choice<string>[] = [...bedrooms].map(
    (bedroomNumber) => bedroomChoiceMap[bedroomNumber],
  );
  bedroomsOptions.unshift({ label: 'Any', value: undefined });

  const sortByOptions: Choice<string>[] = [
    {
      label: 'Newest',
      value: undefined,
    },
    {
      label: 'Lowest Price',
      value: 'PRICEASCENDING',
    },
    {
      label: 'Highest Price',
      value: 'PRICEDESCENDING',
    },
  ];

  return {
    bedroomsOptions,
    currentListingStatus,
    listingStatusOptions,
    propertyTypesOptions,
    sortByOptions,
    suburbSlugsOptions,
  };
};
