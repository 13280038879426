import React from 'react';
import { gql, useFragment } from '@apollo/client';

import { toGlobalId } from '../../utils';
import { AgencyAboutSectionFragment } from '../../generated/graphql';

import AboutSection from './components/about-section';
import getDefaultAgencyBio from './utils/get-default-agency-bio';
import sanitizeBio from './utils/sanitize-bio';
import getProfileTier from './utils/get-profile-tier';

const FRAGMENT_NAME = 'AgencyAboutSection';
export const AGENCY_ABOUT_SECTION_FRAGMENT = gql`
  fragment AgencyAboutSection on Agency {
    __typename
    id
    name
    description
    hasReinswBadge
    profileTier

    address {
      displayAddress
      suburb {
        suburbId
        name
      }
    }
    statistics {
      id
      forSale
      soldTotal
      forRent
      leased
    }
  }
`;

const AgencyAboutSectionWithFragment = ({
  agencyId,
  // Tracking
  onReadMore = () => undefined,
}: {
  agencyId: number;
  onReadMore?: () => void;
}): JSX.Element | null => {
  const id = toGlobalId('Agency', agencyId);
  const { data } = useFragment<AgencyAboutSectionFragment, unknown>({
    from: {
      __typename: 'Agency',
      id,
    },
    fragment: AgencyAboutSectionWithFragment.fragment,
    fragmentName: FRAGMENT_NAME,
  });

  if (!data?.name) {
    return null;
  }

  const description = data?.description
    ? sanitizeBio(data.description)
    : getDefaultAgencyBio({
        agencyName: data?.name || undefined,
        address: data?.address?.displayAddress || undefined,
        listingsTotal: {
          sale: data?.statistics?.forSale ?? 0,
          soldTotal: data?.statistics?.soldTotal ?? 0,
          lease: data?.statistics?.forRent ?? 0,
          leased: data?.statistics?.leased ?? 0,
        },
        suburb: data?.address?.suburb?.name || '',
      });

  return (
    <AboutSection
      name={data?.name}
      profileDescriptionText={description}
      isAgency
      profileTier={getProfileTier(data?.profileTier || undefined)}
      hasReinswBadge={!!data?.hasReinswBadge}
      // Tracking
      onReadMore={onReadMore}
    />
  );
};

AgencyAboutSectionWithFragment.fragment = AGENCY_ABOUT_SECTION_FRAGMENT;
AgencyAboutSectionWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyAboutSectionWithFragment;
