/**
 * From //www.domain.com.au/2012831395
 * To http://www.domain.com.au/3102-30-wellington-street-bondi-nsw-2026-2012831395
 */

// remove multiple continuous - by single -
const formatSeo = (unFormattedSeo: string): string =>
  unFormattedSeo.replace(/[^a-zA-Z0-9-]/g, '-').replace(/-+/g, '-');

export type Params = {
  displayableAddress?: string;
  state: string;
  postcode: string;
  adId: number;
};

export default ({
  displayableAddress = '',
  state,
  postcode,
  adId,
}: Params): string => {
  const seo = [state, postcode, adId];
  if (displayableAddress) {
    seo.unshift(displayableAddress);
  }
  const unFormattedSeo = seo.join('-').toLowerCase();
  const formattedSeo = formatSeo(unFormattedSeo);
  return `//www.domain.com.au/${formattedSeo}`;
};
