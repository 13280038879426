import React from 'react';
import { gql, useQuery } from '@apollo/client';
import AgencySalesStatisticsStoryWithFragment from './agency-sales-statistics-story-with-fragment';
import { GetAgencySalesStatisticsStoryQuery } from '../../generated/graphql';

export type Props = {
  agencyId: number;
};

export const GET_AGENCY_SALES_STATISTICS_STORY_QUERY = gql`
  query getAgencySalesStatisticsStory($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      ...AgencySalesStatisticsStory
    }
  }
  ${AgencySalesStatisticsStoryWithFragment.fragment}
`;

const AgencySalesStatisticsStoryWithQuery = ({
  agencyId,
}: Props): React.ReactElement | null => {
  const { data } = useQuery<GetAgencySalesStatisticsStoryQuery>(
    GET_AGENCY_SALES_STATISTICS_STORY_QUERY,
    {
      variables: { agencyId },
    },
  );

  if (!data) {
    return null;
  }

  return <AgencySalesStatisticsStoryWithFragment agencyId={agencyId} />;
};

export default AgencySalesStatisticsStoryWithQuery;
