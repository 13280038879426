/* eslint-disable react/no-danger */
import Head from 'next/head';
import React from 'react';

import type { SchemaBreadCrumbList } from '../../../../@types/schema';

export type GoogleSchemaProps = {
  breadcrumbsJSON: SchemaBreadCrumbList;
  pageSchema: unknown;
};

export const SCHEMA_ORG = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Domain',
  url: 'http://www.domain.com.au',
  sameAs: [
    'https://www.facebook.com/domain.com.au',
    'https://twitter.com/domaincomau',
    'https://www.linkedin.com/company/domain-com-au',
  ],
};

/**
 * Make the schema JSON for a view
 * by merging common schemas and page schemas
 */
const makeSchemaJSON = ({
  breadcrumbsJSON,
  pageSchema,
}: GoogleSchemaProps): string => {
  const schemaData = [SCHEMA_ORG, breadcrumbsJSON, pageSchema];

  return JSON.stringify(schemaData);
};

export const GoogleSchema = ({
  breadcrumbsJSON,
  pageSchema,
}: GoogleSchemaProps): JSX.Element => (
  <Head>
    <script
      title="google-schema"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
    ${makeSchemaJSON({ breadcrumbsJSON, pageSchema })}
  `,
      }}
    />
  </Head>
);
