import React, { ReactNode, useState } from 'react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { Button } from '@domain-group/fe-co-button';
import {
  ChevronDownIconSmall,
  ChevronUpIconSmall,
} from '@domain-group/fe-co-icon';

import * as styles from './expander.style';

export interface IProps {
  id: string;
  labelName: string;
  children: ReactNode;
}

const Expander = ({ id, labelName, children }: IProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const shouldReduceMotion = useReducedMotion();

  const animation = styles.animationSettings(shouldReduceMotion);

  return (
    <>
      <div id={`${id}_id`} role="region" aria-labelledby={`${id}_label-id`}>
        <AnimatePresence>
          {expanded && (
            <motion.div
              initial={animation.collapsed}
              animate={animation.open}
              exit={animation.collapsed}
              transition={animation.transition}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Button
        data-testid={`${id}_expand-feedback-button`}
        css={styles.expandFeedbackButton}
        type="button"
        appearance="link"
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-controls={`${id}_id`}
        id={`${id}_labelId`}
      >
        {expanded ? (
          <>
            <ChevronUpIconSmall />
            <span>View less</span>
            <span css={styles.srOnly}>{labelName}</span>
          </>
        ) : (
          <>
            <ChevronDownIconSmall />
            <span>View more</span>
            <span css={styles.srOnly}>{labelName}</span>
          </>
        )}
      </Button>
    </>
  );
};

export default Expander;
