import React from 'react';
import getConfig from 'next/config';
import { gql, useFragment } from '@apollo/client';

import Footer from '@domain-group/fe-co-footer';

import { LocationProfile } from '../../generated/graphql';

const TYPE_NAME = 'LocationProfile';
const FRAGMENT_NAME = 'SearchResultsFooter';
const FRAGMENT = gql`
  fragment SearchResultsFooter on LocationProfile {
    id
    suburbName
    urlSlug
    surroundingSuburbs {
      name
      urlSlug
    }
    data {
      apartmentsAndUnitsForSale
      blockOfUnitsForSale
      duplexesForSale
      housesForSale
      penthousesForSale
      semiDetachedHousesForSale
      studiosForSale
      terracedHousesForSale
      townhousesForSale
      villasForSale

      apartmentsAndUnitsForRent
      duplexesForRent
      housesForRent
      penthousesForRent
      semiDetachedHousesForRent
      studiosForRent
      terracedHousesForRent
      townhousesForRent
      villasForRent
    }
  }
`;

const { publicRuntimeConfig } = getConfig();

const SearchResultsFooterWithFragment = ({
  id,
}: {
  id?: string | null;
}): JSX.Element => {
  const { complete, data: locationProfile } = useFragment<
    LocationProfile,
    unknown
  >({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: SearchResultsFooterWithFragment.fragment,
    fragmentName: FRAGMENT_NAME,
  });

  if (!complete || !locationProfile) {
    return <Footer baseUrl={publicRuntimeConfig.BASE_URL} />;
  }

  /* Calm down Typescript */
  const surroundingSuburbs =
    (locationProfile.surroundingSuburbs?.filter(
      (suburb) => !!suburb?.urlSlug && !!suburb?.name,
    ) as {
      urlSlug: string;
      name: string;
    }[]) ?? undefined;

  return (
    <Footer
      baseUrl={publicRuntimeConfig.BASE_URL}
      suburb={{
        name: locationProfile?.suburbName ?? '',
        slug: locationProfile?.urlSlug ?? '',
      }}
      surroundingSuburbs={surroundingSuburbs}
      sale={{
        apartmentsAndUnits: !!locationProfile.data?.apartmentsAndUnitsForSale,
        blockOfUnits: !!locationProfile.data?.blockOfUnitsForSale,
        duplexes: !!locationProfile.data?.duplexesForSale,
        houses: !!locationProfile.data?.housesForSale,
        penthouses: !!locationProfile.data?.penthousesForSale,
        semiDetachedHouses: !!locationProfile.data?.semiDetachedHousesForSale,
        studios: !!locationProfile.data?.studiosForSale,
        terracedHouses: !!locationProfile.data?.terracedHousesForSale,
        townhouses: !!locationProfile.data?.townhousesForSale,
        villas: !!locationProfile.data?.villasForSale,
      }}
      rent={{
        apartmentsAndUnits: !!locationProfile.data?.apartmentsAndUnitsForRent,
        duplexes: !!locationProfile.data?.duplexesForRent,
        houses: !!locationProfile.data?.housesForRent,
        penthouses: !!locationProfile.data?.penthousesForRent,
        semiDetachedHouses: !!locationProfile.data?.semiDetachedHousesForRent,
        studios: !!locationProfile.data?.studiosForRent,
        terracedHouses: !!locationProfile.data?.terracedHousesForRent,
        townhouses: !!locationProfile.data?.townhousesForRent,
        villas: !!locationProfile.data?.villasForRent,
      }}
    />
  );
};

SearchResultsFooterWithFragment.fragment = FRAGMENT;

export default SearchResultsFooterWithFragment;
