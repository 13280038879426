import React, { useEffect } from 'react';
import Head from 'next/head';
import { initMixpanel, domExists } from '@domain-group/fe-helper';
import { getDeviceType } from '@domain-group/fe-brary';
import mixpanel from 'mixpanel-browser';
import {
  Hero,
  WhyDomain,
  SpeakToAgent,
  DomainForOwners,
  SellingGuide,
  Footer,
} from './components';
import tracking from '../../tracking/seller-landing-page/tracking';
import * as styles from './landing-page.style';

type SellerLandingProps = {
  baseUrl?: string;
  sessionId?: string;
  userId?: string;
  mixpanelToken: string;
};

const SellerLanding = ({
  baseUrl,
  sessionId,
  userId,
  mixpanelToken,
}: SellerLandingProps): JSX.Element => {
  useEffect(() => {
    if (!mixpanelToken) {
      return;
    }
    initMixpanel(mixpanel, mixpanelToken, {
      userId,
      sessionToken: sessionId,
    });
    if (domExists()) {
      const screenWidth = window?.screen?.width;
      const deviceCategory = screenWidth
        ? getDeviceType(screenWidth)
        : 'No Screen';
      mixpanel.register({
        siteSection: 'Owners',
        deviceCategory,
      });
    }
  }, [mixpanelToken, sessionId, userId]);

  const { trackFindLocalAgent, trackAppraisalFunnel, trackStartReading } =
    tracking();

  return (
    <main css={styles.main} data-theme-name="domain-2022">
      <Head>
        <link
          rel="stylesheet"
          href="https://s.domainstatic.com.au/design-tokens/latest/css/domain-2022.css"
        />
      </Head>
      <div>
        <Hero />
        <WhyDomain />
        <SpeakToAgent
          baseUrl={baseUrl}
          trackFindLocalAgent={trackFindLocalAgent}
          trackAppraisalFunnel={trackAppraisalFunnel}
        />
        <DomainForOwners
          baseUrl={baseUrl}
          trackAppraisalFunnel={trackAppraisalFunnel}
        />
        <SellingGuide baseUrl={baseUrl} trackStartReading={trackStartReading} />
        <Footer />
      </div>
    </main>
  );
};

export default SellerLanding;
