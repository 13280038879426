import React from 'react';
import { gql, useFragment } from '@apollo/client';
import getConfig from 'next/config';
import Header from '@domain-group/fe-co-header';

import { mapContactBreadcrumbs, mapHeaderData } from './utils';
import { ContactHeaderFragment } from '../../generated/graphql';

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactHeader';
const FRAGMENT = gql`
  fragment ContactHeader on Contact {
    id
    agentIdV2
    agencyId
    name
    profileUrl
    profileTier
    address {
      displayAddress
      suburb {
        id
        suburbId
        name
        slug
      }
    }
    statistics {
      totalForSale
      totalSoldAndAuctioned
      totalForRent
      totalLeased
    }
  }
`;

const ContactProfileHeaderWithFragment = ({
  agentIdV2,
  agencyId,
}: {
  agentIdV2: string;
  agencyId: number;
}): JSX.Element => {
  const { data: contact } = useFragment<ContactHeaderFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactProfileHeaderWithFragment.fragment,
    fragmentName: ContactProfileHeaderWithFragment.fragmentName,
  });

  const { publicRuntimeConfig } = getConfig();
  const baseUrl = publicRuntimeConfig.BASE_URL;

  const headerData = mapHeaderData({
    baseUrl,
    breadcrumbsData: mapContactBreadcrumbs({
      baseUrl,
      profileUrl: contact?.profileUrl ?? undefined,
      name: contact?.name ?? undefined,
      suburbName: contact?.address?.suburb?.name,
      suburbSlug: contact?.address?.suburb?.slug ?? undefined,
      statistics: contact?.statistics,
    }),
  });

  return (
    <div data-testid="header">
      <Header
        {...headerData}
        appearance={
          contact?.profileTier === 'platinum' ? 'transparent' : undefined
        }
      />
    </div>
  );
};

ContactProfileHeaderWithFragment.fragment = FRAGMENT;
ContactProfileHeaderWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactProfileHeaderWithFragment;
