import React from 'react';
import * as styles from './badge.style';

const Badge = ({
  badgeCount,
  isActive = false,
}: {
  badgeCount: number;
  isActive: boolean;
}): JSX.Element => (
  <span aria-hidden="true" css={styles.countpin(isActive)}>
    {badgeCount}
  </span>
);

export default Badge;
