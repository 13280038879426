import React from 'react';
import Head from 'next/head';

export type MetaTagsProps = {
  noIndexFollow: boolean;
  description?: string;
};

export const MetaTags = ({
  noIndexFollow,
  description,
}: MetaTagsProps): JSX.Element => (
  <>
    <Head>
      <meta name="description" content={description} key="description" />
    </Head>
    {noIndexFollow ? (
      <Head>
        <meta name="robots" content="noindex, follow" key="robots" />
      </Head>
    ) : (
      ''
    )}
  </>
);
