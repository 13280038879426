import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * Get the path of a static file in the public folder (deployed to S3 for environments other than local)
 * @param {string} key
 * @returns {string}
 */
const getPublicUrl = (path: string): string =>
  `${publicRuntimeConfig.PUBLIC_STATIC_PREFIX}${path}`;

export default getPublicUrl;
