import numeral from 'numeral';

export default (number: number): string => {
  if (number < 999500) {
    return numeral(number).format('$0a');
  }
  if (number >= 999500 && number < 1000000) {
    return '$1.0m';
  }

  return numeral(number).format('$0.0a');
};
