import { css, SerializedStyles } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';
import { HERO_SECTION, PROFILE_TIERS } from '../../../constants';

import { stickyBarHeight } from '../../sticky-bar/sticky-bar.style';

const { spacing } = tokens;

// eslint-disable-next-line import/prefer-default-export
export const agencyLogo = ({
  heroSection,
  profileTier,
  isActive,
  isAgency,
}: {
  heroSection: HERO_SECTION;
  profileTier: PROFILE_TIERS;
  isActive: boolean;
  isAgency: boolean;
}): SerializedStyles => css`
  display: block;
  margin-bottom: ${spacing.xs};
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
  }

  ${profileTier === PROFILE_TIERS.platinum && isAgency
    ? css`
        max-width: 200px;
        max-height: 200px;
        margin: 0 auto;
      `
    : css`
        ${heroSection === HERO_SECTION.section &&
        css`
          ${isAgency
            ? css`
                max-width: 100px;
                max-height: ${stickyBarHeight}px;
                margin: 0 auto ${spacing.xs} auto;

                ${media('>=tablet')} {
                  max-width: 200px;
                  max-height: 200px;
                  margin: 0 0 ${spacing.m} 0;
                }
              `
            : css`
                display: inline-block;
                max-width: 100px;
                max-height: ${stickyBarHeight}px;
              `};
        `};
      `}

  ${heroSection === HERO_SECTION.sticky &&
  css`
    max-width: 100px;
    max-height: ${stickyBarHeight}px;
    margin-bottom: 0;
    margin-right: ${spacing.s};
    transition:
      max-width 0.4s ease-in-out,
      max-height 0.4s ease-in-out;

    ${isActive &&
    css`
      max-width: 100px;
    `};

    ${profileTier === PROFILE_TIERS.platinum &&
    css`
      max-width: 100px;

      ${media('>=tablet')} {
        max-width: 200px;

        ${isActive &&
        css`
          max-width: 100px;
        `};
      }
    `};
  `};

  ${profileTier === PROFILE_TIERS.branded &&
  css`
    ${media('>=tablet')} {
      margin-top: -${24 + 70 + 4}px; /* Padding + Wrapper Height + Underline */
    }
  `};
`;
