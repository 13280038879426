import React from 'react';
import { gql, useQuery } from '@apollo/client';

import AgencyProfileMapWithFragment from './agency-profile-map-with-fragment';

export const GET_MAP_QUERY = gql`
  query getAgencyMapData($agencyId: Int) {
    agency(agencyId: $agencyId){
      ...${AgencyProfileMapWithFragment.fragmentName}
    }
  }
  ${AgencyProfileMapWithFragment.fragment}
`;

const AgencyProfileMapWithQuery = ({
  agencyId,
}: {
  agencyId: number;
}): JSX.Element | null => {
  const { data } = useQuery(GET_MAP_QUERY, {
    variables: { agencyId },
  });

  if (!data) {
    return null;
  }

  return <AgencyProfileMapWithFragment agencyId={agencyId} />;
};

export default AgencyProfileMapWithQuery;
