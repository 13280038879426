const uniqBy = (arr, predicate) => [
  ...arr
    .reduce((map, item) => {
      const key = item === null || item === undefined ? item : predicate(item);

      if (!map.has(key)) {
        map.set(key, item);
      }

      return map;
    }, new Map())
    .values(),
];

export default uniqBy;
