import { css } from '@emotion/react';
import { tokens, media, applyFont } from '@domain-group/fe-brary';

export const ultimateGuides = css``;

export const wrapper = css`
  display: grid;
  grid-column-gap: ${tokens.spacing.s};
  grid-row-gap: ${tokens.spacing.s};
  grid-auto-rows: auto;
  grid-template-columns: 1fr;

  ${media('>=tablet')} {
    grid-template-columns: 1fr 1fr;
  }

  ${media('>=desktop')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const titleWrapper = css`
  display: flex;
`;

export const title = css`
  ${applyFont(tokens.typography.subheading)}
  color: ${tokens.colors.neutral.heavy.default};
  margin-bottom: ${tokens.spacing.m};

  ${media('>=tablet')} {
    margin-right: ${tokens.spacing.s};
    margin-bottom: ${tokens.spacing.m};
  }
`;

export const viewMore = (isTop = true) => css`
  display: inline-block;
  color: ${tokens.colors.interactive.base.default};
  ${applyFont(tokens.typography.body)}
  text-decoration: none;

  &:hover {
    color: ${tokens.colors.interactive.base.hover};
  }

  ${isTop
    ? css`
        ${media('<tablet')} {
          display: none;
        }
      `
    : css`
        margin-top: ${tokens.spacing.m};

        ${media('>=tablet')} {
          display: none;
        }
      `}
`;
