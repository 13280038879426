import React, { ReactElement } from 'react';

export interface PaginationSeoParams {
  paginationSEOLinks?: {
    prev?: string;
    next?: string;
  };
}

const paginationSeo = ({
  paginationSEOLinks,
}: PaginationSeoParams): ReactElement => (
  <>
    {paginationSEOLinks && paginationSEOLinks.next && (
      <link
        title="pagination-seo-next"
        rel="next"
        href={paginationSEOLinks.next}
      />
    )}
    {paginationSEOLinks && paginationSEOLinks.prev && (
      <link
        title="pagination-seo-prev"
        rel="prev"
        href={paginationSEOLinks.prev}
      />
    )}
  </>
);

export default paginationSeo;
