import { pushToDataLayer } from '@domain-group/fe-helper';

interface TrackSubmitEnquiryFailureOptions {
  isAgency: boolean;
  agencyId?: string;
  agencyName?: string;
  contactId?: number;
  contactName?: string;
}

const trackEnquirySubmitError = ({
  isAgency,
  agencyId,
  agencyName,
  contactId,
  contactName,
}: TrackSubmitEnquiryFailureOptions): void => {
  const eventLabel = isAgency
    ? `${agencyName} - ${agencyId}`
    : `${contactName} - ${contactId}`;

  pushToDataLayer({
    event: 'GAevent',
    eventAction: 'Email Enquiry Unsuccessful',
    eventCategory: 'Interaction',
    eventLabel,
  });
};

export default trackEnquirySubmitError;
