import React from 'react';

export type PluralPropertiesProps = {
  propertyNum: number;
  prefix?: string;
  postfix?: string;
  highlight?: boolean;
};

const PluralProperties = ({
  propertyNum,
  prefix = '',
  postfix = '',
  highlight = false,
}: PluralPropertiesProps): JSX.Element => {
  const property = propertyNum > 1 ? 'properties' : 'property';
  const HighlightWrap = highlight ? 'strong' : 'span';

  return (
    <HighlightWrap>
      {prefix}
      {propertyNum} {property}
      {postfix}
    </HighlightWrap>
  );
};

export default PluralProperties;
