import React from 'react';

import * as styles from './no-listing-message.style';
import { Image } from '../../../../shared';

const NoListingsMessage = (): JSX.Element => (
  <div
    css={styles.listingsNoResults}
    data-testid="listings-tabs__listings-no-results"
  >
    <Image src="/no-listings-illustration.png" alt="No matches" />
    <p
      css={styles.listingsNoResultsTitle}
      data-testid="listings-tabs__listings-no-results-title"
    >
      No exact matches
    </p>
    <p>Change your search filters for more results</p>
  </div>
);

export default NoListingsMessage;
