import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Loading } from './components';
import ContactSalesStatisticsWithFragment from './contact-sales-statistics-with-fragment';

export const GET_CONTACT_SALES_STATISTICS_QUERY = gql`
  query contactByAgencyIdAndAgentId($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...ContactProfileSalesStatistics
    }
  }
  ${ContactSalesStatisticsWithFragment.fragment}
`;

export type ContactSalesStatisticsWithFragmentProps = {
  agencyId: number;
  agentIdV2: string;
  tracking?: {
    onSuburbChange?: (suburbName: string) => void;
  };
};

const ContactSalesStatisticsWithQuery = ({
  agencyId,
  agentIdV2,
  tracking,
}: ContactSalesStatisticsWithFragmentProps): JSX.Element | null => {
  const { data, loading, error } = useQuery(
    GET_CONTACT_SALES_STATISTICS_QUERY,
    {
      variables: { agencyId, agentIdV2 },
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return null;
  }

  return (
    <ContactSalesStatisticsWithFragment
      agencyId={agencyId}
      agentIdV2={agentIdV2}
      tracking={tracking}
    />
  );
};

export default ContactSalesStatisticsWithQuery;
