import React from 'react';
import getConfig from 'next/config';
import GuideCard from './components/guide-card/guide-card';
import * as styles from './ultimate-guide.style';
import { trackViewMore } from '../../../../tracking/landing-page';
import { getPublicUrl } from '../../../../utils';

const list = [
  {
    title: 'Selling',
    subTitle: 'How and when to sell your home',
    imageURL: '/ultimate-guide/selling.jpeg',
    articleLink: '/advice/selling',
  },
  {
    title: 'Buying',
    subTitle: 'Buying Property: Tips, Guides & Advice',
    imageURL: '/ultimate-guide/selling-choose-agent.jpeg',
    articleLink: '/advice/buying',
  },
  {
    title: 'Investing',
    subTitle: 'The ultimate guide to property investing',
    imageURL: '/ultimate-guide/investing.jpeg',
    articleLink: '/advice/investing',
  },
  {
    title: 'Renting',
    subTitle: 'Renting A Home: Renting Tips, Guides & Advice',
    imageURL: '/ultimate-guide/renting.jpeg',
    articleLink: '/advice/renting',
  },
  {
    title: 'Renovating',
    subTitle: 'House Renovation Hints, Tips & Guides',
    imageURL: '/ultimate-guide/selling-value.jpeg',
    articleLink: '/advice/renovating',
  },
  {
    title: 'Building',
    subTitle: 'Building A House: Ideas, Tips & Advice',
    imageURL: '/ultimate-guide/building.jpeg',
    articleLink: '/advice/building',
  },
];

const guideInfo = {
  viewMore: 'More guides, tips and strategies',
  link: '/advice/',
};

const UltimateGuide = (): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  const baseUrlWithProtocol = publicRuntimeConfig?.BASE_URL;

  return (
    <div css={styles.ultimateGuides}>
      <div css={styles.titleWrapper}>
        <h3 css={styles.title}>Advice, guides and tips</h3>
        <a
          target="_blank"
          rel="noreferrer"
          css={styles.viewMore()}
          href={`${baseUrlWithProtocol}${guideInfo.link}`}
          onClick={() => trackViewMore()}
        >
          {guideInfo.viewMore}
        </a>
      </div>
      <ul css={styles.wrapper}>
        {list.map((item) => (
          <li key={item.subTitle}>
            <GuideCard
              title={item.title}
              subTitle={item.subTitle}
              imageURL={getPublicUrl(item.imageURL)}
              articleLink={item.articleLink}
            />
          </li>
        ))}
      </ul>
      <a
        target="_blank"
        rel="noreferrer"
        css={styles.viewMore(false)}
        href={`${baseUrlWithProtocol}${guideInfo.link}`}
      >
        {guideInfo.viewMore}
      </a>
    </div>
  );
};

export default UltimateGuide;
