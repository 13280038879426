import { css } from '@emotion/react';
import { media, tokens, applyFont } from '@domain-group/fe-brary';

const {
  colors: { neutral },
  spacing,
  typography,
} = tokens;

/* eslint-disable-next-line import/prefer-default-export */
export const agencyNameLarge = css`
  ${applyFont(typography.subheading)}
  color: ${neutral.surface.default};
  margin-bottom: ${spacing.xs};

  ${media('>=tablet')} {
    ${applyFont(typography.heading)}
  }
`;
