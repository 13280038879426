import { css } from '@emotion/react';

export const loadingWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
`;

export const loadingElement = css``;
