import React from 'react';

import { AgencyLogo, AgencyAddress } from '../..';

import * as styles from './agency-details-sticky.style';
import { HERO_SECTION, PROFILE_TIERS } from '../../../constants';

export type Props = {
  profileTier: PROFILE_TIERS;
  agencyName: string;
  agencyUrl: string;
  imageUrl: string;
  address: string;
  isActive?: boolean;
  isAgency?: boolean;
  hideOfficeLocation?: boolean;
  onAgencyAddressClick: () => void;
  onAgencyLogoClick?: () => void;
};

const heroSection = HERO_SECTION.sticky;

const AgencyDetailsSticky = ({
  profileTier,
  agencyName,
  agencyUrl,
  imageUrl,
  address,
  isActive = false,
  isAgency = true,
  hideOfficeLocation = false,
  onAgencyAddressClick = () => undefined,
  onAgencyLogoClick = () => undefined,
}: Props): JSX.Element => (
  <div
    css={styles.agencyDetails({
      heroSection,
    })}
    data-testid="agency-details"
  >
    {imageUrl && (
      <AgencyLogo
        heroSection={heroSection}
        profileTier={profileTier}
        agencyUrl={agencyUrl}
        imageUrl={imageUrl}
        agencyName={agencyName}
        isAgency={isAgency}
        isActive={isActive}
        onAgencyLogoClick={onAgencyLogoClick}
      />
    )}
    <div
      css={styles.body({
        heroSection,
        isActive,
      })}
      data-testid={`trade-profile-hero-${heroSection}__agency-details-body`}
    >
      <p
        css={styles.agencyName({
          heroSection,
          profileTier,
        })}
        data-testid={`trade-profile-hero-${heroSection}__name`}
      >
        {agencyName}
      </p>
      {!hideOfficeLocation && (
        <AgencyAddress
          heroSection={heroSection}
          address={address}
          onAgencyAddressClick={onAgencyAddressClick}
        />
      )}
    </div>
  </div>
);

export default AgencyDetailsSticky;
