import React from 'react';
import SocialLink from './social-link';

import * as styles from './social.style';

export type Props = {
  inBanner?: boolean;
  isPlatinum?: boolean;
  web?: string | null;
  video?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  linkedIn?: string | null;
  instagram?: string | null;
  youtube?: string | null;
  onSocialClick?: ({
    eventLabel,
    eventAction,
  }: {
    eventLabel: string;
    eventAction: string;
  }) => void;
  isAgency: boolean;
};

const Social = ({
  inBanner = true,
  isPlatinum = false,
  web,
  video,
  facebook,
  twitter,
  linkedIn,
  instagram,
  youtube,
  onSocialClick = () => undefined,
  isAgency,
}: Props): JSX.Element => {
  const belowStuff = !!(inBanner && isPlatinum) || !inBanner;

  return (
    <ul
      css={styles.socialLinks(inBanner, belowStuff)}
      data-testid="trade-profile-social__social-links"
    >
      {web && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={web}
          key="web"
          iconName="link"
          eventLabel={web}
          eventAction={isAgency ? 'Globe' : 'personal website'}
          target="_blank"
        />
      )}
      {video && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={video}
          key="video"
          iconName="video"
          eventLabel={video}
          eventAction="Video"
          target="_self"
        />
      )}
      {facebook && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={facebook}
          key="facebook"
          iconName="facebook"
          eventLabel={facebook}
          eventAction="Facebook"
          target="_blank"
        />
      )}
      {instagram && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={instagram}
          key="instagram"
          iconName="instagram"
          eventLabel={instagram}
          eventAction="Instagram"
          target="_blank"
        />
      )}
      {youtube && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={youtube}
          key="youtube"
          iconName="youtube"
          eventLabel={youtube}
          eventAction="Youtube"
          target="_blank"
        />
      )}
      {twitter && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={twitter}
          key="twitter"
          iconName="twitter"
          eventLabel={twitter}
          eventAction="Twitter"
          target="_blank"
        />
      )}
      {linkedIn && (
        <SocialLink
          inBanner={inBanner}
          onClick={onSocialClick}
          url={linkedIn}
          key="linkedIn"
          iconName="linkedin"
          eventLabel={linkedIn}
          eventAction="LinkedIn"
          target="_blank"
        />
      )}
    </ul>
  );
};

export default Social;
