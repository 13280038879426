/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Radio } from '@domain-group/fe-co-form-elements';

import type { Choice } from '../../../@types';

import * as style from './radio-option-group.style';

export type RadioOptionGroupProps = {
  current?: Choice<string>;
  title?: string;
  groupName: string;
  handleChange: (newChoice?: Choice<string>) => void;
  options?: Choice<string>[];
};
export const RadioOptionGroup = ({
  current,
  title,
  groupName,
  handleChange,
  options = [],
}: RadioOptionGroupProps): JSX.Element => (
  <div
    aria-labelledby={`label_${groupName}`}
    aria-controls="listing-results"
    css={style.radioGroup}
    role="radiogroup"
    tabIndex={0}
  >
    <div
      css={[style.filterHeading, style.headingSpacer]}
      data-testid="listing-tabs__filters-title"
      id={`label_${groupName}`}
    >
      {title}
    </div>
    {options?.map((choice) =>
      choice ? (
        <div
          key={`radio-form__${choice.value}`}
          css={style.radioLine}
          data-testid={`${groupName}_${choice.value}`}
        >
          <Radio
            aria-checked={current?.value === choice?.value}
            aria-label={choice.label}
            checked={current?.value === choice?.value}
            id={`${groupName}_${choice.value}`}
            labelContent={
              <div
                css={
                  current?.value === choice?.value
                    ? style.selectedLabel
                    : undefined
                }
              >
                {' '}
                {choice.label}{' '}
              </div>
            }
            name={groupName}
            onChange={() => {
              handleChange(choice);
            }}
            tabIndex={-1}
            value={choice.value}
          />
          {choice?.count ? (
            <span css={style.badge(current?.value === choice?.value)}>
              {choice.count}
            </span>
          ) : null}
        </div>
      ) : null,
    )}
  </div>
);
