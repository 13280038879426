import React, { ReactNode } from 'react';

import * as styles from './layout.styles';

interface TwoColumnLayoutProps {
  aside?: ReactNode;
  children?: ReactNode;
  marginBottom?: 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}

const TwoColumnLayout = ({
  aside,
  children,
  marginBottom,
}: TwoColumnLayoutProps): JSX.Element => (
  <div css={styles.twoColumnWrapper(marginBottom)}>
    <div css={styles.main}>{children}</div>
    {aside && (
      <aside css={styles.aside}>
        <div css={styles.stickyAside}>{aside}</div>
      </aside>
    )}
  </div>
);

export default TwoColumnLayout;
