import React from 'react';
import getConfig from 'next/config';
import { urlUtil } from '@domain-group/fe-helper';

import { LinkButton } from '@domain-group/fe-co-button';
import { h5Tag } from '@domain-group/fe-brary';

import * as styles from './appraisal-request-card.styles';

const AppraisalRequestCard = (): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  const baseUrlWithProtocol = publicRuntimeConfig?.BASE_URL;

  const entryPoint = 'Agency search results';

  return (
    <article css={styles.card}>
      <svg
        width="236px"
        height="101px"
        viewBox="0 0 236 101"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        css={styles.image}
      >
        <title>
          Illustration of a house with a magnifying glass highlighting price
        </title>
        <defs>
          <path
            d="M23.1600089,5.43003124 C32.9253585,5.43003124 40.8706039,13.3470168 40.8706039,23.0776328 C40.8706039,32.8082487 32.9253585,40.7252343 23.1600089,40.7252343 C13.3946592,40.7252343 5.44941385,32.8082487 5.44941385,23.0776328 C5.44941385,13.3470168 13.3946592,5.43003124 23.1600089,5.43003124 M37.475619,41.1813569 L49.8430638,53.5048128 C50.3743816,54.0342408 51.0719066,54.3003124 51.7694316,54.3003124 C52.4669566,54.3003124 53.1644815,54.0342408 53.6957994,53.5048128 C54.7611598,52.4432417 54.7611598,50.7273518 53.6957994,49.6657807 L41.3283546,37.3423248 C44.4454194,33.4136972 46.3200177,28.4615087 46.3200177,23.0776328 C46.3200177,10.3523546 35.9307102,0 23.1600089,0 C10.3893075,0 0,10.3523546 0,23.0776328 C0,35.802911 10.3893075,46.1552655 23.1600089,46.1552655 C28.5658274,46.1552655 33.5329681,44.2873348 37.475619,41.1813569 Z"
            id="path-1"
          />
          <filter
            x="-3.7%"
            y="-3.8%"
            width="111.1%"
            height="111.2%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="1.0368"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset
              dx="2"
              dy="2"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            />
            <feComposite
              in="shadowOffsetOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowOffsetOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.901960784   0 0 0 0 0.91372549   0 0 0 0 0.929411765  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
            />
          </filter>
          <filter
            x="-3.7%"
            y="-3.8%"
            width="111.1%"
            height="111.2%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <feOffset
              dx="2"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetInner1"
            />
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
              result="shadowInnerInner1"
            />
            <feColorMatrix
              values="0 0 0 0 0.952941176   0 0 0 0 0.964705882   0 0 0 0 0.97254902  0 0 0 1 0"
              type="matrix"
              in="shadowInnerInner1"
            />
          </filter>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Owner's-card-flow-01"
            transform="translate(-2494.000000, -1542.000000)"
          >
            <g
              id="login-screen"
              transform="translate(2424.000000, 1393.000000)"
            >
              <g
                id="illustration-login-driver-sharing"
                transform="translate(69.000000, 149.000000)"
              >
                <g transform="translate(0.648612, 0.000000)">
                  <g
                    id="BLOCK-1"
                    transform="translate(20.016000, -60.408000)"
                  />
                  <g id="big-house" transform="translate(0.577224, 0.000000)">
                    <path
                      d="M179.854164,0 C195.163498,-2.8122789e-15 207.574164,12.4106667 207.574164,27.72 C207.574164,32.8438554 206.183967,37.643013 203.760184,41.7608626 L207.934164,41.76 C223.243498,41.76 235.654164,54.1706667 235.654164,69.48 C235.654164,84.7893333 223.243498,97.2 207.934164,97.2 L56.0141643,97.2 C40.704831,97.2 28.2941643,84.7893333 28.2941643,69.48 C28.2941643,64.3565594 29.684136,59.5577642 32.107556,55.4401377 L27.9341643,55.44 C12.624831,55.44 0.214164306,43.0293333 0.214164306,27.72 C0.214164306,12.4106667 12.624831,2.8122789e-15 27.9341643,0 L179.854164,0 Z"
                      id="Combined-Shape"
                      fill="#C8ECED"
                      opacity="0.8"
                    />
                    <g id="House" transform="translate(22.695297, 31.409240)">
                      <polygon
                        id="Path-6"
                        fill="#F3F6F8"
                        points="17.9636461 68.1406531 152.434368 68.1406531 152.434368 30.2243469 158.172025 30.2243469 158.172025 24.5764356 145.762157 6.14802885 63.1277909 6.14802885 50.8114561 0.512335738 35.9272922 7.17270033 35.9272922 2.56167869 27.2020926 2.56167869 27.2020926 11.2713862 22.0696223 14.3454007 24.6358575 20.4934295 27.2020926 20.4934295 27.2020926 35.3511659 27.2020926 58.9186098 17.9636461 58.9186098"
                      />
                      <polygon
                        id="Fill-1"
                        fill="#FAFBFC"
                        points="66.722114 67.6283174 151.921121 67.6283174 151.921121 20.4934295 66.722114 20.4934295"
                      />
                      <polygon
                        id="Stroke-2"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="66.722114 67.6283174 151.921121 67.6283174 151.921121 29.7154728 66.722114 29.7154728"
                      />
                      <polygon
                        id="Fill-4"
                        fillOpacity="0.4"
                        fill="#7E8693"
                        points="75.9605606 37.9128446 150.894627 37.9128446 150.894627 29.7154728 75.9605606 29.7154728"
                      />
                      <polygon
                        id="Fill-9"
                        fill="#FFFFFF"
                        points="82.119525 53.2829167 106.755382 53.2829167 106.755382 34.8388302 82.119525 34.8388302"
                      />
                      <polygon
                        id="Stroke-11"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="82.119525 53.2829167 106.755382 53.2829167 106.755382 34.8388302 82.119525 34.8388302"
                      />
                      <path
                        d="M83.146019,40.3585275 C85.8855547,39.9448601 89.1507261,40.0418635 93.334008,41.9009619 C97.283674,43.6550402 102.4497,43.238166 105.728888,41.5947198 L105.728888,35.8635016 L83.146019,35.8635016 L83.146019,40.3585275 Z"
                        id="Fill-12"
                        fillOpacity="0.4"
                        fill="#7E8693"
                      />
                      <path
                        d="M150.095273,24.5921154 L56.1471399,24.5921154 C55.0530532,24.5921154 54.1512196,23.7340645 54.0968155,22.6413313 L53.4849798,10.3523157 C53.4286027,9.21995278 54.3008615,8.25628854 55.4332244,8.19991136 C55.4672247,8.19821858 55.5012618,8.1973718 55.5353043,8.1973718 L141.737339,8.1973718 C142.416995,8.1973718 143.052595,8.53376132 143.434816,9.09575725 L151.79275,21.3847728 C152.430351,22.3222638 152.187242,23.599128 151.249751,24.2367289 C150.909275,24.4682916 150.507031,24.5921154 150.095273,24.5921154 Z"
                        id="Fill-13"
                        fill="#FFCEC4"
                      />
                      <polyline
                        id="Stroke-14"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="63.1293848 6.14802885 145.903294 6.14802885 158.080086 24.5921154 57.2734217 24.5921154"
                      />
                      <path
                        d="M152.938064,31.5153325 L78.0200779,31.7581409 C76.8863183,31.7618154 75.9642459,30.8457004 75.9605714,29.7119409 C75.9605642,29.7097232 75.9605606,29.7075054 75.9605606,29.7052876 L75.9605606,28.6943224 C75.9605606,27.5605569 76.8796591,26.6414584 78.0134246,26.6414584 L152.925305,26.6414584 C154.052704,26.6414584 154.969144,27.5506429 154.978104,28.6780059 L154.98421,29.4461629 C154.993221,30.5798925 154.081457,31.5062671 152.947727,31.5152785 C152.944506,31.5153041 152.941285,31.5153221 152.938064,31.5153325 Z"
                        id="Fill-15"
                        fill="#FFCEC4"
                      />
                      <polygon
                        id="Stroke-16"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="158.080086 29.4593049 60.5631497 29.7154728 60.5631497 24.5921154 158.032356 24.5921154"
                      />
                      <path
                        d="M73.9077587,68.6542825 L31.8211297,68.6542825 C30.1204815,68.6542825 28.7418337,67.2756347 28.7418337,65.5749865 L28.7418337,21.4203887 C28.7418337,20.2188221 29.4407484,19.1270046 30.5319408,18.6239525 L51.8328655,8.80398345 C52.6594023,8.42294058 53.6121641,8.42705978 54.4353752,8.81523521 L75.2210794,18.6164875 C76.2991715,19.1248491 76.9870547,20.2097348 76.9870547,21.401672 L76.9870547,65.5749865 C76.9870547,67.2756347 75.6084069,68.6542825 73.9077587,68.6542825 Z"
                        id="Fill-17"
                        fill="#FAFBFC"
                      />
                      <polyline
                        id="Stroke-18"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="74.9340665 22.5427725 74.9340665 67.6283174 74.9340665 67.6283174 26.6888456 67.6283174 26.6888456 22.5427725"
                      />
                      <polygon
                        id="Fill-20"
                        fill="#F2F5F7"
                        points="39.0067744 29.7154728 63.6426318 29.7154728 63.6426318 25.6167869 39.0067744 25.6167869"
                      />
                      <polygon
                        id="Stroke-21"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="39.0067744 30.7401443 63.6426318 30.7401443 63.6426318 24.5921154 39.0067744 24.5921154"
                      />
                      <polygon
                        id="Stroke-22"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="35.9272922 67.6283174 65.69562 67.6283174 65.69562 61.4802885 35.9272922 61.4802885"
                      />
                      <line
                        x1="94.4374537"
                        y1="37.3916781"
                        x2="94.4374537"
                        y2="50.7310805"
                        id="Stroke-24"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M40.0331443,63.5296315 L58.5102857,63.5296315 C59.6440511,63.5296315 60.5631497,62.6105329 60.5631497,61.4767675 L60.5631497,45.089066 C60.5631497,43.9553005 59.6440511,43.036202 58.5102857,43.036202 L40.0331443,43.036202 C38.8993788,43.036202 37.9802803,43.9553005 37.9802803,45.089066 L37.9802803,61.4767675 C37.9802803,62.6105329 38.8993788,63.5296315 40.0331443,63.5296315 Z"
                        id="Fill-25"
                        fill="#C8ECED"
                      />
                      <polygon
                        id="Stroke-26"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="40.0332684 61.4802885 62.6161378 61.4802885 62.6161378 40.986859 40.0332684 40.986859"
                      />
                      <line
                        x1="51.3247031"
                        y1="40.986859"
                        x2="51.3247031"
                        y2="61.4802885"
                        id="Stroke-27"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="46.1922328"
                        y1="51.2335738"
                        x2="48.2452209"
                        y2="51.2335738"
                        id="Stroke-28"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="54.4041853"
                        y1="51.2335738"
                        x2="56.4571734"
                        y2="51.2335738"
                        id="Stroke-29"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M105.899175,58.6304722 C105.343652,58.6304722 104.811786,58.7195308 104.313365,58.8763348 C103.571036,56.8173307 101.490068,55.3322597 99.0330407,55.3322597 C96.2611356,55.3322597 93.9680734,57.2222808 93.5381752,59.6953693 C91.6652223,60.3591223 90.3314775,62.0428624 90.3314775,64.0227034 C90.3314775,66.5795222 92.5535697,68.6529888 95.2944765,68.6529888 C97.416232,68.6529888 99.2206623,67.4084521 99.9311772,65.6615336 C100.453255,65.5823705 100.95086,65.436223 101.413388,65.2322256 C102.207109,66.8017881 103.914465,67.8918043 105.899175,67.8918043 C108.639266,67.8918043 110.861359,65.8183377 110.861359,63.2615188 C110.861359,60.7039388 108.639266,58.6304722 105.899175,58.6304722"
                        id="Fill-32"
                        fill="#A7DDAF"
                      />
                      <path
                        d="M13.9818876,59.9010063 C13.2395587,57.8420021 11.1585904,56.3569311 8.70156329,56.3569311 C5.92965815,56.3569311 3.63659596,56.1976094 3.20669779,58.6706979 C1.33374482,59.3344508 0,63.0675339 0,65.0473748 C0,67.6041937 2.22209228,68.6529888 4.96299907,68.6529888 C7.08475458,68.6529888 13.5829877,68.6529888 15.5676979,68.6529888 C18.307789,68.6529888 20.5298812,66.8430091 20.5298812,64.2861903 C20.5298812,61.7286103 18.307789,59.6551437 15.5676979,59.6551437 C15.0121749,59.6551437 14.4803084,59.7442023 13.9818876,59.9010063 Z"
                        id="Fill-32"
                        fill="#A7DDAF"
                      />
                      <path
                        d="M106.925669,56.5811292 C106.370146,56.5811292 105.83828,56.6701878 105.339859,56.8269918 C104.59753,54.7679877 102.516562,53.2829167 100.059535,53.2829167 C97.2876297,53.2829167 94.9945675,55.1729379 94.5646693,57.6460264 C92.6917163,58.3097793 91.3579715,59.9935195 91.3579715,61.9733604 C91.3579715,64.5301792 93.5800638,66.6036459 96.3209706,66.6036459 C98.4427261,66.6036459 100.247156,65.3591092 100.957671,63.6121907 C101.479749,63.5330275 101.977354,63.3868801 102.439882,63.1828826 C103.233603,64.7524451 104.940959,65.8424614 106.925669,65.8424614 C109.66576,65.8424614 111.887853,63.7689947 111.887853,61.2121759 C111.887853,58.6545959 109.66576,56.5811292 106.925669,56.5811292 Z"
                        id="Stroke-33"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.594192,57.6058007 C16.0386689,57.6058007 15.5068025,57.6948593 15.0083816,57.8516633 C14.2660527,55.7926592 12.1850845,54.3075882 9.72805735,54.3075882 C6.95615222,54.3075882 4.66309002,56.1976094 4.23319185,58.6706979 C2.36023888,59.3344508 1.02649406,61.0181909 1.02649406,62.9980319 C1.02649406,65.5548507 3.24858635,67.6283174 5.98949313,67.6283174 C8.11124864,67.6283174 14.4391948,67.6283174 16.423905,67.6283174 C19.163996,67.6283174 21.5563753,64.7936662 21.5563753,62.2368474 C21.5563753,59.6792674 19.334283,57.6058007 16.594192,57.6058007 Z"
                        id="Stroke-33"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <polygon
                        id="Fill-34"
                        fill="#E0D8D1"
                        points="75.9605606 67.6283174 112.914347 67.6283174 112.914347 59.4309456 75.9605606 59.4309456"
                      />
                      <polygon
                        id="Stroke-35"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="74.9340665 67.6283174 112.914347 67.6283174 112.914347 59.4309456 74.9340665 59.4309456"
                      />
                      <polygon
                        id="Fill-36"
                        fill="#E0D8D1"
                        points="17.4503991 67.6283174 31.8213159 67.6283174 31.8213159 59.4309456 17.4503991 59.4309456"
                      />
                      <polygon
                        id="Stroke-37"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="17.4503991 67.6283174 31.8213159 67.6283174 31.8213159 59.4309456 17.4503991 59.4309456"
                      />
                      <line
                        x1="24.6358575"
                        y1="59.4309456"
                        x2="24.6358575"
                        y2="66.6036459"
                        id="Stroke-38"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="83.146019"
                        y1="59.4309456"
                        x2="83.146019"
                        y2="66.6036459"
                        id="Stroke-39"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="90.3314775"
                        y1="59.4309456"
                        x2="90.3314775"
                        y2="66.6036459"
                        id="Stroke-40"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="97.5169359"
                        y1="59.4309456"
                        x2="97.5169359"
                        y2="66.6036459"
                        id="Stroke-41"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <line
                        x1="104.702394"
                        y1="59.4309456"
                        x2="104.702394"
                        y2="66.6036459"
                        id="Stroke-42"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <polygon
                        id="Fill-43"
                        fill="#FFFFFF"
                        points="35.9272922 7.68503606 26.6888456 12.2960577 26.6888456 2.04934295 35.4141385 2.04934295"
                      />
                      <polygon
                        id="Stroke-44"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="35.4141385 7.68503606 26.6888456 11.783722 26.6888456 2.04934295 35.4141385 2.04934295"
                      />
                      <polygon
                        id="Fill-45"
                        fill="#FAFBFC"
                        points="115.993829 67.6283174 145.762157 67.6283174 145.762157 39.9621875 115.993829 39.9621875"
                      />
                      <polygon
                        id="Stroke-46"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="115.993829 67.6283174 145.762157 67.6283174 145.762157 39.9621875 115.993829 39.9621875"
                      />
                      <path
                        d="M76.4593517,19.6037468 L51.497064,7.70545219 C50.9236365,7.4321275 50.2559768,7.43966598 49.6888669,7.72586836 L26.3010529,19.5289554 C25.2888793,20.0397673 24.0542556,19.6333333 23.5434438,18.6211597 C23.541165,18.6166444 23.538903,18.6121207 23.5366577,18.6075886 L22.4609987,16.436421 C21.9603109,15.425806 22.3686921,14.2005291 23.3755783,13.6923841 L49.6078691,0.453739931 C50.1749746,0.167538949 50.842629,0.159997576 51.4160544,0.433315891 L79.2532059,13.7016559 C80.2714499,14.1869932 80.7073594,15.4029731 80.2293691,16.4246865 L79.2020817,18.6205335 C78.7216463,19.6474736 77.499677,20.0905032 76.4727369,19.6100677 C76.4682676,19.6079768 76.4638058,19.6058698 76.4593517,19.6037468 Z"
                        id="Fill-47"
                        fill="#F3F6F8"
                      />
                      <path
                        d="M75.4328576,21.6530897 L50.47057,9.75479514 C49.8971425,9.48147045 49.2294827,9.48900893 48.6623728,9.77521131 L25.2745588,21.5782984 C24.2623852,22.0891102 23.0277616,21.6826763 22.5169497,20.6705027 C22.514671,20.6659873 22.5124089,20.6614636 22.5101636,20.6569316 L21.4345046,18.485764 C20.9338168,17.4751489 21.3421981,16.249872 22.3490842,15.7417271 L48.5813751,2.50308288 C49.1484805,2.2168819 49.8161349,2.20934053 50.3895604,2.48265884 L78.2267119,15.7509989 C79.2449558,16.2363361 79.6808653,17.4523161 79.2028751,18.4740295 L78.1755876,20.6698764 C77.6951522,21.6968165 76.4731829,22.1398461 75.4462428,21.6594107 C75.4417735,21.6573198 75.4373117,21.6552128 75.4328576,21.6530897 Z"
                        id="Fill-47"
                        fill="#FFCEC4"
                      />
                      <polygon
                        id="Stroke-48"
                        stroke="#1E283D"
                        strokeWidth="2.052864"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        points="78.325881 20.4934295 50.5879575 7.27212584 24.4547542 20.4607237 21.5563753 14.6104808 50.506952 0 81.0930309 14.5785927"
                      />
                      <line
                        x1="130.364746"
                        y1="64.5543029"
                        x2="132.417734"
                        y2="64.5543029"
                        id="Stroke-49"
                        stroke="#1E283D"
                        strokeWidth="1.026432"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <g
                      id="icon-/-search"
                      transform="translate(153.574164, 4.320000)"
                    >
                      <ellipse
                        id="Oval-2"
                        fillOpacity="0.680000007"
                        fill="#FFFFFF"
                        cx="24.1283626"
                        cy="23.0084544"
                        rx="18.4205779"
                        ry="18.3550591"
                      />
                      <g
                        id="Group-6"
                        transform="translate(11.285847, 16.822703)"
                        fill="#0EA800"
                      >
                        <path
                          d="M0.648611898,5.17621622 C0.648611898,3.92616 1.76422436,2.88056432 3.24305949,2.64116432 L3.24305949,1.94108108 C3.24305949,1.58262811 3.53363762,1.29405405 3.89167139,1.29405405 C4.24970516,1.29405405 4.54028329,1.58262811 4.54028329,1.94108108 L4.54028329,2.64504649 C5.75837643,2.84044865 6.74686096,3.57158919 7.04522244,4.56412865 C7.10489473,4.76082486 7.13473088,4.96787351 7.13473088,5.17621622 C7.13473088,5.53337514 6.84415275,5.82324324 6.48611898,5.82324324 C6.12808521,5.82324324 5.83750708,5.53337514 5.83750708,5.17621622 C5.83750708,5.09469081 5.82583207,5.01445946 5.80248204,4.93681622 C5.62216793,4.33508108 4.79972805,3.88216216 3.89167139,3.88216216 C2.83702844,3.88216216 1.94583569,4.47483892 1.94583569,5.17621622 C1.94583569,5.87759351 2.83702844,6.46897622 3.89167139,6.46897622 C5.680543,6.46897622 7.13473088,7.6297427 7.13473088,9.05837838 C7.13473088,10.3084346 6.01911841,11.3527362 4.54028329,11.5934303 L4.54028329,12.2935135 C4.54028329,12.6506724 4.24970516,12.9405405 3.89167139,12.9405405 C3.53363762,12.9405405 3.24305949,12.6506724 3.24305949,12.2935135 L3.24305949,11.5934303 C1.76422436,11.3527362 0.648611898,10.3084346 0.648611898,9.05837838 C0.648611898,8.70121946 0.939190028,8.41135135 1.2972238,8.41135135 C1.65525756,8.41135135 1.94583569,8.70121946 1.94583569,9.05837838 C1.94583569,9.75975568 2.83702844,10.3524324 3.89167139,10.3524324 C4.94631433,10.3524324 5.83750708,9.75975568 5.83750708,9.05837838 C5.83750708,8.35700108 4.94631433,7.76303027 3.89167139,7.76303027 C2.10279977,7.76303027 0.648611898,6.60226378 0.648611898,5.17621622 L0.648611898,5.17621622 Z"
                          id="Path"
                        />
                        <path
                          d="M9.08056657,5.17621622 C9.08056657,3.92616 10.196179,2.88056432 11.6750142,2.64116432 L11.6750142,1.94108108 C11.6750142,1.58262811 11.9655923,1.29405405 12.3236261,1.29405405 C12.6816598,1.29405405 12.972238,1.58262811 12.972238,1.94108108 L12.972238,2.64504649 C14.1903311,2.84044865 15.1788156,3.57158919 15.4771771,4.56412865 C15.5368494,4.76082486 15.5666856,4.96787351 15.5666856,5.17621622 C15.5666856,5.53337514 15.2761074,5.82324324 14.9180737,5.82324324 C14.5600399,5.82324324 14.2694618,5.53337514 14.2694618,5.17621622 C14.2694618,5.09469081 14.2577867,5.01445946 14.2344367,4.93681622 C14.0541226,4.33508108 13.2316827,3.88216216 12.3236261,3.88216216 C11.2689831,3.88216216 10.3777904,4.47483892 10.3777904,5.17621622 C10.3777904,5.87759351 11.2689831,6.46897622 12.3236261,6.46897622 C14.1124977,6.46897622 15.5666856,7.6297427 15.5666856,9.05837838 C15.5666856,10.3084346 14.4510731,11.3527362 12.972238,11.5934303 L12.972238,12.2935135 C12.972238,12.6506724 12.6816598,12.9405405 12.3236261,12.9405405 C11.9655923,12.9405405 11.6750142,12.6506724 11.6750142,12.2935135 L11.6750142,11.5934303 C10.196179,11.3527362 9.08056657,10.3084346 9.08056657,9.05837838 C9.08056657,8.70121946 9.3711447,8.41135135 9.72917847,8.41135135 C10.0872122,8.41135135 10.3777904,8.70121946 10.3777904,9.05837838 C10.3777904,9.75975568 11.2689831,10.3524324 12.3236261,10.3524324 C13.378269,10.3524324 14.2694618,9.75975568 14.2694618,9.05837838 C14.2694618,8.35700108 13.378269,7.76303027 12.3236261,7.76303027 C10.5347544,7.76303027 9.08056657,6.60226378 9.08056657,5.17621622 L9.08056657,5.17621622 Z"
                          id="Path"
                        />
                        <path
                          d="M20.1069688,2.64116432 L20.1069688,1.94108108 C20.1069688,1.58262811 20.397547,1.29405405 20.7555807,1.29405405 C21.1136145,1.29405405 21.4041926,1.58262811 21.4041926,1.94108108 L21.4041926,2.64504649 C22.6222858,2.84044865 23.6107703,3.57158919 23.9091318,4.56412865 C23.9688041,4.76082486 23.9986402,4.96787351 23.9986402,5.17621622 C23.9986402,5.53337514 23.7080621,5.82324324 23.3500283,5.82324324 C22.9919946,5.82324324 22.7014164,5.53337514 22.7014164,5.17621622 C22.7014164,5.09469081 22.6897414,5.01445946 22.6663914,4.93681622 C22.4860773,4.33508108 21.6636374,3.88216216 20.7555807,3.88216216 C19.7009378,3.88216216 18.809745,4.47483892 18.809745,5.17621622 C18.809745,5.87759351 19.7009378,6.46897622 20.7555807,6.46897622 C22.5444524,6.46897622 23.9986402,7.6297427 23.9986402,9.05837838 C23.9986402,10.3084346 22.8830278,11.3527362 21.4041926,11.5934303 L21.4041926,12.2935135 C21.4041926,12.6506724 21.1136145,12.9405405 20.7555807,12.9405405 C20.397547,12.9405405 20.1069688,12.6506724 20.1069688,12.2935135 L20.1069688,11.5934303 C18.6281337,11.3527362 17.5125212,10.3084346 17.5125212,9.05837838 C17.5125212,8.70121946 17.8030994,8.41135135 18.1611331,8.41135135 C18.5191669,8.41135135 18.809745,8.70121946 18.809745,9.05837838 C18.809745,9.75975568 19.7009378,10.3524324 20.7555807,10.3524324 C21.8102237,10.3524324 22.7014164,9.75975568 22.7014164,9.05837838 C22.7014164,8.35700108 21.8102237,7.76303027 20.7555807,7.76303027 C18.9667091,7.76303027 17.5125212,6.60226378 17.5125212,5.17621622 C17.5125212,3.92616 18.6281337,2.88056432 20.1069688,2.64116432 Z"
                          id="Path"
                        />
                        <path
                          d="M0,3.88216216 C0,2.63210595 1.11561246,1.58651027 2.59444759,1.34711027 L2.59444759,0.647027027 C2.59444759,0.288574054 2.88502572,0 3.24305949,0 C3.60109326,0 3.89167139,0.288574054 3.89167139,0.647027027 L3.89167139,1.35099243 C5.10976453,1.54639459 6.09824907,2.27753514 6.39661054,3.27007459 C6.45628283,3.46677081 6.48611898,3.67381946 6.48611898,3.88216216 C6.48611898,4.23932108 6.19554085,4.52918919 5.83750708,4.52918919 C5.47947331,4.52918919 5.18889518,4.23932108 5.18889518,3.88216216 C5.18889518,3.80063676 5.17722017,3.72040541 5.15387014,3.64276216 C4.97355603,3.04102703 4.15111615,2.58810811 3.24305949,2.58810811 C2.18841654,2.58810811 1.2972238,3.18078486 1.2972238,3.88216216 C1.2972238,4.58353946 2.18841654,5.17492216 3.24305949,5.17492216 C5.0319311,5.17492216 6.48611898,6.33568865 6.48611898,7.76432432 C6.48611898,9.01438054 5.37050652,10.0586822 3.89167139,10.2993762 L3.89167139,10.9994595 C3.89167139,11.3566184 3.60109326,11.6464865 3.24305949,11.6464865 C2.88502572,11.6464865 2.59444759,11.3566184 2.59444759,10.9994595 L2.59444759,10.2993762 C1.11561246,10.0586822 0,9.01438054 0,7.76432432 C0,7.40716541 0.29057813,7.1172973 0.648611898,7.1172973 C1.00664567,7.1172973 1.2972238,7.40716541 1.2972238,7.76432432 C1.2972238,8.46570162 2.18841654,9.05837838 3.24305949,9.05837838 C4.29770244,9.05837838 5.18889518,8.46570162 5.18889518,7.76432432 C5.18889518,7.06294703 4.29770244,6.46897622 3.24305949,6.46897622 C1.45418788,6.46897622 0,5.30820973 0,3.88216216 L0,3.88216216 Z"
                          id="Path"
                        />
                        <path
                          d="M8.43195467,3.88216216 C8.43195467,2.63210595 9.54756714,1.58651027 11.0264023,1.34711027 L11.0264023,0.647027027 C11.0264023,0.288574054 11.3169804,0 11.6750142,0 C12.0330479,0 12.3236261,0.288574054 12.3236261,0.647027027 L12.3236261,1.35099243 C13.5417192,1.54639459 14.5302037,2.27753514 14.8285652,3.27007459 C14.8882375,3.46677081 14.9180737,3.67381946 14.9180737,3.88216216 C14.9180737,4.23932108 14.6274955,4.52918919 14.2694618,4.52918919 C13.911428,4.52918919 13.6208499,4.23932108 13.6208499,3.88216216 C13.6208499,3.80063676 13.6091748,3.72040541 13.5858248,3.64276216 C13.4055107,3.04102703 12.5830708,2.58810811 11.6750142,2.58810811 C10.6203712,2.58810811 9.72917847,3.18078486 9.72917847,3.88216216 C9.72917847,4.58353946 10.6203712,5.17492216 11.6750142,5.17492216 C13.4638858,5.17492216 14.9180737,6.33568865 14.9180737,7.76432432 C14.9180737,9.01438054 13.8024612,10.0586822 12.3236261,10.2993762 L12.3236261,10.9994595 C12.3236261,11.3566184 12.0330479,11.6464865 11.6750142,11.6464865 C11.3169804,11.6464865 11.0264023,11.3566184 11.0264023,10.9994595 L11.0264023,10.2993762 C9.54756714,10.0586822 8.43195467,9.01438054 8.43195467,7.76432432 C8.43195467,7.40716541 8.7225328,7.1172973 9.08056657,7.1172973 C9.43860034,7.1172973 9.72917847,7.40716541 9.72917847,7.76432432 C9.72917847,8.46570162 10.6203712,9.05837838 11.6750142,9.05837838 C12.7296571,9.05837838 13.6208499,8.46570162 13.6208499,7.76432432 C13.6208499,7.06294703 12.7296571,6.46897622 11.6750142,6.46897622 C9.88614255,6.46897622 8.43195467,5.30820973 8.43195467,3.88216216 L8.43195467,3.88216216 Z"
                          id="Path"
                        />
                        <path
                          d="M19.4583569,1.34711027 L19.4583569,0.647027027 C19.4583569,0.288574054 19.7489351,0 20.1069688,0 C20.4650026,0 20.7555807,0.288574054 20.7555807,0.647027027 L20.7555807,1.35099243 C21.9736739,1.54639459 22.9621584,2.27753514 23.2605199,3.27007459 C23.3201922,3.46677081 23.3500283,3.67381946 23.3500283,3.88216216 C23.3500283,4.23932108 23.0594502,4.52918919 22.7014164,4.52918919 C22.3433827,4.52918919 22.0528045,4.23932108 22.0528045,3.88216216 C22.0528045,3.80063676 22.0411295,3.72040541 22.0177795,3.64276216 C21.8374654,3.04102703 21.0150255,2.58810811 20.1069688,2.58810811 C19.0523259,2.58810811 18.1611331,3.18078486 18.1611331,3.88216216 C18.1611331,4.58353946 19.0523259,5.17492216 20.1069688,5.17492216 C21.8958405,5.17492216 23.3500283,6.33568865 23.3500283,7.76432432 C23.3500283,9.01438054 22.2344159,10.0586822 20.7555807,10.2993762 L20.7555807,10.9994595 C20.7555807,11.3566184 20.4650026,11.6464865 20.1069688,11.6464865 C19.7489351,11.6464865 19.4583569,11.3566184 19.4583569,10.9994595 L19.4583569,10.2993762 C17.9795218,10.0586822 16.8639093,9.01438054 16.8639093,7.76432432 C16.8639093,7.40716541 17.1544875,7.1172973 17.5125212,7.1172973 C17.870555,7.1172973 18.1611331,7.40716541 18.1611331,7.76432432 C18.1611331,8.46570162 19.0523259,9.05837838 20.1069688,9.05837838 C21.1616118,9.05837838 22.0528045,8.46570162 22.0528045,7.76432432 C22.0528045,7.06294703 21.1616118,6.46897622 20.1069688,6.46897622 C18.3180972,6.46897622 16.8639093,5.30820973 16.8639093,3.88216216 C16.8639093,2.63210595 17.9795218,1.58651027 19.4583569,1.34711027 Z"
                          id="Path"
                        />
                      </g>
                      <g
                        id="Fill-1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <use
                          fill="black"
                          fillOpacity="1"
                          filter="url(#filter-2)"
                          xlinkHref="#path-1"
                        />
                        <use
                          fill="#E6E9ED"
                          fillRule="evenodd"
                          xlinkHref="#path-1"
                        />
                        <use
                          fill="black"
                          fillOpacity="1"
                          filter="url(#filter-3)"
                          xlinkHref="#path-1"
                        />
                        <use
                          stroke="#1E293D"
                          strokeWidth="2.0736"
                          xlinkHref="#path-1"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    d="M11.5927525,96.9178378 L232.130307,96.9178378 C233.202337,96.9178378 234.071388,97.7868894 234.071388,98.8589189 C234.071388,99.9309484 233.202337,100.8 232.130307,100.8 L11.5927525,100.8 C10.520723,100.8 9.65167139,99.9309484 9.65167139,98.8589189 C9.65167139,97.7868894 10.520723,96.9178378 11.5927525,96.9178378 Z"
                    id="Rectangle-8"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M6.08430595,98.2118919 L12.7442765,98.2118919 M20.1052965,98.2118919 L32.7241881,98.2118919 L211.141293,98.2118919 M222.007561,98.2118919 L230.420156,98.2118919"
                    id="Rectangle-6"
                    stroke="#1E283D"
                    strokeWidth="1.296"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div css={styles.content}>
        <h1 css={h5Tag}>Looking to sell?</h1>
        <p css={styles.copyPadding}>
          Get in touch with agents who can help, with a free market appraisal
          from experts in your area.
        </p>
        <LinkButton
          href={urlUtil.createUrlWithParams({
            baseUrl: baseUrlWithProtocol,
            path: '/services/appraisal',
            params: {
              exitUrl: `${baseUrlWithProtocol}/real-estate-agents`,
              'entry-point': entryPoint,
            },
          })}
          rel="noopener"
          // onClick={trackAppraisalFunnelEntry}
          // onAuxClick={trackAppraisalFunnelEntry}
          css={styles.button}
        >
          Get a free appraisal
        </LinkButton>
      </div>
    </article>
  );
};

export default AppraisalRequestCard;
