import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';

export * from '../filter-headings.style';

const { colors, spacing, typography } = tokens;

export const radioGroup = css`
  padding: ${spacing.s} ${spacing.s};
`;

export const radioLine = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const selectedLabel = css`
  ${applyFont(typography.bodyBold)}
`;

/**
 * Inspired by https://github.com/domain-group/fe-pa-search-results/blob/357aafd067d0ad83dfe1001abf9c628e1822f5a2/src/js/listing-details/components/toolbar/style.js#L157
 */
export const badge = (selected) => css`
  display: block;
  top: -7px;
  right: 2px;
  padding: 0 8px;
  ${applyFont(typography.miniBold)}
  text-align: center;

  position: static;
  margin-left: 4px;
  border-radius: 22px;
  height: 22px;
  line-height: 22px;
  background: ${colors.neutral.background.default};

  ${selected &&
  css`
    color: ${colors.primary.base.default};
    background: ${colors.primary.background.default};
  `}
`;
