import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { toGlobalId, notEmpty } from '../../utils';
import { AgencyProfileSalesStatisticsFragment } from '../../generated/graphql';
import { AgencySalesStatistics } from './components';
import { SUBURB_STATISTICS_FRAGMENT } from './shared';

export type Props = {
  agencyId: number;
  tracking?: {
    onSuburbChange?: (suburbName: string) => void;
  };
};

const fragmentName = 'AgencyProfileSalesStatistics';
const GET_AGENCY_SALES_STATISTICS_FRAGMENT = gql`
  fragment AgencyProfileSalesStatistics on Agency {
    id
    showTabSoldLastYear

    activeSuburbs {
      suburbName
      suburbSlug
      listingCount
    }

    suburbStatistics {
      ...SuburbStatistics
    }
  }
  ${SUBURB_STATISTICS_FRAGMENT}
`;

const AgencySalesStatisticsWithFragment = ({
  agencyId,
  tracking,
}: Props): React.ReactElement | null => {
  const agencyFragmentId = toGlobalId('Agency', agencyId);
  const { complete, data } = useFragment<AgencyProfileSalesStatisticsFragment>({
    from: {
      __typename: 'Agency',
      id: agencyFragmentId,
    },
    fragment: GET_AGENCY_SALES_STATISTICS_FRAGMENT,
    fragmentName,
  });

  if (
    !complete ||
    !data ||
    // Hide if agency hasn't elected to show
    !data?.showTabSoldLastYear ||
    // Hide if no sold properties
    !data?.suburbStatistics?.total ||
    // Minimum one active suburb
    !data?.activeSuburbs?.length
  ) {
    return null;
  }

  // Type safety - remove nully active suburbs
  const activeSuburbs = data?.activeSuburbs?.filter(notEmpty) || [];

  return (
    <AgencySalesStatistics
      agencyId={agencyId}
      tracking={tracking}
      allSuburbStatistics={data.suburbStatistics}
      activeSuburbs={activeSuburbs}
    />
  );
};

AgencySalesStatisticsWithFragment.fragmentName = fragmentName;
AgencySalesStatisticsWithFragment.fragment =
  GET_AGENCY_SALES_STATISTICS_FRAGMENT;

export default AgencySalesStatisticsWithFragment;
