import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';

const { colors, typography } = tokens;

/* eslint-disable import/prefer-default-export */
export const countpin = (isActive) => css`
  display: inline-block;
  min-width: 30px;
  height: 20px;
  padding: 2px 6px 0;
  text-align: center;
  color: ${isActive
    ? colors.interactive.base.default
    : colors.neutral.medium.hover};
  background: ${isActive
    ? colors.interactive.surface.pressed
    : colors.neutral.subdued.pressed};
  border-radius: 100px;
  ${applyFont(typography.mini)}
`;
