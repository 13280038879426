import React, { useEffect } from 'react';
import { parseJSON, formatDistanceToNow } from 'date-fns';
import { useInView } from 'react-intersection-observer';

import { Button } from '@domain-group/fe-co-button';

import type { ReviewListEntry } from '../../../@types/recommendations';

import RmaLogo from './rma-logo';
import PropertyCard from './property-card';
import * as styles from './review.style';

export type ReviewProps = ReviewListEntry & {
  openModal?: null | ((n?: number) => void);
  onActivate?: (inView: boolean, index: number) => void;
  showPropertyCard?: boolean;
  listView: boolean;
};

const Review = ({
  index,
  title,
  description,
  reviewedOn,
  streetAddress,
  postcode,
  suburb,
  state,
  bedrooms = 0,
  bathrooms = 0,
  carparks = 0,
  showPropertyCard = false,
  listView = false,
  descLength = 165,
  openModal = null,
  onActivate = () => undefined,
}: ReviewProps): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      onActivate(inView, index);
    }
  }, [index, inView, onActivate]);

  return (
    <article css={styles.container(listView)} ref={ref}>
      <header css={styles.header(listView)}>
        <h3>{title}</h3>
        <p>
          Seller recommendation | {suburb} |{' '}
          {formatDistanceToNow(parseJSON(reviewedOn), { addSuffix: true })}
        </p>
      </header>
      <div css={styles.body}>
        <p>
          {descLength ? description.slice(0, descLength) : description}
          {openModal && (
            <span css={styles.moreButtonShade}>
              <Button
                type="button"
                appearance="link"
                onClick={() => openModal(index)}
              >
                More
              </Button>
            </span>
          )}
        </p>
      </div>
      {showPropertyCard && (
        <>
          <PropertyCard
            bedrooms={bedrooms}
            bathrooms={bathrooms}
            carparks={carparks}
            streetAddress={streetAddress}
            suburb={suburb}
            postcode={postcode}
            state={state}
          />
          <span css={styles.promo}>
            Recommendation provided by <RmaLogo height={20} />
          </span>
        </>
      )}
    </article>
  );
};

export default Review;
