import React from 'react';
import { gql, useFragment } from '@apollo/client';
import Video from '@domain-group/fe-co-video';
import { ContactVideoFragment } from '../../generated/graphql';

type ContactVideoProps = {
  agentIdV2: string;
  agencyId: number;
};

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactVideo';
const FRAGMENT = gql`
  fragment ContactVideo on Contact {
    id
    agentIdV2
    agencyId
    agentVideoUrl
  }
`;

const ContactVideoWithFragment = ({
  agentIdV2,
  agencyId,
}: ContactVideoProps): JSX.Element | null => {
  const { complete, data: contact } = useFragment<
    ContactVideoFragment,
    unknown
  >({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: ContactVideoWithFragment.fragment,
    fragmentName: ContactVideoWithFragment.fragmentName,
  });

  if (!complete || !contact?.agentVideoUrl) {
    return null;
  }

  return (
    <div data-testid="video">
      <Video videoUrl={contact.agentVideoUrl} />
    </div>
  );
};

ContactVideoWithFragment.fragment = FRAGMENT;
ContactVideoWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactVideoWithFragment;
