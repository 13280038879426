import React from 'react';

import { Avatar } from '../../../../shared';

import * as styles from './contact-banner.style';
import { HERO_SECTION, PROFILE_TIERS } from '../../../constants';

export type Props = {
  profileTier: PROFILE_TIERS;
  avatarUrl: string;
  name: string;
  jobPosition?: string | null;
};

const heroSection = HERO_SECTION.banner;

const ContactBanner = ({
  profileTier,
  avatarUrl,
  name,
  jobPosition,
}: Props): JSX.Element => {
  const avatarTitle = [name, jobPosition || 'Real Estate Agent']
    .filter(Boolean)
    .join(', ');

  return (
    <div css={styles.agentDetails(profileTier)} data-testid="agent-details">
      <div
        css={styles.agentAvatarHolder}
        data-testid="trade-profile-hero__agent-avatar"
      >
        <Avatar
          size="custom"
          imageUrl={avatarUrl}
          imageTitle={avatarTitle}
          css={styles.agentAvatar}
        />
      </div>
      <div data-testid="trade-profile-hero__details-body">
        <h1
          css={styles.name}
          data-testid={`trade-profile-hero-${heroSection}__name`}
        >
          {name}
        </h1>

        {jobPosition && (
          <p
            css={styles.agentJob}
            data-testid={`trade-profile-hero-${heroSection}__agent-job-position`}
          >
            {jobPosition}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactBanner;
