import { css } from '@emotion/react';
import { tokens, applyFont, media } from '@domain-group/fe-brary';
import * as util from '../../style/util';

const { colors, spacing, typography } = tokens;

export const container = css`
  ${util.container({
    gap: spacing.xl,
    backgroundColor: colors.neutral.subdued.default,
  })};
`;

export const contentWrapper = css`
  ${util.contentWrapper({
    gap: spacing.xl,
    flexDirection: 'column',
  })};

  sup {
    font-size: 0.6em;
    vertical-align: top;
    position: relative;
    top: -0.6em;
  }

  h2 {
    ${applyFont(typography.heading)}
  }

  h3 {
    ${applyFont(typography.subheading)}
  }

  p {
    ${applyFont(typography.body)};
    color: ${colors.neutral.medium.default};
  }
`;

export const supText = css`
  color: ${colors.neutral.base.default};
  ${applyFont(typography.mini)};
`;

export const sectionsContainer = css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.l};

  ${media('>=tablet')} {
    flex-basis: 50%;
  }
`;

export const contentContainer = css`
  gap: ${spacing.l};
  align-items: flex-start;
`;

export const imageSection = css`
  display: none;

  ${media('>=tablet')} {
    display: flex;
  }
`;

export const textSection = css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
`;

export const section = css`
  display: flex;
  gap: ${spacing.xs};

  ${media('>=tablet')} {
    align-items: center;
  }
`;
