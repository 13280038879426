import { gql } from '@apollo/client';
import { handleError } from '@domain-group/fe-helper';

export const SEND_ENQUIRY = gql`
  mutation CreateEnquiry($input: EnquiryInput!) {
    createEnquiry(input: $input) {
      s3Key
    }
  }
`;

export const joinMessage = ({ intent = '', message = '' } = {}) =>
  `${intent ? `I'm interested in:\n- ${intent}` : ''}\n\n${message}`;

const sendEnquiry = async ({ funnelState, funnelProps, nav, dispatch }) => {
  const { apolloClient, onSubmit, contactId, onSubmitSuccess, onSubmitError } =
    funnelProps;

  const {
    intent,
    details: { firstName, lastName, email, postcode, phone, message },
  } = funnelState;

  dispatch({
    type: 'setSubmissionError',
    submissionError: null,
  });

  onSubmit({
    intent,
    firstName,
    lastName,
    email,
    postcode,
    phone,
    message,
  });

  const input = {
    enquiryType: 'agentProfile',
    source: 'website',
    subject: `[domain.com.au] User enquiry from domain.com.au`,
    referenceId: contactId,
    firstName,
    lastName,
    email,
    postcode: parseInt(postcode, 10),
    message: joinMessage({ intent, message }),
    ...(phone && { phoneNumber: phone }),
  };

  try {
    await apolloClient.mutate({
      mutation: SEND_ENQUIRY,
      variables: { input },
    });

    onSubmitSuccess({ intent });

    nav.next();
  } catch (err) {
    handleError(err);

    onSubmitError();

    nav.next({
      type: 'setSubmissionError',
      submissionError: err,
    });
  }
};

export default sendEnquiry;
