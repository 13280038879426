import { SerializedStyles, css } from '@emotion/react';
import { media as mq } from '@domain-group/fe-brary';

// eslint-disable-next-line import/prefer-default-export
export const container = (
  changeForDevice?: 'tablet' | 'desktop',
  overrideStyling?: SerializedStyles,
): SerializedStyles => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${changeForDevice
    ? `${mq(`>=${changeForDevice}`)} {
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: flex-start; */
  }`
    : ''}

  ${overrideStyling}
`;
