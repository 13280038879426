import React from 'react';
import { handleError } from '@domain-group/fe-helper';
import ErrorPage from './error-page';

const devEnvironments = ['test', 'development'];

export type Props = {
  children: JSX.Element;
  fallback?: JSX.Element;
};

type State = {
  hasError: boolean;
  errorInfo?: any;
};

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(): { hasError: boolean } {
    return {
      hasError: true,
    };
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      errorInfo: undefined,
    };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    handleError(error, {
      customData: [{ errorInfo }],
      tags: ['nova', 'error-boundary'],
    });
  }

  render(): JSX.Element {
    if (this.state.hasError && this.props.fallback) {
      return this.props.fallback;
    }

    if (this.state.hasError) {
      // Show error in front-end on development environments
      let messageContent;
      if (devEnvironments.includes(process.env.NODE_ENV)) {
        messageContent = JSON.stringify(this.state.errorInfo);
      }

      return <ErrorPage messageContent={messageContent} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
