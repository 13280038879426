import React from 'react';

import { AgencyLogo, AgencyAddress } from '../..';

import * as styles from './agency-details-section-small.style';
import { HERO_SECTION, PROFILE_TIERS } from '../../../constants';

export type Props = {
  profileTier: PROFILE_TIERS;
  agencyName: string;
  agencyUrl: string;
  imageUrl: string;
  address: string;
  isAgency: boolean;
  hideOfficeLocation?: boolean;
  onAgencyAddressClick: () => void;
  onAgencyLogoClick: () => void;
};

const heroSection = HERO_SECTION.section;

const AgencyDetailsSectionSmall = ({
  profileTier,
  agencyName,
  agencyUrl,
  imageUrl,
  address,
  isAgency,
  hideOfficeLocation = false,
  onAgencyAddressClick = () => undefined,
  onAgencyLogoClick = () => undefined,
}: Props): JSX.Element => (
  <div css={styles.agencyDetails({ profileTier })} data-testid="agency-details">
    {imageUrl && (
      <AgencyLogo
        heroSection={heroSection}
        profileTier={profileTier}
        agencyUrl={agencyUrl}
        imageUrl={imageUrl}
        agencyName={agencyName}
        isAgency={isAgency}
        isActive
        onAgencyLogoClick={onAgencyLogoClick}
      />
    )}
    <div data-testid={`trade-profile-hero-${heroSection}__agency-details-body`}>
      <p
        css={styles.agencyNameSmall}
        data-testid={`trade-profile-hero-${heroSection}__name`}
      >
        {agencyName}
      </p>
      {!hideOfficeLocation && (
        <AgencyAddress
          heroSection={heroSection}
          address={address}
          onAgencyAddressClick={onAgencyAddressClick}
        />
      )}
    </div>
  </div>
);

export default AgencyDetailsSectionSmall;
