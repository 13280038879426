import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { notEmpty } from '../../utils';
import { ContactProfileSalesStatisticsFragment } from '../../generated/graphql';
import { ContactSalesStatistics } from './components';
import { SUBURB_STATISTICS_FRAGMENT } from './shared';

export type Props = {
  agencyId: number;
  agentIdV2: string;
  tracking?: {
    onSuburbChange?: (suburbName: string) => void;
  };
};

const fragmentName = 'ContactProfileSalesStatistics';
const GET_CONTACT_SALES_STATISTICS_FRAGMENT = gql`
  fragment ContactProfileSalesStatistics on Contact {
    id
    agencyId
    agentIdV2
    isHideSoldLeasedListings

    agencyByAgencyId {
      showTabSoldLastYear
    }

    activeSuburbs {
      suburbName
      suburbSlug
      listingCount
    }

    suburbStatistics {
      ...SuburbStatistics
    }
  }
  ${SUBURB_STATISTICS_FRAGMENT}
`;

const ContactSalesStatisticsWithFragment = ({
  agencyId,
  agentIdV2,
  tracking,
}: Props): React.ReactElement | null => {
  const { complete, data } = useFragment<ContactProfileSalesStatisticsFragment>(
    {
      from: {
        __typename: 'Contact',
        id: `${agencyId}-${agentIdV2}`,
      },
      fragment: GET_CONTACT_SALES_STATISTICS_FRAGMENT,
      fragmentName,
    },
  );

  if (
    !complete ||
    !data ||
    // Hide if agent has specified to hide
    data.isHideSoldLeasedListings ||
    // Or if agency hasn't elected to show sold
    !data?.agencyByAgencyId?.showTabSoldLastYear ||
    // Hide if no sold properties
    !data?.suburbStatistics?.total ||
    // Minimum one active suburb
    !data?.activeSuburbs?.length
  ) {
    return null;
  }

  // Type safety - remove nully active suburbs
  const activeSuburbs = data?.activeSuburbs?.filter(notEmpty) || [];

  return (
    <ContactSalesStatistics
      agencyId={agencyId}
      agentIdV2={agentIdV2}
      tracking={tracking}
      allSuburbStatistics={data.suburbStatistics}
      activeSuburbs={activeSuburbs}
    />
  );
};

ContactSalesStatisticsWithFragment.fragmentName = fragmentName;
ContactSalesStatisticsWithFragment.fragment =
  GET_CONTACT_SALES_STATISTICS_FRAGMENT;

export default ContactSalesStatisticsWithFragment;
