import { css } from '@emotion/react';
import { tokens, applyFont, media } from '@domain-group/fe-brary';
import * as util from '../../style/util';

const { spacing, typography } = tokens;

export const container = css`
  ${util.container({ gap: spacing.xs })};
`;

export const contentWrapper = css`
  ${util.contentWrapper({
    gap: spacing.l,
    flexDirection: 'column',
    alignItems: 'center',
  })};

  sup {
    font-size: 0.3em;
    vertical-align: top;
    position: relative;
    top: -0.8em;
  }
`;

export const textSection = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  min-width: 343px;

  gap: ${spacing.m};

  h1 {
    ${applyFont(typography.displayLarge)}

    span {
      display: block;
      margin-bottom: ${spacing.m};
      ${applyFont(typography.displayMedium)};
    }
  }

  p {
    ${applyFont(typography.lead)}
  }

  ${media('>=desktop')} {
    text-align: left;
  }
`;

export const imageSection = css`
  display: flex;
  justify-content: center;
  gap: ${spacing.xs};
  min-width: 343px;

  ${media('>=tablet')} {
    min-width: 448px;
  }
`;

export const contentContainerStyles = css`
  gap: ${spacing.l};
`;
