import React from 'react';
import { gql, useQuery } from '@apollo/client';
import AgencyTeamWithFragment from './agency-team-with-fragment';
import { GetAgencyTeamQuery } from '../../generated/graphql';

export type Props = {
  agencyId: number;
  mobilePadding?: string;
  // Events
  onViewMore?: () => void;
  // Profile Card events
  onAgentViewProfile?: () => void;
  onAgentCall?: () => void;
  onAgentCallReveal?: () => void;
  onAgentGetAppraisal?: () => void;
  onAgentOpenEnquiryForm?: () => void;
  // Profile Card Enquiry Form events
  onAgentEnquirySubmit?: () => void;
  onAgentEnquirySubmitSuccess?: () => void;
  onAgentEnquirySubmitError?: () => void;
  onAgentEnquiryFinish?: (() => void) | undefined;
};

export const GET_AGENCY_TEAM_QUERY = gql`
  query getAgencyTeam($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      ...AgencyTeam
    }
  }
  ${AgencyTeamWithFragment.fragment}
`;

const AgencyTeamWithQuery = ({
  agencyId,
  mobilePadding,
  onViewMore = () => undefined,
  onAgentViewProfile = () => undefined,
  onAgentCall = () => undefined,
  onAgentCallReveal = () => undefined,
  onAgentGetAppraisal = () => undefined,
  onAgentOpenEnquiryForm = () => undefined,
  onAgentEnquirySubmit = () => undefined,
  onAgentEnquirySubmitSuccess = () => undefined,
  onAgentEnquirySubmitError = () => undefined,
  onAgentEnquiryFinish = () => undefined,
}: Props): React.ReactElement | null => {
  const { data } = useQuery<GetAgencyTeamQuery>(GET_AGENCY_TEAM_QUERY, {
    variables: { agencyId },
  });

  if (!data) {
    return null;
  }

  return (
    <AgencyTeamWithFragment
      agencyId={agencyId}
      mobilePadding={mobilePadding}
      onViewMore={onViewMore}
      onAgentViewProfile={onAgentViewProfile}
      onAgentCall={onAgentCall}
      onAgentCallReveal={onAgentCallReveal}
      onAgentGetAppraisal={onAgentGetAppraisal}
      onAgentOpenEnquiryForm={onAgentOpenEnquiryForm}
      onAgentEnquirySubmit={onAgentEnquirySubmit}
      onAgentEnquirySubmitSuccess={onAgentEnquirySubmitSuccess}
      onAgentEnquirySubmitError={onAgentEnquirySubmitError}
      onAgentEnquiryFinish={onAgentEnquiryFinish}
    />
  );
};

export default AgencyTeamWithQuery;
