import React, { useState } from 'react';
import Modal from '@domain-group/fe-co-modal';
import Link from 'next/link';
import { useReducedMotion } from 'framer-motion';
import { srOnly } from '@domain-group/fe-brary';
import { Avatar, Header, EnquiryForm, Details } from '../shared';
import { ContactButtons, SoldStatistics } from './components';

import * as styles from './agency-profile-card.style';

interface Statistics {
  sold?: number;
  forSale?: number;
  leased?: number;
  forRent?: number;
}

interface Agent {
  profileUrl: string;
  name: string;
  profilePhoto?: string | null;
}

export type AgencyProfileCardProps = {
  agencyId: number;
  name: string;
  profileUrl: string;
  statistics?: Statistics | null;
  telephone?: string | null;
  logoUrl?: string | null;
  logoColour?: string | null;
  address?: string | null;
  isShortStats?: boolean | null;
  topAgents?: Agent[] | null;
  hideOfficeLocation?: boolean;
  // Callbacks
  onCall?: () => void;
  onCallReveal?: () => void;
  onViewProfile?: (details: { agencyId: number }) => void;
  onOpenEnquiryForm?: () => void;
  onEnquirySubmit?: (details: Details) => void;
  // Layout
  isVertical?: boolean;
  profileTier?: 'basic' | 'branded' | 'platinum';
};

const AgencyProfileCard = ({
  agencyId,
  logoUrl,
  logoColour,
  name,
  address,
  telephone,
  profileUrl,
  statistics,
  isShortStats = false,
  topAgents = [],
  // Callbacks
  onCall = () => null,
  onCallReveal = () => null,
  onViewProfile = () => null,
  onOpenEnquiryForm = () => null,
  onEnquirySubmit = () => null,
  // Layout
  isVertical = false,
  profileTier = 'basic',
  hideOfficeLocation,
}: AgencyProfileCardProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const shouldReduceMotion = useReducedMotion();

  const isBranded = profileTier === 'branded';
  const isPlatinum = profileTier === 'platinum';

  return (
    <>
      <div css={styles.container} data-testid="profile-card">
        <Header
          logoPosition="left"
          agencyLogoUrl={logoUrl || ''}
          agencyName={name || ''}
          logoColour={logoColour || undefined}
          isVertical={isVertical}
          isBranded={isBranded}
          isPlatinum={isPlatinum}
        />
        <div css={styles.body({ isVertical, isPlatinum })}>
          <div>
            <h3 css={styles.name({ isVertical, isBranded, isPlatinum })}>
              {name}
            </h3>
            {!hideOfficeLocation && <p css={styles.location}>{address}</p>}
            <Link href={profileUrl}>
              <a
                css={styles.link}
                href={profileUrl}
                onClick={() => onViewProfile({ agencyId })}
              >
                <span css={srOnly}>View {name}&apos;s profile</span>
              </a>
            </Link>
          </div>
          <SoldStatistics
            sold={statistics?.sold}
            forSale={statistics?.forSale}
            leased={statistics?.leased}
            forRent={statistics?.forRent}
            isVertical={isVertical}
            isShortStats={isShortStats}
          />
          <div>
            {topAgents?.length ? (
              <div css={styles.avatars({ isVertical })}>
                {topAgents.map(
                  ({
                    profileUrl: agentProfileUrl,
                    profilePhoto,
                    name: agentName,
                  }) => (
                    <Link
                      href={agentProfileUrl}
                      key={`${agentName}-${agentProfileUrl}`}
                    >
                      <a href={agentProfileUrl} title={agentName}>
                        <span css={srOnly}>
                          View the profile of {agentName}{' '}
                        </span>
                        <Avatar
                          css={styles.avatar}
                          imageUrl={profilePhoto}
                          imageTitle={agentName}
                          size="small"
                        />
                      </a>
                    </Link>
                  ),
                )}
              </div>
            ) : null}
          </div>
          <div css={styles.contactButtonsWrapper(isVertical)}>
            <ContactButtons
              isVertical={isVertical}
              agencyId={agencyId}
              name={name}
              telephone={telephone}
              onCall={onCall}
              onCallReveal={onCallReveal}
              onOpenModal={() => {
                setModalOpen(true);
                onOpenEnquiryForm();
              }}
            />
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal
          closeCallback={() => setModalOpen(false)}
          hasCloseAnimation={!shouldReduceMotion}
        >
          <div css={styles.modalContainer}>
            <EnquiryForm
              referenceId={agencyId}
              name={name}
              enquiryType="agencyProfile" // Needs to be agencyProfile, this is an enum value
              onSubmit={onEnquirySubmit}
              onFinish={() => setModalOpen(false)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AgencyProfileCard;
