import React, { useEffect, useRef } from 'react';
import { LinkButton, Button } from '@domain-group/fe-co-button';
import { srOnly } from '@domain-group/fe-brary';
import { AppraisalIllustration } from '../../..';
import * as styles from './submission-success.style';

type SubmissionSuccessProps = {
  resetForm: () => void;
};

const submissionSuccessTitleId = 'submission-success__title';

const SubmissionSuccess = ({
  resetForm,
}: SubmissionSuccessProps): JSX.Element => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    sectionRef?.current?.focus();
  }, []);

  return (
    <section
      aria-labelledby={submissionSuccessTitleId}
      tabIndex={-1}
      ref={sectionRef}
    >
      <h3 css={styles.modalTitle} id={submissionSuccessTitleId}>
        Enquiry sent!
      </h3>
      <article css={styles.article}>
        <div>
          <h1 css={styles.articleTitle}>Looking to sell?</h1>
          <p css={styles.articleParagraph}>
            Find out the state of the market and how much you can expect to
            receive from your property from experts in your area.
          </p>
          <LinkButton css={styles.articleLink} href="/sell" appearance="link">
            Find out more
          </LinkButton>
        </div>
        <div css={styles.illustration}>
          <AppraisalIllustration />
        </div>
      </article>
      <Button
        appearance="outline"
        onClick={resetForm}
        data-testid="enquiry-start-over"
      >
        Ok<span css={srOnly}> reset enquiry form</span>
      </Button>
    </section>
  );
};

export default SubmissionSuccess;
