export default (arr: string[]): string =>
  arr
    .map((item: string, index: number, array: string[]): string => {
      let link = '';

      if (index < array.length - 2) {
        link = ', ';
      } else if (index === array.length - 2) {
        link = ' and ';
      }
      return `${item}${link}`;
    })
    .join('');
