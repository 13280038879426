/* eslint-disable import/prefer-default-export */
import React from 'react';

import type { Choice } from '../../../@types';

import * as style from './radio-tiles.style';

export type RadioTilesProps = {
  title?: string;
  current?: Choice<string>;
  groupName: string;
  handleChange: (newChoice?: Choice<string>) => void;
  options?: Choice<string>[];
};

export const RadioTiles = ({
  title,
  groupName, // Name of the radio group
  options = [],
  handleChange,
  current,
}: RadioTilesProps): JSX.Element => (
  <div
    aria-labelledby={`label_${groupName}`}
    css={style.radioGroup}
    role="radiogroup"
    tabIndex={0}
    aria-controls="listing-results"
  >
    <div
      css={[style.filterHeading, style.headingSpacer]}
      data-testid="listing-tabs__filters-title"
      id={`label_${groupName}`}
    >
      {title}
    </div>

    <div css={style.tileContainer}>
      {options?.map((choice) => (
        <label
          data-testid={`${groupName}_${choice.value}`}
          key={`${groupName}_${choice.value}`}
          htmlFor={`${groupName}_${choice.value}`}
          css={style.tile({
            isChecked: current?.value === choice?.value,
          })}
        >
          {choice.label}
          <input
            aria-checked={current?.value === choice.value}
            aria-label={choice.label}
            checked={current?.value === choice.value}
            css={style.hiddenInput}
            id={`${groupName}_${choice.value}`}
            onChange={() => {
              handleChange(choice);
            }}
            tabIndex={-1}
            type="radio"
          />
        </label>
      ))}
    </div>
  </div>
);
