import EnquiryForm from './enquiry-form';
import Form, { Details as DetailsType } from './form';

export { INTENT_OPTIONS } from './constants';

export { Form };

export type Details = DetailsType;

export default EnquiryForm;
