import notEmpty from './array-filter-not-empty';

import type { VendorReview } from '../generated/graphql';
import type { ReviewListEntry } from '../@types';

export default (reviews: Array<VendorReview | null>): Array<ReviewListEntry> =>
  reviews.filter(notEmpty).map((review, index) => ({
    index: index + 1,
    descLength: review?.description?.length ?? 0,
    bedrooms: review?.bedrooms ?? 0,
    bathrooms: review?.bathrooms ?? 0,
    carparks: review?.carparks ?? 0,
    title: review?.title ?? '',
    description: review?.description ?? '',
    reviewedOn: review?.reviewedOn ?? new Date().toString(),
    streetAddress: review?.streetAddress ?? '',
    postcode: review?.postCode ?? '',
    suburb: review?.suburb ?? '',
    state: review?.state ?? '',
    agentReviewTrackingPixelUrl: review?.agentReviewTrackingPixelUrl ?? '',
    agencyReviewTrackingPixelUrl: review?.agencyReviewTrackingPixelUrl ?? '',
    reviewerName: review?.reviewerName ?? '',
  }));
