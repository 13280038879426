type ProfileTier = {
  basic: boolean;
  branded: boolean;
  platinum: boolean;
};

const getProfileTier = (tier?: string): ProfileTier => ({
  basic: tier === 'basic' || !tier,
  branded: tier === 'branded',
  platinum: tier === 'platinum',
});

export default getProfileTier;
