import { css, keyframes } from '@emotion/react';
import {
  rgba,
  globalTokens,
  media,
  fontH6,
  tokens,
} from '@domain-group/fe-brary';

const lightGrey = tokens.colors.neutral.medium.default;
const lightFontColour = css`
  color: ${lightGrey};
`;

export const formGroup = css`
  ${media('>tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${tokens.spacing.s};
  }
`;

export const intro = css`
  position: relative;
  ${fontH6};
  ${lightFontColour};
  width: 100%;
  margin: ${tokens.spacing.s} 0;
  padding-bottom: ${tokens.spacing.xxs};
  text-transform: ${tokens.typography.overline.textTransform};
  grid-column: 1 / span 2;
  letter-spacing: 1px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: ${tokens.colors.neutral.trim.default};
  }
`;

export const radioGroup = css`
  ${media('>=tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${tokens.spacing.s};
    grid-row-gap: ${tokens.spacing.xs};
    margin: ${tokens.spacing.m} 0;

    > div {
      margin: 0;
    }

    span {
      text-align: left;
    }
  }
`;

export const button = css`
  width: 225px;
  margin-top: ${tokens.spacing.m};
`;

export const form = css`
  position: relative;
`;

export const fieldset = css`
  /* Fieldset reset */
  min-width: 0;

  &:not(:-moz-handler-blocked) {
    display: table-cell;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const submittingLoader = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba(globalTokens.colors.white, 0.75)};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1s ease 0s 1;
`;
