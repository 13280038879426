import { css } from '@emotion/react';
import { applyFont, tokens } from '@domain-group/fe-brary';
import { Target, Transition } from 'framer-motion';

export { srOnly } from '@domain-group/fe-brary';

const { spacing, typography } = tokens;
interface AnimationSettings {
  collapsed: Target;
  open: Target;
  transition: Transition;
}

export const animationSettings = (
  shouldReduceMotion: boolean | null,
): AnimationSettings => ({
  collapsed: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
  },
  open: {
    opacity: 1,
    height: 'auto',
  },
  transition: {
    duration: shouldReduceMotion ? 0 : 0.5,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
});

export const expandFeedbackButton = css`
  ${applyFont(typography.bodyBold)}
  margin-bottom: -8px;

  > *:first-of-type {
    margin-right: ${spacing.xxs};
  }
`;
