import React from 'react';
import { gql, useFragment } from '@apollo/client';
import Testimonials from './components/testimonials';
import formatTestimonials from './utils/format-testimonials';
import { TestimonialsFragment } from '../../generated/graphql';

const TYPE_NAME = 'Contact';
export const GET_CONTACT_TESTIMONIALS = gql`
  fragment Testimonials on Contact {
    id
    testimonials {
      testimonial
      author
      dateAuthored
    }
  }
`;

const ContactTestimonialsWithFragment = ({
  agencyId,
  agentIdV2,
}: {
  agentIdV2: string;
  agencyId: number;
}): JSX.Element | null => {
  const { complete, data } = useFragment<TestimonialsFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id: `${agencyId}-${agentIdV2}`,
    },
    fragment: GET_CONTACT_TESTIMONIALS,
    fragmentName: 'Testimonials',
  });

  if (!complete || !data?.testimonials || !data?.testimonials?.length) {
    return null;
  }

  const formattedTestimonials = formatTestimonials(data.testimonials);

  return <Testimonials testimonials={formattedTestimonials} />;
};

ContactTestimonialsWithFragment.fragment = GET_CONTACT_TESTIMONIALS;
ContactTestimonialsWithFragment.fragmentName = 'Testimonials';

export default ContactTestimonialsWithFragment;
