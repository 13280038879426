import React from 'react';
import getConfig from 'next/config';
import Header from '@domain-group/fe-co-header';

import { Breadcrumb, mapHeaderData } from './utils';

const { publicRuntimeConfig } = getConfig();

const SearchResultsHeader = ({
  breadcrumbsData,
}: {
  breadcrumbsData: Breadcrumb[];
}): JSX.Element => {
  const headerData = mapHeaderData({
    baseUrl: publicRuntimeConfig.BASE_URL,
    breadcrumbsData,
  });

  return <Header {...headerData} />;
};

export default SearchResultsHeader;
