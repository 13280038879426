import { css, SerializedStyles } from '@emotion/react';
import { media, tokens, applyFont } from '@domain-group/fe-brary';
import { HERO_SECTION, PROFILE_TIERS } from '../../../constants';
import { stickyBarHeight } from '../../sticky-bar/sticky-bar.style';

const {
  colors: { neutral },
  spacing,
  typography,
} = tokens;

export const agencyDetails = ({
  heroSection,
}: {
  heroSection: HERO_SECTION;
}): SerializedStyles => css`
  ${heroSection === HERO_SECTION.sticky &&
  css`
    display: flex;
    align-items: center;
    height: ${stickyBarHeight}px;
  `};
`;

export const body = ({
  heroSection,
  isActive = false,
}: {
  heroSection: HERO_SECTION;
  isActive?: boolean;
}): SerializedStyles => css`
  ${heroSection === HERO_SECTION.sticky &&
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition:
      opacity 0.4s ease-in-out,
      max-width 0.4s ease-in-out;
    min-width: 0;
    flex-grow: 1;

    ${isActive &&
    css`
      opacity: 1;
    `};
  `};
`;

export const agencyName = ({
  heroSection,
  profileTier,
}: {
  heroSection: HERO_SECTION;
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  ${heroSection === HERO_SECTION.section &&
  css`
    ${applyFont(typography.subheading)}
    display: inline-block;
    margin-bottom: 0;
    color: ${neutral.heavy.default};

    ${(profileTier === PROFILE_TIERS.branded ||
      profileTier === PROFILE_TIERS.platinum) &&
    css`
      ${applyFont(typography.heading)}
      font-weight: normal;
    `};

    ${media('>=tablet')} {
      ${applyFont(typography.heading)}
    }
  `};

  ${heroSection === HERO_SECTION.banner &&
  css`
    color: ${neutral.surface.default};
    margin-bottom: ${spacing.m};

    ${profileTier === PROFILE_TIERS.platinum &&
    css`
      ${media('>=tablet')} {
        ${applyFont(typography.subheading)}
        font-weight: normal;
      }
    `};
  `};

  ${heroSection === HERO_SECTION.sticky &&
  css`
    color: ${neutral.surface.default};
    ${applyFont(typography.subheading)}
    font-weight: normal;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;
