import React from 'react';

import ReinswIcon from './reinsw-icon';
import * as styles from './badge.style';

interface BadgeProps {
  name: string;
  isFullWidthSection: boolean;
}

const Badge = ({ name, isFullWidthSection }: BadgeProps): JSX.Element => (
  <div css={styles.badge(isFullWidthSection)}>
    <div css={styles.iconContainer}>
      <ReinswIcon />
    </div>
    <div css={styles.divider(isFullWidthSection)} />
    <p css={styles.badgeText(isFullWidthSection)}>
      {name} is a member of the Real Estate Institute of New South&nbsp;Wales.
    </p>
  </div>
);

export default React.memo(Badge);
