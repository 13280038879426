import React from 'react';
import { Radio } from '@domain-group/fe-co-form-elements';
import { Option } from './filters-form';
import * as style from './radio-option-group.style';

interface RadioOptionGroupProps {
  current?: string | null;
  title: string;
  groupName: string;
  handleChange: (option: string) => void;
  options?: Option[];
}

const RadioOptionGroup = ({
  current = null,
  title,
  groupName,
  handleChange,
  options = [],
}: RadioOptionGroupProps): JSX.Element => (
  <div
    aria-labelledby={`label_${groupName}`}
    css={style.radioGroup}
    role="radiogroup"
    tabIndex={0}
  >
    <div
      css={[style.filterHeading, style.headingSpacer]}
      data-testid="listing-tabs__filters-title"
      id={`label_${groupName}`}
    >
      {title}
    </div>
    {options?.map((choice) =>
      choice ? (
        <div
          key={`radio-form__${choice.value}`}
          css={style.radioLine}
          data-testid={`${groupName}_${choice.value}`}
        >
          <Radio
            aria-checked={current === choice?.value}
            aria-label={choice.label}
            checked={current === choice?.value}
            id={`${groupName}_${choice.value}`}
            labelContent={
              <div
                css={
                  current === choice?.value ? style.selectedLabel : undefined
                }
              >
                {' '}
                {choice.label}{' '}
              </div>
            }
            name={groupName}
            onChange={() => {
              handleChange(choice?.value);
            }}
            tabIndex={-1}
            value={choice.value}
          />
          {choice?.count ? (
            <span css={style.badge(current === choice?.value)}>
              {choice?.count}
            </span>
          ) : null}
        </div>
      ) : null,
    )}
  </div>
);

export default RadioOptionGroup;
