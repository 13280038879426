/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';

const digitalDataInit = ({
  trackingDisabled,
}: {
  trackingDisabled: boolean;
}): ReactElement => (
  <>
    {!trackingDisabled && (
      <script
        title="digital-data-init"
        key="data-layer"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `if (typeof dataLayer === 'undefined') {
        dataLayer = [];
      }`,
        }}
      />
    )}
  </>
);

export default digitalDataInit;
