import React from 'react';
import { gql, useQuery } from '@apollo/client';
import AgencyVideoWithFragment from './agency-video-with-fragment';
import { AgencyIdProps } from '../../@types';
import { GetAgencyVideoQuery } from '../../generated/graphql';

export const GET_AGENCY_VIDEO_QUERY = gql`
  query getAgencyVideo($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      ...AgencyVideo
    }
  }
  ${AgencyVideoWithFragment.fragment}
`;

const AgencyVideoWithQuery = ({
  agencyId,
}: AgencyIdProps): React.ReactElement | null => {
  const { data } = useQuery<GetAgencyVideoQuery>(GET_AGENCY_VIDEO_QUERY, {
    variables: { agencyId },
  });

  if (!data) {
    return null;
  }

  return <AgencyVideoWithFragment agencyId={agencyId} />;
};

export default AgencyVideoWithQuery;
