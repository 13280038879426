import React from 'react';
import { gql, useQuery } from '@apollo/client';

import AgencyEnquiryFormWithFragment, {
  AGENCY_ENQUIRY_FORM_SECTION_FRAGMENT,
  AGENCY_ENQUIRY_FORM_PREFILL_FRAGMENT,
} from './agency-enquiry-form-with-fragment';
import { Details } from '../shared/enquiry-form/form';
import {
  AgencyEnquiryFormQuery,
  AgencyEnquiryFormUserPrefillQuery,
} from '../../generated/graphql';

export const AGENCY_ENQUIRY_FORM = gql`
  query agencyEnquiryForm($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      ...AgencyEnquiryFormSection
    }
  }
  ${AGENCY_ENQUIRY_FORM_SECTION_FRAGMENT}
`;

export const AGENCY_ENQUIRY_FORM_USER_DETAILS_PREFILL = gql`
  query agencyEnquiryFormUserPrefill {
    me {
      ...AgencyEnquiryFormUserDetailsPrefill
    }
  }
  ${AGENCY_ENQUIRY_FORM_PREFILL_FRAGMENT}
`;

export type AgencyEnquiryFormWithFragmentProps = {
  agencyId: number;
  onInView?: () => void;
  onSubmit?: (submitValues: Details) => void;
  onSubmitSuccess?: (submitValues: Details) => void;
  onSubmitError?: (submitValues: Details) => void;
};

const AgencyEnquiryFormWithQuery = ({
  agencyId,
  onInView,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
}: AgencyEnquiryFormWithFragmentProps): JSX.Element | null => {
  const { data, loading, error } = useQuery<AgencyEnquiryFormQuery>(
    AGENCY_ENQUIRY_FORM,
    { variables: { agencyId } },
  );

  const { data: userData, loading: userLoading } =
    useQuery<AgencyEnquiryFormUserPrefillQuery>(
      AGENCY_ENQUIRY_FORM_USER_DETAILS_PREFILL,
    );

  if (loading || userLoading || error) {
    return null;
  }

  if (!data || !data?.agency?.id) {
    return null;
  }

  return (
    <AgencyEnquiryFormWithFragment
      agencyId={agencyId}
      userFragmentId={userData?.me?.id}
      onInView={onInView}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitError={onSubmitError}
    />
  );
};

export default AgencyEnquiryFormWithQuery;
