import React, { useState } from 'react';
import { srOnly } from '@domain-group/fe-brary';
import * as styles from './agency-logo.style';
import { HERO_SECTION, PROFILE_TIERS } from '../../../constants';

export type Props = {
  heroSection: HERO_SECTION;
  profileTier: PROFILE_TIERS;
  agencyUrl: string;
  imageUrl: string;
  agencyName: string;
  isAgency?: boolean;
  isActive?: boolean;
  onAgencyLogoClick?: ({ url }: { url: string }) => void;
};

const AgencyLogo = ({
  heroSection,
  profileTier,
  agencyUrl,
  imageUrl,
  agencyName,
  isAgency = false,
  isActive = false,
  onAgencyLogoClick = () => undefined,
}: Props): JSX.Element => {
  const [hasError, setHasError] = useState(false);

  // Handle agency logo 404s by hiding logo
  if (hasError) {
    return <></>;
  }

  return (
    <a
      css={styles.agencyLogo({
        heroSection,
        isAgency,
        profileTier,
        isActive,
      })}
      data-testid="trade-profile-hero__agency-logo"
      href={agencyUrl}
      onClick={() => onAgencyLogoClick({ url: agencyUrl })}
    >
      <img
        src={imageUrl}
        alt={agencyName}
        onError={() => {
          setHasError(true);
        }}
      />
      <span css={srOnly}>{agencyName}</span>
    </a>
  );
};

export default AgencyLogo;
