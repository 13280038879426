/* eslint-disable import/prefer-default-export */
import { css, SerializedStyles } from '@emotion/react';
import { tokens, media, applyFont } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

export const breakpoint = 680;

export const tabLinkWrap = css`
  display: flex;
  background-color: ${tokens.colors.neutral.surface.default};
  position: relative;
  margin-bottom: ${tokens.spacing.s};
  ${media('>tablet')} {
    margin-bottom: ${tokens.spacing.m};
  }
`;

export const tabLink = (active: boolean): SerializedStyles => css`
  font: inherit;
  outline: inherit;
  display: block;
  padding: 0;
  padding-bottom: ${tokens.spacing.xxs};
  margin-right: ${tokens.spacing.m};
  border: 0;
  border-bottom: 2px solid transparent;
  color: ${tokens.colors.neutral.heavy.default};
  ${applyFont(tokens.typography.captionBold)}
  background: none;
  cursor: pointer;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 100%;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: transparent;
  }

  &:focus {
    ::after {
      background-color: ${tokens.colors.neutral.background.hover};
      opacity: 1;
    }
  }

  ${active &&
  css`
    ::after,
    :focus::after {
      opacity: 1;
      background-color: ${tokens.colors.primary.trim.pressed};
    }
  `};
`;
