import {
  DigitalDataCategory,
  DigitalDataPage,
  DigitalDataSearch,
  DigitalData,
  UserDetailsRedacted,
  MembershipType,
  MembershipState,
} from '../../../@types';

export interface DigitalDataOptions {
  pageInfo: Omit<DigitalDataPage, 'generator'>;
  category: DigitalDataCategory;
  search?: DigitalDataSearch;
  user?: UserDetailsRedacted;
}

const createDigitalData = ({
  pageInfo,
  category,
  search = {},
  user,
}: DigitalDataOptions): DigitalData => {
  let digitalDataUser;

  if (!user || !!user?.disableUserTracking) {
    digitalDataUser = {
      profile: {
        profileInfo: {
          profileId: null,
          domainUserId: null,
        },
      },
      isEuropeanTraffic: true,
      membershipType: MembershipType.visitor,
      membershipState: MembershipState.Not_logged_in,
    };
  } else {
    digitalDataUser = {
      profile: {
        profileInfo: {
          profileId: user.userId || null,
          domainUserId: user.domainUserId || null,
        },
      },
      isEuropeanTraffic: false,
      membershipType: user.userId
        ? MembershipType.member
        : MembershipType.visitor,
      membershipState: user.userId
        ? MembershipState.Logged_in
        : MembershipState.Not_logged_in,
      ipAddress: user.ipHash || null,
      sessionToken: user.sessionId || null,
      originalSession: user.sessionId || null,
      emailHash: user.emailHash || null,
    };
  }

  return {
    page: {
      pageInfo: {
        ...pageInfo,
        search: {
          ...search,
        },
        generator: 'DO',
      },
      category,
    },
    user: digitalDataUser,
    version: '1.0',
    events: [],
  };
};

export default createDigitalData;
