import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { handleError } from '@domain-group/fe-helper';

import AppraisalCTAWithFragment from './appraisal-cta-with-fragment';
import AppraisalCTALoader from './components/appraisal-cta-loader';
import AppraisalCTA from './components/appraisal-cta';
import { GetContactIdAppraisalCtaQuery } from '../../generated/graphql';

export type AppraisalContactProps = {
  contactId: number;
  url?: string;
  onClick?: () => void;
};

export const GET_CONTACT_ID_APPRAISAL_CTA_QUERY = gql`
  query getContactIdAppraisalCTA($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      ...AppraisalCTA
    }
  }
  ${AppraisalCTAWithFragment.fragment}
`;

const AppraisalCTAWithQuery = ({
  contactId,
  url,
  onClick,
}: AppraisalContactProps): JSX.Element | null => {
  const { data, loading, error } = useQuery<GetContactIdAppraisalCtaQuery>(
    GET_CONTACT_ID_APPRAISAL_CTA_QUERY,
    {
      variables: { contactId },
    },
  );

  const { agentIdV2, agencyId } = data?.contactByContactId || {};

  if (loading) {
    return <AppraisalCTALoader />;
  }

  if (error || !data || !agencyId || !agentIdV2) {
    handleError(error);
    return <AppraisalCTA contactId={contactId} url={url} />;
  }

  return (
    <AppraisalCTAWithFragment
      contactId={contactId}
      agencyId={agencyId}
      agentIdV2={agentIdV2}
      url={url}
      onClick={onClick}
    />
  );
};

AppraisalCTAWithQuery.query = GET_CONTACT_ID_APPRAISAL_CTA_QUERY;

export default AppraisalCTAWithQuery;
