import React from 'react';

export default (): JSX.Element => (
  <svg
    width="93"
    height="42"
    viewBox="0 0 93 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8099 42H92.4082V34.3274H19.8099V42Z"
      fill="#005C8F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1409 15.1511C17.0228 13.2575 19.1839 11.7974 21.5068 10.7665C18.9635 10.3079 15.9241 11.3179 13.6153 13.6412C11.2991 15.9655 10.3155 19.003 10.7945 21.5329C11.8147 19.2094 13.2595 17.0414 15.1409 15.1511Z"
      fill="#8AA7C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46148 6.37798C4.57724 8.27209 2.41518 9.73474 0.0912476 10.7657C2.63503 11.2253 5.67443 10.2153 7.98426 7.89153C10.3 5.56724 11.2851 2.53068 10.7997 0C9.7864 2.3248 8.33904 4.49128 6.46148 6.37798Z"
      fill="#8AA7C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.40178 15.0937C8.30321 16.9684 9.76288 19.1262 10.7944 21.4405C11.2613 18.906 10.2498 15.8745 7.91841 13.5727C5.58549 11.2663 2.53865 10.2848 0 10.7658C2.33291 11.7768 4.50626 13.219 6.40178 15.0937Z"
      fill="#005C8F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1973 6.43977C13.2995 4.56203 11.8393 2.40705 10.7998 0.0947876C10.3427 2.62598 11.3529 5.65742 13.6833 7.96227C16.0141 10.2641 19.061 11.2507 21.6027 10.7692C19.2667 9.75996 17.0933 8.31547 15.1973 6.43977Z"
      fill="#005C8F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4436 12.543C13.4436 13.0187 13.0619 13.4102 12.5799 13.4102C12.1015 13.4102 11.7131 13.0187 11.7131 12.543C11.7131 12.0665 12.1015 11.6783 12.5799 11.6783C13.0619 11.6783 13.4436 12.0665 13.4436 12.543Z"
      fill="#8AA7C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8099 16.8093H26.0441C28.7129 16.8093 30.6297 18.4952 30.6297 20.8133C30.6297 22.9202 29.5681 23.9962 27.5343 24.5683L31.1979 31.4517H29.0389L25.4931 24.8739H21.8285V31.4517H19.8099V16.8093ZM21.8282 18.5143V23.1463H25.6747C27.5099 23.1463 28.507 22.334 28.507 20.813C28.507 19.2849 27.5099 18.5143 25.5138 18.5143H21.8282Z"
      fill="#6F8F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.0846 16.8093H42.2269L41.6769 18.5356H35.0812V23.0246H41.1328V24.7724H35.0812V29.7072H41.7038L42.2916 31.4517H33.0846V16.8093Z"
      fill="#6F8F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9503 31.4517H45.9684V16.8093H43.9503V31.4517Z"
      fill="#6F8F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.1133 16.8429H50.8459L57.3785 28.3556V16.8429H59.4123V31.6091H56.9891L50.1243 19.5651V31.6091H48.1133V16.8429Z"
      fill="#005C8F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.611 26.7958C62.8801 28.029 63.2485 28.7019 64.006 29.3162C64.7892 29.974 65.8564 30.3188 67.1308 30.3188C68.9797 30.3188 70.4378 29.0914 70.4378 27.5763C70.4378 26.1439 69.4743 25.4055 66.6591 24.7503C64.3577 24.2178 63.9262 24.0324 62.6741 23.0068C61.894 22.1876 61.5438 21.3904 61.5438 20.4053C61.5438 18.0546 63.7384 16.4521 66.9672 16.4521C70.8511 16.4521 71.8146 18.7679 72.1845 19.5907L70.6679 20.9994C70.3806 20.1211 69.7795 18.2989 66.8048 18.2989C64.8492 18.2989 63.6407 19.0961 63.6407 20.4053C63.6407 21.7994 65.7961 22.3145 67.2137 22.6623C69.4743 23.1723 72.6142 24.0324 72.6142 27.3937C72.6142 30.238 70.2921 32.1231 66.7627 32.1231C64.6884 32.1231 63.0019 31.4472 61.9106 30.175C61.4612 29.6464 61.2388 29.2354 60.905 28.2129L62.611 26.7958Z"
      fill="#005C8F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.7529 16.8429H83.847L87.0952 28.8457L90.6484 16.8429H92.6206L88.2827 31.6091H86.106L82.8021 19.4649L79.4546 31.6091H77.2933L73.0018 16.8429H74.992L78.4431 28.8242L81.7529 16.8429Z"
      fill="#005C8F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.908 36.1549H27.9016L29.0134 39.3007H29.0249L30.1088 36.1549H31.0908V40.1728H30.4192V37.072H30.4079L29.2899 40.1728H28.7089L27.5912 37.072H27.5799V40.1728H26.908V36.1549Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.7092 36.1549H41.6106V36.7626H39.415V37.8147H41.4469V38.3889H39.415V39.5651H41.6501V40.1728H38.7092V36.1549Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.0256 36.1549H50.0192L51.131 39.3007H51.1425L52.2261 36.1549H53.2081V40.1728H52.5365V37.072H52.5253L51.4078 40.1728H50.8262L49.7088 37.072H49.6975V40.1728H49.0256V36.1549Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.532 39.5994H62.7793C63.2084 39.5994 63.4568 39.3684 63.4568 38.9633C63.4568 38.5694 63.2084 38.3441 62.7793 38.3441H61.532V39.5994ZM61.532 37.8379H62.6833C63.0219 37.8379 63.2762 37.6407 63.2762 37.2806C63.2762 36.8755 63.0671 36.7292 62.6833 36.7292H61.532V37.8379ZM60.8268 36.1549H62.7855C63.5081 36.1549 63.9822 36.4812 63.9822 37.1508C63.9822 37.5559 63.7734 37.8488 63.4063 38.0061V38.0176C63.8973 38.1245 64.1628 38.5071 64.1628 39.0528C64.1628 39.6833 63.7226 40.1728 62.6782 40.1728H60.8268V36.1549Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.5721 36.1549H74.4735V36.7626H72.2779V37.8147H74.3099V38.3889H72.2779V39.5651H74.513V40.1728H71.5721V36.1549Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.6059 37.9839H83.7631C84.209 37.9839 84.4517 37.7648 84.4517 37.3425C84.4517 36.8305 84.1187 36.7292 83.7519 36.7292H82.6059V37.9839ZM81.8999 36.1549H83.8249C84.7053 36.1549 85.1571 36.56 85.1571 37.2408C85.1571 38.0119 84.6207 38.2089 84.4909 38.2539V38.2651C84.7336 38.2989 85.0894 38.4789 85.0894 39.1147C85.0894 39.5817 85.1571 40.0096 85.3092 40.1728H84.553C84.4457 39.9984 84.4457 39.7789 84.4457 39.5817C84.4457 38.8447 84.2934 38.5183 83.6443 38.5183H82.6056V40.1728H81.8999V36.1549Z"
      fill="#FFFFFE"
    />
  </svg>
);
