/* eslint-disable react/no-danger */
import Head from 'next/head';
import React from 'react';
import htmlescape from 'htmlescape';

import type { DigitalData } from '../../../@types';

export type DigitalDataTagProps = {
  digitalData: DigitalData;
};

export const DigitalDataTag = ({
  digitalData,
}: DigitalDataTagProps): JSX.Element => (
  <Head>
    <script
      title="digital-data"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `var sitecatEnabled = false;
  var digitalData = ${htmlescape(digitalData)};
  var dataLayer = [digitalData];`,
      }}
    />
  </Head>
);
