import { css } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';
import { mobileBreakpoint } from './appraisal-cta.style';

export const card = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${mobileBreakpoint}px) {
    flex-direction: row;
    justify-content: center;
  }

  ${media('>=desktop')} {
    justify-content: flex-start;
  }
`;

export const avatar = css`
  width: 86px;
  height: 86px;
  margin-bottom: ${tokens.spacing.s};

  @media (min-width: ${mobileBreakpoint}px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: ${tokens.spacing.s};
    margin-right: ${tokens.spacing.m};
  }

  ${media('>=desktop')} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const text = css`
  width: 90%;
  height: 144px;

  @media (min-width: ${mobileBreakpoint}px) {
    width: 390px;
    height: 120px;
  }

  ${media('>desktop')} {
    width: 390px;
    height: 54px;
  }
`;
