import React from 'react';

import { AgencyAddress } from '../..';

import * as styles from './agency-banner.style';
import { HERO_SECTION } from '../../../constants';

export type Props = {
  agencyName: string;
  address: string;
  hideOfficeLocation?: boolean;
  onAgencyAddressClick: () => void;
};

const heroSection = HERO_SECTION.banner;

const AgencyBanner = ({
  agencyName,
  address,
  hideOfficeLocation = false,
  onAgencyAddressClick = () => undefined,
}: Props): JSX.Element => (
  <div data-testid={`trade-profile-hero-${heroSection}__agency-details-body`}>
    <h1
      css={styles.agencyNameLarge}
      data-testid={`trade-profile-hero-${heroSection}__name`}
    >
      {agencyName}
    </h1>
    {!hideOfficeLocation && (
      <AgencyAddress
        heroSection={heroSection}
        address={address}
        onAgencyAddressClick={onAgencyAddressClick}
      />
    )}
  </div>
);

export default AgencyBanner;
