import { sendGroupStats, getMixpanel } from '@domain-group/fe-helper';
import { trackCTAEntryToFunnel } from '@domain-group/fe-co-agent-showcase';
import pushToDataLayer from './push-to-datalayer';

export const APPRAISAL_ENTRY_POINT = 'Sell Landing Page';

const trackFindLocalAgent = () => {
  sendGroupStats(
    {
      platform: 'Website',
      eventType: 'CTALeadsToFunnel',
      sourceEntityType: 'SellerLandingPage',
      eventSource: 'Button-RequestAnAppraisal',
      eventCategory: 'takeaction.intent',
      targetEntityType: 'AppraisalFunnel',
    },
    undefined,
    true,
  );

  pushToDataLayer({
    event: 'GAevent',
    eventCategory: 'Interaction',
    eventAction: 'CTA - Find an Agent',
    eventLabel: 'Find an Agent - SL',
  });
};

const trackAppraisalFunnel = () => {
  trackCTAEntryToFunnel();

  pushToDataLayer({
    event: 'GAevent',
    eventCategory: 'Interaction',
    eventAction: 'CTA - Appraisal Funnel',
    eventLabel: 'Request an appraisal - SL',
  });

  // Mixpanel
  try {
    const mixpanel = getMixpanel();
    mixpanel.track('Request Appraisal', {
      entryPoint: APPRAISAL_ENTRY_POINT,
    });
  } catch (err) {
    console.error(err);
  }
};

const trackStartReading = () => {
  sendGroupStats(
    {
      platform: 'Website',
      eventType: 'CTALeadsToFunnel',
      sourceEntityType: 'SellerLandingPage',
      eventSource: 'Button-StartReading',
      eventCategory: 'takeaction.intent',
      targetEntityType: 'SellerContent',
    },
    undefined,
    true,
  );

  pushToDataLayer({
    event: 'GAevent',
    eventCategory: 'Interaction',
    eventAction: 'CTA - Seller Content',
    eventLabel: 'Start Reading - SL',
  });
};

export default () => ({
  trackFindLocalAgent,
  trackAppraisalFunnel,
  trackStartReading,
});
