import { gql } from '@apollo/client';

const SUBURB_STATISTICS_FRAGMENT = gql`
  fragment SuburbStatistics on SuburbSoldStatistics {
    averageDaysOnMarket
    averageSoldPrice
    totalSaleAmount
    total

    salesType {
      sold
      auction
    }

    listingType {
      apartment
      house
      land
      rural
    }

    listingTypeAverage {
      apartment
      house
      land
      rural
    }
  }
`;

export default SUBURB_STATISTICS_FRAGMENT;
