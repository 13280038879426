import { pushToDataLayer } from '@domain-group/fe-helper';

interface TrackSubmitEnquirySuccessOptions {
  isAgency: boolean;
  agencyId?: string;
  agencyName?: string;
  contactId?: number;
  contactName?: string;
  intent?: string;
}

const trackEnquirySubmitSuccess = ({
  isAgency,
  agencyId,
  agencyName,
  contactId,
  contactName,
  intent,
}: TrackSubmitEnquirySuccessOptions): void => {
  const eventLabel = isAgency
    ? `${agencyName} - ${agencyId}`
    : `${contactName} - ${contactId}`;

  pushToDataLayer({
    event: 'GAevent',
    eventAction: 'Email Enquiry Successful',
    eventCategory: 'Conversion',
    eventLabel,
    dimension147: intent,
  });
};

export default trackEnquirySubmitSuccess;
