import React from 'react';
import { gql, useQuery } from '@apollo/client';
import ContactProfileCardWithFragment from './contact-profile-card-with-fragment';
import { GetContactProfileCardQuery } from '../../generated/graphql';

export type Props = {
  contactId: number;
  agentIdV2: string;
  agencyId: number;
  // Card props
  isPropertyManager?: boolean;
  hideAppraisalCta?: boolean;
  isShortStats?: boolean;
  isVertical?: boolean;
  hideOffice?: boolean;
  // Events
  onViewProfile?: ({ contactId }: { contactId: number }) => void;
  onCall?: ({ contactId }: { contactId: number }) => void;
  onCallReveal?: () => void;
  onGetAppraisal?: () => void;
  onOpenEnquiryForm?: () => void;
  // Enquiry Form events
  onEnquirySubmit?: ({
    contactId,
    intent,
    phone,
    postcode,
    message,
  }: {
    contactId: number;
    postcode: string;
    intent?: string;
    phone?: string | null;
    message?: string;
  }) => void;
  onEnquirySubmitSuccess?: ({
    contactId,
    intent,
    name,
  }: {
    contactId: number;
    intent?: string;
    name?: string | null;
  }) => void;
  onEnquirySubmitError?: ({
    contactId,
    name,
  }: {
    contactId: number;
    name?: string | null;
  }) => void;
  onEnquiryFinish?: (() => void) | undefined;
  appraisalEntryPoint?: string;
};

export const GET_CONTACT_PROFILE_CARD_QUERY = gql`
  query getContactProfileCard($agencyId: Int!, $agentIdV2: String!) {
    contactByAgencyIdAndAgentId(agencyId: $agencyId, agentIdV2: $agentIdV2) {
      ...ContactProfileCard
    }
  }
  ${ContactProfileCardWithFragment.fragment}
`;

const ContactProfileCardWithQuery = ({
  contactId,
  agentIdV2,
  agencyId,
  // Card props
  isPropertyManager = false,
  hideAppraisalCta = false,
  isShortStats = false,
  isVertical = false,
  hideOffice = false,
  onViewProfile = () => undefined,
  onCall = () => undefined,
  onCallReveal = () => undefined,
  onGetAppraisal = () => undefined,
  onOpenEnquiryForm = () => undefined,
  onEnquirySubmit = () => undefined,
  onEnquirySubmitSuccess = () => undefined,
  onEnquirySubmitError = () => undefined,
  onEnquiryFinish = () => undefined,
  appraisalEntryPoint,
}: Props): JSX.Element | null => {
  const { data } = useQuery<GetContactProfileCardQuery>(
    GET_CONTACT_PROFILE_CARD_QUERY,
    {
      variables: { agentIdV2, agencyId },
    },
  );

  if (!data) {
    return null;
  }

  return (
    <ContactProfileCardWithFragment
      contactId={contactId}
      agentIdV2={agentIdV2}
      agencyId={agencyId}
      isPropertyManager={isPropertyManager}
      hideAppraisalCta={hideAppraisalCta}
      isShortStats={isShortStats}
      isVertical={isVertical}
      hideOffice={hideOffice}
      onViewProfile={onViewProfile}
      onCall={onCall}
      onCallReveal={onCallReveal}
      onGetAppraisal={onGetAppraisal}
      onOpenEnquiryForm={onOpenEnquiryForm}
      onEnquirySubmit={onEnquirySubmit}
      onEnquirySubmitSuccess={onEnquirySubmitSuccess}
      onEnquirySubmitError={onEnquirySubmitError}
      onEnquiryFinish={onEnquiryFinish}
      appraisalEntryPoint={appraisalEntryPoint}
    />
  );
};

export default ContactProfileCardWithQuery;
