import { css, SerializedStyles } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

export const optionContent = css`
  display: flex;
  align-items: center;
`;

export const option = css`
  ${applyFont(typography.body)}
  min-width: 0;
  flex-grow: 1;
  line-height: 24px;
`;

// Height and width to match fe-co-avatar small size
export const icon = css`
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  margin-right: ${spacing.s};

  svg {
    display: block;
    height: 32px;
    width: 32px;
  }
`;

export const name = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const optionCategory = css`
  display: block;
  ${applyFont(typography.mini)}
  color: ${colors.neutral.medium.default};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
`;

export const optionItemHighlightText = (
  highlighted: boolean,
): SerializedStyles => css`
  ${!highlighted &&
  css`
    ${applyFont(typography.bodyBold)}
  `};
`;
