/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';

import htmlescape from 'htmlescape';
import { DigitalData } from '../../@types';

const digitalData = ({
  digitalData: dData,
  titanEnabled,
}: {
  digitalData?: DigitalData;
  titanEnabled: boolean;
}): ReactElement | null => {
  if (!dData) {
    return null;
  }

  return (
    <script
      title="digital-data"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `var titanEnabled = ${titanEnabled ? 'true' : 'false'};
    var sitecatEnabled = false;
    var digitalData = ${htmlescape(dData)};
    var dataLayer = [digitalData];`,
      }}
    />
  );
};

export default digitalData;
