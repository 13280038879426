/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export const errorMessage = css`
  background-color: ${tokens.colors.critical.background.default};
  color: ${tokens.colors.critical.base.default};
  margin: 0 auto ${tokens.spacing.m};
  padding: ${tokens.spacing.xxs};
`;
