/* eslint-disable import/prefer-default-export */
import { variables } from '@domain-group/fe-brary';
import btoa from 'btoa-lite';

export const getSvgDataUrl = (logoColour?: string | null): string => {
  const circlePinSvg = `
<svg width="150" height="150" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <filter id="point" y="-500%" height="1500%" x="-500%" width="1500%">
   <feDropShadow dx="0" dy="0" stdDeviation="25" flood-color="black" flood-opacity="0.7"/>
  </filter>
 </defs>
  <circle cx="50%" cy="50%" r="10" stroke="white" stroke-width="3" style="fill:${
    logoColour || variables.colour.heading
  }; filter:url(#point);"/>
</svg>
`;

  return `data:image/svg+xml;charset=UTF-8;base64, ${btoa(circlePinSvg)}`;
};
