import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { Button } from '@domain-group/fe-co-button';
import { useFunnel } from '@domain-group/fe-funnel-utils';
import { Avatar, OptionsField, INTENT_OPTIONS } from '../../../shared';
import { buildAppraisalUrl } from '../../../../utils';
import * as styles from './step-intent.style';

const validationSchema = Yup.object().shape({
  intent: Yup.string().required(),
});

const Intent = () => {
  const { funnelProps, nav } = useFunnel();

  const initialValues = { intent: '' };

  const { handleSubmit, isValid, setFieldTouched, setFieldValue, values } =
    useFormik({
      validationSchema,
      initialValues,
      isInitialValid: () => validationSchema.isValidSync(initialValues),
      // validateOnMount: true,
      // enableReinitialize: true,
      onSubmit: ({ intent }) => {
        funnelProps?.onSubmitIntent(intent);

        if (intent === INTENT_OPTIONS[0].value) {
          // Navigate to appraisal funnel for "Selling" option
          global.window.location.href = buildAppraisalUrl({
            entryPoint: 'Agent Profile - Enquiry Form',
            contactId: funnelProps.contactId,
          });
        } else {
          // Navigate to next step for all other enquiries
          nav.next({
            type: 'setIntent',
            intent,
          });
        }
      },
    });

  // Listen for changes to "intent"
  const onChangeIntent = (fieldName, value) => {
    funnelProps?.onSelectIntent(value);

    setFieldValue(fieldName, value);
  };

  return (
    <form onSubmit={handleSubmit} css={styles.wrapper(funnelProps.isShortForm)}>
      <motion.div layoutId="avatar" css={styles.avatarHolder}>
        <Avatar
          imageTitle={funnelProps.contact.name}
          imageUrl={funnelProps.contact.profilePhoto.url}
          css={styles.avatar}
        />
      </motion.div>
      <h3 css={styles.title}>Contact {funnelProps.contact.name}</h3>
      <div role="radiogroup" aria-labelledby="group_label">
        <p id="group_label" css={styles.intro}>
          What would you like information about?
        </p>
        <OptionsField
          onBlur={setFieldTouched}
          onChange={onChangeIntent}
          fieldName="intent"
          selected={values?.intent}
          isTile
          options={INTENT_OPTIONS}
        />
      </div>
      <Button type="submit" disabled={!isValid} stretched css={styles.button}>
        Next
      </Button>
    </form>
  );
};

export default Intent;
