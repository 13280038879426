import React from 'react';
import { gql, useFragment } from '@apollo/client';
import { toGlobalId } from '../../utils';
import { AgencySalesStatisticsStoryFragment } from '../../generated/graphql';
import { Intro, SummarySales, SummarySegment, SummaryTop } from './components';

export type Props = {
  agencyId: number;
};

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencySalesStatisticsStory';
const FRAGMENT = gql`
  fragment AgencySalesStatisticsStory on Agency {
    id
    name
    profileUrl
    showTabSoldLastYear

    suburbStatistics {
      averageDaysOnMarket
      averageSoldPrice
      totalSaleAmount
      total

      salesType {
        sold
        auction
      }
      listingType {
        apartment
        house
        land
        rural
      }
      topListing {
        soldPrice
        daysOnMarket
        soldDate
        displayableAddress
        bedrooms
        listingType
        salesType
        state
        postcode
        adId
      }
    }

    activeSuburbs {
      suburbName
      salesType
      listingCount
      suburbSlug
    }
  }
`;

const AgencySalesStatisticsStoryWithFragment = ({
  agencyId,
}: Props): JSX.Element | null => {
  const agencyFragmentId = toGlobalId(TYPE_NAME, agencyId);

  const { complete, data: agency } =
    useFragment<AgencySalesStatisticsStoryFragment>({
      from: {
        __typename: TYPE_NAME,
        id: agencyFragmentId,
      },
      fragment: AgencySalesStatisticsStoryWithFragment.fragment,
      fragmentName: AgencySalesStatisticsStoryWithFragment.fragmentName,
    });

  // Hide if chosen by agency or no sold properties
  if (
    !complete ||
    !agency ||
    !agency?.showTabSoldLastYear ||
    agency?.suburbStatistics?.total === 0
  ) {
    return null;
  }

  return (
    <div data-testid="sales-statistics-story">
      <Intro name={agency?.name || ''}>
        <SummarySales
          name={agency?.name || ''}
          isAgent={false}
          agencyName={agency?.name || ''}
          agencyUrl={agency?.profileUrl || ''}
          totalSoldAndAuctioned={agency?.suburbStatistics?.total || 0}
          averageSoldPrice={agency?.suburbStatistics?.averageSoldPrice || 0}
          totalSoldPrice={agency?.suburbStatistics?.totalSaleAmount || 0}
        />
        <SummarySegment
          name={agency?.name || ''}
          house={agency?.suburbStatistics?.listingType?.house || 0}
          unit={agency?.suburbStatistics?.listingType?.apartment || 0}
          land={agency?.suburbStatistics?.listingType?.land || 0}
          rural={agency?.suburbStatistics?.listingType?.rural || 0}
          totalSold={agency?.suburbStatistics?.salesType?.sold || 0}
          totalAuctioned={agency?.suburbStatistics?.salesType?.auction || 0}
          averageDaysOnMarket={
            agency?.suburbStatistics?.averageDaysOnMarket || 0
          }
        />
        <SummaryTop
          isAgent={false}
          name={agency?.name || ''}
          soldPrice={agency?.suburbStatistics?.topListing?.soldPrice || 0}
          daysOnMarket={agency?.suburbStatistics?.topListing?.daysOnMarket || 0}
          displayableAddress={
            agency?.suburbStatistics?.topListing?.displayableAddress || ''
          }
          soldDate={agency?.suburbStatistics?.topListing?.soldDate || ''}
          bedrooms={agency?.suburbStatistics?.topListing?.bedrooms || 0}
          listingType={agency?.suburbStatistics?.topListing?.listingType || ''}
          salesType={agency?.suburbStatistics?.topListing?.salesType || ''}
          state={agency?.suburbStatistics?.topListing?.state || ''}
          postcode={agency?.suburbStatistics?.topListing?.postcode || ''}
          adId={agency?.suburbStatistics?.topListing?.adId || 0}
        />
      </Intro>
    </div>
  );
};

AgencySalesStatisticsStoryWithFragment.fragment = FRAGMENT;
AgencySalesStatisticsStoryWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencySalesStatisticsStoryWithFragment;
