import { Breadcrumb } from '.';

interface Statistics {
  totalForSale?: number | null;
  totalSoldAndAuctioned?: number | null;
  totalForRent?: number | null;
  totalLeased?: number | null;
}

const getAgentType = (statistics: Statistics | undefined | null): string => {
  const { totalForSale, totalSoldAndAuctioned, totalForRent, totalLeased } =
    statistics || {};

  if ((totalForSale || 0) + (totalSoldAndAuctioned || 0) > 0) {
    return 'sales-agents';
  }

  if ((totalForRent || 0) + (totalLeased || 0) > 0) {
    return 'property-managers';
  }

  return 'sales-agents';
};

const checkIfDefaultFilter = (agentType: string) =>
  agentType === 'sales-agents';

const mapContactBreadcrumbs = ({
  baseUrl,
  profileUrl = '',
  name = '',
  suburbName = '',
  suburbSlug = '',
  statistics,
}: {
  baseUrl: string;
  profileUrl?: string;
  name?: string;
  suburbName?: string;
  suburbSlug?: string;
  statistics?: Statistics | null;
}): Breadcrumb[] => {
  const agentType = getAgentType(statistics);
  const isDefaultFilter = checkIfDefaultFilter(agentType);

  return [
    {
      title: 'Real Estate Agents',
      url: `${baseUrl}/real-estate-agents/`,
    },
    {
      title: suburbName,
      url: `${baseUrl}/real-estate-agents/${suburbSlug}/${
        isDefaultFilter ? '' : `?agentFilter=${agentType}`
      }`,
    },
    {
      title: name,
      url: `${baseUrl}/real-estate-agent/${profileUrl}`,
    },
  ];
};

export default mapContactBreadcrumbs;
