/* eslint-disable react/no-danger */
import Head from 'next/head';
import React from 'react';
import htmlescape from 'htmlescape';

import type {
  AgencyDigitalDataFragment,
  ContactDigitalDataFragment,
} from '../../../../generated/graphql';

import type {
  DigitalData,
  DigitalDataPage,
  DigitalDataServerInfo,
  UserDetailsRedacted,
} from '../../../../@types';

import {
  agentPageTitleGenerator,
  agencyPageTitleGenerator,
  userDataGenerator,
} from '../../utils';

import { startCase } from '../../../../utils';

export type DigitalDataTagProps = {
  agencyData?: AgencyDigitalDataFragment;
  contactData?: ContactDigitalDataFragment;
  contactId?: number;
  source: string;
  isEmbeddedApp: boolean;
  sysEnv: DigitalDataPage['sysEnv'];
  user?: UserDetailsRedacted;
};

const capitalize = (str: string): string =>
  str
    .split(' ')
    .map((part) => startCase(part))
    .join(' ');

export const mapContactData = (
  data: ContactDigitalDataFragment,
  serverInfo: DigitalDataServerInfo,
  user?: UserDetailsRedacted,
): DigitalData => {
  const formattedAgencyName = startCase(data?.agencyName ?? '');
  const formattedSuburb = capitalize(data.address?.suburb?.name ?? '');
  const formattedAgentName = `${data?.name ?? ''} - ${formattedSuburb}`;
  const pageName = agentPageTitleGenerator({
    agencyName: data?.agencyName ?? '',
    displayAddress: data.address?.displayAddress ?? '',
    name: data?.name ?? '',
  });

  const digitalDataUser = userDataGenerator(user);

  return {
    events: [],
    version: '1.0',
    page: {
      pageInfo: {
        search: {},
        property: {},
        generator: 'DO',
        agencyId: `${data.agencyId || ''}`, // welcome to Web dev where everything is a string and types are made up
        agentId: serverInfo.contactId,
        ...(data.address?.suburb?.state
          ? { state: data.address.suburb.state }
          : {}),
        ...(data.address?.suburb?.name
          ? { suburb: data.address.suburb.name }
          : {}),
        ...(data.address?.suburb?.postcode
          ? { postcode: data.address.suburb.postcode }
          : {}),

        agencyName: formattedAgencyName,
        agentName: formattedAgentName,
        pageName,
        source: serverInfo.source,
        isEmbeddedApp: serverInfo.isEmbeddedApp,
        sysEnv: serverInfo.sysEnv,
      },
      category: {
        pageType: 'Agent Profile',
        primaryCategory: 'Find an Agent',
        subCategory1: 'Agent Profile',
        ...(data.profileTier
          ? { subCategory2: startCase(data.profileTier) }
          : {}),
      },
    },
    user: digitalDataUser,
  };
};

export const mapAgencyData = (
  data: AgencyDigitalDataFragment,
  serverInfo: DigitalDataServerInfo,
  user?: UserDetailsRedacted,
): DigitalData => {
  const formattedAgencyName = startCase(data?.name ?? '');
  const formattedSuburb = capitalize(data.address?.suburb?.name ?? '');
  const pageName = agencyPageTitleGenerator({
    agencyName: data?.name ?? '',
    suburb: data.address?.suburb?.name ?? '',
    state: data.address?.suburb?.state ?? '',
    postCode: data.address?.suburb?.postcode ?? '',
  });

  const digitalDataUser = userDataGenerator(user);

  return {
    events: [],
    version: '1.0',
    page: {
      pageInfo: {
        search: {},
        property: {},
        generator: 'DO',
        pageTemplate: 'agencyDetails',
        agencyId: `${data.agencyId || ''}`, // welcome to Web dev where everything is a string and types are made up
        ...(data.address?.suburb?.state
          ? { state: data.address.suburb.state }
          : {}),
        ...(data.address?.suburb?.name
          ? { suburb: data.address.suburb.name }
          : {}),
        ...(data.address?.suburb?.postcode
          ? { postcode: data.address.suburb.postcode }
          : {}),
        agencyName: `${formattedAgencyName} - ${formattedSuburb}`,
        pageName,
        source: serverInfo.source,
        isEmbeddedApp: serverInfo.isEmbeddedApp,
        sysEnv: serverInfo.sysEnv,
      },
      category: {
        pageType: 'Agency Profile',
        primaryCategory: 'Find an Agent',
        subCategory1: 'Agency Profile',
        ...(data.profileTier
          ? { subCategory2: startCase(data.profileTier) }
          : {}),
      },
    },
    user: digitalDataUser,
  };
};

export const DigitalDataTag = ({
  agencyData,
  contactData,
  source,
  isEmbeddedApp,
  sysEnv,
  contactId,
  user,
}: DigitalDataTagProps): JSX.Element => {
  let digitalData;
  const serverInfo: DigitalDataServerInfo = {
    source,
    isEmbeddedApp,
    sysEnv,
    contactId,
  };

  if (agencyData) {
    digitalData = mapAgencyData(agencyData, serverInfo, user);
  }

  if (contactData) {
    digitalData = mapContactData(contactData, serverInfo, user);
  }

  return (
    <Head>
      <script
        title="digital-data"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var sitecatEnabled = false;
  var digitalData = ${htmlescape(digitalData)};
  var dataLayer = [digitalData];`,
        }}
      />
    </Head>
  );
};
