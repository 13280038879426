import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import * as styles from './sold-statistics.style';

export type Props = {
  name?: 'Agency' | 'Agent';
  sold?: number | null;
  forSale?: number | null;
  leased?: number | null;
  forRent?: number | null;
  isVertical: boolean;
  isShortStats?: boolean | null;
};

const SoldStatistics = ({
  name = 'Agency',
  sold = null,
  forSale = null,
  leased = null,
  forRent = null,
  isVertical = false,
  isShortStats = false,
}: Props): React.ReactElement => (
  <div>
    <h4 css={srOnly}>{name} sales statistics</h4>
    <dl css={styles.statistics(isVertical)}>
      {sold ? (
        <div css={styles.statistic}>
          <dt>Sold</dt>
          <dd>{sold}</dd>
        </div>
      ) : null}
      {forSale ? (
        <div css={styles.statistic}>
          <dt>For sale</dt>
          <dd>{forSale}</dd>
        </div>
      ) : null}
      {!isShortStats && leased ? (
        <div css={styles.statistic}>
          <dt>Leased</dt>
          <dd>{leased}</dd>
        </div>
      ) : null}
      {forRent ? (
        <div css={styles.statistic}>
          <dt>For rent</dt>
          <dd>{forRent}</dd>
        </div>
      ) : null}
    </dl>

    {!sold && !forSale && !leased && !forRent ? (
      <div css={styles.unavailable}>
        <p>* Sales statistics unavailable</p>
      </div>
    ) : null}
  </div>
);

export default SoldStatistics;
