import { css } from '@emotion/react';
import { applyFont, tokens } from '@domain-group/fe-brary';

const { colors, spacing, borderRadius, typography } = tokens;

export const article = css`
  position: relative;
  border-radius: ${borderRadius.mini};
  background: ${colors.neutral.surface.default};
  color: ${colors.neutral.heavy.default};
  border: 1px solid ${colors.neutral.trim.default};
  padding: ${spacing.m};
  margin: ${spacing.s} 0;
  display: flex; /* Fallback */
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${spacing.s};
  align-items: center;
`;

export const modalTitle = css`
  ${applyFont(typography.heading)}
`;

export const articleTitle = css`
  ${applyFont(typography.subheading)}
  color: ${colors.neutral.heavy.default};
  margin: 0 0 ${spacing.xxs};
`;

export const articleParagraph = css`
  ${applyFont(typography.body)}
`;

export const articleLink = css`
  ${applyFont(typography.body)}
  color: ${colors.interactive.base.default};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: ${colors.interactive.base.hover};
  }
`;

export const illustration = css`
  max-width: 100%;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;
