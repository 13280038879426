import { css, SerializedStyles } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

// minimum width to show the phone number on a single line
export const contactButtonsDesktopWidth = 132;

export const wrapper = (
  isVertical = false,
  isNarrow = false,
): SerializedStyles => css`
  ${!isVertical &&
  css`
    ${media('>=desktop')} {
      width: ${contactButtonsDesktopWidth * 2 + 12}px;
    }
  `}

  ${!isVertical &&
  isNarrow &&
  css`
    ${media('>=desktop')} {
      width: ${contactButtonsDesktopWidth}px;
    }
  `}
`;

export const buttonGroup = (
  isVertical = false,
  isNarrow = false,
): SerializedStyles => css`
  display: grid;
  grid-column-gap: ${tokens.spacing.xs};
  grid-row-gap: ${tokens.spacing.xs};
  grid-template-rows: 1fr 1fr;
  z-index: 1;
  position: relative;

  ${isNarrow
    ? css`
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'single single';
      `
    : css`
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'single single' 'wide wide';
      `}

  ${!isVertical &&
  isNarrow &&
  css`
    ${media('>=desktop')} {
      display: grid;
      grid-template-columns: auto;
      grid-template-areas: 'single';
    }
  `}
`;

export const button = (isAppraisal = false): SerializedStyles => css`
  position: relative;
  z-index: 1;
  padding: 2px;

  ${isAppraisal &&
  css`
    grid-area: wide;
    min-width: ${contactButtonsDesktopWidth}px;
  `}

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
