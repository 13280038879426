import { sendGroupStats } from '@domain-group/fe-helper';
import { getPageDetails } from '../utils';
import { sendToMixpanel } from '../mixpanel';

export default ({
  isAgency,
  id,
  userId,
  userToken,
  suburbSlugs,
}: {
  isAgency: boolean;
  id: string | number;
  userId?: string;
  userToken?: string;
  suburbSlugs?: string[];
}): void => {
  const { pageName } = getPageDetails();

  sendToMixpanel('Profile Card Phone Button');

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/PhoneEnquiry/2.0.6-schema.json
  sendGroupStats({
    // eventVersion: '2.0.6',
    platform: 'Website' as any,
    eventType: 'PhoneEnquiry',
    eventCategory: 'lead.phone',
    sourceEntityType: isAgency ? 'agency' : 'agent', // Inconsistent casing is required by the schema for this event/value, unfortunately
    sourceEntityId: id,
    eventSource: 'AgentSearch',
    eventSourceDetails: pageName,
    eventProvider: 'fe-ops-agency-server',
    userId,
    userToken,
    teamName: 'Seller',
    locationId: suburbSlugs?.join(',') ?? '',
  });
};
