import React from 'react';
import { SerializedStyles } from '@emotion/core';
import * as styles from './content-container.style';

type ContentContainerProps = {
  first: JSX.Element;
  second: JSX.Element;
  changeForDevice?: 'tablet' | 'desktop';
  overrideStyles?: SerializedStyles;
};

const ContentContainer = ({
  first,
  second,
  changeForDevice,
  overrideStyles,
}: ContentContainerProps): JSX.Element => (
  <div css={styles.container(changeForDevice, overrideStyles)}>
    {first}
    {second}
  </div>
);

export default ContentContainer;
