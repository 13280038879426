import { css } from '@emotion/react';
import { applyFont, tokens } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

// eslint-disable-next-line import/prefer-default-export
export const addendum = css`
  margin-top: ${spacing.xs};
  ${applyFont(typography.mini)}
  color: ${colors.neutral.medium.default};
`;
