import React from 'react';
import { gql, useFragment } from '@apollo/client';
import getConfig from 'next/config';
import Footer from '@domain-group/fe-co-footer';

import { toGlobalId } from '../../utils';
import { AgencyFooterFragment } from '../../generated/graphql';

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyFooter';
const FRAGMENT = gql`
  fragment AgencyFooter on Agency {
    id
    address {
      displayAddress
      suburb {
        suburbId
        name
        slug
      }
    }
  }
`;

const AgencyProfileFooterWithFragment = ({
  agencyId,
}: {
  agencyId: number;
}): JSX.Element => {
  const id = toGlobalId(TYPE_NAME, `${agencyId}`);
  const { data: agency } = useFragment<AgencyFooterFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyProfileFooterWithFragment.fragment,
    fragmentName: AgencyProfileFooterWithFragment.fragmentName,
  });

  const { publicRuntimeConfig } = getConfig();
  const baseUrl = publicRuntimeConfig.BASE_URL;

  let suburb;
  if (agency?.address?.suburb?.name && agency?.address?.suburb?.slug) {
    suburb = {
      name: agency.address.suburb.name,
      slug: agency.address.suburb.slug,
    };
  }

  return (
    <div data-testid="footer">
      <Footer
        baseUrl={baseUrl}
        suburb={suburb}
        sale={{
          apartmentsAndUnits: true,
          penthouses: true,
          studios: true,
          duplexes: true,
          houses: true,
          semiDetachedHouses: true,
          terracedHouses: true,
          villas: true,
          townhouses: true,
          blockOfUnits: true,
        }}
        rent={{
          apartmentsAndUnits: true,
          penthouses: true,
          studios: true,
          duplexes: true,
          houses: true,
          semiDetachedHouses: true,
          terracedHouses: true,
          villas: true,
          townhouses: true,
          // Doesn't seem to work for rent?
          // blockOfUnits: true,
        }}
      />
    </div>
  );
};

AgencyProfileFooterWithFragment.fragment = FRAGMENT;
AgencyProfileFooterWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyProfileFooterWithFragment;
