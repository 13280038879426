import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import { EmailButton, CallButton } from '../../../shared';
import * as styles from '../../../../styles/contact-buttons.style';

export type Props = {
  isVertical: boolean;
  agencyId: number;
  name: string;
  telephone?: string | null;
  onCall: (args: { agencyId: number }) => void;
  onCallReveal: (args: { agencyId: number }) => void;
  onOpenModal: () => void;
};

const AgencyContactButtons = ({
  isVertical,
  agencyId,
  name,
  telephone,
  onCall,
  onCallReveal,
  onOpenModal,
}: Props): JSX.Element => {
  const isNarrow = true;

  return (
    <div css={styles.wrapper(isVertical, isNarrow)}>
      <h4 css={srOnly}>Contact agency</h4>
      <div css={styles.buttonGroup(isVertical, isNarrow)}>
        <EmailButton stretched name={name} onClick={onOpenModal} size="small" />
        {telephone ? (
          <CallButton
            stretched
            phoneNumber={telephone}
            name={name}
            onCall={() => onCall({ agencyId })}
            onCallReveal={() => onCallReveal({ agencyId })}
            size="small"
          />
        ) : null}
      </div>
    </div>
  );
};

export default AgencyContactButtons;
