import React, { useContext } from 'react';
import Head from 'next/head';
import { ServerInfoContext } from '../../contexts';

import {
  DigitalDataInit,
  DigitalDataTag,
  GoogleSchema,
  MetaTags,
  PaginationSeoLinks,
} from './components';
import {
  createBreadcrumbsJSONBlob,
  generateDigitalDataSearchResults,
} from './utils';
import { ServerInfo, UserDetailsRedacted } from '../../@types';
import { BreadCrumbs } from './utils/create-breadcrumbs-blob';
import { buildUrl } from '../search-results/utils';
import { AgencySearchQuery } from '../../generated/graphql';

export type SearchResultsHeadProps = {
  isAgency: boolean;
  total?: number | null;
  totalPages?: number | null;
  suburbOptions: AgencySearchQuery['locationProfiles'];
  suburbSlugsArray: string[];
  sortBy: string;
  agentType: string;
  keyword?: string;
  breadcrumbs: BreadCrumbs;
  pageNumber: number;
  agencyIds?: number[];
  user?: UserDetailsRedacted;
};

const SearchResultsHead = ({
  isAgency,
  total,
  suburbOptions,
  suburbSlugsArray,
  sortBy,
  agentType,
  keyword,
  breadcrumbs,
  pageNumber,
  totalPages,
  agencyIds,
  user,
}: SearchResultsHeadProps): JSX.Element => {
  const suburbsText = suburbOptions
    ?.map(
      (suburb) => `${suburb?.suburbName} ${suburb?.state} ${suburb?.postcode}`,
    )
    .join(', ');
  const title = `${total} Real Estate ${
    isAgency ? 'Agencies' : 'Agents'
  } active in ${suburbsText}`;
  const description =
    `Choose from ${total} real estate ${
      isAgency ? 'agencies' : 'agents'
    } active in ` +
    `${suburbsText} on Domain. Find experienced local real estate agents to help you ` +
    'manage your real estate needs.';

  const {
    sysEnv,
    baseUrl,
    path,
    source,
    isEmbeddedApp,
    trackingDisabled,
    noIndexFollow,
  } = useContext<ServerInfo>(ServerInfoContext);

  // canonical url
  // multiple suburbs or any filter is considered a user action and not SEOable.
  const shouldIndex = suburbOptions?.length === 1 && !keyword;
  const canonical = `${baseUrl}${path}`.toLowerCase();

  const digitalData = generateDigitalDataSearchResults({
    isAgency,
    sysEnv,
    source,
    isEmbeddedApp,
    pageNumber,
    agencyIds,
    suburbs: suburbOptions,
    user,
  });

  return (
    <>
      <Head>
        <title>{title}</title>
        {shouldIndex && <link rel="canonical" href={canonical} />}
      </Head>
      <MetaTags
        noIndexFollow={!!noIndexFollow && !shouldIndex}
        description={description}
      />
      <GoogleSchema breadcrumbsJSON={createBreadcrumbsJSONBlob(breadcrumbs)} />

      <DigitalDataTag digitalData={digitalData} />
      <DigitalDataInit trackingDisabled={trackingDisabled} />

      <PaginationSeoLinks
        seoPaginationPrev={
          pageNumber === 1
            ? undefined
            : buildUrl({
                isAgency,
                suburbs: suburbSlugsArray,
                sortBy,
                agentFilter: agentType,
                keywordName: keyword,
                page: pageNumber - 1,
              }).as
        }
        seoPaginationNext={
          pageNumber === totalPages
            ? undefined
            : buildUrl({
                isAgency,
                suburbs: suburbSlugsArray,
                sortBy,
                agentFilter: agentType,
                keywordName: keyword,
                page: pageNumber + 1,
              }).as
        }
      />
    </>
  );
};

export default SearchResultsHead;
