/* This function will format telephone numbers to international australian number
 * Some samples of numbers coming from backend:
 * +61234567890
 * 1300 856 712
 * 0469 715 429
 * 98765443
 * (02) 9898 9898
 * 9876 5443
 * 02 82060998
 * 133911
 * output number for this function should start with +61 and be only digits more than 8 characters
 */
export const addAusCodeToPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.startsWith('+61')) {
    return phoneNumber.replace(/\s/g, '');
  }

  if (phoneNumber.startsWith('61')) {
    return `+${phoneNumber.replace(/\s/g, '')}`;
  }

  const purePhoneNumber = phoneNumber.replace(/[^\d]/g, ''); // remove all space and characters
  if (
    purePhoneNumber.length > 8 ||
    (purePhoneNumber.length === 6 && purePhoneNumber.startsWith('13'))
  ) {
    if (purePhoneNumber.charAt(0) === '0') {
      // if starts with 0 remove zero and add +61
      return `+61${purePhoneNumber.slice(1)}`;
    }
    if (purePhoneNumber.charAt(0) === '1') {
      // if starts with 1 only add +61
      return `+61${purePhoneNumber}`;
    }
  }

  return phoneNumber;
};

const stripUnwantedCharacters = (number: string): string =>
  number.replace(/^\+61/, '').replace(/[^0-9]/g, '');

export const formatTelephoneNumber = (
  ausNumberWithCountryCode: string,
): string => {
  const ausNumber = stripUnwantedCharacters(ausNumberWithCountryCode);
  // https://en.wikipedia.org/wiki/Telephone_numbers_in_Australia omitting the braces (0x) to
  // match profile listings format
  if (ausNumber.startsWith('1300') || ausNumber.startsWith('1800')) {
    return `${ausNumber.slice(0, 4)} ${ausNumber.slice(4, 7)} ${ausNumber.slice(
      7,
    )}`;
  }
  if (ausNumber.startsWith('13') && ausNumber.length === 6) {
    return `${ausNumber.slice(0, 2)} ${ausNumber.slice(2)}`;
  }
  if (ausNumber.startsWith('4') || ausNumber.startsWith('5')) {
    return `0${ausNumber.slice(0, 3)} ${ausNumber.slice(
      3,
      6,
    )} ${ausNumber.slice(6)}`;
  }
  return `0${ausNumber.slice(0, 1)} ${ausNumber.slice(1, 5)} ${ausNumber.slice(
    5,
  )}`;
};
