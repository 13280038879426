import { css } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';
import * as util from '../../style/util';

const { colors, spacing, typography } = tokens;

export const container = css`
  ${util.container({
    gap: spacing.xxl,
    backgroundColor: colors.neutral.background.default,
  })};
`;

export const contentWrapper = css`
  ${util.contentWrapper({
    gap: spacing.xl,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  })};

  h2 {
    ${applyFont(typography.heading)}
  }

  p {
    ${applyFont(typography.body)}
  }
`;

export const sectionsContainer = (centerAlign: boolean) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${centerAlign ? 'center' : 'flex-start'};
  gap: ${spacing.m};
  align-self: center;
  flex: 1 1 0;
`;

export const link = css`
  padding: ${spacing.m} 0;
  min-width: 170px;
  span {
    ${applyFont(typography.body)};
  }
`;

export const overriddenStyling = css`
  gap: ${spacing.xl};
`;
