import { SerializedStyles, css } from '@emotion/react';
import { tokens, applyFont, media } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

const { colors, borderRadius, typography, spacing } = tokens;

export const region = css`
  position: relative;
`;

export const dataWrapper = css`
  border: 1px solid ${colors.neutral.trim.default};
  border-radius: ${borderRadius.outer};

  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${media('>=tablet')} {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`;

export const disclaimer = css`
  margin-top: ${spacing.m};
  color: ${colors.neutral.medium.default};
`;

export const loading = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const data = (isLoading = false): SerializedStyles => css`
  transition: opacity 0.25s ease;

  ${isLoading &&
  css`
    opacity: 0.75;
  `}
`;

export const errorWrapper = css``;

export const error = css`
  ${applyFont(typography.body)}
  background-color: ${colors.critical.background.default};

  color: ${colors.critical.base.default};
  padding: ${spacing.m};
  border-radius: ${borderRadius.mini};

  strong {
    font-weight: bold;
  }
`;
