import React, { useEffect, useState } from 'react';
import * as styles from './header.style';

export type Props = {
  logoPosition: 'left' | 'right';
  isBranded: boolean;
  logoColour?: string;
  agencyLogoUrl?: string;
  agencyName: string;
  isVertical: boolean;
  isPlatinum: boolean;
};

const Header = ({
  logoPosition,
  isBranded,
  logoColour,
  agencyLogoUrl,
  agencyName,
  isVertical,
  isPlatinum,
}: Props): JSX.Element => {
  const [logoSrc, setLogoSrc] = useState<undefined | string>();
  const [hasLogoError, setLogoError] = useState(false);

  // Set image URL client-side as "onError" is not supported server-side
  // https://github.com/vercel/next.js/issues/16127
  useEffect(() => {
    setLogoSrc(agencyLogoUrl);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      css={styles.header({
        colour: logoColour,
        isVertical,
        isBranded,
        isPlatinum,
      })}
    >
      {agencyLogoUrl && !hasLogoError ? (
        <div
          css={styles.agencyLogo({
            logoPosition,
            colour: logoColour,
            isVertical,
            isPlatinum,
          })}
        >
          {(isBranded || isPlatinum) && (
            <>
              <svg
                css={styles.curve({
                  colour: logoColour,
                  isPlatinum,
                })}
                viewBox="0 0 10 10"
                width="3px"
                height="3px"
              >
                <path d="M 10 0 v 10 q 0 -10 -10 -10 Z" />
              </svg>
              <svg
                css={styles.curve({
                  colour: logoColour,
                  isPlatinum,
                  isRight: true,
                })}
                viewBox="0 0 10 10"
                width="3px"
                height="3px"
              >
                <path d="M 0 0 v 10 q 0 -10 10 -10 Z" />
              </svg>
            </>
          )}
          {logoSrc && (
            <img
              onError={() => setLogoError(true)}
              src={logoSrc}
              alt={agencyName}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Header;
