/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { media, tokens } from '@domain-group/fe-brary';

export const listingCard = css`
  display: inline-block;
  background: ${tokens.colors.neutral.surface.default};
  text-align: left;
  vertical-align: top;
  flex-shrink: 0;
  width: 200px;
  margin: 0 ${tokens.spacing.xxs} ${tokens.spacing.m};

  > div {
    padding-bottom: ${tokens.spacing.m}; /** padding the bottom of the card */
  }

  ${media('>=tablet')} {
    width: 33.33%;
    padding: 0 calc(0.5 * ${tokens.spacing.m});
    margin: 0 0 ${tokens.spacing.m};
    display: flex;
  }
`;
