import { removeProtocol } from '../../../utils';
import { Breadcrumb } from '.';

const mapHeaderData = ({
  baseUrl: baseUrlWithProtocol,
  breadcrumbsData,
}: {
  baseUrl: string;
  breadcrumbsData?: Breadcrumb[] | null;
}): {
  baseUrl: string;
  signupUrl: string;
  loginUrl: string;
  logoutUrl: string;
  useCookieOnClient: boolean;
  breadcrumbs?: Breadcrumb[] | null;
} => {
  const baseUrl = removeProtocol(baseUrlWithProtocol);

  const data = {
    baseUrl,
    signupUrl: `${baseUrlWithProtocol}/authentications/Signup`,
    loginUrl: `${baseUrlWithProtocol}/authentications/Login`,
    logoutUrl: `${baseUrlWithProtocol}/authentications/logoff`,
    useCookieOnClient: true,
  };
  return { ...data, breadcrumbs: breadcrumbsData };
};

export default mapHeaderData;
