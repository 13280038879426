import React from 'react';

import { Avatar } from '../../../../shared';
import { APPEARANCE, HERO_SECTION, PROFILE_TIERS } from '../../../constants';
import { CtaButtonGroup } from '../../cta-button-group';

import * as styles from './contact-details-section.style';

export type Props = {
  profileTier: PROFILE_TIERS;
  avatarUrl: string;
  name: string;
  jobPosition?: string | null;
  phoneNumber: string;
  hasEmail?: boolean | null;
  emailFormComponentId: string;
  onCallButtonClick: () => void;
  onEmailButtonClick: () => void;
  socialRender: null | JSX.Element;
  agencyDetailsRender: null | JSX.Element;
};

const heroSection = HERO_SECTION.section;

const ContactDetailsSection = ({
  profileTier,
  avatarUrl,
  name,
  jobPosition,
  phoneNumber,
  hasEmail = false,
  emailFormComponentId,
  onCallButtonClick,
  onEmailButtonClick,
  socialRender,
  agencyDetailsRender,
}: Props): JSX.Element | null => {
  const avatarTitle = [name, jobPosition || 'Real Estate Agent']
    .filter(Boolean)
    .join(', ');

  if (profileTier === PROFILE_TIERS.platinum) {
    return agencyDetailsRender;
  }

  return (
    <div css={styles.details({ profileTier })} data-testid="agent-details">
      <div
        css={styles.agentAvatarHolder({
          profileTier,
        })}
        data-testid="trade-profile-hero__agent-avatar"
      >
        <Avatar
          size="custom"
          imageUrl={avatarUrl}
          imageTitle={avatarTitle}
          css={styles.agentAvatar({
            profileTier,
          })}
        />
      </div>
      <div css={styles.body} data-testid="trade-profile-hero__details-body">
        <div css={styles.agentDetails({ profileTier })}>
          <h1
            css={styles.name}
            data-testid={`trade-profile-hero-${heroSection}__name`}
          >
            {name}
          </h1>
          {jobPosition && (
            <p
              css={styles.agentJob}
              data-testid={`trade-profile-hero-${heroSection}__agent-job-position`}
            >
              {jobPosition}
            </p>
          )}
          {socialRender}
          <CtaButtonGroup
            isAgency
            profileTier={profileTier}
            callButtonAppearance={APPEARANCE.outline}
            emailButtonAppearance={APPEARANCE.highlight}
            heroSection={HERO_SECTION.section}
            phoneNumber={phoneNumber}
            name={name}
            onCallButtonClick={onCallButtonClick}
            hasEmail={hasEmail}
            emailFormComponentId={emailFormComponentId}
            onEmailButtonClick={onEmailButtonClick}
          />
        </div>
        {agencyDetailsRender}
      </div>
    </div>
  );
};

export default ContactDetailsSection;
