import {
  AGENCY_VIEW,
  DEFAULT_SORT_VALUE,
  SALES_AGENT,
  SORT_OPTIONS_AGENCIES,
  SORT_OPTIONS_AGENTS,
} from '../components/search-filter-bar/enums';

interface SearchResultsState {
  isAgency: boolean;
  suburbs: string[];
  page: number;
  keywordName?: string;
  agentFilter: string;
  sortBy: string;
}

interface Query {
  isAgency?: boolean;
  searchParam?: string; // Needs to match routing param in ./src/routes/real-estate-agencies.js|real-estate-agents.js
  suburbs?: string;
  page?: string;
  keywordName?: string;
  agentFilter?: string;
  sortBy?: string;
}

const getSearchFromQuery = (
  initialData: Query,
  query?: Query,
  pathname?: string,
): SearchResultsState | null => {
  // Preference query over ssr initial data
  const data = query && Object.keys(query).length > 0 ? query : initialData;

  // Suburbs
  let suburbs;
  if (data.searchParam) {
    suburbs = [data.searchParam];
  } else {
    if (!data.suburbs) {
      // No suburbs, no play
      return null;
    }
    suburbs = data.suburbs.split(',');
  }
  // Page
  const page = data.page ? parseInt(data.page, 10) : 1;
  // Keyword
  const keywordName = data.keywordName;
  // Agent type
  const agentFilter = data.agentFilter ?? SALES_AGENT;

  // Search view
  let isAgency = false;
  if (pathname) {
    isAgency = pathname.includes(AGENCY_VIEW);
  } else if (typeof data.isAgency === 'boolean') {
    isAgency = data.isAgency;
  }

  // Sort by
  let sortBy = DEFAULT_SORT_VALUE;
  if (data.sortBy) {
    // Lowercase first letter to account for "old" sort values (eg. TotalSoldDescending)
    // TODO: Remove this once the new sort options have been in production for a while
    const sortByValueFormatted =
      data.sortBy.charAt(0).toLowerCase() + data.sortBy.slice(1);

    const SORT_OPTIONS = isAgency ? SORT_OPTIONS_AGENCIES : SORT_OPTIONS_AGENTS;

    const sortByQuery = SORT_OPTIONS.find(
      (option) => option?.value === sortByValueFormatted,
    )?.value;

    if (sortByQuery) {
      sortBy = sortByQuery;
    }
  }

  return { isAgency, suburbs, keywordName, page, agentFilter, sortBy };
};

export default getSearchFromQuery;
