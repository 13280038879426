import React from 'react';
import { gql, useFragment } from '@apollo/client';

import { AgencyListingsStoryFragment } from '../../generated/graphql';

import ListingsStory from './listings-story/listings-story';
import type { ListingsTotals } from './listings-story/listings-story';
import { toGlobalId } from '../../utils';

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyListingsStory';
const FRAGMENT = gql`
  fragment AgencyListingsStory on Agency {
    id
    name
    hideOfficeLocation
    address {
      displayAddress
    }
    statistics {
      id
      forSale
      sold
      forRent
      leased
      auctioned
    }
  }
`;

type AgencyListingsStoryWithFragmentProps = { agencyId: number };

const AgencyListingsStoryWithFragment = ({
  agencyId,
}: AgencyListingsStoryWithFragmentProps): JSX.Element | null => {
  const id = toGlobalId(TYPE_NAME, agencyId);

  const { complete, data } = useFragment<AgencyListingsStoryFragment>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyListingsStoryWithFragment.fragment,
    fragmentName: AgencyListingsStoryWithFragment.fragmentName,
  });

  if (!complete) {
    return null;
  }

  const agencyName = data?.name;
  const agencyAddress = data?.address?.displayAddress;
  const hideOfficeLocation = data?.hideOfficeLocation;

  const statistics = data?.statistics;
  const { forSale, sold, forRent, leased, auctioned } = statistics || {};

  // Agency name is required for story
  if (!agencyName) {
    return null;
  }
  // No stats
  if (!forSale && !sold && !forRent && !leased && !auctioned) {
    return null;
  }

  const listingsTotal: ListingsTotals = {
    auction: auctioned,
    lease: forRent,
    leased,
    sale: forSale,
    sold,
  };

  return (
    <div data-testid="listings-story">
      <ListingsStory
        agencyName={agencyName}
        agencyAddress={agencyAddress}
        hideOfficeLocation={hideOfficeLocation}
        listingsTotal={listingsTotal}
      />
    </div>
  );
};

AgencyListingsStoryWithFragment.fragment = FRAGMENT;
AgencyListingsStoryWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyListingsStoryWithFragment;
