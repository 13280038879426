import React from 'react';
import { gql, useFragment } from '@apollo/client';
import Video from '@domain-group/fe-co-video';
import { toGlobalId } from '../../utils';
import { AgencyIdProps } from '../../@types';
import { AgencyVideoFragment } from '../../generated/graphql';

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyVideo';
const FRAGMENT = gql`
  fragment AgencyVideo on Agency {
    __typename
    id
    agencyVideoUrl
  }
`;

const AgencyVideoWithFragment = ({
  agencyId,
}: AgencyIdProps): React.ReactElement | null => {
  const id = toGlobalId(TYPE_NAME, agencyId);

  const { complete, data: agency } = useFragment<AgencyVideoFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyVideoWithFragment.fragment,
    fragmentName: AgencyVideoWithFragment.fragmentName,
  });

  if (!complete || !agency?.agencyVideoUrl) {
    return null;
  }

  return (
    <div data-testid="video">
      <Video videoUrl={agency.agencyVideoUrl} />
    </div>
  );
};

AgencyVideoWithFragment.fragment = FRAGMENT;
AgencyVideoWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyVideoWithFragment;
