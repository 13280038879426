import { css } from '@emotion/react';
import { media, h4Tag, fontH6, fontBase, tokens } from '@domain-group/fe-brary';
import { wrapper as centeredWrapper, avatarSize } from './step-intent.style';

export { avatar } from './step-intent.style';

export const avatarHolder = css`
  ${avatarSize};
`;

const lightGrey = tokens.colors.neutral.base.default;
const lightFontColour = css`
  color: ${lightGrey};
`;

export const wrapper = (isWide = false) => css`
  ${centeredWrapper(isWide)};
  text-align: left;
`;

export const header = css`
  display: flex;
  justify-content: space-between;
`;

export const headerContent = css`
  text-align: left;
  ${fontBase};
  ${lightFontColour};
`;

// According to the design system, we should not be mixing the families like this.
// However, to maintain the same look, the different families have been chosen.
export const backButton = css`
  ${fontH6};
  position: relative;
  padding: ${tokens.spacing.s};
  margin: calc(-1 * ${tokens.spacing.s}) 0 0 calc(-1 * ${tokens.spacing.s});
  border: none;
  background-color: transparent;
  color: ${tokens.colors.neutral.medium.default};
  transition: all 0.2s ease;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    bottom: 8px;
    height: 1px;
    right: 12px;
    width: 0;
    background-color: ${tokens.colors.neutral.medium.default};
    transition: all 0.2s ease;
  }

  &:hover,
  &:focus {
    color: ${tokens.colors.interactive.base.hover};

    &:after {
      width: calc(100% - ${tokens.spacing.l});
      background-color: ${tokens.colors.interactive.base.hover};
    }
  }
`;

export const backIcon = css`
  margin-top: -2px;
  margin-right: ${tokens.spacing.xxs};
`;

export const title = css`
  ${h4Tag};
  color: ${tokens.colors.neutral.heavy.default};
  margin-bottom: ${tokens.spacing.xxs};
`;

export const body = css`
  color: ${tokens.colors.neutral.medium.default};
`;

export const formGroup = css`
  ${media('>tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${tokens.spacing.s};
  }
`;

export const radioGroup = css`
  ${media('>=tablet')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${tokens.spacing.s};
    grid-row-gap: ${tokens.spacing.xs};
    margin: ${tokens.spacing.m} 0;

    > div {
      margin: 0;
    }

    span {
      text-align: left;
    }
  }
`;

export const button = css`
  width: 225px;
  margin-top: ${tokens.spacing.m};
`;
