/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export { srOnly } from '@domain-group/fe-brary';

export const pillModalwrapper = css`
  margin: -10px;
  padding: ${tokens.spacing.m};
  /* This is a fix because otherwise sort-by appears higher in z-index than modal and hides it */
`;

export const buttonGroupWrapper = css`
  text-align: right;
`;

/**
 * This is borrowed from
 * link: https://github.com/domain-group/fe-co-search-filters/blob/dd5a7656b5a6b27e43c777010c0d2b25d0e6a900/src/style/dynamic-search-filters.js#L129
 */
export const cancelButtonStyleOverride = css`
  background-color: transparent !important;
  color: ${tokens.colors.neutral.heavy.default} !important;
  font-weight: normal;
  margin-right: 10px;
  &:hover,
  &:focus {
    background-color: ${tokens.colors.neutral.background.hover} !important;
  }
`;
