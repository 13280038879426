export const getAppraisalCTAUrl = ({
  url,
  agentId,
  entryPoint,
}: {
  url?: string;
  agentId?: string;
  entryPoint?: string;
} = {}): string => {
  const params = new URLSearchParams({
    ...(agentId && { agentId }),
    ...(entryPoint && { 'entry-point': entryPoint }),
  }).toString();

  return `${url || '/services/appraisal'}${params ? `?${params}` : ''}`;
};

export const joinUrls = (urls: string[]): string =>
  urls.reduce((res, singleUrl) => {
    if (!singleUrl) {
      return res;
    }

    const cleaned = singleUrl.replace(/^\/*/, '').replace(/\/*$/, '');
    return !res ? cleaned : `${res}/${cleaned}`; // eslint-disable-line  no-negated-condition
  }, '');
