import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import { formatCurrency } from '../../../../utils';
import * as styles from './statistics.style';

export type Props = {
  isVertical: boolean;
  averageSoldPrice?: number | null;
  averageDaysOnMarket?: number | null;
  totalSoldAndAuctioned?: number | null;
  totalJointSoldAndAuctioned?: number | null;
};

const SoldStatistics = ({
  isVertical = false,
  averageSoldPrice = null,
  averageDaysOnMarket = null,
  totalSoldAndAuctioned = null,
  totalJointSoldAndAuctioned = null,
}: Props): React.ReactElement => (
  <div css={styles.wrapper(isVertical)}>
    <div>
      <h4 css={srOnly}>Agent sales statistics</h4>
      <dl css={styles.statistics(isVertical)}>
        {averageSoldPrice ? (
          <div css={styles.statistic}>
            <dt>
              Avg sold <br /> price
            </dt>
            <dd>{formatCurrency(averageSoldPrice)}</dd>
          </div>
        ) : null}
        {averageDaysOnMarket ? (
          <div css={styles.statistic}>
            <dt>
              Avg days <br /> on market
            </dt>
            <dd>{averageDaysOnMarket && Math.floor(averageDaysOnMarket)}</dd>
          </div>
        ) : null}
        {totalSoldAndAuctioned ? (
          <div css={styles.statistic}>
            <dt>
              Properties <br /> sold
            </dt>
            <dd>{totalSoldAndAuctioned}</dd>
          </div>
        ) : null}
      </dl>
      {totalJointSoldAndAuctioned ? (
        <div css={styles.jointStat}>
          * Plus {totalJointSoldAndAuctioned} sale
          {totalJointSoldAndAuctioned > 1 ? 's' : ''} as joint agent
        </div>
      ) : null}

      {!averageSoldPrice &&
      !averageDaysOnMarket &&
      !totalSoldAndAuctioned &&
      !totalJointSoldAndAuctioned ? (
        <div css={styles.unavailable}>
          <p>* Sales statistics unavailable</p>
        </div>
      ) : null}
    </div>
  </div>
);

export default SoldStatistics;
