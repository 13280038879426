import React from 'react';

import { AgencyLogo, AgencyAddress, CtaButtonGroup } from '../..';

import * as styles from './agency-details-section.style';
import { APPEARANCE, HERO_SECTION, PROFILE_TIERS } from '../../../constants';

export type Props = {
  profileTier: PROFILE_TIERS;
  agencyName: string;
  agencyUrl: string;
  imageUrl: string;
  address: string;
  hideOfficeLocation?: boolean;
  phoneNumber: string;
  hasEmail: boolean;
  emailFormComponentId: string;
  onAgencyAddressClick: () => void;
  onAgencyLogoClick: () => void;
  onCallButtonClick?: () => void;
  onEmailButtonClick: () => void;
  socialRender: null | JSX.Element;
};

const heroSection = HERO_SECTION.section;

const AgencyDetailsSection = ({
  profileTier,
  agencyName,
  agencyUrl,
  imageUrl,
  address,
  hideOfficeLocation = false,
  onAgencyAddressClick = () => undefined,
  onAgencyLogoClick = () => undefined,
  phoneNumber,
  hasEmail,
  emailFormComponentId,
  onCallButtonClick = () => undefined,
  onEmailButtonClick = () => undefined,
  socialRender,
}: Props): JSX.Element | null => {
  if (profileTier === PROFILE_TIERS.platinum) {
    if (!imageUrl) {
      return null;
    }

    return (
      <AgencyLogo
        heroSection={heroSection}
        profileTier={profileTier}
        agencyUrl={agencyUrl}
        imageUrl={imageUrl}
        agencyName={agencyName}
        isAgency
        isActive={false}
        onAgencyLogoClick={onAgencyLogoClick}
      />
    );
  }

  return (
    <div css={styles.agencyDetails} data-testid="agency-details">
      {imageUrl && (
        <AgencyLogo
          heroSection={heroSection}
          profileTier={profileTier}
          agencyUrl={agencyUrl}
          imageUrl={imageUrl}
          agencyName={agencyName}
          isAgency
          isActive={false}
          onAgencyLogoClick={onAgencyLogoClick}
        />
      )}
      <div
        data-testid={`trade-profile-hero-${heroSection}__agency-details-body`}
      >
        <h1
          css={styles.agencyNameLarge({
            profileTier,
          })}
          data-testid={`trade-profile-hero-${heroSection}__name`}
        >
          {agencyName}
        </h1>
        {!hideOfficeLocation && (
          <AgencyAddress
            heroSection={heroSection}
            address={address}
            onAgencyAddressClick={onAgencyAddressClick}
          />
        )}
      </div>
      {socialRender}
      <CtaButtonGroup
        isAgency
        profileTier={profileTier}
        callButtonAppearance={APPEARANCE.outline}
        emailButtonAppearance={APPEARANCE.highlight}
        heroSection={HERO_SECTION.section}
        phoneNumber={phoneNumber}
        name={agencyName}
        onCallButtonClick={onCallButtonClick}
        hasEmail={hasEmail}
        emailFormComponentId={emailFormComponentId}
        onEmailButtonClick={onEmailButtonClick}
      />
    </div>
  );
};

export default AgencyDetailsSection;
