import React from 'react';

import { formatCurrency, formatDate, formatSeoUrl } from '../../../../utils';

import * as styles from './summary.styles';

export type Props = {
  isAgent: boolean;
  name?: string;
  soldPrice: number;
  daysOnMarket: number;
  soldDate?: string;
  displayableAddress: string;
  bedrooms: number;
  listingType: string;
  salesType: string; // using following 'sold' | 'soldpriortoauction' | 'auction' will throw errors because GQL is just string
  state: string;
  postcode: string;
  adId: number;
};

const SummaryTop = ({
  isAgent,
  name,
  soldPrice,
  daysOnMarket,
  displayableAddress,
  soldDate,
  bedrooms,
  listingType,
  salesType,
  state,
  postcode,
  adId,
}: Props): JSX.Element => {
  const linkUrl = formatSeoUrl({ displayableAddress, state, postcode, adId });

  let linkPrefix = '';
  if (listingType === 'rural') {
    linkPrefix = 'a rural property in ';
  }
  if (listingType === 'land') {
    linkPrefix = 'a block of land in ';
  }

  const bedroomInfo = bedrooms > 0 ? `a ${bedrooms}-bedroom` : '';
  const propertyType =
    listingType !== 'rural' && listingType !== 'land' ? listingType : '';

  const averageOnMarket =
    daysOnMarket > 0
      ? `, and was on the market for ${Math.ceil(daysOnMarket)} days`
      : '';

  // Needs to be a func instead of an object const because gql says it can be any type of string
  const salesTypeDisplayName = (type: string) => {
    switch (type) {
      case 'sold':
        return 'private treaty';
      case 'soldpriortoauction':
        return 'prior to auction';
      default:
        return 'auction';
    }
  };

  return (
    <p css={styles.paragraph}>
      {isAgent ? `${name}'s` : 'The'} highest recorded sale price was{' '}
      {formatCurrency(soldPrice)} for {linkPrefix}
      <a href={linkUrl}>{displayableAddress}</a>, {bedroomInfo} {propertyType}{' '}
      which sold {salesType === 'soldpriortoauction' ? '' : 'by '}
      {salesTypeDisplayName(salesType)} on {formatDate(soldDate)}
      {averageOnMarket}.
    </p>
  );
};

export default SummaryTop;
