import React from 'react';
import { gql, useQuery } from '@apollo/client';

import ContactAboutSectionWithFragment from './contact-about-section-with-fragment';
import { ContactAboutSectionQuery } from '../../generated/graphql';

export const GET_CONTACT_QUERY = gql`
  query contactAboutSection($contactId: Int!) {
    contactByContactId(contactId: $contactId) {
      ...ContactAboutSection
    }
  }
  ${ContactAboutSectionWithFragment.fragment}
`;

const ContactAboutSectionWithQuery = ({
  contactId,
}: {
  contactId: number;
}): JSX.Element | null => {
  const { data, loading } = useQuery<ContactAboutSectionQuery>(
    GET_CONTACT_QUERY,
    {
      variables: { contactId },
    },
  );

  if (
    loading ||
    !data?.contactByContactId?.agencyId ||
    !data?.contactByContactId?.agentIdV2
  ) {
    return null;
  }

  const agencyId = data.contactByContactId.agencyId;
  const agentIdV2 = data.contactByContactId.agentIdV2;

  return (
    <ContactAboutSectionWithFragment
      agencyId={agencyId}
      agentIdV2={agentIdV2}
    />
  );
};

export default ContactAboutSectionWithQuery;
