import sanitizeHtml from 'sanitize-html';
import { SchemaBreadCrumbList } from '../../../@types';

export type BreadCrumbs = {
  title: string;
  url: string;
}[];

const createBreadcrumbsJSONBlob = (breadcrumbs: BreadCrumbs) => {
  const breadcrumbJSONItems = breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': sanitizeHtml(breadcrumb.url, {
        allowedTags: [],
        allowedAttributes: {},
      }),
      name: sanitizeHtml(breadcrumb.title, {
        allowedTags: [],
        allowedAttributes: {},
      }),
    },
  }));

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbJSONItems,
  } as SchemaBreadCrumbList;
};

export default createBreadcrumbsJSONBlob;
