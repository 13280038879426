import React from 'react';
import { gql, useFragment } from '@apollo/client';

import AboutSection from './components/about-section';
import { ContactAboutSectionFragment } from '../../generated/graphql';
import { ListingCardWithFragment } from '../listing-card';
import getDefaultBio, {
  GetDefaultAgentBioArgs,
} from './utils/get-default-agent-bio';
import getProfileTier from './utils/get-profile-tier';
import sanitizeBio from './utils/sanitize-bio';

const mapDataToBioArgs = (
  data: ContactAboutSectionFragment,
): GetDefaultAgentBioArgs => ({
  name: data?.name || undefined,
  agencyName: data?.agencyName || undefined,
  agencyAddress: data?.agencyByAgencyId?.address?.displayAddress || undefined,
  listingsTotal: {
    sale: data?.statistics?.totalForSale || 0,
    sold: data?.statistics?.totalSold || 0,
    auction: data?.statistics?.totalAuctioned || 0,
    lease: data?.statistics?.totalLeased || 0,
  },
});

const FRAGMENT_NAME = 'ContactAboutSection';
export const AGENT_ABOUT_SECTION_FRAGMENT = gql`
  fragment ContactAboutSection on Contact {
    __typename
    id
    agentIdV2
    agencyId
    name
    profileText
    profileTier
    hasReinsw
    agencyName
    agencyByAgencyId {
      id
      agencyId
      address {
        displayAddress
      }
    }
    statistics {
      id
      totalSold
      totalLeased
      totalAuctioned
      totalForSale
    }

    nextInspectionByContact {
      listingByAdId {
        ...ListingCard
      }
    }
  }
  ${ListingCardWithFragment.fragment}
`;

const ContactAboutSectionWithFragment = ({
  agentIdV2,
  agencyId,

  // Tracking
  onReadMore = () => undefined,
}: {
  agentIdV2: string;
  agencyId: number;
  onReadMore?: () => void;
}): JSX.Element | null => {
  const id = `${agencyId}-${agentIdV2}`;
  const { complete, data } = useFragment<ContactAboutSectionFragment, unknown>({
    from: {
      __typename: 'Contact',
      id,
    },
    fragment: ContactAboutSectionWithFragment.fragment,
    fragmentName: FRAGMENT_NAME,
  });

  if (!complete || !data?.name) {
    return null;
  }

  let description;
  if (data?.profileText) {
    description = sanitizeBio(data?.profileText);
  } else {
    description = getDefaultBio(mapDataToBioArgs(data));
  }

  return (
    <AboutSection
      name={data?.name}
      profileDescriptionText={description}
      isAgency
      profileTier={getProfileTier(data?.profileTier || undefined)}
      hasReinswBadge={!!data?.hasReinsw}
      adId={
        data?.nextInspectionByContact?.listingByAdId?.listingId || undefined
      }
      // Tracking
      onReadMore={onReadMore}
    />
  );
};

ContactAboutSectionWithFragment.fragment = AGENT_ABOUT_SECTION_FRAGMENT;
ContactAboutSectionWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactAboutSectionWithFragment;
