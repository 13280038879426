import { gql } from '@apollo/client';
import type { ApolloClient } from '@apollo/client';
import { buildAgencies, buildContacts, buildLocations } from '.';
import { Option } from '../types';

const AUTO_COMPLETE_QUERY = gql`
  query FindAndAgentAutocomplete(
    $input: String!
    $maxResults: Int
    $locationTypes: [LocationSuggestionType]
  ) {
    suggestLocations(
      prefixText: $input
      locationTypes: $locationTypes
      maxResults: $maxResults
    ) {
      ... on SuburbSuggestion {
        displayName
        nameSlug
      }
    }
    suggestedAgencies(name: $input, pageSize: $maxResults) {
      agencyName
      profileUrl
    }
    suggestedContacts(name: $input, pageSize: $maxResults) {
      id
      contactId
      name
      thumbnail
      profileUrl
    }
  }
`;

const suggestLocations = async ({
  input,
  apolloClient,
  setError,
  setNoResults,
}: {
  input: string;
  apolloClient: ApolloClient<any>;
  setError: (error: string | null) => void;
  setNoResults: (error: string | null) => void;
}): Promise<Option[]> => {
  const response = await apolloClient.query({
    query: AUTO_COMPLETE_QUERY,
    variables: {
      input,
      maxResults: 10,
      locationTypes: ['SUBURB'],
    },
    errorPolicy: 'all', // https://www.apollographql.com/docs/react/v2/data/error-handling/#error-policies
  });

  const allGroupsAreEmpty =
    response.data?.suggestLocations?.length === 0 &&
    response.data?.suggestedAgencies?.length === 0 &&
    response.data?.suggestedContacts?.length === 0;

  if (response.error) {
    setError('There was an error connecting with the server.');
    setNoResults(null);

    return [];
  }

  if (allGroupsAreEmpty) {
    setError(null);
    setNoResults('No location found, please try another search.');

    return [];
  }

  setError(null);
  setNoResults(null);

  const results = [
    ...buildLocations(response.data.suggestLocations),
    ...buildAgencies(response.data.suggestedAgencies),
    ...buildContacts(response.data.suggestedContacts),
  ];

  // De-duplicate results
  return results.reduce<Option[]>((acc, current) => {
    if (!acc.some(({ value }) => value === current.value)) {
      acc.push(current);
    }

    return acc;
  }, []);
};

export default suggestLocations;
