import { css } from '@emotion/react';
import {
  media,
  applyFont,
  srOnly as srOnlyStyles,
  tokens,
} from '@domain-group/fe-brary';

const quoteMarkFontSize = 120;
const navigationButtonSize = 12;

export const testimonials = css`
  .carousel .slick-slider .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-arrow {
    /* Have to use important here to override Javascript set inline styles */
    display: none !important;

    ${media('>=tablet')} {
      display: block !important;
    }
  }

  .slick-prev,
  .slick-next {
    border: 0;
    background-color: transparent;

    &:hover,
    &:active {
      background-color: transparent;
    }

    &:focus,
    &:hover {
      color: ${tokens.colors.primary.base.default};
    }
  }

  .carousel__dots {
    position: relative;
    font-size: 0;
    text-align: center;

    li {
      display: inline-block;
      margin: ${tokens.spacing.xs} ${tokens.spacing.xs} 0 ${tokens.spacing.xs};
    }

    ${media('>=tablet')} {
      /* Have to use important here to override JS set inline styles */
      display: none !important;
    }

    button {
      display: block;
      width: ${navigationButtonSize}px;
      height: ${navigationButtonSize}px;
      padding: 0;
      margin: 0;
      border: none;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      background: ${tokens.colors.neutral.subdued.default};

      &:hover {
        background: ${tokens.colors.neutral.background.hover};
      }
    }
    .slick-active button {
      background: ${tokens.colors.interactive.base.default};
    }
  }
`;

export const title = css`
  margin-bottom: ${tokens.spacing.s};
  color: ${tokens.colors.neutral.heavy.default};
  ${applyFont(tokens.typography.heading)};
`;

export const carouselTestimonial = css`
  display: flex;
  align-items: center;
  max-width: 660px;
  min-height: 330px;
  margin: 0 auto;
  padding: ${tokens.spacing.m};
`;

export const testimonial = css`
  position: relative;
  display: flex;
  align-items: flex-start;

  &::before {
    content: '“';
    display: block;
    font-size: ${quoteMarkFontSize}px;
    line-height: 1;
    font-style: normal;
    color: ${tokens.colors.neutral.trim.default};
    margin-right: ${tokens.spacing.s};
    position: relative;
    top: -28px;
  }
`;

export const quoteContainer = css`
  position: relative;
`;

export const quote = css`
  ${applyFont(tokens.typography.lead)}
`;

export const footer = css`
  position: relative;
  margin-top: ${tokens.spacing.xl};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(-1 / 2 * ${tokens.spacing.xl});
    left: 0;
    width: ${tokens.spacing.m};

    border-top: 1px solid ${tokens.colors.neutral.trim.default};
  }
`;

export const author = css`
  ${applyFont(tokens.typography.subheading)};
  margin: 0;
  color: ${tokens.colors.neutral.heavy.default};
`;

export const date = css`
  ${applyFont(tokens.typography.caption)};
  color: ${tokens.colors.neutral.medium.default};
`;

export const readMore = css`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  width: auto;
  cursor: pointer;
  text-decoration: none;
  ${applyFont(tokens.typography.body)};
  color: ${tokens.colors.interactive.base.default};
  display: inline-block;
  margin-top: ${tokens.spacing.s};

  &:hover {
    color: ${tokens.colors.interactive.base.hover};
  }
`;

export const srOnly = srOnlyStyles;

// Modal

export const testimonialsModal = css`
  padding: ${tokens.spacing.m};

  ${media('>=tablet')} {
    padding: ${tokens.spacing.xl};
  }
`;

export const testimonialsModalParagraph = css`
  ${applyFont(tokens.typography.subheading)}
`;
