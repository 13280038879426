import { css } from '@emotion/react';
import { tokens, media } from '@domain-group/fe-brary';

// eslint-disable-next-line import/prefer-default-export
export const wrapper = css`
  ${media('>=desktop')} {
    display: grid;
    grid-template-columns: 290px 1fr;
    grid-column-gap: ${tokens.spacing.m};
  }
`;
