import React from 'react';
import getConfig from 'next/config';
import { trackGuide } from '../../../../../../tracking/landing-page';
import * as styles from './guide-card.style';

interface GuideCardProps {
  title: string;
  subTitle: string;
  imageURL?: string;
  articleLink: string;
}

const GuideCard = ({
  title,
  subTitle,
  imageURL = '',
  articleLink,
}: GuideCardProps): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  const baseUrlWithProtocol = publicRuntimeConfig?.BASE_URL;

  return (
    <div css={[styles.cardWrapper]}>
      <a
        target="_blank"
        rel="noreferrer"
        css={styles.articleLink}
        href={`${baseUrlWithProtocol}${articleLink}`}
        onClick={() => trackGuide()}
      >
        <div css={styles.imageWrapper}>
          <img src={imageURL} css={styles.image} alt={subTitle} />
        </div>
        <div css={styles.articleInfo}>
          <p css={styles.title}>{title}</p>
          <p css={styles.subTitle}>{subTitle}</p>
        </div>
      </a>
    </div>
  );
};

export default GuideCard;
