/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';
import isPlainObject from 'lodash/fp/isPlainObject';
import flattenDepth from 'lodash/fp/flattenDepth';

export const SCHEMA_ORG = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Domain',
  url: 'http://www.domain.com.au',
  sameAs: [
    'https://www.facebook.com/domain.com.au',
    'https://twitter.com/domaincomau',
    'https://www.linkedin.com/company/domain-com-au',
  ],
};

/**
 * Make the schema JSON for a view
 * by merging common schemas and page schemas
 *
 * @param otherSchemas
 * {{
 *   '@context': {string}
 *   '@type': {string}
 * }[] | {
 *   '@context': {string}
 *   '@type': {string}
 * }[][]}
 * @returns {string}
 */
export const makeSchemaJSON = (...otherSchemas: string[]): string => {
  const schemaData = [SCHEMA_ORG, ...flattenDepth(1, otherSchemas)].filter(
    (sch) => isPlainObject(sch) && JSON.stringify(sch) !== '{}',
  );

  return JSON.stringify(schemaData);
};

export const googleSchema = ({
  breadcrumbsJSON,
  pageSchema,
}: {
  breadcrumbsJSON: string;
  pageSchema: string;
}): ReactElement => (
  <script
    title="google-schema"
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: `
    ${makeSchemaJSON(breadcrumbsJSON, pageSchema)}
  `,
    }}
  />
);
