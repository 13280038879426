import { sendToMixpanel } from '../mixpanel';
import { getPageDetails } from '../utils';

export default ({
  isAgency,
  enquiryCTA = 'Profile Card Email Button',
  enquiryFormType = 'modal',
}: {
  isAgency: boolean;
  enquiryCTA?: string;
  enquiryFormType?: string;
}): void => {
  const { pageName } = getPageDetails();
  sendToMixpanel(`Display Contact ${isAgency ? 'Agency' : 'Agent'} Form`, {
    enquiryCTA,
    enquiryLocation: pageName,
    enquiryFormType,
  });
};
