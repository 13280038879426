import React from 'react';
import { PinIcon } from '@domain-group/fe-co-icon';

import * as styles from './agency-address.style';
import { HERO_SECTION } from '../../../constants';

export type Props = {
  heroSection: HERO_SECTION;
  address: string;
  onAgencyAddressClick?: () => void;
};

const AgencyAddress = ({
  heroSection,
  address,
  onAgencyAddressClick = () => undefined,
}: Props): JSX.Element => (
  <div
    css={styles.agencyDetailsAddress({ heroSection })}
    data-testid={`trade-profile-hero-${heroSection}__agency-details-address`}
  >
    <a
      css={styles.addressLink({ heroSection })}
      href="#profile-map"
      onClick={() => onAgencyAddressClick()}
    >
      <span css={styles.addressIcon}>
        <PinIcon name="pin" size="small" />
      </span>
      <span css={styles.addressLabel} data-testid="address__label">
        &nbsp;{address}
      </span>
    </a>
  </div>
);

export default AgencyAddress;
