import { media, tokens, applyFont } from '@domain-group/fe-brary';
import { css, SerializedStyles } from '@emotion/react';

import { promo as listPromo } from './reviews-list.style';

const { colors, spacing, typography } = tokens;

export const container = (listView: boolean): SerializedStyles => css`
  padding: ${spacing.s};
  ${listView &&
  css`
    background-color: transparent;
    ${media('>=tablet')} {
      background-color: ${colors.neutral.subdued.default};
    }
  `}
`;

export const propertyCard = css`
  margin-top: ${spacing.s};
  padding: ${spacing.s};
  background-color: ${colors.neutral.subdued.default};
  user-select: none;

  .address-wrapper {
    margin-bottom: ${spacing.xs};
    font-weight: bold;
  }
`;

export const promo = css`
  ${listPromo};
  ${applyFont(typography.body)}
  margin-top: ${spacing.s};
  img {
    height: 20px;
  }
`;

export const header = (listView: boolean): SerializedStyles => css`
  display: flex;
  margin-bottom: ${spacing.xs};
  justify-content: space-between;
  user-select: none;

  h3 {
    ${applyFont(typography.subheading)}
    margin-bottom: 0;
    color: ${colors.neutral.heavy.default};
  }

  p {
    color: ${colors.neutral.medium.default};
  }

  ${!listView &&
  css`
    flex-direction: column;
  `}

  ${media('<tablet')} {
    flex-direction: column;
  }
`;

export const body = css`
  p {
    margin-bottom: 0;
    ${applyFont(typography.body)}
    user-select: none;
  }
`;

export const moreButtonShade = css`
  position: relative;
  margin-left: ${spacing.s};

  &::before {
    content: ' ...';

    display: block;
    position: absolute;
    top: -2px;
    left: -16px;
    width: 16px;
    height: 100%;

    text-align: center;

    background-color: ${colors.neutral.subdued.default};
  }

  > * {
    padding: 0;
  }
`;

export const trackingPixel = css`
  display: block;
  height: 0;
  width: 0;
`;
