import { css, SerializedStyles } from '@emotion/react';
import { tokens, applyFont } from '@domain-group/fe-brary';
import { HERO_SECTION } from '../../../constants';

const {
  colors: { neutral },
  typography: { body },
  spacing,
  // spacing,
} = tokens;

export const agencyDetailsAddress = ({
  heroSection,
}: {
  heroSection: HERO_SECTION;
}): SerializedStyles => css`
  display: block;
  ${applyFont(body)}

  svg {
    color: currentColor;
    fill: currentColor;
  }

  ${heroSection === HERO_SECTION.banner &&
  css`
    margin: ${spacing.none} auto;
  `};
`;

export const addressLink = ({
  heroSection,
}: {
  heroSection: HERO_SECTION;
}): SerializedStyles => css`
  color: ${neutral.medium.default};
  text-decoration: none;

  &:hover {
    color: ${neutral.medium.hover};
  }

  ${heroSection === HERO_SECTION.banner &&
  css`
    color: ${neutral.surface.default};

    &:hover {
      color: ${neutral.surface.hover};
    }
  `};

  ${heroSection === HERO_SECTION.sticky &&
  css`
    color: ${neutral.trim.default};
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus {
      color: ${neutral.trim.hover};
    }
  `};
`;

export const addressIcon = css`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: ${spacing.xxs} ${spacing.none};
  vertical-align: middle;

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`;

export const addressLabel = css`
  line-height: ${body.lineHeight};
  vertical-align: middle;
`;
