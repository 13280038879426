import React from 'react';
import { Button, LinkButton } from '@domain-group/fe-co-button';
import { useFunnel } from '@domain-group/fe-funnel-utils';
import { srOnly } from '@domain-group/fe-brary';
import { AppraisalIllustration } from '../../../shared';

import * as styles from './step-success.style';

const Success = () => {
  const { nav, funnelProps } = useFunnel();

  return (
    <div css={styles.wrapper(funnelProps.isShortForm)}>
      <h3 css={styles.title}>Enquiry sent!</h3>
      <article css={styles.article}>
        <h1 css={styles.articleTitle}>Looking to sell?</h1>
        <p>
          Find out the state of the market and how much you can expect to
          receive from your property from experts in your area.
        </p>
        <LinkButton css={styles.link} href="/sell" appearance="link">
          Find out more
        </LinkButton>
        <div css={styles.illustration}>
          <AppraisalIllustration />
        </div>
      </article>
      <Button
        css={styles.button}
        appearance="outline"
        onClick={() => {
          if (funnelProps.onFinish) {
            funnelProps.onFinish();
          } else {
            nav.next({ type: 'clearInput' });
          }
        }}
        data-testid="enquiry-start-over"
      >
        Ok <span css={srOnly}>restart contact form</span>
      </Button>
    </div>
  );
};

export default Success;
