/* eslint-disable react/require-default-props */
import React from 'react';

import PropertyFeatures from '@domain-group/fe-co-property-features';
import Address from '@domain-group/fe-co-address';

import * as styles from './review.style';

type PropertyCardProps = {
  streetAddress: string;
  suburb: string;
  postcode: string;
  state: string;
  bedrooms?: number;
  bathrooms?: number;
  carparks?: number;
};

const PropertyCard = ({
  streetAddress,
  suburb,
  postcode,
  state,
  bedrooms = 0,
  bathrooms = 0,
  carparks = 0,
}: PropertyCardProps): JSX.Element => (
  <div css={styles.propertyCard}>
    <Address
      streetName={streetAddress}
      suburb={suburb}
      postcode={postcode}
      state={state}
    />
    <PropertyFeatures beds={bedrooms} baths={bathrooms} parking={carparks} />
  </div>
);

export default PropertyCard;
