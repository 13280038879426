import React from 'react';
import format from 'date-fns/format';
import { srOnly } from '@domain-group/fe-brary';
import * as styles from './summary.style';
import { Statistics } from '..';

export type Props = {
  totalForSale?: number | null;
  totalSoldAndAuctioned?: number | null;
  totalJointSoldAndAuctioned?: number | null;
  totalForRent?: number | null;
  totalLeased?: number | null;
  totalJointLeased?: number | null;
};

const Summary = ({
  totalForSale = null,
  totalSoldAndAuctioned = null,
  totalJointSoldAndAuctioned = null,
  totalForRent = null,
  totalLeased = null,
  totalJointLeased = null,
}: Props): React.ReactElement => (
  <div css={styles.wrapper}>
    <div>
      <h2 css={styles.title}>
        Properties <span css={srOnly}>sales summary</span>
      </h2>
      <p css={styles.subtitle}>
        <span css={srOnly}>Summary for</span> 12 month period to{' '}
        {format(new Date(), 'MMMM d, yyyy')}
      </p>
    </div>
    <Statistics
      totalForSale={totalForSale}
      totalSoldAndAuctioned={totalSoldAndAuctioned}
      totalJointSoldAndAuctioned={totalJointSoldAndAuctioned}
      totalForRent={totalForRent}
      totalLeased={totalLeased}
      totalJointLeased={totalJointLeased}
    />
  </div>
);

export default Summary;
