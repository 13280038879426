export default (param: string, value?: string | string[]): void => {
  // Can only update query params on client side
  if (typeof window === 'undefined') {
    return;
  }

  const url = new URL(window.document?.location?.toString());
  const params = url.searchParams;
  const pathname = window.document?.location?.pathname;

  if (!param) {
    return;
  }

  if (!value) {
    params.delete(param);
  }

  if (Array.isArray(value)) {
    params.set(param, value.join(','));
  }
  if (value && !Array.isArray(value)) {
    params.set(param, value);
  }

  const paramString = params.toString();

  window.history.pushState(
    '',
    window.document.title,
    `${pathname}${paramString && '?'}${paramString}`,
  );
};
