import React from 'react';
import { ViewMore } from '@domain-group/fe-co-expander';

import * as styles from './profile-descriptions.style';

/*
  WARNING! Using `dangerouslySetInnerHTML` because the Agency can enter HTML into their
  description on the Agency Profile edit page in Agent Admin. But you must sanitize the
  `details` prop before passing it in to protect against XSS attacks!
*/

interface ProfileDescriptionProps {
  profileDescriptionMaxLength?: number;
  text: string;
  name: string;
  onReadMore?: () => void;
}

/* eslint-disable react/no-danger */
const ProfileDescription = ({
  name,
  text,
  profileDescriptionMaxLength = 800,
  onReadMore = () => undefined,
}: ProfileDescriptionProps): JSX.Element => {
  const [readMore, setReadMore] = React.useState(true);
  const handleExpandToggle = () => {
    if (readMore) {
      onReadMore?.();
    }
    setReadMore(!readMore);
  };

  return (
    <article css={styles.profileDescription} data-testid="profile-description">
      <h2 css={styles.title} data-testid="agent-profiles__title">
        About {name}
      </h2>
      <ViewMore
        isDisabled={text.length < (profileDescriptionMaxLength || 0)}
        expandedHeight={350}
        onExpandToggle={handleExpandToggle}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </ViewMore>
    </article>
  );
};

export default ProfileDescription;
