import React from 'react';
import Link from 'next/link';
import { formatCurrency } from '../../../../utils';

import * as styles from './summary.styles';

export type Props = {
  isAgent: boolean;
  name?: string;
  agencyName: string;
  agencyUrl?: string;
  totalSoldAndAuctioned: number;
  averageSoldPrice: number;
  totalSoldPrice: number;
  children?: JSX.Element | null;
};

const SummarySales = ({
  isAgent,
  name,
  agencyName,
  agencyUrl = '',
  totalSoldAndAuctioned,
  averageSoldPrice,
  totalSoldPrice,
  children,
}: Props): JSX.Element => (
  <p css={styles.paragraph}>
    {isAgent ? (
      <span>
        {name} from{' '}
        <Link href={agencyUrl}>
          <a href={agencyUrl}>{agencyName}</a>
        </Link>{' '}
        has{' '}
      </span>
    ) : (
      <span>The team at {agencyName} have </span>
    )}
    sold{' '}
    <b>
      {totalSoldAndAuctioned}{' '}
      {totalSoldAndAuctioned === 1 ? 'property' : 'properties'}
    </b>{' '}
    in the last <b>12 months</b>
    {totalSoldAndAuctioned > 1 && averageSoldPrice > 0 && totalSoldPrice > 0 ? (
      <>
        , with an average sale price of {formatCurrency(averageSoldPrice)} and
        total sales valued at {formatCurrency(totalSoldPrice)}*
      </>
    ) : (
      ''
    )}
    . {children}
  </p>
);

export default SummarySales;
