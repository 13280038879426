import { css, SerializedStyles } from '@emotion/react';
import { media, tokens, applyFont } from '@domain-group/fe-brary';
import { PROFILE_TIERS } from '../../../constants';

const {
  colors: { neutral },
  typography,
  spacing,
} = tokens;

export const agencyDetails = css`
  text-align: center;

  ${media('>=tablet')} {
    text-align: left;
  }
`;

export const agencyNameLarge = ({
  profileTier,
}: {
  profileTier: PROFILE_TIERS;
}): SerializedStyles => css`
  ${applyFont(typography.subheading)}
  display: inline-block;
  margin-bottom: ${spacing.none};
  color: ${neutral.heavy.default};

  ${(profileTier === PROFILE_TIERS.branded ||
    profileTier === PROFILE_TIERS.platinum) &&
  css`
    ${applyFont(typography.heading)}
  `};

  ${media('>=tablet')} {
    ${applyFont(typography.heading)}
  }
`;
