import React from 'react';
import { concatenateArrayToString } from '../../../../utils';

import * as styles from './summary.styles';

export type Props = {
  name: string;
  house?: number;
  unit?: number;
  land?: number;
  rural?: number;
  totalSold: number;
  totalAuctioned: number;
  averageDaysOnMarket: number;
};

const SummarySegment = ({
  name,
  house = 0,
  unit = 0,
  land = 0,
  rural = 0,
  totalSold,
  totalAuctioned,
  averageDaysOnMarket,
}: Props): JSX.Element => {
  const soldTypes: string[] = [];
  const landAndRuralCount = land + rural;

  if (house > 0) {
    soldTypes.push(`${house} ${house === 1 ? 'house' : 'houses'}`);
  }
  if (unit > 0) {
    soldTypes.push(`${unit} ${unit === 1 ? 'apartment' : 'apartments'}`);
  }
  if (landAndRuralCount > 0) {
    soldTypes.push(
      `${landAndRuralCount} ${
        landAndRuralCount === 1 ? 'block' : 'blocks'
      } of land or rural ${
        landAndRuralCount === 1 ? 'property' : 'properties'
      }`,
    );
  }

  const combinedTotal = totalSold + totalAuctioned;

  const salesAffix = totalSold > 0 ? 'by private treaty' : '';
  const auctionAffix = totalAuctioned > 0 ? 'by auction' : '';
  const salesPlural = (number: number) => (number > 1 ? 'sales' : 'sale');
  const conjunction = combinedTotal >= 2 ? 'and ' : '';

  const soldLines = (
    <>
      {totalSold > 0 && totalAuctioned > 0
        ? ` with ${totalSold} ${salesPlural(
            totalSold,
          )} ${salesAffix} and ${totalAuctioned} ${auctionAffix}`
        : ` ${salesAffix}${auctionAffix}`}
    </>
  );

  return (
    <p css={styles.paragraph}>
      {name} sold {concatenateArrayToString(soldTypes)}
      {combinedTotal >= 2 ? soldLines : ` ${salesAffix}${auctionAffix}`},{' '}
      {conjunction}
      {combinedTotal >= 2 ? 'these properties were' : 'this property was'} on
      the market for {combinedTotal >= 2 ? 'an average of ' : ''}
      {Math.floor(averageDaysOnMarket)} days.
    </p>
  );
};

export default SummarySegment;
