import { css } from '@emotion/react';
import { applyFont, tokens, media } from '@domain-group/fe-brary';

const { colors, spacing, typography } = tokens;

export const sortByWrap = css`
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;

  ${media('>=desktop')} {
    flex-direction: row;
  }
`;

export const sortBy = css`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const sortByIcon = css`
  display: flex;
  margin-right: ${spacing.xxs};
`;

export const sortByTitle = css`
  margin-right: ${spacing.xxs};
`;

export const sortByButton = css`
  vertical-align: middle;
  background: transparent;
  display: inline-block;
  border: 0;
  margin: 0;
  padding: 0;
  color: ${colors.neutral.medium.default};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.interactive.base.default};
  }
`;

/* Modal */
export const sortByModal = css`
  padding: ${spacing.xl};
`;

export const sortByHeading = css`
  ${applyFont(typography.heading)}
  color: ${colors.neutral.heavy.default};
  margin-bottom: ${spacing.m};
`;

export const sortByParagraph = css`
  ${applyFont(typography.body)}
  margin-bottom: ${spacing.s};
  color: ${colors.neutral.heavy.default};
`;

export const sortByLink = css`
  font-weight: bold;
  color: ${colors.neutral.heavy.default};
  text-decoration: none;
  vertical-align: middle;

  &:hover {
    color: ${colors.interactive.base.default};
    text-decoration: underline;
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.neutral.heavy.default};
  }
`;

export const sortByList = css`
  color: ${colors.neutral.heavy.default};
  margin-bottom: ${spacing.m};

  li {
    padding-left: ${spacing.s};
    position: relative;
    ${applyFont(typography.body)}
    margin-bottom: ${spacing.s};

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: ${spacing.xxs};
      height: ${spacing.xxs};
      border-radius: 50%;
      background-color: ${colors.interactive.base.default};
    }
  }
`;
