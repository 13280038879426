// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pickby
// eslint-disable-next-line @typescript-eslint/ban-types
export function pickBy(object: object): object {
  const obj = {};
  Object.entries(object).forEach((key, val) => {
    if (val) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj[key] = val;
    }
  });
  return obj;
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle
export function throttle(func: () => void, timeFrame: number) {
  let lastTime = 0;
  return (...args: any) => {
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (now - lastTime >= timeFrame) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      func(...args);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lastTime = now;
    }
  };
}

export function debounce<A = unknown, R = void>(
  callback: (args?: A) => R,
  timeout: number,
): [(args?: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args?: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(callback(args));
      }, timeout);
    });

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_times
export const times = (num: number, fun: (index: number) => void) =>
  [...Array(num).keys()].forEach((index) => fun(index));

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_upperfirst
export const upperFirst = (string: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

// Lodash alternative for Factories
// https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6#gistcomment-3889214
export const merge = (source = {}, target = {}) => {
  Object.entries(source).forEach((key, val) => {
    if (val !== null && typeof val === `object`) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (target[key] === undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign,no-proto
        target[key] = new val.__proto__.constructor();
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      merge(val, target[key]);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      target[key] = val;
    }
  });
  return target; // we're replacing in-situ, so this is more for chaining than anything else
};
