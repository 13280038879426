import React, { ReactNode } from 'react';
import * as styles from './hero-banner.style';

export type Props = {
  backgroundImage?: string;
  colour?: string;
  isPlatinum?: boolean;
  renderComponent?: ReactNode;
  children?: ReactNode;
};

const HeroBanner = ({
  backgroundImage,
  colour,
  isPlatinum = false,
  renderComponent = null,
  children,
}: Props): JSX.Element => (
  <div
    css={styles.banner({
      backgroundImage,
      borderColour: colour,
    })}
    data-testid="trade-profile-hero__banner"
  >
    <div css={styles.bannerContainer}>
      <>
        {renderComponent}
        <div css={styles.bannerContents(isPlatinum)}>{children}</div>
      </>
    </div>
  </div>
);

export default HeroBanner;
