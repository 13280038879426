import React from 'react';

import { formatDate } from '../../../../utils';
import * as styles from './intro.style';

export type Props = {
  name?: string;
  children?: JSX.Element | JSX.Element[];
};

const Intro = ({ name, children }: Props): JSX.Element => (
  <>
    <h2 css={styles.title} data-testid="sold-stats__stats-title">
      {name} sales statistics
    </h2>
    <p css={styles.subtitle} data-testid="sold-stats__stats-subtitle">
      12 month period to {formatDate()}
    </p>
    {children}
  </>
);

export default Intro;
