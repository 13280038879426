import { css } from '@emotion/react';
import { tokens } from '@domain-group/fe-brary';

export { filterHeading } from '../filter-headings.style';

export * from '../filters-bar.style';

export const formSection = css`
  border-bottom: 1px solid ${tokens.colors.neutral.trim.default};
`;
